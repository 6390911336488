import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Grid, Container } from '@mui/material';
import NavBar from '../../../components/NavBar';
import instance from '../../../globals/axios';

const EditEmpCompany = () => {
  const [id, setId] = useState(1);
  const [name, setName] = useState("");
  const [panNum, setPanNum] = useState("");
  const [gstNum, setGstNum] = useState("");
  const [gstVerified, setGstVerified] = useState(false);
  const [createdAt, setCreatedAt] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [verified, setVerified] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [companyBrief, setCompanyBrief] = useState("");
  const [services, setServices] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [parentCompany, setParentCompany] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [website, setWebsite] = useState("");
  const [socialMediaPage, setSocialMediaPage] = useState("");
  const [socialMediaPlatformAndLinks, setSocialMediaPlatformAndLinks] = useState(null);
  const [iVeriId, setIVeriId] = useState("");
  const [admin, setAdmin] = useState(3);

  useEffect(() => {
    const company_id = localStorage.getItem('company_id');
    instance.get(`company/${company_id}/`)
      .then(response => {
        const data = response.data;
        setId(data.id);
        setName(data.name);
        setPanNum(data.pan_num);
        setGstNum(data.gst_num);
        setGstVerified(data.gst_verified);
        setCreatedAt(data.created_at);
        setUpdatedAt(data.updated_at);
        setVerified(data.verified);
        setIsActive(data.is_active);
        setCompanyBrief(data.company_brief);
        setServices(data.services);
        setLocation(data.location);
        setType(data.type);
        setParentCompany(data.parent_company);
        setAddress(data.address);
        setLogo(data.logo);
        setWebsite(data.website);
        setSocialMediaPage(data.social_media_page);
        setSocialMediaPlatformAndLinks(data.social_meida_platform_and_links);
        setIVeriId(data.iVeri_id);
        setAdmin(data.admin);
      })
      .catch(error => {
        console.error('Error fetching company data:', error);
      });
  }, []);

  const handleUpdateCompany = () => {
    const company_id = localStorage.getItem('company_id');
    instance.put(`company/${company_id}/`, {
      // id,
      // name,
      // pan_num: panNum,
      // gst_num: gstNum,
      // gst_verified: gstVerified,
      // created_at: createdAt,
      // updated_at: updatedAt,
      // verified,
      // is_active: isActive,
      company_brief: companyBrief,
      services: services,
      location: location,
      type: type,
      parent_company: parentCompany,
      address: address,
      logo: logo,
      website: website,
      social_media_page: socialMediaPage,
      social_meida_platform_and_links: socialMediaPlatformAndLinks,
      // iVeri_id: iVeriId,
      // admin,
    })
      .then(response => {
        console.log('Company updated successfully:', response.data);
        // Add any success message or redirection logic as needed
      })
      .catch(error => {
        console.error('Error updating company:', error);
      });
  };

  return (

    <>
    <NavBar />
    <Container sx={{ marginTop: "110px", p:2}}>
      <h2>Edit Company</h2>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Company ID"
            value={id}
            disabled
            onChange={e => setId(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            disabled
            onChange={e => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="PAN Number"
            value={panNum}
            disabled
            onChange={e => setPanNum(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="GST Number"
            value={gstNum}
            disabled
            onChange={e => setGstNum(e.target.value)}
          />
        </Grid>
    
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Created At"
            value={createdAt}
            disabled
            onChange={e => setCreatedAt(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Updated At"
            value={updatedAt}
            disabled
            onChange={e => setUpdatedAt(e.target.value)}
          />
        </Grid>
        
        
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Company Brief"
            value={companyBrief}
            onChange={e => setCompanyBrief(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Services"
            value={services}
            onChange={e => setServices(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Location"
            value={location}
            onChange={e => setLocation(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Type"
            value={type}
            onChange={e => setType(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Parent Company"
            value={parentCompany}
            onChange={e => setParentCompany(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Address"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Logo"
            value={logo}
            onChange={e => setLogo(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Website"
            value={website}
            onChange={e => setWebsite(e.target.value)}
          />
        </Grid>
        </Grid>
      
        <Button variant="contained" color="primary" onClick={handleUpdateCompany}>
        Save Changes
      </Button>
    </Container>
    </>
  );
};

export default EditEmpCompany;
