
// export function isAuthenticated() {
//   const token = getToken();
//   return token !== null;
// }

export function isAuthenticated() {
  return window.localStorage.getItem("access_token") !== undefined && window.localStorage.getItem("access_token") !== null;
}

export function getPassWord() {
  return encodeURI(getCookie('pass'));
}

export function setPasswordCookie(pass) {
  setCookie('pass', pass, 5);
}

export function logOut() {
  deleteCookie('pass');
}

function setCookie(cname, cvalue, hour) {
  const d = new Date();
  d.setTime(d.getTime() + (5 * hour * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function deleteCookie(name) {
  document.cookie = '';
}

export function setPasswordLocalStorage(pass) {
  console.log('settinglocalpss')
}

export function getPasswordLocalStorage() {
  return localStorage.getItem('encPass')
}

export function clearPasswordLocalStorage() {
  return localStorage.removeItem('encPass')
}

export function saveAuth(token) {
  if (token !== undefined)
    saveToLocal('auth', token)
  else
    window.sessionStorage.removeItem("auth");
}

export function saveToLocal(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getToken() {
  let authJson = window.sessionStorage.getItem('access_token');
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return auth['token'];
  }
  return null;
}