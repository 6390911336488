import React, { useState } from "react";
import { TextField, Container, Grid, Typography, Alert } from "@mui/material";
import logo from "../../src/assets/iveri.png";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ForgotPasswordPic from "../assets/forgot.png";
import CustomButton from "../components/Button/CustomButtom";
import axios from "axios";
import { SERVER_BASE_ADDRESS } from "../globals/const";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMsg(""); // Clear any previous error

    try {
      const response = await axios.post(`${SERVER_BASE_ADDRESS}user/forgot-password/`, { email });
      if (response.status === 200) {
        setLinkSent(true);
      }
    } catch (error) {
      setErrorMsg("An error occurred. Please try again."); // Display error message
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      fullWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "space-between",
      }}>
      <Grid container mt="10%" justifyContent="center">
        <Grid item xs={12} md={5}>
          <ArrowBackIosIcon
            onClick={handleBack}
            sx={{
              position: "absolute",
              top: 25,
              left: 10,
              border: "2px solid black",
              borderRadius: "100%",
              padding: "1px",
              paddingLeft: "6px",
              color: "black",
              height: "15px",
              width: "11px",
            }}
          />
          <Grid flex xs={6} sx={{ mx: "auto", position: "relative" }}>
            <img alt="iVeri logo" src={logo} style={{ width: "100%" }} />
          </Grid>
          <Typography sx={{ fontSize: "30px", fontWeight: "600", pt: 2 }}>
            Forgot Password
          </Typography>
          <Grid container justifyContent="center" my={10}>
            <img
              alt="Forgot Password"
              src={ForgotPasswordPic}
              style={{ width: "100px", height: "auto" }}
            />
          </Grid>

          {linkSent ? (
            <Alert severity="success" sx={{ mb: 3 }}>
              Password reset link sent successfully!
            </Alert>
          ) : (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container mt={2}>
                <Typography sx={{ color: "#596574", fontSize: "10px", py: 2 }}>
                  Enter Email ID or Phone no. for Forgot Password
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>Email or Phone no.</Typography>
                <TextField
                  fullWidth
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    borderRadius: "5px",
                    height: "45px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                      "& input": {
                        padding: "10px 14px",
                      },
                    },
                  }}
                />
              </Grid>
              {errorMsg && (
                <Typography sx={{ color: "red", fontSize: "12px", mt: 2 }}>{errorMsg}</Typography>
              )}
            </form>
          )}
        </Grid>
      </Grid>

      {!linkSent && (
        <Grid container justifyContent="center" mt={4} mb={2}>
          <CustomButton onClick={handleSubmit} loading={loading}>
            {loading ? "Sending..." : "Send Reset Link"}
          </CustomButton>
        </Grid>
      )}
    </Container>
  );
};

export default ForgotPassword;
