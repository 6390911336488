import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { isAuthenticated } from "./globals/authentication";

//////for user/////
// import Login from './screens/User/Login';
// import Register from './screens/User/Register'
import Login from "./screens/Login";
import Register from "./screens/Register";
import Test from "./screens/User/EditPages/TestPage";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Verify from "./screens/VerifyUserCreds";
// Above three links are newly added

// For User
import StartPage from "./screens/User/StartPage";
import ShareableViewProfile from "./screens/User/ShareableViewProfile";
import ViewProfile from "./screens/User/ViewProfile";
import ProfileEditMenu from "./screens/User/ProfileEditMenu";
import LinkDigilocker from "./screens/User/LinkDigilocker";
import LanguageProficiency from "./screens/User/EditPages/Lang";
import ExperiencePage from "./screens/User/EditPages/ExperienceDetails";
import Skills from "./screens/User/EditPages/Skills";
import DocumentLocker from "./screens/User/EditPages/DocumentLocker";
import Timeline from "./screens/User/EditPages/Timeline";
import EducationDetail from "./screens/User/EditPages/EducationDetail";
import BasicInfo from "./screens/User/EditPages/BasicInfo";
import Address from "./screens/User/EditPages/Address";
import TempAddress from "./screens/User/EditPages/TempAddress";
import AdditionalInfo from "./screens/User/EditPages/AdditionalInfo";
import ProfilePage from "./screens/User/ProfilePage";
import ShareProfile from "./screens/User/ShareProfile";
import ShareProfileLink from "./screens/User/ShareProfileLink"
import MyApplications from "./screens/User/MyApplications";
import ResumePreview from "./screens/User/ResumePreview";
import PersonalDetailsInfo from "./screens/User/EditPages/PersonalDetailsInfo";
import JobDetailView from "./screens/User/ViewPage/JobDetailView";
import EducationViewPage from "./screens/User/ViewPage/EducationViewProfile";
import ExperienceViewPage from "./screens/User/ViewPage/ExperienceViewProfile";
import AddressViewPage from "./screens/User/ViewPage/AddressView";
import ProfileHeader from "./components/ProfileHeader";
import LanguageProficiencyView from "./screens/User/ViewPage/LanguageProficiencyView";
import SkillView from "./screens/User/ViewPage/SkillView";
import ViewShareProfileWithDoc from "./screens/User/ViewShareProfileWithDoc"
import ShareProfileDoc from "./screens/User/ShareProfile/ShareProfileDoc";
import ViewShareProfileWithoutDoc from "./screens/User/ViewShareProfileWithoutDoc"
import ShareProfileWithoutDoc from "./screens/User/ShareProfile/ShareProfileWithoutDoc";
import CreateSubscription from "./screens/suscription/CreateSubscription";
import MySubscriptions from "./screens/suscription/MySubscriptions";
import PaymentPage from "./screens/User/PaymentPage";
import LinkGeneratedDoc from "./screens/User/ShareProfile/LinkGeneratedDoc";
import OnboardingPage from "./screens/User/OnboardingPage";
import LinkGeneratedWithoutDoc from "./screens/User/ShareProfile/LinkGeneratedWithoutDoc";

/////for Agency/////
import RoleAgency from "./screens/Agency/RoleAgency";
import PersonalDetails from "./screens/Agency/ViewPages/PersonalDetails";
import EducationalDetails from "./screens/Agency/ViewPages/EducationDetails";
import Viewpage from "./screens/Agency/ViewPages/ViewPage";
import ProffesionalDetails from "./screens/Agency/ViewPages/ProffesionalDetails";
import EditEducational from "./screens/Agency/EditPages/EditEducational";
import EditProfessional from "./screens/Agency/EditPages/EditProfessional";
import BankingDetails from "./screens/Agency/Homepage/BankingDetails";
import CompanyProfile from "./screens/Agency/Homepage/CompanyProfile";
import DashboardDetails from "./screens/Agency/Homepage/DashboardDetails";
import Menu from "./screens/Agency/Homepage/Menu";
import UserManagement from "./screens/Agency/Homepage/UserManagement";
import AgencyLogin from "./screens/Agency/LoginPage/AgencyLogin";
import ForgotID from "./screens/Agency/LoginPage/ForgotID";
import SignUp from "./screens/Agency/LoginPage/SignUp";
// import Verification from './screens/Agency/LoginPage/Verification';
import EditCompany from "./screens/Agency/EditPages/EditCompany";
import EditPersonal from "./screens/Agency/EditPages/EditPersonal";
import EditSendCompany from "./screens/Agency/EditPages/EditCompany";
import EditSendEducational from "./screens/Agency/EditPages/EditEducational";
import EditSendProfessional from "./screens/Agency/EditPages/EditProfessional";
import VerifierHomepage from "./screens/Agency/VerifierPages/VerifierHomepage";
import CompanyForm from "./components/CompanySignup";
import AdditionalViewPage from "./screens/User/ViewPage/ViewAddionalPage";
import PersonalInfoViewPage from "./screens/User/ViewPage/PersonalInfoViewPage";
import BasicInfoViewPage from "./screens/User/ViewPage/BasicInfoViewPage";

/////for Employer/////
import RoleEmployer from "./screens/Employer/RoleEmployer";
import EmpSignup from "./screens/Employer/EmpSignup";
import EmpMenu from "./screens/Employer/Homepage/EmpMenu";
import EmpCompanyProfile from "./screens/Employer/Homepage/EmpCompanyProfile";
import EmpUserManagement from "./screens/Employer/Homepage/EmpUserManagement";
import EmpBankingDetaiils from "./screens/Employer/Homepage/EmpBankingDetails";
import EditCompanyProfile from "./screens/Employer/Homepage/EditCompanyProfile";
import JDCreator from "./screens/Employer/JDCreator";
import AiJDCreator from "./screens/Employer/AiJDCreator";

import HmMenu from "./screens/Employer/HiringManagerPage/HmMenu";
import HmRequiredApproved from "./screens/Employer/HiringManagerPage/HmRequiredApproved";
import HmViewApplicant from "./screens/Employer/HiringManagerPage/HmViewApplicant";
import HmViewEdit from "./screens/Employer/HiringManagerPage/HmViewEdit";
import HmNewRequirement from "./screens/Employer/HiringManagerPage/HmNewRequirement";
import JDParser from "./screens/Employer/JDParser";

import CVpool from "./screens/Employer/CVpool";
import JDParserUpdate from "./screens/Employer/JDParserUpdate";
import EmpDashboardDetails from "./screens/Employer/Homepage/EmpDashboardDetails";
import CompanyStructure from "./screens/Employer/Homepage/CompanyStructure";
import HRHomepage from "./screens/Employer/HumanResources/HRHomepage";
import HRProfile from "./screens/Employer/HumanResources/HRProfile";
import HRNewRequirement from "./screens/Employer/HumanResources/HRNewRequirement";
import HRRequiredApproval from "./screens/Employer/HumanResources/HRRequirementApproval";
import HRRequiredApproved from "./screens/Employer/HumanResources/HRRequirementApproved";
import LinkCompany from "./screens/Employer/Homepage/LinkCompany";
import UpdateRequirements from "./screens/Employer/HumanResources/UpdateRequirements";
import PublishRequirements from "./screens/Employer/HumanResources/PublishRequirement";
import HMViewRequirements from "./screens/Employer/HiringManagerPage/ViewRequirements";
import HRViewRequirements from "./screens/Employer/HumanResources/ViewRequirements";

import ViewCandidates from "./screens/Employer/ViewCandidate";
import ViewCandidatesDetails from "./screens/Employer/ViewCandidateDetails";
import ViewApplicantions from "./screens/Employer/ViewApplications";

import ExperienceCards from "./screens/User/ExperienceView";
import EducationCards from "./screens/User/EducationView";
import EditEducation from "./screens/User/EditPages/EditEducation";
import EditExperience from "./screens/User/EditPages/EditExperience";
import ResumeExtractor from "./screens/ResumeParser";
import ViewJobPosts from "./screens/User/ViewJobPosts";
import ViewJobPostDetails from "./screens/User/ViewJobPostDetails";
import ViewReqByID from "./screens/Employer/ViewReqByID";
import ViewApplicationById from "./screens/Employer/ViewApplicationByID";
import ViewClosedRequirements from "./screens/Employer/ViewClosedRequirements";

import AddNewUser from "./screens/Recruiter/AddNewUser";
import RecruiterMenu from "./screens/Recruiter/RecruiterMenu";
import RecruiterProfile from "./screens/Recruiter/RecruiterProfile";
import RecruiterViewUsers from "./screens/Recruiter/ViewUsers";

import ChangePhoneNumber from "./screens/ChangePhoneNumber";
import ResumeMatching from "./screens/Employer/ResumeMatching";
import InterviewScreen from "./screens/User/InterviewScreen";
import InterviewDAReport from "./screens/Employer/InterviewDAReport";
import ViewInterviews from "./screens/Employer/ViewInterviews";
import MultipleResume from "./screens/Employer/MultipleResume";
import MyUploads from "./screens/Employer/UploadedDocs";
import MatchingDetails from "./screens/Employer/MatchingDetails";
import VerifyEmail from "./screens/User/VerifyEmail";
import VerifyMobile from "./screens/User/VerifyMobile";



export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={StartPage} />
      <Route exact path="/login" component={Login} />
      <Route path="/sign-up" component={Register} />
      <Route path="/company-signup" component={CompanyForm} />
      <Route path="/iveri-profile/:iveri_id" component={ShareableViewProfile} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/verify" component={Verify} />
      <Route path="/verify-email" component={VerifyEmail} />
      <Route path="/verify-mobile" component={VerifyMobile} />
      <Route path="/resume-parser" component={ResumeExtractor} />
      <Route path="/view-job/:req_id" component={ViewReqByID} />
      <Route path="/change-phone-number" component={ChangePhoneNumber} />
      <Route path="/interview/:interview_id" component={InterviewScreen} />
      <Route path="/share-profile-without-docs/:uuid" component={ShareProfileWithoutDoc} />
      <Route path="/share-profile-docs/:uuid" component={ShareProfileDoc} />


      {isAuthenticated() ? (
        <>
          <Route path="/test" component={Test} />
          <Route path="/view-profile" component={ViewProfile} />
          <Route path="/edit-profile-menu" component={ProfileEditMenu} />
          <Route path="/edit-language-proficiency" component={LanguageProficiency} />
          <Route path="/edit-language-proficiencies/:id" component={LanguageProficiency} />
          <Route path="/edit-experience-details" component={ExperiencePage} />
          <Route path="/edit-experience-detail/:id" component={ExperiencePage} />
          <Route path="/edit-education-details" component={EducationDetail} />
          <Route path="/edit-education-detail/:id" component={EducationDetail} />
          <Route path="/edit-skills" component={Skills} />
          <Route path="/edit-skill/:id" component={Skills} />
          <Route path="/edit-document-locker" component={DocumentLocker} />
          <Route path="/edit-basic-info" component={BasicInfo} />
          <Route path="/basic-info-view" component={BasicInfoViewPage} />
          <Route path="/personal-details-info" component={PersonalDetailsInfo} />
          <Route path="/personal-details-infos/:id" component={PersonalDetailsInfo} />
          <Route path="/personal-details-view" component={PersonalInfoViewPage} />
          <Route path="/edit-address" component={Address} />
          <Route path="/edit-addres/:id" component={Address} />
          <Route path="/edit-temp-address" component={TempAddress} />
          <Route path="/edit-temp-addres/:id" component={TempAddress} />
          <Route path="/edit-additional-info" component={AdditionalInfo} />
          <Route path="/edit-additional-infos/:id" component={AdditionalInfo} />
          <Route path="/timeline" component={Timeline} />
          <Route path="/view-education" component={EducationCards} />
          <Route path="/view-experience" component={ExperienceCards} />
          <Route path="/share-profile" component={ShareProfile} />
          <Route path="/update-education-details/:id" component={EditEducation} />
          <Route path="/update-experience-details/:id" component={EditExperience} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/link-digilocker" component={LinkDigilocker} />
          <Route path="/apply-jobs" component={ViewJobPosts} />
          <Route path="/apply-jobs-details/:id" component={ViewJobPostDetails} />
          <Route path="/viewpage" component={Viewpage} />
          <Route path="/education-details" component={EducationalDetails} />
          <Route path="/professional-details" component={ProffesionalDetails} />
          <Route path="/Personal-Details" component={PersonalDetails} />
          {/* <Route path="/interview/:interview_id" component={InterviewScreen} /> */}
          <Route path="/report/:interview_id" component={InterviewDAReport} />
          <Route path="/view-interviews" component={ViewInterviews} />
          <Route path="/resume-preview" component={ResumePreview} />
          <Route path="/job/:id" component={JobDetailView} />
          <Route path="/education-viewpage" component={EducationViewPage} />
          <Route path="/experience-viewpage" component={ExperienceViewPage} />
          <Route path="/address-viewpage" component={AddressViewPage} />
          <Route path="/profile" component={ProfileHeader} />
          <Route path="/additional-viewpage" component={AdditionalViewPage} />
          <Route path="/language-viewpage" component={LanguageProficiencyView} />
          <Route path="/skill-viewpage" component={SkillView} />
          <Route path="/share-profile-link" component={ShareProfileLink} />
          <Route path="/share-profile-doc" component={ViewShareProfileWithDoc} />
          <Route path="/share-profile-without-doc" component={ViewShareProfileWithoutDoc} />
          <Route path="/create-suscription" component={CreateSubscription} />
          <Route path="/my-subscriptions" component={MySubscriptions} />
          <Route path="/payment-page" component={PaymentPage} />
          <Route path="/link-gen-docs" component={LinkGeneratedDoc} />
          <Route path="/link-gen-without-docs" component={LinkGeneratedWithoutDoc} />
          <Route path="/onboarding" component={OnboardingPage} />
          
          
        
          <Route path="/role-agency" component={RoleAgency} />
          <Route path="/edit-personal/:verification_id" component={EditPersonal} />
          <Route path="/edit-educational" component={EditEducational} />
          <Route path="/edit-professional" component={EditProfessional} />
          <Route exact path="/verification/:verification_id" component={EditCompany} />
          <Route path="/my-applications" component={MyApplications} />

          <Route path="/banking-details" component={BankingDetails} />
          <Route path="/dashboard-details" component={DashboardDetails} />
          <Route path="/company-profile" component={CompanyProfile} />
          <Route path="/agency-menu" component={Menu} />
          <Route path="/user-management" component={UserManagement} />
          <Route path="/AgencyLogin" component={AgencyLogin} />
          <Route path="/ForgotID" component={ForgotID} />
          <Route path="/SignUp" component={SignUp} />
          <Route path="/edit-send-company" component={EditSendCompany} />
          <Route path="/edit-send-educational" component={EditSendEducational} />
          <Route path="/edit-send-professional" component={EditSendProfessional} />

          <Route path="/verifier-homepage" component={VerifierHomepage} />
          <Route path="/role-employer" component={RoleEmployer} />
          <Route path="/emp-signup" component={EmpSignup} />
          <Route path="/emp-menu" component={EmpMenu} />
          <Route path="/emp-company-profile" component={EmpCompanyProfile} />
          <Route path="/emp-user-management" component={EmpUserManagement} />
          <Route path="/emp-banking-details" component={BankingDetails} />
          <Route path="/emp-link-company" component={LinkCompany} />
          <Route path="/edit-emp-company" component={EditCompanyProfile} />

          <Route path="/hiring-manager" component={HmMenu} />
          <Route path="/view-hm-requirements" component={HMViewRequirements} />
          <Route path="/hm-view-edit" component={HmViewEdit} />
          <Route path="/requirement-approved" component={HmRequiredApproved} />
          <Route path="/view-applicant" component={HmViewApplicant} />
          <Route path="/new-requirement" component={HmNewRequirement} />
          <Route path="/jd-creator" component={JDCreator} />
          <Route path="/ai-jd-creator" component={AiJDCreator} />
          <Route path="/jd-parser" component={JDParser} />
          <Route path="/cv-pool" component={CVpool} />
          <Route path="/jd-parser-update" component={JDParserUpdate} />

          <Route path="/emp-dashboard-details" component={EmpDashboardDetails} />
          <Route path="/company-structure" component={CompanyStructure} />
          <Route path="/human-resources" component={HRHomepage} />
          <Route path="/hr-profile" component={HRProfile} />
          <Route path="/hr-view-requirements" component={HRViewRequirements} />
          <Route path="/hr-new-requirement" component={HRNewRequirement} />
          <Route path="/hr-req-approval/:req_id" component={HRRequiredApproval} />
          <Route path="/hr-req-approved" component={HRRequiredApproved} />
          <Route path="/view-candidates" component={ViewCandidates} />
          <Route path="/view-candidates-details/:iveri_id" component={ViewCandidatesDetails} />
          <Route path="/publish-requirements/:req_id" component={PublishRequirements} />
          <Route path="/view-applications" component={ViewApplicantions} />
          <Route path="/view-application/:req_id" component={ViewApplicationById} />
          <Route path="/update-requirements/:req_id" component={UpdateRequirements} />
          <Route path="/view-closed-requirements" component={ViewClosedRequirements} />
          <Route path="/resume-matching" component={ResumeMatching} />
          <Route path="/batch-upload" component={MultipleResume} />
          <Route path="/my-uploads" component={MyUploads} />
          <Route exact path="/matching-details" component={MatchingDetails} />

          <Route path="/recruiter" component={RecruiterMenu} />
          <Route path="/recruiter-profile" component={RecruiterProfile} />
          <Route path="/add-new-user" component={AddNewUser} />
          <Route path="/view-users" component={RecruiterViewUsers} />
        </>
      ) : (
        <></>
      )}
    </BrowserRouter>
  );
}
