import { Box, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import afterDeleteImage from "../../assets/afterDelete.png"

const AddSectionPlaceholder = ({ message, buttonText, link, onClick }) => {
  const history = useHistory();

  return (
    <Box textAlign="center" mt={5}>
      <img src={afterDeleteImage} style={{ width: "90%", height: "90%" }} alt="Placeholder"/>
      <Typography variant="h6" color="#272260" fontWeight="bold" my={2}>
        Sorry, but it seems that {message} is missing. Could you please add it?
      </Typography>
      <Button
        onClick={onClick || (() => history.push(link))}
        mt={2}
        sx={{ border: "1px solid #666671", textTransform: "none", color: "#666671", fontSize: "24px" }}
        fullWidth
      >
        {buttonText || "+ Add"}
      </Button>
    </Box>
  );
};

export default AddSectionPlaceholder;
