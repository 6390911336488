import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Container,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { MoreHorizontal } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import carasoul1 from "../../assets/carasoul1.png"
import carasoul2 from "../../assets/carasoul2.png"
import carasoul3 from "../../assets/caraoul3.png"
import carasoul4 from "../../assets/carasoul4.png"

const CardBase = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  height: '250px',
}));

const CardBase1 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  height: '190px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const OnboardingCard = styled(CardBase)(({ theme }) => ({
  background: '#F8F5FF',
  marginBottom: theme.spacing(2),
}));

const ActionCard = styled(CardBase1)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const ProgressDot = styled(Box)(({ active }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: active ? '#1A1A1A' : '#E0E0E0',
  margin: '0 4px',
  cursor: 'pointer',
}));

const AUTOPLAY_DURATION = 3000;

const OnboardingPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  const carouselData = [
    {
      title: "Add Your Personal and Professional Details",
      description: "Begin by entering all your personal information and professional details in the app. This includes your name, contact information, work experience, education, and skills.",
      image: carasoul1
    },
    {
      title: "Upload your Documents",
      description: "Once your details are filled in, enhance your profile by uploading relevant documents, such as certificates, portfolios, or other credentials.",
      image: carasoul2
    },
    {
      title: "Create Your Digital Profile",
      description: "After entering your information and uploading documents, generate your comprehensive digital profile. This profile reflects your qualifications and achievements.",
      image: carasoul3
    },
    {
      title: "Share Your Profile",
      description: "Easily share your profile with potential employers or clients using a unique link. Choose from:\n\n• Profile with Documents\n• Profile without Documents",
      image: carasoul4
    }
  ];

  const handleCardClick = (path) => (event) => {
    // Prevent navigation if clicking on the more options button
    if (event.target.closest('.more-options-button')) {
      event.stopPropagation();
      return;
    }
    history.push(path);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep((prevStep) => 
        prevStep === carouselData.length - 1 ? 0 : prevStep + 1
      );
    }, AUTOPLAY_DURATION);

    return () => clearInterval(timer);
  }, [carouselData.length]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "30px" }}>
        <Box sx={{ flexGrow: 1, mb: 3 }}>
          <SwipeableViews
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {carouselData.map((slide, index) => (
              <OnboardingCard key={index} elevation={0}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                  <Grid item xs={8}>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#1A1A1A', mb: 1 }}>
                      {slide.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" 
                      sx={{ whiteSpace: 'pre-line' }}>
                      {slide.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                      component="img"
                      src={slide.image}
                      alt={`Step ${index + 1}`}
                      sx={{ 
                        width: '100%',
                        height: 'auto',
                        maxHeight: '160px',
                        objectFit: 'contain'
                      }}
                    />
                  </Grid>
                </Grid>
              </OnboardingCard>
            ))}
          </SwipeableViews>

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            {carouselData.map((_, index) => (
              <ProgressDot
                key={index}
                active={index === activeStep}
                onClick={() => handleStepChange(index)}
              />
            ))}
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ActionCard 
                elevation={0} 
                sx={{ backgroundColor: '#FAF3E0'}}
                onClick={handleCardClick('/basic-info-view')}
              >
                <Box sx={{ 
                  flex: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                  <IconButton 
                    className="more-options-button"
                    size="small" 
                    sx={{ 
                      position: 'absolute', 
                      top: -8, 
                      right: -8,
                      color: '#272260'
                    }}
                  >
                    <MoreHorizontal size={20} />
                  </IconButton>
                  <Typography sx={{ fontWeight: 800, mb: 1, color: "#272260", fontSize: "26px" }}>
                    Edit Profile
                  </Typography>
                  <Typography sx={{ fontWeight: 550, mb: 1, color: "#272260", fontSize: "15px" }}>
                    Enter your personal information and professional details
                  </Typography>
                </Box>
              </ActionCard>
            </Grid>
            <Grid item xs={6}>
              <ActionCard 
                elevation={0} 
                sx={{ backgroundColor: '#F5F5DC' }}
                onClick={handleCardClick('/view-profile')}
              >
                <Box sx={{ 
                  flex: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                  <IconButton 
                    className="more-options-button"
                    size="small" 
                    sx={{ 
                      position: 'absolute', 
                      top: -8, 
                      right: -8,
                      color: '#272260'
                    }}
                  >
                    <MoreHorizontal size={20} />
                  </IconButton>
                  <Typography sx={{ fontWeight: 800, mb: 1, color: "#272260", fontSize: "26px" }}>
                    View Profile
                  </Typography>
                  <Typography sx={{ fontWeight: 550, mb: 1, color: "#272260", fontSize: "15px" }}>
                    Explore your professional journey
                  </Typography>
                </Box>
              </ActionCard>
            </Grid>
            <Grid item xs={6}>
              <ActionCard 
                elevation={0} 
                sx={{ backgroundColor: '#E0F2F1' }}
                onClick={handleCardClick('/share-profile-link')}
              >
                <Box sx={{ 
                  flex: 1, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  position: 'relative'
                }}>
                  <IconButton 
                    className="more-options-button"
                    size="small" 
                    sx={{ 
                      position: 'absolute', 
                      top: -8, 
                      right: -8,
                      color: '#272260'
                    }}
                  >
                    <MoreHorizontal size={20} />
                  </IconButton>
                  <Typography sx={{ fontWeight: 800, mb: 1, color: "#272260", fontSize: "26px" }}>
                    Share Profile
                  </Typography>
                  <Typography sx={{ fontWeight: 550, mb: 1, color: "#272260", fontSize: "15px" }}>
                    Easily share your profile using a unique link
                  </Typography>
                </Box>
              </ActionCard>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default OnboardingPage;