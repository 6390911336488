import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@material-ui/core";
import { Container, Box } from "@mui/material";
import { styled } from "@mui/system";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "5px",
  },
  button: {
    color: theme.palette.primary.contrastText,
    borderRadius: "30px",
    backgroundColor: theme.palette.primary.main,
    padding: "2px 30px",
    fontWeight: "600",
    fontSize: "15px",
    marginTop: "12px",
  },
  container: {
    minHeight: "70vh",
    padding: "0 12px",
    border: `5px ${theme.palette.primary.main} solid`,
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  img: {
    width: "7rem",
  },
  nav: {
    minHeight: "10vh",
    display: "flex",
    alignItems: "center",
    marginTop: "5.5rem",
    border: `5px ${theme.palette.primary.main} solid`,
    padding: "0",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "5px",
  },
}));

export default function VerifierHomepage(props) {
  const classes = useStyles();
  const history = useHistory();

  const [requisitionType, setRequisitionType] = useState("");
  const [requisitionName, setRequisitionName] = useState("");
  const [bgvAssignedTo, setBgvAssignedTo] = useState("");
  const [candidateProfileLink, setCandidateProfileLink] = useState("");
  const [bgvCompletionStatus, setBgvCompletionStatus] = useState("");
  const [bgvRequisitionNumber, setBgvRequisitionNumber] = useState("");
  const [selectedBgvOption, setSelectedBgvOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const buttons = [
    {
      label: "Educational Details",
      path: "/edit-send-educational",
    },
    {
      label: "Professional Details",
      path: "/edit-send-professional",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const path = event.target.value.toLowerCase().replace(/\s/g, "-");
    history.push(`/${path}`);
  };

  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
            <Grid container spacing={2}>
            
            <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Below are the sections of which verification is needed</Typography>
            </Grid>
              <Grid item xs={12}>
              {buttons.map((button, index) => (
            <Button
              key={index}
              fullWidth
              component={Link}
              to={button.path}
              variant="contained"
              style={{ marginBottom: "8px" }}
            >
              {button.label}
            </Button>
          ))}
              </Grid>
            </Grid>
          


        </Container>
    </>
  );
}