import React from "react";
import { IconButton, Box, Grid, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTypography from "../Textfield/CustomTypography";
import { styled } from "@mui/material/styles";
import EditIcon from "../../assets/Icons/Edit.png"

// If DetailTypography is just Typography, define it as follows
const DetailTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#000",
}));

const ProfileCard = ({ title, data, onEdit, onDelete}) => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{

          paddingTop: 2,

        }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" sx={{fontWeight: "600"}}>
            {title}
          </Typography>
          <IconButton onClick={onEdit} sx={{ padding: '4px' }}>
            <img
              src={EditIcon}
              style={{ width: '24px', height: '24px' }}
              alt="Edit"
            />
          </IconButton>

        </Box>
        {Object.entries(data).map(([key, value]) => (
          <Box display="flex" alignItems="center" mb={0.8} key={key}>
            <CustomTypography>{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}</CustomTypography>
            <DetailTypography>{value || "N/A"}</DetailTypography>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default ProfileCard;
