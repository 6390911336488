import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const data = [
  {
    personName: "Gaurav Shah",
    contactNumber: "4567891231",
    email: "Gaurav@sample.com",
    relationWithCandidate: "Professional",
    verificationDocuments: "upload",
  },
];

const LabelContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: "rgb(0, 184, 230)",
  fontWeight: "600",
  marginRight: theme.spacing(1),
}));

const LabelField = ({ label, value }) => {
  const isSocialMediaLink = label === "Link of the Course";
  const isDocumentUpload = label === "Document uploaded";

  const handleLinkClick = () => {
    if (isSocialMediaLink) {
      window.open(value, "_blank");
    }
  };

  const handleDocumentClick = () => {
    if (isDocumentUpload) {
      window.open(value, "_blank", "fullscreen=yes");
    }
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <LabelContainer item>
      <FontAwesomeIcon icon={faCircleCheck} style={{color: "rgb(0, 184, 230)",}} />
        <LabelText>{label + ":"}</LabelText>
      </LabelContainer>
      {isSocialMediaLink ? (
        <Grid item>
          <a href={value} target="_blank" rel="noopener noreferrer">
            <Typography style={{ cursor: "pointer" }} onClick={handleLinkClick}>
              {value}
            </Typography>
          </a>
        </Grid>
      ) : isDocumentUpload ? (
        <Grid item>
          <Typography style={{ cursor: "pointer" }} onClick={handleDocumentClick}>
            {value}
          </Typography>
        </Grid>
      ) : (
        <Grid item>
          <Typography>{value}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const labelValuePairs = [
  { label: "Fixed Salary", value: "30 LPA" },
  { label: "Variable Salary", value: "05 LPA" },
  { label: "Accommodation", value: "Yes, Fully Furnished, Family" },
  { label: "Other Perks", value: "Travel, 2 Family Trips in India" },
  { label: "Current Company", value: "Birla Group" },
  { label: "Current Profile", value: "Finance Controller" },
  { label: "Current Designation", value: "General Manager" },
  { label: "Current Reporting to", value: "CFO" },
  { label: "Current Reportees", value: "3 Direct Reportees" },
  { label: "Work Location", value: "Ahmedabad" },
  { label: "Office Type", value: "Corporate Office" },
  { label: "Documents", value: "Offer_Letter.pdf" },
];

export default function ProfessionalDetails() {
  const classes = useStyles();
  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Grid container spacing={2}>
          {labelValuePairs.map(({ label, value }) => (
            <Grid item xs={12} key={label}>
              <LabelField label={label} value={value} />
            </Grid>
          ))}
        </Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="My Table">
            <TableHead>
              <TableRow>
                <TableCell>Person Name</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Relation with Candidate</TableCell>
                <TableCell>Verification Documents</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.personName}>
                  <TableCell component="th" scope="row">
                    {row.personName}
                  </TableCell>
                  <TableCell>{row.contactNumber}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.relationWithCandidate}</TableCell>
                  <TableCell>{row.verificationDocuments}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
    </>
  );
}
