import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Button, Typography } from '@mui/material';
import logo from "../../assets/iveri.png";
import { useHistory } from 'react-router-dom';

const StartPage = () => {
  const [boxVisible, setBoxVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setBoxVisible(true);
    }, 1000);
  }, []);

  return (
    <Container component="main" style={{ height: '100vh', overflow: 'hidden', position: 'relative' }}>
      <Grid 
        container 
        justifyContent="center" 
        alignItems="center" 
        style={{ 
          height: '100%', 
          transition: 'transform 1s ease-in-out',
          transform: boxVisible ? 'translateY(-25%)' : 'translateY(0)',
        }}
      >
        <Grid item xs={12} md={5} textAlign="center">
          <img alt="iVeri logo" src={logo} style={{ width: '270px', height: '145px', transition: 'all 1s ease-in-out' }} />
        </Grid>
      </Grid>

      <Box 
        sx={{
          position: 'fixed',
          bottom: boxVisible ? '0' : '-50%',
          left: 0,
          right: 0,
          height: '35vh',
          backgroundColor: '#EFEFF0',
          borderTopRightRadius: "40px",
          borderTopLeftRadius: "40px",
          transition: 'bottom 1s ease-in-out',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          px: 2,
          pt: 5, 
        }}
      >
        <Typography variant="h5" sx={{ mb: 3}}>
          Welcome Back!
        </Typography>
        <Button 
        variant="outlined"  
        sx={{ mb: 2, width: '100%', backgroundColor: "white", border: "none", color: 'black', height: "2.8em", fontSize: "17px", textTransform: 'none'}}
        onClick={() => history.push('/login')}
        >
          Login
        </Button>
        <Button 
        variant="outlined"  
        sx={{ mb: 2, width: '100%', backgroundColor: "white", border: "none", color: 'black', height: "2.8em", fontSize: "17px", textTransform: 'none'}}
        onClick={() => history.push('/sign-up')}
        >
          Sign Up
        </Button>
      </Box>
    </Container>
  );
};

export default StartPage;
