import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Container, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, Chip, TableRow, Paper, Grid, Box, Card, CardContent } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { LocalConvenienceStoreOutlined, OpenInNew } from '@mui/icons-material';
import { LocationOn, MonetizationOn, Event, Description, AccountCircle, Business, AccountBalance, Person, EventNote } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser, FaCheck, FaTimes, FaPlus} from 'react-icons/fa';

const ViewEducation = ({ educationalDetails }) => {
  return (
    <React.Fragment>
        <Box>
            <Typography variant="h5" sx={{ marginTop:4 }} textAlign="center">
                Educational Details
            </Typography>
        </Box>

`  {educationalDetails && educationalDetails.length > 0 ? (
                  <Grid container p={2}>
                  {educationalDetails.map(detail => (
                  <Grid item mx="auto" xs={12} fullWidth key={detail.id}>
                      <Card variant="outlined" sx={{ backgroundColor: '#f5f5f5', borderRadius: '15px', padding: '8px', marginBottom: '8px' }}>
                      <CardContent>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Type: {detail.type || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Degree: {detail.degree || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Start Date: {detail.start_date ? new Date(detail.start_date).toLocaleDateString() : 'N.A.'} 
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          End Date: {detail.end_date ? new Date(detail.end_date).toLocaleDateString() : 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Specialization: {detail.specialization || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Board: {detail.board || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Institute Name: {detail.institute_name || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Score: {detail.score || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Total Score: {detail.total_score || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Score Unit: {detail.score_unit || 'N.A.'}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                          Certificates: {detail.certificate_name || 'N.A.'}
                          </Typography>
                          <Box sx={{ alignItems:"center", justifyContent: 'center' }}>
                          <Box sx={{mt:2}}>
                          {detail.awards_file && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/null") && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/False") && (
                              <Button
                              fullWidth
                                  onClick={() => window.open(detail.awards_file, '_blank')}
                                  variant="outlined"
                                  startIcon={<OpenInNew />}
                              >
                                  Awards Document
                              </Button>
                          )}
                          </Box>
                          <Box sx={{mt:2}}>
                          {detail.extra_curricular_file && (detail.extra_curricular_file !== "https://iveri-docs.s3.amazonaws.com/null") && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/False") && (
                              <Button
                              fullWidth
                                  variant="outlined"
                                  startIcon={<OpenInNew sx={{ marginRight: '4px' }} />}
                                  onClick={() => window.open(detail.extra_curricular_file, '_blank')}
                              >
                                  Extra Curricular Document
                              </Button>
                          )}
                          </Box>
                          <Box sx={{mt:2, mb:2}}>
                          {detail.educational_file && (detail.educational_file !== "https://iveri-docs.s3.amazonaws.com/null") && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/False") && (
                              <Button
                              fullWidth
                                  variant="outlined"
                                  startIcon={<OpenInNew sx={{ marginRight: '4px' }} />}
                                  onClick={() => window.open(detail.educational_file, '_blank')}
                              >
                                  Educational Document
                              </Button>
                          )}
                          </Box>
                      </Box>
                      
                          <Typography variant="body1"  sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                              Projects:
                          </Typography>
          
                          {detail.projects && detail.projects.map(project => (
                              project.project_title && 
                              <Box>
                              <Card sx={{ p:1, m:1}} elevation={2}>
                                  <Typography key={project.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                  Project Title: {project.project_title}
                                  </Typography>
                                  <Typography key={project.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                  Project Description: {project.project_desc}
                                  </Typography>
                                  <Typography key={project.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                  Project Link: {project.project_link}
                                  </Typography>
                              </Card>
                              </Box>
                          ))}
                          <Typography variant="body1" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                              Internships:
                          </Typography>
                          {detail.internships && detail.internships.map(internship => (
                              internship.internship_company && internship.internship_role &&
                              <Box>
                              
                                  <Card sx={{ p:1, m:1}} elevation={2}>
                                      <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                      Internship Company: {internship.internship_company}
                                      </Typography>
                                      <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                      Internship Role: {internship.internship_role}
                                      </Typography>
                                      <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                      From: { new Date(internship.internship_duration_from).toLocaleDateString()}
                                      </Typography>
                                      <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                                      To: { new Date(internship.internship_duration_to).toLocaleDateString() || 'N.A.'}
                                      </Typography> 
                                  </Card>
          
                              </Box>
                          ))}
          
                          
          
                      </CardContent>
                      </Card>
                  </Grid>
                  ))}
                  </Grid>
  ) : (
    <Grid item xs={12} sx={{  mx: "auto ", textAlign:"center"}}>
      <Chip sx={{ mx: "auto ", textAlign:"center"}} label="No Education Detail Added" color="warning" variant="outlined" />
    </Grid>
  )}`




    </React.Fragment>
  )
}

export default ViewEducation