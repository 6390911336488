import React, { useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Card,
  CardContent,
  InputAdornment,
  Button
} from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  select: {
    width: "100%",
  },
  label: {
    fontWeight: "600",
    minWidth: 200,
    color: "rgb(0, 184, 230)",
  },
  selectContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const initialData = [
  {
    personName: "Gaurav Shah",
    contactNumber: "4567891231",
    email: "Gaurav@sample.com",
    relationWithCandidate: "Professional",
    verificationDocuments: "upload",
  },
];

const labelValuePairs = [
  { label: "Fixed Salary", value: "30 LPA", options: ["30 LPA", "50 LPA"] },
  { label: "Variable Salary", value: "05 LPA", options: ["05 LPA", "15 LPA"] },
  { label: "Accommodation", value: "Yes, Fully Furnished, Family", options: ["Yes, Fully Furnished, Family", "No"] },
  { label: "Other Perks", value: "Travel, 2 Family Trips in India", options: ["Travel, 2 Family Trips in India", "International Trips"] },
  { label: "Current Company", value: "Birla Group", options: ["Birla Group", "Adtiya International"] },
  { label: "Current Profile", value: "Finance Controller", options: ["Finance Controller", "Software International"] },
  { label: "Current Designation", value: "General Manager", options: ["General Manager", "Asst. manager"] },
  { label: "Current Reporting to", value: "CFO", options: ["CFO", "ED"] },
  { label: "Current Reportees", value: "3 Direct Reportees", options: ["3 Direct Reportees", "1 Reportees"] },
  { label: "Work Location", value: "Ahmedabad", options: ["Ahmedabad", "Delhi"] },
  { label: "Office Type", value: "Corporate Office", options: ["Corporate Office", "Work from home"] },
  { label: "Documents", value: "Offer_Letter.pdf", options: ["Offer_Letter.pdf"] },
];

const LabelField = ({ classes, label, value, options, onChange }) => {
  const isSocialMediaLink = label === "Link of the Course";
  const isDocumentUpload = label === "Document uploaded";
  const hasConfirmedLackData = options && options.length > 0;
  const [selectedValue, setSelectedValue] = useState(value);

  const handleLinkClick = () => {
    if (isSocialMediaLink) {
      window.open(value, "_blank");
    }
  };

  const handleDocumentClick = () => {
    if (isDocumentUpload) {
      window.open(value, "_blank", "fullscreen=yes");
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange(label, newValue);
  };


  const handleConfirmedLackDataChange = (event) => {
    const isConfirmed = event.target.value === "Confirmed";
    const isLackOfData = event.target.value === "Lack of Data";
    let newValue = value;
    if (isConfirmed || isLackOfData) {
      const prefix = isConfirmed ? "(Confirmed)" : "(Lack of Data)";
      newValue = value.replace(/\(Confirmed\)|\(Lack of Data\)/, "").trim() + " " + prefix;
    }
    onChange(label, newValue);
  };
  return (
    <Card sx={{ boxShadow: 2, p: 2, marginBottom: "20px" }}>
      <CardContent>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontSize: "20px" }}>
          {label + ":" + value}
        </Typography>
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
                <InputLabel id="status">Status</InputLabel>
                <Select fullWidth label="Status" id="status">
                <MenuItem value="Confirmed">Confirmed</MenuItem>
                <MenuItem value="Not Confirmed">Not Confirmed</MenuItem>
                <MenuItem value="Lack of data / doc from Candidate">Lack of data / doc from Candidate</MenuItem>
                </Select>
            </FormControl>
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
                <InputLabel id="source">Source of Verification</InputLabel>
                <Select fullWidth label="Source of Verification" id="source">
                <MenuItem value="Online">Online</MenuItem>
                <MenuItem value="Telephonic">Telephonic</MenuItem>
                <MenuItem value="Physical">Physical</MenuItem>
                <MenuItem value="iVeri link via mail">iVeri link via mail</MenuItem>
                </Select>
            </FormControl>
      </Grid>
      <Grid item xs={6}>
      <TextField  fullWidth variant="outlined" size="large" label="More Details"/>
      </Grid>
      <Grid item xs={6}>
      <Button
        variant="outlined"
        color="primary"
        component="label"
        htmlFor="upload-documents"
        style={{width:"157px",height:"55px"}}
      >
        Upload Docs
        <input type="file" id="upload-documents" style={{ display: "none" }} />
      </Button>
      </Grid>
      {/* <Grid item xs={6}>
        {isSocialMediaLink ? (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <Typography onClick={handleLinkClick} style={{ cursor: "pointer" }}>
              {value}
            </Typography>
          </a>
        ) : isDocumentUpload ? (
          <Typography onClick={handleDocumentClick} style={{ cursor: "pointer" }}>
            {value}
          </Typography>
        ) : (
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <FormControl variant="standard" className={classes.select}>
            <Select
        labelId={`${label}-confirmed-lack-data-label`}
        id={`${label}-confirmed-lack-data-select`}
        value={selectedValue}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
            </FormControl>
            {hasConfirmedLackData && (
              <FormControl variant="standard" className={classes.select}>
                <Select
                  labelId={`${label}-confirmed-lack-data-label`}
                  id={`${label}-confirmed-lack-data-select`}
                  value={value.includes("Confirmed") ? "Confirmed" : value.includes("Lack of Data") ? "Lack of Data" : ""}
                  onChange={handleConfirmedLackDataChange}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                  <MenuItem value="Lack of Data">Lack of Data</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
        )}
      </Grid> */}
    </Grid>
    </CardContent>
    </Card>
  );
};

const EditProfessional = () => {
  const classes = useStyles();
  const [data, setData] = useState(initialData);
  const [values, setValues] = useState({});

  const handleCellChange = (event, index, key) => {
    const updatedData = [...data];
    updatedData[index][key] = event.target.value;
    setData(updatedData);
  };

  const handleChange = (label, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [label]: value,
    }));
  };

  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
          {labelValuePairs.map(({ label, value, options }) => (
            <LabelField
              key={label}
              classes={classes} // Pass classes to the LabelField component
              label={label}
              value={values[label] || value}
              options={options}
              onChange={handleChange}
            />
          ))}
        
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Person Name</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Relation with Candidate</TableCell>
                <TableCell>Verification Documents</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <TextField
                      value={row.personName}
                      onChange={(event) =>
                        handleCellChange(event, index, "personName")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.contactNumber}
                      onChange={(event) =>
                        handleCellChange(event, index, "contactNumber")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.email}
                      onChange={(event) =>
                        handleCellChange(event, index, "email")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.relationWithCandidate}
                      onChange={(event) =>
                        handleCellChange(event, index, "relationWithCandidate")
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={row.verificationDocuments}
                      onChange={(event) =>
                        handleCellChange(event, index, "verificationDocuments")
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
    </>
  );
};

export default EditProfessional;
