import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@material-ui/core';
import { Typography, Button,  Container, Box } from "@mui/material";
import logo from "../../../iveri.png";



const useStyles = makeStyles({
  container: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginBottom: "8px",
  },
  submitButton: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  linkButton: {
    marginBottom: "16px",
  },
  img: {
    width: "10rem",
},
});

const ForgotLogin = (props) => {
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(OTP);
  };

  return (
    <>
    
    
    
            
    
    <Container component="main" maxWidth="xs">
      <Layout>
    

      <div className={classes.container}>
        <Typography component="h2" variant="h5">
          Login Details
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            label="Email Id"
            variant="outlined"
            fullWidth
            className={classes.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="youremail@gmail.com"
            id="email"
            name="email"
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            Send OTP
          </Button>
          <TextField
            label="Submit OTP"
            variant="outlined"
            fullWidth
            className={classes.input}
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
            type="password"
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            Log In
          </Button>
        </form>
        <Button
          variant="contained"
          color="secondary"
          className={classes.linkButton}
          onClick={() => props.onFormSwitch("SignUp")}
        >
          Don't have an account? Register here.
        </Button>
      </div>
      </Layout>
      
    </Container>
    
    </>

  );
};

export default ForgotLogin;