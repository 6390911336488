import React from 'react'
import instance from '../../../globals/axios'
import CompanyForm from '../../../components/CompanySignup'
import { Container } from '@mui/material'
import NavBar from '../../../components/NavBar'

const CompanyProfile = () => {
  return (
    <Container fullwidth>
      <NavBar />
      <CompanyForm />
    </Container>
  )
}

export default CompanyProfile