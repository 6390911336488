import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  styled,
  IconButton,
  Stack,
  Snackbar,
} from '@mui/material';
import { Link } from "react-router-dom";
import Sidebar from '../../components/Sidebar';
import shareProfilePic from "../../assets/shareProfile.png";
import shareWithDocIcon from "../../assets/sharewithDoc.png";
import shareWithoutDocIcon from "../../assets/sharewithoutDoc.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { HOME_PAGE } from "../../globals/const";
import instance from '../../globals/axios'; 
import { SERVER_BASE_ADDRESS } from "../../globals/const";
import CircleIcon from '@mui/icons-material/Circle';

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: "15px",
  backgroundColor: "rgb(245, 245, 245)",
  transition: 'background-color 0.3s',
  height: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#EFEFF0',
  borderRadius: "10px",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    }
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  gap: theme.spacing(1),
}));

const ShareProfile = () => {
  const [sharedurlwithdoc, setSharedurlWithDoc] = useState("");
  const [sharedurlwithoutdoc, setSharedurlWithoutDoc] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isPaidUser = JSON.parse(localStorage.getItem('is_paid_user'));

  useEffect(() => {
    instance.get(SERVER_BASE_ADDRESS + "create-shared-link/").then((res) => {
      res.data.forEach((item) => {
        if (item.sharing_mode === "WITH_DOC") {
          setSharedurlWithDoc(item.uuid);
        } else if (item.sharing_mode === "WITHOUT_DOC") {
          setSharedurlWithoutDoc(item.uuid);
        }
      });
      console.log(res.data);
    });
  }, []);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbarMessage('Link copied to clipboard!');
      setOpenSnackbar(true);
    } catch (err) {
      console.error('Failed to copy:', err);
      setSnackbarMessage('Failed to copy link');
      setOpenSnackbar(true);
    }
  };

  const handleShare = async (text) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Share Profile',
          text: 'Check out my profile!',
          url: text
        });
      } else {
        handleCopy(text);
      }
    } catch (err) {
      console.error('Failed to share:', err);
    }
  };


  return (
    <Box sx={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <Sidebar />
      <Container maxWidth="sm" sx={{ px: { xs: 2, sm: 3 } }}>
        {/* Header */}
        <Box sx={{ pt: 2, pb: 3, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
          <Typography variant="h5" component="h1" sx={{ color: '#262262', fontWeight: 600 }}>
            Share Profile
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <Box sx={{ position: 'relative', width: { xs: 240, sm: 300 }, height: { xs: 240, sm: 300 } }}>
            <img 
              src={shareProfilePic}
              alt="Share profile illustration"
              style={{ 
                width: '100%',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Box>

        {/* Description */}
        <Box sx={{ textAlign: 'left', mb: 4 }}>
          <Typography variant="h6" component="h2" sx={{ mb: 1, fontWeight: 600, color: "#262262" }}>
            Share Your Profile
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ fontStyle: 'italic', color: "#262262" }}>
            "Choose how you want to share your profile. You can include documents or share just your profile information."
          </Typography>
        </Box>
        <Typography variant="body1" textAlign="center" mb={2}>
      Subscription: 
      <CircleIcon
        sx={{
          color: isPaidUser ? 'green' : 'red',
          fontSize: '12px',
          marginLeft: '4px',
        }}
      />
      {isPaidUser ? ' (Active)' : ' (Inactive)'}
    </Typography>
        <Grid container spacing={2} component={Link} to={`${HOME_PAGE}/share-profile-doc`} sx={{ textDecoration: 'none', mb: 4 }}>
          <Grid item xs={6}>
            <StyledCard>
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                p: { xs: 1.5, sm: 2 },
                '&:last-child': { pb: { xs: 1.5, sm: 2 } }
              }}>
                <Box sx={{
                  borderRadius: 1,
                  p: 1,
                  mb: 1.5
                }}>
                  <img src={shareWithDocIcon} style={{ width: "30px", height: "30px", cursor: "pointer" }} />
                </Box>
                <Typography variant="subtitle2" sx={{
                  fontWeight: 500,
                  mb: 1,
                  fontSize: { xs: '0.875rem', sm: '1rem', color: "#262262", fontWeight: "550" }
                }}>
                  Share Profile with Documents
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' }
                }}>
                  Select this option to share your profile along with any attached documents.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Box>
      
          </Box>
          <Grid item xs={6} component={Link} to={`${HOME_PAGE}/share-profile-without-doc`} sx={{ textDecoration: 'none' }}>
            <StyledCard>
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                textAlign: 'left',
                p: { xs: 1.5, sm: 2 },
                '&:last-child': { pb: { xs: 1.5, sm: 2 }
                }}}>
                <Box sx={{
                  borderRadius: 1,
                  p: 1,
                  mb: 1.5
                }}>
                  <img src={shareWithoutDocIcon} style={{ width: "30px", height: "30px", cursor: "pointer" }} />
                </Box>
                <Typography variant="subtitle2" sx={{
                  fontWeight: 500,
                  mb: 1,
                  fontSize: { xs: '0.875rem', sm: '1rem', color: "#262262", fontWeight: "550" }
                }}>
                  Share Profile without Documents
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem' }
                }}>
                  Choose this option to share your profile information without any attached documents.
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>

        {/* Generated Links */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            Share Profile with Documents Link :
          </Typography>
<StyledTextField
  fullWidth
  variant="outlined"
  placeholder="Your generated link will be here"
  disabled
  value={sharedurlwithdoc ? `http://${window.location.host}/share-profile-docs/${sharedurlwithdoc}` : ''}
/>
          <Stack direction="row" spacing={1} sx={{ mt: 1, justifyContent: 'flex-end' }}>
            <IconButton
              onClick={() => handleCopy(`http://${window.location.host}/share-profile-docs/${sharedurlwithdoc}`)}
              sx={{ 
                color: '#262262',
                '&:hover': { 
                  backgroundColor: 'transparent',
                  color: '#262262cc'  // Adding slight transparency on hover
                }
              }}
            >
              <ContentCopyIcon />
            </IconButton>
            <IconButton
              onClick={() => handleShare(`http://${window.location.host}/share-profile-docs/${sharedurlwithdoc}`)}
              sx={{ 
                color: '#262262',
                '&:hover': { 
                  backgroundColor: 'transparent',
                  color: '#262262cc'  // Adding slight transparency on hover
                }
              }}
            >
              <ShareIcon />
            </IconButton>
          </Stack>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            Share Profile without Documents Link :
          </Typography>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Your generated link will be here"
            disabled
            value={sharedurlwithoutdoc ? `http://${window.location.host}/share-profile-without-docs/${sharedurlwithoutdoc}` : ''}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1, justifyContent: 'flex-end' }}>
            <IconButton
              onClick={() => handleCopy(`http://${window.location.host}/share-profile-without-docs/${sharedurlwithoutdoc}`)}
              sx={{ 
                color: '#262262',
                '&:hover': { 
                  backgroundColor: 'transparent',
                  color: '#262262cc'  // Adding slight transparency on hover
                }
              }}
            >
              <ContentCopyIcon />
            </IconButton>
            <IconButton
              onClick={() => handleShare(`http://${window.location.host}/share-profile-without-docs/${sharedurlwithoutdoc}`)}
              sx={{ 
                color: '#262262',
                '&:hover': { 
                  backgroundColor: 'transparent',
                  color: '#262262cc'  // Adding slight transparency on hover
                }
              }}
            >
              <ShareIcon />
            </IconButton>
          </Stack>
        </Box>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </Container>
    </Box>
  );
};

export default ShareProfile;
