import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { Link } from "react-router-dom";
import { Container, Button, Typography, Box } from "@mui/material";

const LabelField = ({ label, value }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    margin="5px 0"
  >
    <Typography>{label + ":"}</Typography>
    {label === "Candidate Profile Link" ? (
      <a href={value} target="_blank" rel="noopener noreferrer">
        <Typography>{value}</Typography>
      </a>
    ) : (
      <Typography>{value}</Typography>
    )}
  </Box>
);

const labelValuePairs = [
  { label: "Requisition Type", value: "company" },
  { label: "Requisition Raised by Name", value: "Adani Group" },
  { label: "BGV Assigned to", value: "Self" },
  { label: "Candidate Profile Link", value: "wwwe.xyz.com" },
  { label: "BGV Completion Status", value: "Ongoing" },
  { label: "BGV Requisition Unique Number", value: "COM101" },
];

const buttons = [
  {
    label: "Personal Details",
    path: "/Personal-Details",
  },
  {
    label: "Educational Details",
    path: "/education-details",
  },
  {
    label: "Professional Details",
    path: "/professional-details",
  },
];

export default function Viewpage() {
  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        {labelValuePairs.map(({ label, value }) => (
          <LabelField key={label} label={label} value={value} />
        ))}
        <Box marginTop="20px">
          {buttons.map((button, index) => (
            <Button
              key={index}
              fullWidth
              component={Link}
              to={button.path}
              variant="contained"
              style={{ marginBottom: "8px" }}
            >
              {button.label}
            </Button>
          ))}
        </Box>
        </Container>
    </>
  );
}
