import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, Paper, FormControl, InputLabel, CircularProgress, Box, Alert, Chip, Stack, MenuItem, Select, Container, TextField, Button, Typography } from '@mui/material';
import NavBar from '../../components/NavBar';
import axios from 'axios';
import instance from '../../globals/axios';
import { Link } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const MyComponent = () => {
  const [hrList, setHrList] = useState([]);
  const [selectedHr, setSelectedHr] = useState('');
  const [designation, setDesignation] = useState('');
  const [profileTitle, setProfileTitle] = useState('');
  const [sbuName, setSbuName] = useState('');
  const [extractedData, setExtractedData] = useState('Data to be passed');


  const company_id = localStorage.getItem('company_id');
  const [details, setDetails] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return date.toLocaleDateString(undefined, options);
  }

    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    
    
    // Handle file drop
    const onDrop = useCallback((acceptedFiles) => {
      // Do something with the files
      setFile(acceptedFiles[0]);
    }, []);
    
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: 'application/pdf',
      maxFiles: 1,
    });
    
    const handleConvert = async () => {
      if (!file) {
        alert('Please upload a file first.');
        return;
      }
      
      setLoading(true);
      setError('');
      let formData = new FormData();
      formData.append('pdf_file', file);
      
      try {
        const response = await instance.post('process-pdf/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
      });

      const data = JSON.parse(response.data.extracted_details);
      setExtractedData(data)
      if (data) {
        setDetails(data);
      } else {
        setError('No data found in the response.');
      }
    } catch (error) {
      setError('There was an error processing your file');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <React.Fragment>
    <NavBar />

    <Container maxWidth="md" sx={{ marginTop:"100px"}}>

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Create Iveri JD from Uploaded JD</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined" {...getRootProps()} style={{ padding: '20px', textAlign: 'center' }}>
          <input {...getInputProps()} />
          <Typography variant="body1">Drag 'n' drop some files here, or click to select files</Typography>
        </Paper>
      </Grid>
      <Grid item mx="auto" xs={7}>
        <Button fullWidth variant="contained" color="primary" onClick={handleConvert}>
          Convert
        </Button>
      </Grid>

      <Grid fullWidth xs={12} my={4}>
        {loading && (
          <Grid item xs={12} display="flex" justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        {!loading && error && (
          <Grid item xs={12}>
            <Alert severity="error" icon={<ErrorOutlineIcon fontSize="inherit" />}>
              {error}
            </Alert>
          </Grid>
        )}
        {!loading && details && (
          <Grid container p={4}>
            <Grid item xs={12}>
              <Typography textAlign="center" variant="subtitle1"><strong>Profile Title:</strong> {details.profile_title}</Typography>
              <Typography textAlign="center" variant="subtitle1"><strong>Designation:</strong> {details.designation}</Typography>
              <Typography variant="subtitle1" mt={2}><strong>Job Brief:</strong> {details.job_brief}</Typography>
              <Typography variant="subtitle1"><strong>Company:</strong> {details.company}</Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="subtitle1">Roles and Responsibilities:</Typography>
              {Array.isArray(details.roles_and_responsibilities) ? (
                  details.roles_and_responsibilities?.map((competency, index) => (
                    <Chip key={index} label={competency} variant="outlined" sx={{ m: 0.5 }} />
                  ))
                ) : (
                  <Chip label={details.roles_and_responsibilities} variant="outlined" sx={{ m: 0.5 }} />
                )}
              {/* {details.roles_and_responsibilities?.map((role, index) => (
                <Chip key={index} label={role} variant="outlined" sx={{ m: 0.5 }} />
              ))} */}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="subtitle1">Level of Position:</Typography>
              <Typography variant="body2">{details.level_of_position}</Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="subtitle1">Functional Competency:</Typography>
              {Array.isArray(details.functional_competency) ? (
                  details.functional_competency?.map((competency, index) => (
                    <Chip key={index} label={competency} variant="outlined" sx={{ m: 0.5 }} />
                  ))
                ) : (
                  <Chip label={details.functional_competency} variant="outlined" sx={{ m: 0.5 }} />
                )}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="subtitle1">Behavioural Competency:</Typography>
              {Array.isArray(details.behavioural_competency) ? (
                  details.behavioural_competency?.map((competency, index) => (
                    <Chip key={index} label={competency} variant="outlined" sx={{ m: 0.5 }} />
                  ))
                ) : (
                  <Chip label={details.behavioural_competency} variant="outlined" sx={{ m: 0.5 }} />
                )}
              {/* {details.behavioural_competency?.map((competency, index) => (
                <Chip key={index} label={competency} variant="outlined" sx={{ m: 0.5 }} />
              ))} */}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="subtitle1">Skills Required:</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {Array.isArray(details.skills) ? (
                  details.skills?.map((competency, index) => (
                    <Chip key={index} label={competency} variant="outlined" sx={{ m: 0.5 }} />
                  ))
                ) : (
                  <Chip label={details.skills} variant="outlined" sx={{ m: 0.5 }} />
                )}

                {/* {details.skills.required?.map((skill, index) => (
                  <Chip key={index} label={skill} variant="outlined" />
                ))} */}
              </Box>
            </Grid>
            <Grid item mt={2} xs={12}>
              <Link
                to={{
                  pathname: '/jd-parser-update', // Target route
                  state: { extractedData }, // Pass state data
                }}
              >
                <Button startIcon={<TaskIcon />} fullWidth variant="outlined" color="primary">
                  Process
                </Button>
              </Link>
            </Grid>

          </Grid>
          
        )}
      </Grid>
      
    </Grid>
    </Container>
    </React.Fragment>
  );
}

export default MyComponent;
