import React from 'react'
import { Link } from "react-router-dom";
import { Button, Box, Container, Grid, Typography, TextField, Card } from "@mui/material";
import NavBar from '../../components/NavBar'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { motion } from 'framer-motion';

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import MicIcon from '@mui/icons-material/Mic';

const pulseAnimation = {
    scale: [1, 1.2, 1], // Scale from 1 to 1.2 and back to 1
    opacity: [1, 0.8, 1], // Opacity from 1 to 0.8 and back to 1
    transition: {
      duration: 1, // Total duration of the animation
      repeat: Infinity, // Repeat the animation indefinitely
    },
};


export default function JDCreator() {


    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    
    const startListening = () => SpeechRecognition.startListening({ continuous: true });

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

      
    return (
        <>
        <NavBar />
        <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Box textAlign="center">
            <Typography variant='h5'>
            JD creator with AI 
            </Typography>

            <Card elevation={6} sx={{ p: 2, my: 4, borderRadius:"12px" }}>
            <Box mx="auto">
                {listening ? (
                <motion.div animate={pulseAnimation}>
                    <GraphicEqIcon />
                </motion.div>
                ) : (
                <MicIcon />
                )}
            </Box>
            <Typography variant='body1'>{transcript}
            </Typography>
            </Card>

            <Grid container p={2} spacing={2} textAlign="center" mx="auto">
                <Grid item xs={3} mx="auto" alignItems="center">
                    <Button onClick={resetTranscript} fullwidth variant='outlined' color="primary">
                    Reset
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={startListening} fullwidth variant='contained' color="success">
                    Start 
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={SpeechRecognition.stopListening} fullwidth variant='contained' color="error">
                    Stop 
                    </Button>
                </Grid>

            </Grid>
        </Box>
        </Container>
        </>
    )
}
