import React, { useState, useEffect } from 'react';
import { Grid, FormControl, InputLabel, MenuItem, Select, Container, TextField, Button, Typography } from '@mui/material';
import NavBar from '../../../components/NavBar';
import axios from 'axios';
import instance from '../../../globals/axios';

const MyComponent = () => {
  const [hrList, setHrList] = useState([]);
  const [selectedHr, setSelectedHr] = useState('');
  const [designation, setDesignation] = useState('');
  const [profileTitle, setProfileTitle] = useState('');
  const [sbuName, setSbuName] = useState('');


  const company_id = localStorage.getItem('company_id');

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return date.toLocaleDateString(undefined, options);
  }

  useEffect(() => {
    // Fetch HR list
    instance.get(`/company/${company_id}/get_user/`, {
      params: {
        role: 5
      }
    })
    .then(response => {
      setHrList(response.data);
    })
    .catch(error => {
      console.error('Error fetching HR list:', error);
    });
  }, []);

  console.log(hrList)

  const handleHrSelect = (event) => {
    const selectedHrData = hrList.find(hr => hr.user.iveri_id === event.target.value);
    setSelectedHr(selectedHrData);
    setDesignation(selectedHrData.user.designation || '');
    setProfileTitle(selectedHrData.user.profile_title || '');
  }

  const handleSave = () => {
    const dataToPost = {
      name: selectedHr.user.first_name + ' ' + selectedHr.user.last_name,
      iveri_id: selectedHr.user.iveri_id,
      designation: designation,
      profile_title: profileTitle,
      HR: selectedHr.user.id,
      sbu_name: sbuName,
      is_active: true,
      is_published: false
    };

    instance.post(`/company/${company_id}/add_requirement/`, dataToPost)
      .then(response => {
        alert('Requirement Posted successfully:');
        setTimeout(() => {
          window.location = '/hiring-manager'; 
        }, 500); 
      })
      .catch(error => {
        console.error('Error posting:', error);
      });
  }

  return (
    <React.Fragment>

    <NavBar />
    <Container maxWidth="md" sx={{ marginTop:"100px"}}>

    <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5'>
      Create A New Requirement
      </Typography>
    </Grid>
    
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="SBU Name"
          value={sbuName}
          onChange={(e) => setSbuName(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Profile Title"
          value={profileTitle}
          onChange={(e) => setProfileTitle(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Designation"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Select HR</InputLabel>
          <Select
            value={selectedHr ? selectedHr.user.iveri_id : ''}
            label="Select HR"
            onChange={handleHrSelect}
          >
            {hrList.map(hr => (
              <MenuItem key={hr.user.iveri_id} value={hr.user.iveri_id}>
                {hr.user.first_name} {hr.user.last_name} - Iveri ID: {hr.user.iveri_id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>Requirement raised on: {formatDate(currentDateTime)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Grid>
    </Grid>
    </Container>
    </React.Fragment>
  );
}

export default MyComponent;
