import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Tooltip, Box, Typography, CircularProgress, Container } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import NavBar from '../../components/NavBar';
import instance from '../../globals/axios';
import DashboardMetrics from '../../components/InterviewDashboard';
import dayjs from 'dayjs';

const InterviewAssessmentTable = () => {
  const [interviews, setInterviews] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const interviewerIveriId = localStorage.getItem('iveri_id');

  useEffect(() => {
    fetchInterviews(page + 1, pageSize);  // API expects 1-based page index
  }, [page, pageSize]);

  const fetchInterviews = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await instance.get(`interview-system/get-interviews/?iveri_id=${interviewerIveriId}&page=${page}&page_size=${pageSize}`);
      setInterviews(response.data.results);
      setRowCount(response.data.count);
    } catch (error) {
      console.error("Error fetching interviews:", error);
    }
    setLoading(false);
  };

  const columns = [
    { field: 'interview_id', headerName: 'Interview ID', width: 150, renderCell: (params) => (
        <Tooltip title="Interview ID">
          <Typography variant="body2">{params.value}</Typography>
        </Tooltip>
      )
    },
    { field: 'selected_candidate_id', headerName: 'Candidate ID', width: 180, renderCell: (params) => (
        <Tooltip title="Selected Candidate ID">
          <Typography variant="body2">{params.value}</Typography>
        </Tooltip>
      )
    },
    { field: 'created_at', headerName: 'Created At', width: 200, renderCell: (params) => (
        <Tooltip title="Creation Date">
          <Typography variant="body2">
            {dayjs(params.value).format('hh:mm A DD MMM YYYY')}
          </Typography>
        </Tooltip>
      )
    },
    { field: 'actions', headerName: 'Actions', width: 180, sortable: false, renderCell: (params) => (
      <div>
          <Tooltip title="View Details">
              <Button
                  variant="contained"
                  color="primary"
                  size='small'
                  startIcon={<Visibility />}
                  onClick={() => window.location.href = `/report/${params.row.interview_id}`}
              >
                  View DA Report
              </Button>
          </Tooltip>
      </div>
  )}
  ];

  return (
    <React.Fragment>
      <NavBar />

      <Container sx={{ marginTop: "90px", maxWidth:"md"}} maxWidth="md">
        <DashboardMetrics />

        <Box fullWidth my={2}>
          <Typography variant='h4' textAlign="center">
            Interview Status
          </Typography>
        </Box>
        <div style={{ height: 450, width: '100%' }}>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress />
            </div>
          ) : (
            <DataGrid
              rows={interviews}
              columns={columns}
              pagination
              page={page}
              pageSize={pageSize}
              rowCount={rowCount}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              getRowId={(row) => row.interview_id} // Ensure the DataGrid uses the unique id field
              disableSelectionOnClick
              pageSizeOptions={[10, 25, 50, 100]} // Add page size options
              components={{
                NoRowsOverlay: () => (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography variant="h6">No interviews found</Typography>
                  </div>
                ),
              }}
            />
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default InterviewAssessmentTable;
