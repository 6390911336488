import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you have axios installed and set up
import { Grid, TextField, Button, Container, Box, FormControl, InputLabel, Select, MenuItem, Card, Typography, Divider, IconButton, Radio, RadioGroup, FormControlLabel} from '@mui/material';
import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';  
import KRATable from '../../components/KRATable';



const RequirementDetails = () => {
    const [requirement, setRequirement] = useState(null);
    const [updatedRequirement, setUpdatedRequirement] = useState({});
    const company_id = localStorage.getItem('company_id');

    const iveriId = window.localStorage.getItem("iveri_id");
    const { req_id } = useParams();

    
    useEffect(() => {
        const handleGetRequirement = () => {
            instance.get(`company/get_requirement_by_req_id/?requirement_id=${req_id}`)
            .then(response => {
                setRequirement(response.data);
            })
            .catch(error => {
                console.error('Error fetching requirement data:', error);
            });
        };
        handleGetRequirement();

    }, [])
    

    const handleChange = (field, value) => {
        setUpdatedRequirement(prevState => ({
        ...prevState,
        [field]: value,
        }));
    };

    const renderCompetencyInputs = (competencyType) => {
        const competencies = requirement[competencyType] || {};
      
        return (
          <Container>
            
            {

                requirement[competencyType]===null ? 
                (
                    <Typography color="#bdbdbd" textAlign="center"> No Competencies Found</Typography>
                ) : 
                (
                    Object.keys(competencies).map((title, index) => (
                    <Grid mx="auto" container p={2} spacing={2} key={index}>
                        <Grid item xs={6}>
                        <TextField
                            label="Competency Title"
                            fullWidth
                            value={title}
                            disabled={true}
                        />
                        </Grid>
                        <Grid item xs={4}>
                        <RadioGroup
                            row
                            value={String(competencies[title])}
                        >
                            {[1, 2, 3, 4].map((level) => (
                            <FormControlLabel
                                key={level}
                                value={String(level)}
                                control={<Radio />}
                                label={String(level)}
                                disabled={true}
                            />
                            ))}
                        </RadioGroup>
                        </Grid>
                        
                    </Grid>
                    ))
                )
            }
          </Container>
        );
      };



    return (
        <React.Fragment>
        <NavBar />
        <Container maxWidth="md" sx={{ marginTop: "100px"}}>
        <Grid container fullWidth mb={3}>
            <Grid item xs={1}>
                <Button onClick={() => window.history.back()} fullWidth startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
            </Grid>
            <Grid item xs={10} textAlign="center">
                <Typography variant='h5'>
                {requirement?.profile_title}
                </Typography>
                <Typography mt={1} variant='body1'>
                Job Description
                </Typography>
            </Grid>
        </Grid>
        
        {
        requirement && (

            <Grid container p={2} maxWidth="md" spacing={2} mb={6}>

           

            <Grid item xs={12}>
            <TextField
            disabled
            label="Profile Title"
            fullWidth
            value={requirement.profile_title}
            onChange={e => handleChange('profile_title', e.target.value)}
            />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Designation"
                fullWidth
                value={requirement.designation}
                onChange={e => handleChange('designation', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Requirement Raised On"
                fullWidth
                value={new Date(requirement.requirement_raised_on).toLocaleString()}
                InputProps={{
                    readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Requirement Status"
                fullWidth
                value={requirement.requirement_status}
                InputProps={{
                    readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Requirement ID"
                fullWidth
                value={requirement.requirement_id}
                InputProps={{
                    readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Number of Positions"
                fullWidth
                value={requirement.number_of_positions}
                onChange={e => handleChange('number_of_positions', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Job Location"
                fullWidth
                value={requirement.job_location}
                onChange={e => handleChange('job_location', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Start Date"
                fullWidth
                value={requirement.start_date}
                onChange={e => handleChange('start_date', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Close Date"
                fullWidth
                value={requirement.close_date}
                onChange={e => handleChange('close_date', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Level of Position"
                fullWidth
                value={requirement.level_of_position}
                onChange={e => handleChange('level_of_position', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Role Type"
                fullWidth
                value={requirement.role_type}
                onChange={e => handleChange('role_type', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Reporting To"
                fullWidth
                value={requirement.reporting_to}
                onChange={e => handleChange('reporting_to', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Reportees"
                fullWidth
                value={requirement.reportees}
                onChange={e => handleChange('reportees', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Minumum Experience (in years) "
                fullWidth
                value={requirement.total_experience}
                onChange={e => handleChange('total_experience', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Education Type"
                fullWidth
                value={requirement.education_type}
                onChange={e => handleChange('education_type', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Degree"
                fullWidth
                value={requirement.degree}
                onChange={e => handleChange('degree', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Specialization"
                fullWidth
                value={requirement.specialization}
                onChange={e => handleChange('specialization', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Other Skills"
                fullWidth
                value={requirement.other_skills}
                onChange={e => handleChange('other_skills', e.target.value)}
                />
            </Grid>
            <Grid container mt={1} spacing={2} p={0} m={0} xs={12}>
            <Grid item xs={6}>
              <Typography color="inherit" textAlign="center" mx="auto" mt={2}>
              Offered CTC Range (in lakhs per annum) 
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Minimum "
                fullWidth
                disabled
                type="number"
                value={requirement.offered_ctc_range_min}
                onChange={e => handleChange('offered_ctc_range_min', e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Maximum "
                fullWidth
                disabled
                type="number"
                value={requirement.offered_ctc_range_max}
                onChange={e => handleChange('offered_ctc_range_max', e.target.value)}
              />
            </Grid>
        </Grid>
          
            <Grid item xs={12}>
                <TextField
                disabled
                label="Job Brief"
                fullWidth
                multiline
                rows={5}
                value={requirement.job_brief}
                onChange={e => handleChange('job_brief', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Roles and Responsibilities"
                fullWidth
                multiline
                rows={7}
                value={requirement.roles_and_responsibilities}
                onChange={e => handleChange('roles_and_responsibilities', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Internal Interface"
                fullWidth
                value={requirement.internal_interface}
                onChange={e => handleChange('internal_interface', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="External Interface"
                fullWidth
                value={requirement.external_interface}
                onChange={e => handleChange('external_interface', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
            <Typography textAlign="center" my={2}>Functional Competency</Typography>
            {renderCompetencyInputs('functional_competency')}
            </Grid>
            
            <Grid item xs={12}>
            <Typography textAlign="center" my={2}>Behavioural Competency</Typography>

            {renderCompetencyInputs('behavioural_competency')}
            </Grid>

            <Grid xs={12} fullWidth mx="auto">
                <Typography textAlign="center" px={2} variant="h6" mt={3}>
                    KRAs and KPIs
                </Typography>
            </Grid>

            <Grid xs={12} mt={1} fullWidth>
            <KRATable requirement={requirement} />
            </Grid>


            {/* <Grid container mt={1} spacing={2}>
            {(requirement.KRA) == {} || (requirement.KRA) == null ? (
                <Grid item xs={12}>
                <Typography textAlign="center" color="#bdbdbd" variant="body1">No KRAs available.</Typography>
                </Grid>
            ) : (
                Object.entries(requirement.KRA).map(([kraTitle, { kra_wt, kpis }]) => (
                <Grid item xs={12} key={kraTitle}>
                    <Typography variant="h6">{kraTitle}</Typography>
                    <TextField
                    label="KRA Weight"
                    type="number"
                    value={kra_wt}
                    InputProps={{
                        readOnly: true,
                    }}
                    margin="normal"
                    fullWidth
                    />
                    <Grid container>
                    {Object.entries(kpis).map(([kpiTitle, kpiValue]) => (
                        <Card sx={{ width: "100%", m:2, p:2, borderRadius:"1rem"}} elevation={4} key={kpiTitle}>
                        <Grid item xs={12}>
                            <Typography mb={2}>{kpiTitle}</Typography>
                        </Grid>
                        <Grid display="flex" item m={1} xs={12}>
                            <TextField
                            label="KPI Value"
                            type="number"
                            value={kpiValue}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            />
                        </Grid>
                        </Card>
                    ))}
                    </Grid>
                </Grid>
                ))
            )}
            </Grid> */}

           
            
            
            </Grid>

        )
        }
    
        </Container>

        </React.Fragment>
    );
};

export default RequirementDetails;
