import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Container } from '@mui/material';
import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import { saveAuth, saveRole } from "../../../globals/utils";
import instance from "../../../globals/axios.js";

const TestPage = () => {


  // This is formData. This goes as BODY - the one you sent in Postman, remember?
  // Add all the fields you want and match with post request url.
  const [formData, setFormData] = useState({
    middle_name: '',
    adhaar_number: '',
    pan_number: '',
    // You can add more fields like start_date, end_date, etc.
  
  });


  // This is triggered when form is submitted. 
  // [ Button type="submit" -> onSubmit={handleSubmit} ] -> this function triggered. 
  const handleSubmit = (event) => {
    event.preventDefault();
    // Very important always check if the formData has the json format same as the post url you created requires.
    console.log(formData)

    // USE instance NOT axios. 
    // instance is a axios function created which automatically adds in the access-token. 
    // You can see how instance works in globals/axios.js
    instance
      // url should be post type not get, not fetch not any other.
      .post(SERVER_BASE_ADDRESS + "personal-details/", formData)
      // .get(SERVER_BASE_ADDRESS + "personal-details/")
      .then((res) => {
        console.log(res.data) 
      })
      .catch((e) => {
        alert("Internal server error");
      });
  };

  // Make sure you handle change yours fine.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <TextField
          label="Middle Name"
          name="middle_name"
          value={formData.middle_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Aadhaar Number"
          name="adhaar_number"
          value={formData.adhaar_number}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="PAN Number"
          name="pan_number"
          value={formData.pan_number}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Update
        </Button>
      </form>
    </Container>
  );
};

export default TestPage;
