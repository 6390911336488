
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Container, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, Chip, TableRow, Paper, Grid, Box, Card, CardContent } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { LocalConvenienceStoreOutlined, OpenInNew } from '@mui/icons-material';
import { LocationOn, MonetizationOn, Event, Description, AccountCircle, Business, AccountBalance, Person, EventNote } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser, FaCheck, FaTimes, FaPlus} from 'react-icons/fa';

const ViewLanguages = ({ langData }) => {
  return (
    <React.Fragment>

        <Grid xs={12} item>
            <Typography variant="h6" color="primary" style={{ fontWeight: "600", color: "rgb(0, 184, 230)" }}>
              Language Proficiency
            </Typography>
          </Grid>

        <Grid item xs={12}>
        <TableContainer component={Paper}>
        <Table>
            <TableHead>
            <TableRow>
                <TableCell>Language</TableCell>
                <TableCell>Reading</TableCell>
                <TableCell>Writing</TableCell>
                <TableCell>Speaking</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {langData.map((language, index) => (
                <TableRow key={index}>
                <TableCell>{language.language}</TableCell>
                <TableCell>{language.readingProficiency ? <Check /> : <Clear />}</TableCell>
                <TableCell>{language.writingProficiency ? <Check /> : <Clear />}</TableCell>
                <TableCell>{language.speakingProficiency ? <Check /> : <Clear />}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </Grid>
    </React.Fragment>
  )
}

export default ViewLanguages
