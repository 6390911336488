import React, { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Button, TextField, Container, Grid, Typography, Divider, Card, Chip, InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import logo from '../../src/assets/iveri.png'
import { useHistory, Link } from "react-router-dom";
import instance from '../globals/axios';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location = useLocation();

  // Extracting uid and token from query parameters
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  const handleResetPassword = async () => {
    try {
      // Validate passwords
      if (password !== confirmPassword) {
        alert('Passwords do not match');
        return;
      }

      const response = await instance.post('/user/reset-password/', {
        password,
        confirm_password: confirmPassword,
        uid,
        token,
      });

      alert(response.data.message);
    } catch (error) {
      alert('Error resetting password. Please try again.');
    }
  };

  return (
    <Container component='main' fullWidth >

    <Grid fullWidth mt="10%" container justifyContent="center">

    <Grid item my="auto" xs={12} md={5} >       
      <Grid flex xs={6} sx={{ mx:"auto"}}>
        <img mx="auto" alt='iVeri logo' src={logo} style={{ width: "100%" }} />
      </Grid>
      <Grid container spacing={2}>
      <Grid item mx="auto" sx={{ textAlign: "center"}} my={2} xs={8}>
        <Typography variant="h5">Reset Password</Typography>
      </Grid>
      <Grid item mx="auto" xs={10}>
        <TextField
          label="New Password"
          variant="outlined"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item mx="auto" xs={10}>
        <TextField
          label="Confirm Password"
          variant="outlined"
          fullWidth
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Grid>
      <Grid mx="auto" item my={2} xs={8}>
        <Button fullWidth variant="contained" color="primary" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </Grid>
    </Grid>

      <Grid mx="auto" container justifyContent='center' style={{ marginTop: "20px" }}>
        
        <Link to='/'>Already have an account? Sign in</Link>
     
      </Grid>
      
    </Grid>
  
    </Grid>    

</Container>
  
  );
};

export default ResetPassword;
