import React, { useState } from "react";
import {
    Button,
    Typography,
    Card,
    Box,
    TextField,
    MenuItem,
    Container, Grid, IconButton
  } from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

const initialState1 = [
    { name: "SBU Name", defaultValue: "" },
    { name: "SBU GST", defaultValue: "" },
    { name: "SBU PAN", defaultValue: "" },
    { name: "SBU Brief", defaultValue: "" },
    { name: "Services/Products", defaultValue: "" },
    { name: "Location", defaultValue: "" },
    { name: "Location Type", defaultValue: "Corporate Office" },
    { name: "Parent Company", defaultValue: "" },
    { name: "SBU Address", defaultValue: "" },
    { name: "SBU Logo", defaultValue: null },
    
  ];
  const initialState2 = [
    { name: "SBU Website", defaultValue: "" },
    { name: "SBU Social Media Pages", defaultValue: [] },
    { name: "Social Media Links", defaultValue: "" },
    { name: "Contact Person", defaultValue: "" },
    { name: "Profile Title", defaultValue: "" },
    { name: "Designation", defaultValue: "" },
  ]; 

export default function CompanyStructure() {
    const [moreDetails, setMoreDetails] = useState(false);
    
    const handleSaveClick = () => {
        // Handle final submission logic
      };
    
      const [isSaved, setIsSaved] = useState(false);
      const [formData1, setFormData1] = useState(
        initialState1.reduce((acc, field) => {
          acc[field.name] = field.defaultValue;
          return acc;
        }, {})
      );
    
      const [formData2, setFormData2] = useState(
        initialState2.reduce((acc, field) => {
          acc[field.name] = field.defaultValue;
          return acc;
        }, {})
      );
    
      const handleChange1 = (event) => {
        const { name, value } = event.target;
        setFormData1((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleChange2 = (event) => {
        const { name, value } = event.target;
        setFormData2((prevData) => ({ ...prevData, [name]: value }));
      };
    
      const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setFormData1((prevData) => ({ ...prevData, companyLogo: file }));
      };
    
      /* const handleAddLink = () => {
        const { socialMedia } = formData2;
        const updatedSocialMedia = socialMedia ? socialMedia + ", " : "";
        setFormData2((prevData) => ({ ...prevData, socialMedia: updatedSocialMedia }));
      }; */
    
      const handleNextClick = () => {
        setIsSaved(true);
      };
    
      const [links, setLinks] = useState([]);
      const [newLink, setNewLink] = useState('');
    
      const handleChange3 = (event) => {
        setNewLink(event.target.value);
      };
    
      const handleAddLink = () => {
        if (newLink.trim() !== '') {
          setLinks([...links, newLink]);
          setNewLink('');
        }
      };
    
      const handleRemoveLink = (index) => {
        const updatedLinks = [...links];
        updatedLinks.splice(index, 1);
        setLinks(updatedLinks);
      };
    
  
    return (
      <>
        <NavBar />
        <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px" marginBottom="15px">
            <Typography variant="h5" style={{ color: "rgb(0, 184, 230)"}}>
              SBU
            </Typography>
            <Button
              variant="contained"
              style={{
                color: "rgb(3, 22, 48)",
                borderRadius: "30px",
                backgroundColor: "rgb(0, 184, 230)",
                padding: "2px 30px",
                fontWeight: "600",
                fontSize: "15px",
              }}
              onClick={handleSaveClick}
            >
              SAVE
            </Button>
          </Box>
  
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography>ICM Business</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>View Details</Button>
                  <Button>Edit Details</Button>
                </Grid>
              </Grid>
           </Grid>
           <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography>Aluminium Business</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>View Details</Button>
                  <Button>Edit Details</Button>
                </Grid>
              </Grid>
           </Grid>
           <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography>Copper Business</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>View Details</Button>
                  <Button>Edit Details</Button>
                </Grid>
              </Grid>
           </Grid>
          <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography>Cement Business</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>View Details</Button>
                  <Button>Edit Details</Button>
                </Grid>
              </Grid>
           </Grid>
           <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography>Trading Business</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>View Details</Button>
                  <Button>Edit Details</Button>
                </Grid>
              </Grid>
           </Grid>
        </Grid>
  
          {moreDetails && (
            <>
            {!isSaved ? (
        <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={3}
        >
          <Typography
            style={{
              color: "rgb(0, 184, 230)",
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            SBU Details
          </Typography>
          <Button
            variant="contained"
            style={{
              color: "rgb(3, 22, 48)",
              borderRadius: "30px",
              backgroundColor: "rgb(0, 184, 230)",
              padding: "2px 30px",
              fontWeight: "600",
              fontSize: "15px",
            }}
            onClick={handleNextClick}
          >
            NEXT
          </Button>
        </Box>

        {initialState1.map((field) => (
          <Box key={field.name} display="flex" justifyContent="space-between" mt={1} mb={1}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography style={{ color: 'rgb(0, 184, 230)', fontWeight: '600' }}>
              {field.name}:
            </Typography>
            </Grid>
            <Grid item xs={12}>
            {field.name === 'Location Type' ? (
              <TextField
                select
                name={field.name}
                value={formData1[field.name]}
                onChange={handleChange1}
                style={{ width: '43%' }} fullWidth
              >
                <MenuItem value="Corporate Office">Corporate Office</MenuItem>
                <MenuItem value="Regional Office">Regional Office</MenuItem>
                <MenuItem value="Plant or Unit">Plant or Unit</MenuItem>
              </TextField>
            ) : field.name === 'Company Logo' ? (
              <Button variant="contained" component="label">
                Upload File
                <input type="file" hidden onChange={handleImageUpload} />
              </Button>
            ) : (
              <TextField
                type="text"
                name={field.name}
                value={formData1[field.name]}
                onChange={handleChange1} fullWidth
              />
            )}
            </Grid>
          </Grid>  
          </Box>
        ))}
            </>
        ) : (
          <> 
<Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={3}
        >
          <Typography
            style={{
              color: "rgb(0, 184, 230)",
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            SBU Details
          </Typography>
          <Button
            variant="contained"
            style={{
              color: "rgb(3, 22, 48)",
              borderRadius: "30px",
              backgroundColor: "rgb(0, 184, 230)",
              padding: "2px 30px",
              fontWeight: "600",
              fontSize: "15px",
            }}
            onClick={handleSaveClick}
          >
            SAVE
          </Button>
        </Box>
        {initialState2.map((field) => (
          <Box key={field.name} display="flex" justifyContent="space-between" mt={1} mb={1}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
            <Typography style={{ color: 'rgb(0, 184, 230)', fontWeight: '600' }}>
              {field.name}:
            </Typography>
            </Grid>
            <Grid item xs={12}>
            {field.name === 'Social Media Links' ? (
              <Box display="flex" flexDirection="column">

              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                <TextField
                  fullWidth
                  placeholder="Add Link..."
                  value={newLink}
                  onChange={handleChange3}
                />
                <IconButton onClick={handleAddLink}>
                  <FontAwesomeIcon icon={faPlus} style={{ color: 'blue' }} />
                </IconButton>
              </div>
            
              {links.map((link, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                  <IconButton onClick={() => handleRemoveLink(index)}>
                    <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
                  </IconButton>
                </div>
              ))}
            </Box>
              
            ) : (
              <TextField
                type="text"
                name={field.name}
                value={formData2[field.name]}
                onChange={handleChange2} fullWidth
              />
            )}
            </Grid>
            </Grid>
          </Box>
        ))}
</>
        )}
    </>
          )}
        </Container>
      </>
    );
  }