import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, TextField, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: "#EFEFF0 !important",
  height: "45px !important",
  borderRadius: "5px !important",
  width: "100%", 
  display: "flex",
  alignItems: "center", 
  justifyContent: "space-between", // Align items to the space between
  padding: "0 20px !important",
  boxShadow: "none", // Remove shadow
  border: "none",    // Remove border
  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: "#666671 !important",
  },
  '&.Mui-expanded': {
    minHeight: "45px !important",
  },
  '&.Mui-focused': {
    backgroundColor: "rgba(214,214,214, 0.3) !important",
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0 !important',
  },
}));

const CustomAccordian = ({ title, children }) => {
  return (
    <Accordion sx={{ boxShadow: 'none', border: 'none', mb: 3.5 }}>
      <CustomAccordionSummary    
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
       >
        <Typography>{title}</Typography>
      </CustomAccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordian;
