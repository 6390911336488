import React from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const BackButton = () => {

  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };

  return (
    <Button
      variant="outlined"
      fullWidth
      sx={{
        borderColor: "#2C2D64",
        color: "#2C2D64",
        padding: "10px 0",
        borderRadius: "6px",
      }}
      onClick={handleBack}>
      Back
    </Button>
  );
};

export default BackButton;
