import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Toolbar,
  Badge,
  Menu,
  MenuItem,
  Collapse,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { HOME_PAGE } from "../globals/const";
import {
  Edit as EditIcon,
  PeopleOutline as PeopleOutlineIcon,
  Visibility as VisibilityIcon,
  Timeline as TimelineIcon,
  Work as WorkIcon,
  Widgets as WidgetsIcon,
  Source as SourceIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Logout as LogoutIcon,
  Share as ShareIcon,
  TextSnippet as TextSnippetIcon,
  VoiceChat as VoiceChatIcon,
  Business as BusinessIcon,
  Close as CloseIcon,
  NotificationsNone as NotificationsNoneIcon,
  Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import logo from "../assets/iveri.png";
import { logout } from "../globals/utils";
import shareProfile from "../assets/Icons/share.png";
import viewProfileIcon from "../assets/Icons/Icon.png";
import applicationICon from "../assets/Icons/tick-circle.png";
import ResumeIcon from "../assets/Icons/civic-(cvc).png";
import logoutIcon from "../assets/Icons/logout.png";
import notificationIcon from "../assets/Icons/notification.png";

const Sidebar = () => {
  const drawerWidth = 250;
  const userRole = localStorage.getItem("role");
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleConfirmLogout = () => {
    setOpen(false);
    logout();
  };

  const handleCancelLogout = () => {
    setOpen(false);
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfileClick = () => {
    setEditProfileOpen(!editProfileOpen);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <img src={logo} alt="iVeri Logo" style={{ height: "40px" }} />
        </Box>
        <IconButton color="inherit" onClick={handleNotificationClick}>
          <Badge badgeContent={notifications.length} color="secondary">
            <img src={notificationIcon} style={{ width: "24px", height: "24px" }} />
          </Badge>
        </IconButton>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleSidebarToggle}>
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={handleSidebarToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
            <img src={logo} alt="iVeri Logo" style={{ height: "40px" }} />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleSidebarToggle}
              sx={{ ml: "auto" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <List sx={{ padding: 0 }}>
            {userRole === "User" && (
              <Box>
                <ListItemButton onClick={handleEditProfileClick}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img
                      src={viewProfileIcon}
                      style={{ width: "24px", height: "24px" }}
                      alt="basic Profile"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Edit Profile" />
                  {editProfileOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={editProfileOpen} timeout="auto" unmountOnExit>
                  <Box sx={{ pl: 4 }}>
                    <List
                      component="div"
                      disablePadding
                      sx={{ borderLeft: "1px rgb(222, 222, 222) solid" }}>
                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/basic-info-view`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Basic Information"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/personal-details-view`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Personal details"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/address-viewpage`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Address details"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/additional-viewpage`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Additional Details"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/language-viewpage`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Language Profiency"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/education-viewpage`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Education details"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/experience-viewpage`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Experience details"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/skill-viewpage`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Skills"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        component={Link}
                        to={`${HOME_PAGE}/edit-document-locker`}
                        onClick={handleSidebarToggle}>
                        <ListItemText
                          primary="Edit Document Locker"
                          primaryTypographyProps={{ fontSize: "0.9rem" }}
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </Collapse>
                <ListItemButton
                  component={Link}
                  to={`${HOME_PAGE}/view-profile`}
                  onClick={handleSidebarToggle}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img
                      src={viewProfileIcon}
                      style={{ width: "24px", height: "24px" }}
                      alt="View Profile"
                    />
                  </ListItemIcon>
                  <ListItemText primary="View Profile" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to={`${HOME_PAGE}/share-profile-link`}
                  onClick={handleSidebarToggle}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img src={shareProfile} style={{ width: "24px", height: "24px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Share Profile" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to={`${HOME_PAGE}/my-applications`}
                  onClick={handleSidebarToggle}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img src={applicationICon} style={{ width: "24px", height: "24px" }} />
                  </ListItemIcon>
                  <ListItemText primary="My application" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to={`${HOME_PAGE}/resume-parser`}
                  onClick={handleSidebarToggle}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img src={ResumeIcon} style={{ width: "24px", height: "24px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Resume Parser" />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to={`${HOME_PAGE}/resume-preview`}
                  onClick={handleSidebarToggle}>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    <img src={ResumeIcon} style={{ width: "24px", height: "24px" }} />
                  </ListItemIcon>
                  <ListItemText primary="Resume" />
                </ListItemButton>
              </Box>
            )}

            {userRole === "Employer" && (
              <ListItemButton
                component={Link}
                to={`${HOME_PAGE}/role-employer`}
                onClick={handleSidebarToggle}>
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary="Employer" />
              </ListItemButton>
            )}
            <Divider />
            <ListItemButton onClick={handleLogoutClick}>
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <img src={logoutIcon} style={{ width: "24px", height: "24px" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" style={{ color: "red" }} />
            </ListItemButton>
          </List>

          <Box sx={{ flexGrow: 1 }} />
          <Divider />
        </Box>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {notifications.length === 0 ? (
          <MenuItem onClick={handleNotificationClose}>No notifications</MenuItem>
        ) : (
          notifications.map((notification, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleNotificationClose();
                // Add logic to handle notification click
              }}>
              {notification}
            </MenuItem>
          ))
        )}
      </Menu>
      <Dialog
        open={open}
        onClose={handleCancelLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          textAlign="center"
          sx={{ fontSize: "17px", fontWeight: "bold" }}>
          {"Log out"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontSize: "14px" }}>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 1,
            padding: 2,
          }}>
          <Button
            onClick={handleConfirmLogout}
            sx={{
              backgroundColor: "#272260",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#1f1b54",
              },
            }}>
            Yes
          </Button>
          <Button
            onClick={handleCancelLogout}
            sx={{
              color: "#272260",
              marginLeft: "0px !important",
              border: "1px solid #272260",
              "&:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Sidebar;
