import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Container, Grid, Typography, Card, CircularProgress } from "@mui/material";
import NavBar from '../../components/NavBar';
import {AudioRecorder} from 'react-audio-voice-recorder';
import instance from '../../globals/axios';

export default function JDCreator() {
    const [ audioBlob, setAudioBlob ] = useState(null);
    const [ isRecording, setIsRecording ] = useState(false);
    const [ loading, setLoading ] = useState(false)

    const recorderRef = useRef(null);

    const startRecording = () => {
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    const resetRecording = () => {
        setAudioBlob(null);
    };

    const saveRecording = () => {
        if (audioBlob) {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(audioBlob);
        downloadLink.download = 'recorded_audio.mp3';
        downloadLink.click();
        } else {
        console.log('No recorded audio to save.');
        }
    };

    const processRecording = () => {
        if (audioBlob) {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recorded_audio.mp3');

        instance.post('process-audio/', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
            // Process the server response if needed
            console.log('Server response:', response.data);
            })
            .catch(error => {
            console.error('Error during POST request:', error);
            });
        } else {
        console.log('No recorded audio to process.');
        }
    };

    const [translation, setTranslation ] = useState('');

    const handleAudioData = (blob) => {
        const url = URL.createObjectURL(blob);
        setLoading(true)
        if (blob) {
            const formData = new FormData();
            formData.append('audio', blob);
      
            instance.post('process-audio/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
              .then(response => {
                setTranslation(response.data.transcription)
                setLoading(false)
              })
              .catch(error => {
                setLoading(false)
                console.error('Error during POST request:', error);
              });
          } else {
            console.log('No recorded audio to process.');
        }

        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        document.body.appendChild(audio);
    };

    useEffect(() => {
        recorderRef.current && recorderRef.current.requestDevice();
    }, []);

    return (
        <>
        <NavBar />
        <Container maxWidth="xs" style={{ marginTop: '80px' }}>
            <Box textAlign="center">
            <Card elevation={6} sx={{ p: 2, my: 4, borderRadius: "12px" }}>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h5">JD creator with AI</Typography>
                </Grid>
                <Grid item xs={10} sx={{ textAlign: 'center', px:"auto", mx: 'auto', alignContent: 'center', alignItems: 'center' }}>
                    <AudioRecorder
                        isRecording={isRecording}
                        onRecordingComplete={handleAudioData}
                        ref={recorderRef}
                        audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                        }}
                        showVisualizer={true}
                        downloadOnSavePress={true}
                        downloadFileExtension="mp3"
                    />
                </Grid>
                {/* <Grid item xs={8} mx="auto">
                    <Button  fullWidth variant="outlined" onClick={saveRecording} >
                    Process
                    </Button>
                </Grid> */}
                </Grid>
            </Card>
           
            </Box>

            <Box mt={4} p={2} textAlign="center">
            {loading && (
                <CircularProgress />
            )
            }
            {
                translation && (
                <Card elevation={2} sx={{ padding: 2, width: 'fit-content', mx: 'auto' }}>
                    <Typography>
                    <b>Obtained text</b>
                    <br />
                    {translation}
                    </Typography>
                </Card>
                )
            }
            </Box>
        </Container>
        </>
    );
}
