import React, { useState } from "react";
import { Button, Typography, Box, TextField, Select, MenuItem, Container, Grid } from "@mui/material";
import NavBar from "../../components/NavBar";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useEffect } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { FaTimes } from 'react-icons/fa';

const handleSaveClick = () => {
  // Handle final submission logic
};

const initialState = [
  { name: 'Company Name'},
  { name: 'Company PAN'},
  { name: 'Company GST'},
  { name: 'Admin Name'},
  { name: 'Email'},
  { name: 'Mobile No'},
];

export default function EmpSignup() {
  const [companyName, setCompanyName] = useState('');
  const [companyPan, setCompanyPan] = useState('');
  const [companyGst, setCompanyGst] = useState('');
  const [adminName, setAdminName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [otp, setOtp] = useState('');
  const [otp1, setOtp1] = useState(''); // OTP for GST
  const [otp2, setOtp2] = useState(''); // OTP for Email
  const [showOtpInput, setShowOtpInput] = useState(false);

  const formData = new FormData();

  // Append the values to the formData object
  formData.append('companyName', companyName);
  formData.append('companyPan', companyPan);
  formData.append('companyGst', companyGst);
  formData.append('adminName', adminName);
  formData.append('email', email);
  formData.append('mobileNo', mobileNo);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'Company GST') {
      // Conditionally render OTP field for GST
      if (value.length > 0) {
        // Show OTP input for GST
        setOtp1('');
      } else {
        // Hide OTP input for GST
        setOtp1('');
      }
    }

    if (name === 'Email') {
      // Conditionally render OTP field for Email
      if (value.length > 0) {
        // Show OTP input for Email
        setOtp2('');
      } else {
        // Hide OTP input for Email
        setOtp2('');
      }
    }
  
    switch (name) {
      case 'Company Name':
        setCompanyName(value);
        break;
      case 'Company PAN':
        setCompanyPan(value);
        break;
      case 'Company GST':
        setCompanyGst(value);
        break;
      case 'Admin Name':
        setAdminName(value);
        break;
      case 'Email':
        setEmail(value);
        break;
      case 'Mobile No':
        setMobileNo(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    console.log("Form Data:", {
      companyName,
      companyPan,
      companyGst,
      adminName,
      email,
      mobileNo,
      otp,
      otp1,
      otp2,
    });
  };

  const handlePhoneNumberChange = (formattedValue) => {
    setMobileNo(formattedValue);
    setShowOtpInput(formattedValue.length > 0);
  };
  
  const handleOtpChange = (value) => {
    setOtp(value);
  };
  const handleOtp1Change = (value) => {
    setOtp1(value);
  };
  
  const handleOtp2Change = (value) => {
    setOtp2(value);
  };

  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px" marginBottom="15px">
          <Typography variant="h5" style={{ color: "rgb(0, 184, 230)"}}>
          Enter Below details
          </Typography>
        </Box>
      <form onSubmit={handleSubmit}>
      {initialState.map((field) => (
        <Box key={field.name} marginBottom="10px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            margin="5px 0"
          >
            <Grid container spacing='2' mt={.25}>
            <Grid item xs={12}>  
            <Typography style={{ color: 'rgb(0, 184, 230)', fontWeight: '600' }}>
              {field.name}:
            </Typography>
            </Grid>
            <Grid item xs={12}>
            {field.name === 'Mobile No' ? (
              <>
                <Grid item fullWidth xs={12} style={{ marginTop: '10px' }}>
                  <PhoneInput
                    country={'in'}
                    value={mobileNo}
                    onChange={handlePhoneNumberChange}
                    inputProps={{
                      required: true,
                    }}
                    inputStyle={{ width: '100%' }}
                    containerStyle={{ width: '100%' }}
                  />
                </Grid>

                {showOtpInput && (
                  <Grid item fullWidth xs={12} style={{ marginTop: '10px' }}>
                    <MuiOtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4} // Number of OTP inputs
                    />
                  </Grid>
                )}
              </>
            ) : field.name === 'Company GST' ? (
              <>
                <TextField
                  type="text"
                  name={field.name}
                  style={{ minWidth: '395px' }}
                  value={companyGst}
                  onChange={handleChange}
                />
                {companyGst.length > 0 &&(
                  <Grid item fullWidth xs={12} style={{ marginTop: '10px' }}>
                    <MuiOtpInput
                      value={otp1}
                      onChange={handleOtp1Change}
                      numInputs={4} // Number of OTP inputs
                    />
                  </Grid>
                )}
              </>
            ) : field.name === 'Email' ? (
              <>
                <TextField
                  type="text"
                  name={field.name}
                  style={{ minWidth: '395px' }}
                  value={email}
                  onChange={handleChange}
                />
                {email.length > 0 && (
                  <Grid item fullWidth xs={12} style={{ marginTop: '10px' }}>
                    <MuiOtpInput
                      value={otp2}
                      onChange={handleOtp2Change}
                      numInputs={4} // Number of OTP inputs
                    />
                  </Grid>
                )}
              </>
            ) : (
              <>
                <TextField
                  type="text"
                  name={field.name}
                  style={{ minWidth: '395px' }}
                  value={
                    field.name === 'Company Name'
                      ? companyName
                      : field.name === 'Company PAN'
                      ? companyPan
                      : field.name === 'Admin Name'
                      ? adminName
                      : null
                  }
                  onChange={handleChange}
                />
              </>
            )}
            
            </Grid> 
           </Grid>
          </Box>
        </Box>
      ))}

<Button
    type="submit"
    variant="contained"
    style={{
      color: "rgb(3, 22, 48)",
      borderRadius: "30px",
      backgroundColor: "rgb(0, 184, 230)",
      padding: "2px 30px",
      fontWeight: "600",
      fontSize: "15px",
      marginTop: "20px",
    }}
  >
    SUBMIT
  </Button>
    </form>

      </Container>
    </>
  );
}