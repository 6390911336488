import React, { useState } from 'react';
import { Grid, Typography, TextField, Button, Container } from '@mui/material';
import instance from '../globals/axios';
import { useEffect } from 'react';

const CompanyForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyPan, setCompanyPan] = useState('');
  const [companyGst, setCompanyGst] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const role = window.localStorage.getItem("role");

  useEffect(() => {

    instance.get('/agency/agency-details/') // Replace with your API endpoint
      .then(response => {
        const data = response.data.data[0]; // Assuming you want to extract the first element

        // Set the state variables with the response data
        setCompanyName(data.company_name);
        setCompanyPan(data.company_pan);
        setCompanyGst(data.company_gst);
        setEmail(data.email);
        setPhoneNumber(data.phone_number);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  
  
  const handleFormSubmit = async () => {
    const formData = new FormData();
    formData.append('company_name', companyName); 
    formData.append('company_pan', companyPan);
    formData.append('company_gst', companyGst);
    formData.append('email', email);
    formData.append('phone_number', phoneNumber);
    console.log(formData.entries)
    

    try {
        const response = await instance.post('/agency/agency-details/', formData);
        console.log(response.data);
    } 
    catch (error) {
        console.error('Error submitting form data:', error);
        alert(error.message)
    }

  };
  

  return (
    <Container maxWidth= "md" style={{ marginTop: '80px' }}>
    
        <Grid container spacing={4}>
        <Grid item mt={4} xs={12}>
            <Typography variant="h5" textAlign="center">Company Information</Typography>
        </Grid>
        <Grid item xs={6}>
            <TextField
            fullWidth
            label="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
            fullWidth
            label="Company PAN"
            value={companyPan}
            onChange={(e) => setCompanyPan(e.target.value)}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
            fullWidth
            label="Company GST"
            value={companyGst}
            onChange={(e) => setCompanyGst(e.target.value)}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            />
        </Grid>

        <Grid xs={7} my={4} mx="auto">
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        marginBottom: 4,
                        color: "rgb(3, 22, 48)",
                        borderRadius: "30px",
                        backgroundColor: "rgb(0, 184, 230)",
                        padding: "4px 30px",
                        fontWeight: "600",
                        fontSize: "15px",
                    }}
                    onClick={handleFormSubmit}
                >
                    SAVE
                </Button>
            </Grid>

        </Grid>

    </Container>
  );
};

export default CompanyForm;
