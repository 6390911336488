import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, CircularProgress, Grid, Box } from '@mui/material';
import { Assessment, PersonAdd, People, CalendarToday, CalendarMonth } from '@mui/icons-material';
import axios from 'axios';
import instance from '../globals/axios';

const DashboardMetrics = () => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    instance.get('interview-system/dashboard/interview-overview/')
      .then(response => {
        setMetrics(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching metrics:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const getValue = (key) => metrics && metrics[key] != null ? metrics[key] : 0;

  return (
    <Grid container spacing={2}>
  <Grid item xs={3} >
    <Card sx={{ bgcolor: 'background.paper', boxShadow: 3, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6" component="div">Candidates Attempted</Typography>
        <Typography variant="h4" component="div">{getValue('total_attempted')}</Typography>
      </Box>
      <Assessment color="primary" sx={{ fontSize: 40 }} />
    </Card>
  </Grid>
  <Grid item xs={3} >
    <Card sx={{ bgcolor: 'background.paper', boxShadow: 3, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6" component="div">Candidates Unattempted</Typography>
        <Typography variant="h4" component="div">{getValue('total_unattempted')}</Typography>
      </Box>
      <PersonAdd color="warning" sx={{ fontSize: 40 }} />
    </Card>
  </Grid>
  {/* <Grid item xs={3} >
    <Card sx={{ bgcolor: 'background.paper', boxShadow: 3, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6" component="div">Candidates Expired</Typography>
        <Typography variant="h4" component="div">{getValue('total_expired')}</Typography>
      </Box>
      <People color="error" sx={{ fontSize: 40 }} />
    </Card>
  </Grid> */}
  <Grid item xs={3} >
    <Card sx={{ bgcolor: 'background.paper', boxShadow: 3, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6" component="div">Upcoming Interviews</Typography>
        <Typography variant="h4" component="div">{getValue('total_upcoming')}</Typography>
      </Box>
      <CalendarToday color="action" sx={{ fontSize: 40 }} />
    </Card>
  </Grid>
  <Grid item xs={3} >
    <Card sx={{ bgcolor: 'background.paper', boxShadow: 3, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h6" component="div">Today's Interviews</Typography>
        <Typography variant="h4" component="div">{getValue('total_today')}</Typography>
      </Box>
      <CalendarMonth color="info" sx={{ fontSize: 40 }} />
    </Card>
  </Grid>
</Grid>

  );
};

export default DashboardMetrics;
