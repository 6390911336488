import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button,Typography } from "@mui/material";

const GenericPopup = ({ open, title, message, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericPopup;
