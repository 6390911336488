import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  Container,
  Card,
  CardContent,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
  TableRow,
  Paper,
  Grid,
  Box,
  FormControl,
  TextField,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select, 
  MenuItem,
  Checkbox,
} from "@mui/material";
import { MdVisibility } from 'react-icons/md';
import api from "../../globals/axios.js";
import { useHistory } from "react-router-dom";
import { SERVER_BASE_ADDRESS } from "../../globals/const.js";
import instance from "../../globals/axios.js";
import { Check, Clear, OpenInNew } from "@mui/icons-material";
import {
  LocationOn,
  MonetizationOn,
  Event,
  Description,
  AccountCircle,
  Business,
  AccountBalance,
  Person,
  EventNote,
} from "@mui/icons-material";
import {
  FaLinkedin,
  FaBehance,
  FaGithub,
  FaFacebook,
  FaUser,
  FaCheck,
  FaTimes,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import DocumentLocker from "./EditPages/DocumentLocker.js";
import NavBar from "../../components/NavBar.js";
import ProfileHeader from "../../components/ProfileHeader.js";
import Sidebar from "../../components/Sidebar.js";
import DoneIcon from '@mui/icons-material/Done';
import { styled } from '@mui/material/styles';
import AdditionalInfo from "./EditPages/AdditionalInfo.js";
import EducationTimeline from "../../components/Timeline/EducationTimeline.js";
import ExperienceTimeline from "../../components/Timeline/ExperienceTimeline.js";
import CustomTypography from "../../components/Textfield/CustomTypography.js";
import Editbutton from "../../assets/Icons/Edit.png"
import DocumentBox from "../../components/DocumentBox.js";

const handlePageSelect = (event) => {
  const selectedPage = event.target.value;

  if (selectedPage) {
    window.location.href = selectedPage; 
  }
};

export default function ShareProfileWithoutDoc() {
  const [personalDetails, setPersonalDetails] = useState({});
  const [mediaLinks, setMediaLinks] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [skills, setSkills] = useState([]);
  const [langData, setLangData] = useState(null);
  const [iveriId, setIveriId] = useState(localStorage.getItem("iveri_id"));
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phone_number"));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [gender, setGender] = useState(localStorage.getItem("gender"));
  const [usePermanentAddress, setUsePermanentAddress] = useState(true);
  const [selectedOption, setSelectedOption] = useState('profile');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };


  function convert_python_case(str) {
    return str
      .replace("_", " ")
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  }

  useEffect(() => {
    instance
      .get(SERVER_BASE_ADDRESS + "view-profile/")
      .then((res) => res.data)
      .then((data) => {
        setPersonalDetails(data.personal_details);
        setLangData(data.personal_details.language_data);
        let str = data.personal_details.hobbies;
        let validJsonStr = str.replace(/'/g, '"');
        let myArray = JSON.parse(validJsonStr);
        setHobbies(myArray);
        setMediaLinks(data.links);
      });
  }, []);

  const handleEditBtn = () => {
    history.push("/edit-profile-menu");
  };
  const handleBasicInfoBtn = () => {
    history.push("/basic-info-view");
  };
  const handlePersonalInfoBtn = () => {
    history.push("/personal-details-view");
  };
  
  const handleSkillBtn = () => {
    history.push("/skill-viewpage");
  }

  const handleAddtionalBtn = () => {
    history.push("/additional-viewpage");
  };
  
  const handleAddressbtn = () => {
    history.push("/address-viewpage")
  }

  const handleEditSocialMedia = () => {
    history.push("/share-profile");
  };
  const handleEditLanguageProfiency = () => {
    history.push("/language-viewpage");
  };
  const handleEditEducation = (id) => {
    history.push("/education-viewpage");
  };
  const handleEditExperience = () => {
    history.push("/experience-viewpage");
  };
  const handleDocumentLockerEdit = () => {
    history.push("/edit-document-locker");
  };
  const handleAddtionDataBtn = () => {
    history.push("/additional-viewpage");
  };

  const [educationalDetails, setEducationalDetails] = useState([]);
  console.log('ProfileHeader component rendered');

  useEffect(() => {
    instance
      .get("educational-details/")
      .then((response) => {
        setEducationalDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, []);

  const [permanentAddressDetails, setPermanentAddressDetails] = useState([]);

  useEffect(() => {
    instance
      .get("update-address/")
      .then((response) => {
        setPermanentAddressDetails(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, []);

  const [tempAddressDetails, setTempAddressDetails] = useState(null); // Change to null for initial state

  useEffect(() => {
    instance
      .get("update-temp-address/")
      .then((response) => {
        setTempAddressDetails(response.data); 
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);


  const [additionalInfoDetails, setAdditionalInfoDetails] = useState({
    id: null,
    disability: '',
    visa_country: '',
    visa_type: '',
    visa_file: '',
    vf_uploaded_at: '',
    diff_abled_file: '',
    da_uploaded_at: '',
    user: null,
  });

  useEffect(() => {
    instance
      .get("additional-info/")
      .then((response) => {
        if (response.data.success && response.data.data.length > 0) {
          setAdditionalInfoDetails(response.data.data[0]); // Access the first item in the array
        } else {
          console.error("No additional info found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  


  const openDocument = (url) => {
    window.open(url, "_blank");
  };
  
  const [basicInfoDetails, setbasicInfoDetails] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    adhaar_number: "",
    pan_number: "",
    birth_date: "",
    linkedin: "",
    github: "",
    facebook: "",
    behance: "",
    other: "",
    gender: "",
    relationship_status: "",
    profile_picture: "",
    hobbies: [],
  });

  useEffect(() => {
    instance
      .get("basic-info/")
      .then((response) => {
        setbasicInfoDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const socialMediaLinks = {
    linkedin: {
      url: basicInfoDetails.linkedin,
      icon: <FaLinkedin style={{ fontSize: 30, color: '#0077b5' }} />
    },
    github: {
      url: basicInfoDetails.github,
      icon: <FaGithub style={{ fontSize: 30, color: '#333' }} />
    },
    facebook: {
      url: basicInfoDetails.facebook,
      icon: <FaFacebook style={{ fontSize: 30, color: '#4267B2' }} />
    },
    behance: {
      url: basicInfoDetails.behance,
      icon: <FaBehance style={{ fontSize: 30, color: '#1769ff' }} />
    },
    other: {
      url: basicInfoDetails.other,
      icon: <FaUser style={{ fontSize: 30, color: '#262262' }} /> // Default icon for other platforms
    }
  };

  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    instance
      .get("experience-details/")
      .then((response) => {
        setExperiences(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleGenderChange = (event) => {
    setPersonalDetails({ ...personalDetails, gender: event.target.value });
  };


  const calculateAge = (birthDate) => {
    if (!birthDate) return "N/A"; // or any placeholder you prefer
  
    const today = new Date();
    const birthDateObj = new Date(birthDate);
  
    if (isNaN(birthDateObj)) return "N/A"; // checks if birthDate is an invalid date
  
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
  
    return age;
  };

  const handleCheckboxChange = (event) => {
    setUsePermanentAddress(event.target.checked);
  };



  useEffect(() => {
    instance
    .get('edit-skills/')
      .then(response => {
        setSkills(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the skills!', error);
      });
  }, []);

  const [languageData,setLanguageData] = useState([]);

  useEffect(() => {
    instance
    .get('language-data/')
      .then(response => {
        setLanguageData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the languages!', error);
      });
  }, []);
  const [familyData, setFamilyData] = useState([]); 
  useEffect(() => {
    instance
    .get('family-details/')
      .then(response => {
        setFamilyData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the languages!', error);
      });
  }, []);

  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    // Separate the country code, first part, and second part
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{5})$/);
    if (match) {
      return `(+${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  
  const history = useHistory();
  const [isPaidUser, setIsPaidUser] = useState(false);

  useEffect(() => {
    const paidStatus = localStorage.getItem("is_paid_user") === "true";
    setIsPaidUser(paidStatus);
  }, []);

  const handleGenerateLink = async () => {
    try {
      const data = {
        "sharing_mode": "WITHOUT_DOC",
        "document_types": [],
        "is_public": true
      };
      
      const response = await instance.post(SERVER_BASE_ADDRESS + "create-shared-link/", data);
      console.log(response.data);
      
      // After successful link generation, navigate to the share profile page
      history.push("/link-gen-without-docs");
      
    } catch (error) {
      console.error("Error generating link:", error);
      // You might want to add error handling here
    }
  };

  const handleClick = () => {
    if (!isPaidUser) {
      const currentPath = window.location.pathname;
      history.push('/payment-page', { from: currentPath });
    } else {
      handleGenerateLink();
    }
  };



return (
     <>

    <Container maxWidth= "xs" style={{ marginTop: "30px", marginLeft: "3.3px"}}>
      <ProfileHeader showUploadIcon={false} />
      <Grid container spacing={2} sx={{ mb: "0.2rem", pb: "0" }} flex justifyContent="center" alignItems="center">

  <Grid item xs={12}>
    <Box 
      sx={{
        backgroundColor: "#F8F9F9", 
        padding: 2,
        borderRadius: 1
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}} color="#262262">
          Basic Information
        </Typography>
        <IconButton onClick={handleBasicInfoBtn}>
          <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
        </IconButton>
      </Box>

      {personalDetails.name ? (
        <Box display="flex" alignItems="center" mt={1} mb={0.8}>
          <CustomTypography>Name: </CustomTypography>
          <Typography>{personalDetails.name}</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mt={1} mb={0.8}>
          <CustomTypography>Name: </CustomTypography>
          <Typography>No data yet</Typography>
        </Box>
      )}

      {iveriId ? (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Iveri ID:</CustomTypography>
          <Typography>{iveriId}</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Iveri ID:</CustomTypography>
          <Typography>No data yet</Typography>
        </Box>
      )}

      {personalDetails.gender ? (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Gender:</CustomTypography>
          <Typography>
            {personalDetails.gender === "M"
              ? "Male"
              : personalDetails.gender === "F"
              ? "Female"
              : personalDetails.gender === "O"
              ? "Other"
              : ""}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Gender:</CustomTypography>
          <Typography>No data yet</Typography>
        </Box>
      )}

      {phoneNumber ? (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Phone number:</CustomTypography>
          <Typography>{formatPhoneNumber(phoneNumber)}</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Phone number:</CustomTypography>
          <Typography>No data yet</Typography>
        </Box>
      )}

      {email ? (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Email Id:</CustomTypography>
          <Typography>{email}</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mb={0.8}>
          <CustomTypography>Email Id:</CustomTypography>
          <Typography>No data yet</Typography>
        </Box>
      )}
    </Box>
  </Grid>



          <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Personal Information</Typography>
                        <IconButton onClick={handlePersonalInfoBtn}>
                          <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
                        </IconButton>
              </Box>
          
              <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                <CustomTypography>Date of birth: </CustomTypography>
                <Typography>
                  {basicInfoDetails.birth_date
                    ? new Date(basicInfoDetails.birth_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })
                    : "N.A."}
                </Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>Age:</CustomTypography>
                <Typography>{calculateAge(basicInfoDetails.birth_date)}</Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
                <CustomTypography>
                  Relationship:
                </CustomTypography>
                <Typography>
                  {basicInfoDetails.relationship_status === "S"
                    ? "Single"
                    : basicInfoDetails.relationship_status === "M"
                    ? "Married"
                    : basicInfoDetails.relationship_status === "D"
                    ? "Divorce"
                    : basicInfoDetails.relationship_status === "W"
                    ? "Widowed"
                    : basicInfoDetails.relationship_status === "H"
                    ? "Homemaker"
                    : ""}
                </Typography>
              </Box>
          
              <Box display="flex" alignItems="center" mb={0.8}>
  <CustomTypography>Hobbies:</CustomTypography>
  <Typography>
  {Array.isArray(basicInfoDetails.hobbies) && basicInfoDetails.hobbies.length > 0 && (
    basicInfoDetails.hobbies[0].split(',').map((hobby, index) => (
      <span key={hobby}>
        <Chip
          label={hobby.trim()}
          sx={{
            backgroundColor: "#F8F9F9 !important",
            fontSize: "15.5px !important",
            padding: 0,
            '& .MuiChip-label': {
              padding: 0,
            }
          }}
        />
        {index < basicInfoDetails.hobbies[0].split(',').length - 1 && ", "}
      </span>
    ))
  )}
</Typography>
</Box>

              <Box
  sx={{
    backgroundColor: "#F8F9F9",
    borderRadius: 1,
    margin: 0.5
  }}
>
  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginY: 1 }}>
    <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">Family Details</Typography>
  </Box>

  <Box sx={{ marginTop: 0.5 }}>
    <TableContainer>
      <Table sx={{ borderCollapse: 'collapse' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Relationship</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Name</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Age</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Occupation</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {familyData.map((member) => (
            <TableRow key={member.id}>
              <TableCell sx={{ borderBottom: 'none', padding: '4px'}}>{member.relationship}</TableCell>
              <TableCell sx={{ borderBottom: 'none', padding: '4px'}}>{member.name}</TableCell>
              <TableCell sx={{ borderBottom: 'none', padding: '4px'}}>{member.age !== null ? member.age : "N/A"}</TableCell>
              <TableCell sx={{ borderBottom: 'none', padding: '4px'}}>{member.occupation !== null ? member.occupation : "N/A"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
</Box>


        <Box display="flex" alignItems="center" mb={0.8}>
          <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">
            Social Media Links
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" mb={0.8} mt={1}>
      {basicInfoDetails.social_links && basicInfoDetails.social_links.length > 0 ? (
        <Box display="flex" flexDirection="column" gap={2}>
          {basicInfoDetails.social_links.map((link, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Typography 
                component="span" 
                sx={{ 
                  marginRight: 1,
                  color: "#262262 !important",
                  fontSize: "16px !important",
                  fontWeight: "510 !important",
                }}
              >
                {link.name} -
              </Typography>
              <Typography
                component="a"
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  wordBreak: 'break-all',
                  color: '#1976d2',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {link.url}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>No social links available</Box>
      )}
    </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between">
  <Typography
    sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }}
    color="#262262"
  >
    Additional Information
  </Typography>
  <IconButton onClick={handleAddtionDataBtn}>
          <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
        </IconButton>
        </Box>

{additionalInfoDetails.disability && (
  <Box display="flex" alignItems="center" mt={1} mb={0.8}>
    <CustomTypography>Disability: </CustomTypography>
    <CustomTypography>
      {additionalInfoDetails.disability.charAt(0).toUpperCase() +
        additionalInfoDetails.disability.slice(1).toLowerCase()}
    </CustomTypography>
  </Box>
)}

<Box display="flex" alignItems="center" mt={1} mb={0.8}>
  <CustomTypography>Visa Type: </CustomTypography>
  <CustomTypography>
    {additionalInfoDetails.visa_type ? additionalInfoDetails.visa_type : "N/A"}
  </CustomTypography>
</Box>



            </Box>
        </Grid>

        <Grid item xs={12}>
      <Box
        sx={{
          backgroundColor: "#F8F9F9",
          borderRadius: 1,
          margin: 0.5 
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ margin: 0.5 }}>
          <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">Language Proficiency</Typography>
          <IconButton onClick={handleEditLanguageProfiency} sx={{ padding: 0.5 }}>
          <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
          </IconButton>
        </Box>

        <Box sx={{ marginTop: 0.5 }}>
          <TableContainer>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Language</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Read</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Write</Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">Speak</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {languageData.map((language) => (
                  <TableRow key={language.id}>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>{language.languageName}</TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>{language.readingProficiency}</TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>{language.writingProficiency}</TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>{language.speakingProficiency}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Grid>

  <Grid item xs={12}>
    <Box 
      sx={{
        backgroundColor: "#F8F9F9", 
        padding: 2,
        borderRadius: 1
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}} color="#262262">
          Address Details
        </Typography>
        <IconButton onClick={handleAddressbtn}>
          <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center" mb={0.8}>
        <Typography sx={{fontSize: "22px !important"}} color="#262262">
          Temporary Address
        </Typography>
      </Box>

      {tempAddressDetails ? (
        <>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>House No: </CustomTypography>
            <Typography>{tempAddressDetails.house_no || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Street: </CustomTypography>
            <Typography>{tempAddressDetails.street || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Area: </CustomTypography>
            <Typography>{tempAddressDetails.area || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>City: </CustomTypography>
            <Typography>{tempAddressDetails.city || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>District: </CustomTypography>
            <Typography>{tempAddressDetails.district || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Post Office: </CustomTypography>
            <Typography>{tempAddressDetails.post_office || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Pincode: </CustomTypography>
            <Typography>{tempAddressDetails.pincode || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>State: </CustomTypography>
            <Typography>{tempAddressDetails.state || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Country: </CustomTypography>
            <Typography>{tempAddressDetails.country || "No data yet"}</Typography>
          </Box>
        </>
      ) : (
        <Typography>No data yet</Typography>
      )}

      <Box alignItems="center" mt={2} mb={0.8}>
        <Typography sx={{fontSize: "22px !important"}} color="#262262">
          Permanent Address
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={usePermanentAddress}
              onChange={handleCheckboxChange}
            />
          }
          label="Same as above"
        />
      </Box>

      {!usePermanentAddress && permanentAddressDetails ? (
        <>
          <Box display="flex" alignItems="center" mb={0.8}>
            <Typography sx={{ fontSize: "22px !important" }} color="#262262">
              Permanent Address
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>House No: </CustomTypography>
            <Typography>{permanentAddressDetails.house_no || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Street: </CustomTypography>
            <Typography>{permanentAddressDetails.street || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Area: </CustomTypography>
            <Typography>{permanentAddressDetails.area || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>City: </CustomTypography>
            <Typography>{permanentAddressDetails.city || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>District: </CustomTypography>
            <Typography>{permanentAddressDetails.district || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Post Office: </CustomTypography>
            <Typography>{permanentAddressDetails.post_office || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Pincode: </CustomTypography>
            <Typography>{permanentAddressDetails.pincode || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>State: </CustomTypography>
            <Typography>{permanentAddressDetails.state || "No data yet"}</Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1} mb={0.8}>
            <CustomTypography>Country: </CustomTypography>
            <Typography>{permanentAddressDetails.country || "No data yet"}</Typography>
          </Box>
        </>
      ) : (
        !usePermanentAddress && <Typography>No data yet</Typography>
      )}
    </Box>
  </Grid>


        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Education Information</Typography>
                        <IconButton onClick={handleEditEducation}>
                        <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
                        </IconButton>
              </Box>
               <EducationTimeline />
             </Box> 
            </Grid>

        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Experience Details</Typography>
                        <IconButton onClick={handleEditExperience}>
                        <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
                        </IconButton>
              </Box>
               <ExperienceTimeline />
             </Box> 
            </Grid>
     

        <Grid item xs={12}>
            <Box 
              sx={{
                backgroundColor: "#F8F9F9", 
                padding: 2,
                borderRadius: 1
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Skills</Typography>
                        <IconButton onClick={handleSkillBtn}>
                        <img src={Editbutton} style={{ width: '24px', height: '24px' }}/>
                        </IconButton>
              </Box>
      <Box display="flex" flexDirection="column">
        {skills.map(skill => (
          <>
         <Typography>
           <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#262262" }}>
             Skills:
           </Typography>
           <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
             {` ${skill.skill}`}
           </Typography>
         </Typography>

          </>
        ))}
      </Box>
    </Box>
    </Grid>

    <Grid item xs={12} sm={2}>
    <Box onClick={handleClick}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            backgroundColor: "#272260",
            minWidth: "30px",
            mb: 2,
            color: "white",
            minHeight: "35px",
            border: "1px solid #666671",
            textTransform: "none",
            boxShadow: "none",
            fontSize: "18px",
            padding: "10px 0px",
            "&:hover": {
              backgroundColor: "#1a1640"
            }
          }}
        >
          {isPaidUser ? "Generate Link" : "Pay and Generate Link"}
        </Button>
      </Box>
            </Grid>

          </Grid>
      </Container>
     </>
  )
}