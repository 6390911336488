import React, { useState, useEffect } from 'react';
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import { Button, TextField, Typography, Box, Card, Grid, MenuItem, Container, Checkbox, FormControlLabel } from "@mui/material";
import instance from "../../../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import { statesData, citiesData } from "./data";
import ProfileHeader from '../../../components/ProfileHeader.js';
import DoneIcon from '@mui/icons-material/Done';
import Sidebar from '../../../components/Sidebar.js';

export default function TempAddress() {
  const [houseNo, setHouseNo] = useState('');
  const [street, setStreet] = useState('');
  const [landmark, setLandmark] = useState('');
  const [area, setArea] = useState('');
  const [city, setCity] = useState('');
  const [postOffice, setPostOffice] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [country, setCountry] = useState('India');
  
  const [selectedState, setSelectedState] = useState('');
  const [availableCities, setAvailableCities] = useState([]);
  
  const [useTempAsPermanent, setUseTempAsPermanent] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleStateChange = (state) => {
    setSelectedState(state);
    setAvailableCities(citiesData[state] || []);
    validateForm();
  };

  const validateForm = () => {
    const requiredFields = [houseNo, street, landmark, area, city, postOffice, district, selectedState, pincode];
    const isValid = requiredFields.every(field => field.trim() !== '');
    setIsFormValid(isValid);
  };

  useEffect(() => {
    instance.get(SERVER_BASE_ADDRESS + "update-temp-address/")
      .then((res) => {
        const addressData = res.data;
        setHouseNo(addressData.house_no || '');
        setStreet(addressData.street || '');
        setLandmark(addressData.landmark || '');
        setArea(addressData.area || '');
        setCity(addressData.city || '');
        setPostOffice(addressData.post_office || '');
        setDistrict(addressData.district || '');
        setSelectedState(addressData.state || '');
        setPincode(addressData.pincode || '');
        
        if (addressData.state) {
          setAvailableCities(citiesData[addressData.state] || []);
        }
        validateForm();
      })
      .catch((error) => {
        console.error("Error fetching address data:", error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = new FormData();

    const pincodeRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

    if (!pincode.match(pincodeRegex)) {
      alert("Please enter a valid Indian PIN code.");
      return;
    }

    updatedFormData.append('house_no', houseNo);
    updatedFormData.append('street', street);
    updatedFormData.append('landmark', landmark);
    updatedFormData.append('area', area);
    updatedFormData.append('city', city);
    updatedFormData.append('post_office', postOffice);
    updatedFormData.append('district', district);
    updatedFormData.append('state', selectedState);
    updatedFormData.append('pincode', pincode);
    updatedFormData.append('country', country);

    instance.post(SERVER_BASE_ADDRESS + "update-temp-address/", updatedFormData)
      .then((res) => {
        console.log(res.data);
        alert("Temporary address saved successfully");
        
        if (useTempAsPermanent) {
          instance.post(SERVER_BASE_ADDRESS + "update-address/", updatedFormData)
            .then((res) => {
              console.log(res.data);
              alert("Permanent address saved successfully");
              setTimeout(() => {
                window.location = "/address-viewpage"; 
              }, 1000);
            })
            .catch((error) => {
              console.error(error);
              alert("Failed to save permanent address");
            });
        } else {
          setTimeout(() => {
            window.location = "/address-viewpage"; 
          }, 1000); 
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to save temporary address");
      });
  };

  return (
    <>
      <Sidebar />
      <Container style={{ marginTop: '30px' }}>
        <ProfileHeader showUploadIcon={false} />
        <Box display="flex" alignItems="center" pb={1} mt={2}>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>Add Temporary Address</Typography>
          <DoneIcon sx={{ color: 'white', backgroundColor: "#262262", fontSize: "15px", borderRadius: "100%", ml: 1 }} />
        </Box>

        <Grid container spacing={2} sx={{ my: "0.2rem" }}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            House No<span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            value={houseNo}
            onChange={(e) => {
              setHouseNo(e.target.value);
              validateForm();
            }}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Street<span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            value={street}
            onChange={(e) => {
              setStreet(e.target.value);
              validateForm();
            }}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Landmark<span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            value={landmark}
            onChange={(e) => {
              setLandmark(e.target.value);
              validateForm();
            }}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontSize: "15px" }} pb={1}>
            Area<span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            value={area}
            onChange={(e) => {
              setArea(e.target.value);
              validateForm();
            }}
            variant="outlined"
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              borderRadius: "5px",
              height: "45px",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
                '& input': {
                  padding: '10px 14px', 
                },
              },
            }}
          />
        </Grid>
    
        <Grid item xs={12} md={4}>
          <Typography style={{ marginTop: "6px" }} pb={1}>State<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            select
            required
            size="small"
            value={selectedState}
            onChange={(e) => handleStateChange(e.target.value)}
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              height: "45px",
              borderRadius: "5px",
            }}
            fullWidth
          >
            {statesData.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography color="black" style={{ marginTop: "6px"}} pb={1}>City<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            select
            required
            size="small"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              validateForm();
            }}
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              height: "45px",
              borderRadius: "5px",
            }}
            fullWidth
          >
            {availableCities.map((city, index) => (
              <MenuItem key={index} value={city}>
                {city}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography color="black" style={{ marginTop: "6px" }} pb={1}>District<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            variant="outlined"
            name="district"
            value={district}
            onChange={(e) => {
              setDistrict(e.target.value);
              validateForm();
            }}
            required
            fullWidth
            size="small"
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              height: "45px",
              borderRadius: "5px",
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography color="black" style={{ marginTop: "8px" }} pb={1}>Post Office<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            variant="outlined"
            value={postOffice}
            onChange={(e) => {
              setPostOffice(e.target.value);
              validateForm();
            }}
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              height: "45px",
              borderRadius: "5px",
            }}
            size="small"
          />
        </Grid>
    
        <Grid item xs={12} md={4}>
          <Typography color="black" style={{ marginTop: "8px" }} pb={1}>Pincode<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            variant="outlined"
            name="pincode"
            value={pincode}
            onChange={(e) => {
              setPincode(e.target.value);
              validateForm();
            }}
            required
            fullWidth
            sx={{
              backgroundColor: "rgba(214,214,214, 0.3)",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              height: "45px",
              borderRadius: "5px",
            }}
            size="small"
            onKeyPress={(event) => {
              const charCode = event.charCode;
              if (!(charCode >= 48 && charCode <= 57)) {
                event.preventDefault();
              }
            }}
          />
        </Grid>
          
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useTempAsPermanent}
                onChange={(e) => setUseTempAsPermanent(e.target.checked)}
                color="primary"
              />
            }
            label="Use my temporary address as my permanent address"
          />
        </Grid>
        </Grid>
        
        <Grid
        container
        justifyContent="center"
         my={3}>
          <Grid xs={12} sm={6}>
            <Button
              variant="contained"
              fullWidth
              sx={{ backgroundColor: "#272260" }}
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              SAVE
            </Button>
          </Grid>

        {!useTempAsPermanent && (
          <Grid xs={12} sm={6} mt={2}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ border: "1px solid #272260", color: "black" }}
              onClick={() => window.location.href = '/edit-address'}
            >
              Add Permanent Address
            </Button>
          </Grid>
        )}
       </Grid>

      </Container>
    </>
  );
}