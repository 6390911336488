import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Container, Typography, Grid, TextField, Box } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./DatePicker.css";

import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import instance from "../../../globals/axios.js";
import axios from "axios";
import dayjs from "dayjs";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ProfileHeader from "../../../components/ProfileHeader.js";
import Sidebar from "../../../components/Sidebar.js";
import CustomButton from "../../../components/Button/CustomButtom.js";
import OtpPopup from "../../../components/Popups/OtpPopup.js";


const styles = {
  customDatePicker: {
    margin: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
};

export default function BasicInfo() {
  const [isSaved, setIsSaved] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    adhaar_number: "",
    pan_number: "",
    driving_licence_number: "",
    alternate_mobile_number: "",
    alternate_email: "",
    videoFile: null,
    aadhar_file: null,
    pan_file: null,

  });
  const [showAlternateNumber, setShowAlternateNumber] = useState(false);
  const [showAlternateEmail, setShowAlternateEmail] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // Track OTP sent status
  const history = useHistory();

  const updateFormData = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMobileNumberChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      mobile_number: value,
    }));
  };
  const handleAlternateMobileNumberChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      alternate_mobile_number: value,
    }));
  };

  const handleSendOtp = () => {
    if (formData.alternate_mobile_number) {
      const phoneNumber = formData.alternate_mobile_number.startsWith("91")
        ? formData.alternate_mobile_number.slice(2)
        : formData.alternate_mobile_number;
      instance
        .get(`/otp/otpmoduleintegrations/send_otp/?phone_number=${phoneNumber}`, {
          mobile_number: formData.alternate_mobile_number,
        })
        .then((response) => {
          if (response.status === 200) {
            setOtpSent(true); // OTP sent successfully
            setShowOtpModal(true); // Show OTP modal
          }
        })
        .catch((error) => {
          console.error("Error sending OTP:", error);
          alert("Error sending OTP. Please try again.");
        });
    } else {
      alert("Please enter a valid alternate mobile number.");
    }
  };
  const handleSubmitOtp = (otp) => {
    const phoneNumber = formData.alternate_mobile_number.startsWith("91")
      ? formData.alternate_mobile_number.slice(2)
      : formData.alternate_mobile_number;
    instance
      .get(`/otp/otpmoduleintegrations/verify_otp/?data=${phoneNumber}&otp=${otp}`)
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          setOtpVerified(true); // OTP verified
          setShowOtpModal(false); // Close OTP modal
          alert("OTP Verified Successfully!");
        } else {
          alert("Invalid OTP. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        alert("Error verifying OTP.");
      });
  };

  const getIconStyle = (icon, selectedIcon) => ({
    cursor: "pointer",
    marginRight: "10px", // Adjust the margin as needed
    color: icon === selectedIcon ? "blue" : "gray", // Highlight the selected icon
  });
  const [verifiedDocuments, setVerifiedDocuments] = useState({
    ADHAR: false,
    PANCR: false,
    DRVLC: false
  });
  

  useEffect(() => {
    instance
      .get("basic-info/")
      .then((res) => {
        const basicInfoData = res.data;

        updateFormData(basicInfoData);
        console.log(res.data);
        if (basicInfoData.adhaar_number || basicInfoData.pan_number || basicInfoData.driving_licence_number) {
          setVerifiedDocuments(prev => ({
            ADHAR: !!basicInfoData.adhaar_number,
            PANCR: !!basicInfoData.pan_number,
            DRVLC: !!basicInfoData.driving_licence_number
          }));
        }
  
        if (res.data.alternate_email) {
          setShowAlternateEmail(true);
        }
        if (res.data.alternate_mobile_number) {
          setShowAlternateNumber(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching basic info:", error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form Data:', formData);
    const formDataToSend = new FormData();

    formDataToSend.append("first_name", formData.first_name);
    formDataToSend.append("middle_name", formData.middle_name);
    formDataToSend.append("last_name", formData.last_name);
    formDataToSend.append("mobile_number", formData.mobile_number);
    formDataToSend.append("email", formData.email);
    if (formData.alternate_email) {
      formDataToSend.append("alternate_email", formData.alternate_email);
    }
    if (formData.alternate_mobile_number && otpVerified) {
      formDataToSend.append("alternate_mobile_number", formData.alternate_mobile_number);
    }
    formDataToSend.append("adhaar_number", formData.adhaar_number);
    formDataToSend.append("driving_licence_number", formData.driving_licence_number);
    formDataToSend.append("pan_number", formData.pan_number);
    instance
      .post(SERVER_BASE_ADDRESS + "basic-info/", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        setIsSaved(true);
        alert("Data Saved Successfully");
        setTimeout(() => {
          window.location = "/basic-info-view";
        }, 200);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (!(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122)) {
      event.preventDefault();
    }
  };

  const handleNextClick = () => {
    history.push("/personal-details-info");
  };

  const extractDocumentNumbers = (documents) => {
    if (!documents?.items) return { pan: null, dl: null, aadhar: null };
    
    const documentNumbers = {
      pan: null,
      dl: null,
      aadhaarLastFour: null
    };

    documents.items.forEach(item => {
      switch (item.doctype) {
        case "PANCR":
          documentNumbers.pan = item.uri.split('-')[2];
          break;
        case "DRVLC":
          documentNumbers.dl = item.uri.split('-')[2];
          break;
        case "ADHAR":
          // Extract last 4 digits from the aadhaar_xml
          const uidMatch = documents.aadhaar_xml?.match(/uid=\"xxxxxxxx(\d{4})\"/);
          documentNumbers.aadhaarLastFour = uidMatch ? uidMatch[1] : null;
          break;
      }
    });
  
    return documentNumbers;
  };

  const verifyAadhaarNumber = (inputAadhaar, lastFourDigits) => {
    if (!inputAadhaar || !lastFourDigits) return false;
    return inputAadhaar.slice(-4) === lastFourDigits;
  };


///////////LOCAL//////////
// const [accessToken, setAccessToken] = useState(null);
// const [documents, setDocuments] = useState(null);
// const [aadhaarXml, setAadhaarXml] = useState(null);
// const [error, setError] = useState(null);
// const [userInfo, setUserInfo] = useState(null);

// // Handle Digilocker Authorization
// const handleAuthorizeClick = () => {
//   const callbackUrl = `https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=UZE095AD36&state=oidc_flow&redirect_uri=http://127.0.0.1:8001/api/digilocker-callback/&code_challenge=F49-7ddV6d_E2wv4wCb5EHG8HApHeNNLzC0pDEpfxVw&code_challenge_method=S256&dl_flow=signin&acr=driving_licence+pan+aadhaar+email+mobile+user_alias+opuser_alias+pin&amr=all+driving_licence+aadhaar+pan&pla=Y&scope=address+careof+picture+openid`;
//   window.location.href = callbackUrl;
// };

// // Check for access_token in URL and fetch documents after redirect
// useEffect(() => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const token = urlParams.get("access_token");
//   const state = urlParams.get("state");

//   if (token && state) {
//     setAccessToken(token);
//     fetchDocuments(token);
//   }
// }, []);

// // Fetch documents from backend using access_token
// const fetchDocuments = async (token) => {
//   try {
//     const response = await axios.get(`http://127.0.0.1:8001/api/digilocker-documents/?access_token=${token}`);
//     const { documents, aadhaar_xml } = response.data;
    
//     const { pan, dl, aadhaarLastFour } = extractDocumentNumbers({
//       ...documents,
//       aadhaar_xml
//     });

//     setFormData(prevData => ({
//       ...prevData,
//       pan_number: pan || prevData.pan_number,
//       driving_licence_number: dl || prevData.driving_licence_number,
//     }));

//     setDocuments(documents.items.map(item => ({
//       type: item.doctype,
//       number: item.uri.split('-')[2],
//       issuer: item.issuer,
//       date: item.date,
//       aadhaarLastFour
//     })));

//     // Update verified documents status
//     setVerifiedDocuments(prev => ({
//       ...prev,
//       PANCR: !!pan,
//       DRVLC: !!dl,
//       // Don't auto-verify Aadhaar here
//     }));

//   } catch (error) {
//     console.error("Error fetching documents:", error);
//     setError("Failed to fetch documents");
//   }
// };

// // Update the isDocumentVerified function
// const isDocumentVerified = (documentNumber, docType) => {
//   // If the document was previously verified (saved), return true
//   if (verifiedDocuments[docType]) {
//     return true;
//   }

//   // For fresh Digilocker verification
//   if (docType === 'ADHAR') {
//     const aadhaarLastFour = documents?.find(doc => doc.aadhaarLastFour)?.aadhaarLastFour;
//     const isVerified = verifyAadhaarNumber(documentNumber, aadhaarLastFour);
//     if (isVerified) {
//       setVerifiedDocuments(prev => ({ ...prev, ADHAR: true }));
//     }
//     return isVerified;
//   }

//   const isVerified = documents?.some(doc => 
//     doc.type === docType && doc.number === documentNumber
//   );

//   if (isVerified) {
//     setVerifiedDocuments(prev => ({ ...prev, [docType]: true }));
//   }
//   return isVerified;
// };

// ///////////////////////////TESTING LINK///////////////////////
// const [accessToken, setAccessToken] = useState(null);
// const [documents, setDocuments] = useState(null);
// const [aadhaarXml, setAadhaarXml] = useState(null);
// const [error, setError] = useState(null);
// const [userInfo, setUserInfo] = useState(null);

// // Handle Digilocker Authorization
// const handleAuthorizeClick = () => {
//   const callbackUrl = 'https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=HYD95FECA5&state=oidc_flow&redirect_uri=https%3A%2F%2Fiverify-uat.newrl.net%2Fapi%2Fdigilocker-callback%2F&code_challenge=4KB66idlqwOWfrRrtokJkooPXirZX1YP2nLdOiTV0wQ&code_challenge_method=S256&dl_flow=signin&acr=driving_licence+pan+aadhaar+email+mobile+user_alias+opuser_alias+pin&amr=all+driving_licence+aadhaar+pan+exists_ac_pin&pla=Y&scope=address+careof+picture+openid'
//   window.location.href = callbackUrl;
// };

// // Check for access_token in URL and fetch documents after redirect
// useEffect(() => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const token = urlParams.get("access_token");
//   const state = urlParams.get("state");

//   if (token && state) {
//     setAccessToken(token);
//     fetchDocuments(token);
//   }
// }, []);

// // Fetch documents from backend using access_token
// const fetchDocuments = async (token) => {
//   try {
//     const response = await axios.get(`https://iverify-uat.newrl.net/api/digilocker-documents/?access_token=${token}`);
//     const { documents, aadhaar_xml } = response.data;
    
//     const { pan, dl, aadhaarLastFour } = extractDocumentNumbers({
//       ...documents,
//       aadhaar_xml
//     });

//     setFormData(prevData => ({
//       ...prevData,
//       pan_number: pan || prevData.pan_number,
//       driving_licence_number: dl || prevData.driving_licence_number,
//     }));

//     setDocuments(documents.items.map(item => ({
//       type: item.doctype,
//       number: item.uri.split('-')[2],
//       issuer: item.issuer,
//       date: item.date,
//       aadhaarLastFour
//     })));

//     // Update verified documents status
//     setVerifiedDocuments(prev => ({
//       ...prev,
//       PANCR: !!pan,
//       DRVLC: !!dl,
//       // Don't auto-verify Aadhaar here
//     }));

//   } catch (error) {
//     console.error("Error fetching documents:", error);
//     setError("Failed to fetch documents");
//   }
// };

// // Update the isDocumentVerified function
// const isDocumentVerified = (documentNumber, docType) => {
//   // If the document was previously verified (saved), return true
//   if (verifiedDocuments[docType]) {
//     return true;
//   }

//   // For fresh Digilocker verification
//   if (docType === 'ADHAR') {
//     const aadhaarLastFour = documents?.find(doc => doc.aadhaarLastFour)?.aadhaarLastFour;
//     const isVerified = verifyAadhaarNumber(documentNumber, aadhaarLastFour);
//     if (isVerified) {
//       setVerifiedDocuments(prev => ({ ...prev, ADHAR: true }));
//     }
//     return isVerified;
//   }

//   const isVerified = documents?.some(doc => 
//     doc.type === docType && doc.number === documentNumber
//   );

//   if (isVerified) {
//     setVerifiedDocuments(prev => ({ ...prev, [docType]: true }));
//   }
//   return isVerified;
// };

///////////////////////////PROD///////////////////////
const [accessToken, setAccessToken] = useState(null);
const [documents, setDocuments] = useState(null);
const [aadhaarXml, setAadhaarXml] = useState(null);
const [error, setError] = useState(null);
const [userInfo, setUserInfo] = useState(null);

// Handle Digilocker Authorization
const handleAuthorizeClick = () => {
  const callbackUrl = 'https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=PNEFAA4851&state=oidc_flow&redirect_uri=https%3A%2F%2Fiverify-api.newrl.net%2Fapi%2Fdigilocker-callback%2F&code_challenge=zBjhn_BlbzBxXQcquaBrH1ZCsz7Dz32foAHdyHNvfys&code_challenge_method=S256&dl_flow=signin&acr=pan+aadhaar+email+mobile+user_alias+opuser_alias+pin&amr=all+aadhaar+pan+exists_ac_pin&pla=Y&scope=address+careof+picture+openid'
  window.location.href = callbackUrl;
};

// Check for access_token in URL and fetch documents after redirect
useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("access_token");
  const state = urlParams.get("state");

  if (token && state) {
    setAccessToken(token);
    fetchDocuments(token);
  }
}, []);

// Fetch documents from backend using access_token
const fetchDocuments = async (token) => {
  try {
    const response = await axios.get(`https://iverify-api.newrl.net/api/digilocker-documents/?access_token=${token}`);
    const { documents, aadhaar_xml } = response.data;
    
    const { pan, dl, aadhaarLastFour } = extractDocumentNumbers({
      ...documents,
      aadhaar_xml
    });

    setFormData(prevData => ({
      ...prevData,
      pan_number: pan || prevData.pan_number,
      driving_licence_number: dl || prevData.driving_licence_number,
    }));

    setDocuments(documents.items.map(item => ({
      type: item.doctype,
      number: item.uri.split('-')[2],
      issuer: item.issuer,
      date: item.date,
      aadhaarLastFour
    })));

    // Update verified documents status
    setVerifiedDocuments(prev => ({
      ...prev,
      PANCR: !!pan,
      DRVLC: !!dl,
      // Don't auto-verify Aadhaar here
    }));

  } catch (error) {
    console.error("Error fetching documents:", error);
    setError("Failed to fetch documents");
  }
};

// Update the isDocumentVerified function
const isDocumentVerified = (documentNumber, docType) => {
  // If the document was previously verified (saved), return true
  if (verifiedDocuments[docType]) {
    return true;
  }

  // For fresh Digilocker verification
  if (docType === 'ADHAR') {
    const aadhaarLastFour = documents?.find(doc => doc.aadhaarLastFour)?.aadhaarLastFour;
    const isVerified = verifyAadhaarNumber(documentNumber, aadhaarLastFour);
    if (isVerified) {
      setVerifiedDocuments(prev => ({ ...prev, ADHAR: true }));
    }
    return isVerified;
  }

  const isVerified = documents?.some(doc => 
    doc.type === docType && doc.number === documentNumber
  );

  if (isVerified) {
    setVerifiedDocuments(prev => ({ ...prev, [docType]: true }));
  }
  return isVerified;
};

  const emailMatch = formData?.email && userInfo?.email && formData.email === userInfo.email;
  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "30px" }}>
        <Container>
          <ProfileHeader showUploadIcon={true} />
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              Basic Information
            </Typography>
          </Grid>
          <Grid container spacing={2} mt={0.25}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px" }} pb={1}>
                First Name 
              </Typography>
              <TextField
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="First Name"
                required
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& input": {
                      padding: "10px 14px", // Adjust padding to fit height
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px" }} pb={1}>
                Middle Name 
              </Typography>
              <TextField
                name="middle_name"
                value={formData.middle_name === "null" ? null : formData.middle_name}
                placeholder="Middle name"
                onChange={handleInputChange}
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px", // Set the height
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& input": {
                      padding: "10px 14px", // Adjust padding to fit height
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "15px" }} pb={1}>
                Last Name 
              </Typography>
              <TextField
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Last name"
                required
                size="small"
                fullWidth
                onKeyPress={handleKeyPress}
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  borderRadius: "5px",
                  height: "45px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                    "& input": {
                      padding: "10px 14px", // Adjust padding to fit height
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0.25}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" pb={1}>
                <Typography sx={{ fontSize: "15px" }}>Mobile Number</Typography>
                <DoneIcon
                  sx={{
                    color: "white",
                    backgroundColor: "#262262",
                    fontSize: "15px",
                    borderRadius: "100%",
                    ml: 1,
                  }}
                />
              </Box>
              <PhoneInput
                country={"in"}
                required
                value={formData.mobile_number}
                onChange={handleMobileNumberChange}
                inputStyle={{
                  width: "100%",
                  fontSize: "14px",
                  height: "45px",
                  backgroundColor: "rgba(214,214,214, 0.3)",
                }}
                displayInitialValueAsLocalNumber
                // disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                sx={{
                  color: "#272260",
                  border: "0.5px solid #272260",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "white",
                    border: "1px solid #666671",
                    boxShadow: "none",
                  },
                  "&:active": {
                    backgroundColor: "white",
                    border: "1px solid #666671",
                    boxShadow: "none",
                  },
                }}
                onClick={() => setShowAlternateNumber(!showAlternateNumber)}>
                {showAlternateNumber ? "Remove other Number" : "Add other Number"}
              </Button>
            </Grid>
          </Grid>

          {/* Toggleable Alternate Mobile Number */}
          {showAlternateNumber && (
            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <PhoneInput
                  country={"in"}
                  value={formData.alternate_mobile_number}
                  onChange={handleAlternateMobileNumberChange}
                  inputStyle={{
                    width: "100%",
                    fontSize: "14px",
                    height: "45px",
                  }}
                  placeholder="Alternate Mobile Number"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendOtp}
                  disabled={otpSent} // Disable button once OTP is sent
                  fullWidth>
                  {otpSent ? "OTP Sent" : "Send OTP"}
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Email Address Section */}
          <Grid container spacing={2} mt={0.25}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" pb={1}>
                <Typography sx={{ fontSize: "15px" }}>Email Address</Typography>

                {/* Conditionally render the checkmark icon if emails match */}
                {emailMatch && (
                  <DoneIcon
                    sx={{
                      color: "white",
                      backgroundColor: "#262262",
                      fontSize: "15px",
                      borderRadius: "100%",
                      ml: 1,
                    }}
                  />
                )}
              </Box>

              <TextField
                variant="outlined"
                name="email"
                value={formData?.email || ""} // Safely access formData.email
                onChange={handleInputChange}
                fullWidth
                disabled
                sx={{ backgroundColor: "rgba(214,214,214, 0.3)" }}
                placeholder="Email address"
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                sx={{ color: "#272260", border: "0.5px solid #272260", width: "100%" }}
                onClick={() => setShowAlternateEmail(!showAlternateEmail)}>
                {showAlternateEmail ? "Remove other Email" : "Add other Email"}
              </Button>
            </Grid>
          </Grid>

          {/* Toggleable Alternate Email */}
          {showAlternateEmail && (
            <Grid container spacing={2} mt={0.25}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="alternate_email"
                  value={formData.alternate_email}
                  onChange={handleInputChange}
                  fullWidth
                  placeholder="Alternate Email"
                  size="small"
                />
              </Grid>
            </Grid>
          )}

<Grid item xs={12} mt={1}>
  <Typography sx={{ fontSize: "15px" }} pb={1}>
    Aadhaar card number{' '}
    {formData.adhaar_number && formData.adhaar_number !== "null" && isDocumentVerified(formData.adhaar_number, 'ADHAR') ? (
      <DoneIcon
        sx={{
          color: "white",
          backgroundColor: "#262262",
          fontSize: "15px",
          borderRadius: "100%",
          ml: 1,
        }}
      />
    ) : (
      <span style={{ color: "#FFA500", fontSize: "15px" }}>🟠</span>
    )}
  </Typography>
  <TextField
    name="adhaar_number"
    value={formData.adhaar_number === "null" ? "" : formData.adhaar_number}
    onChange={handleInputChange}
    placeholder="Aadhaar Card Number"
    size="small"
    fullWidth
    sx={{
      backgroundColor: "rgba(214,214,214, 0.3)",
      borderRadius: "5px",
      height: "45px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": { border: "none" },
        "&:hover fieldset": { border: "none" },
        "&.Mui-focused fieldset": { border: "none" },
        "& input": { padding: "10px 14px" },
      },
    }}
  />
</Grid>

        <Grid item xs={12} mt={1}>
          <Typography sx={{ fontSize: '15px' }}>
            PAN card number{' '}
            {formData.pan_number && isDocumentVerified(formData.pan_number, 'PANCR') ? (
              <DoneIcon sx={{
                color: "white",
                backgroundColor: "#262262",
                fontSize: "15px",
                borderRadius: "100%",
                ml: 0.5,
              }}/>
            ) : (
              <span style={{ color: '#FFA500', fontSize: '15px' }}>🟠</span>
            )}
          </Typography>
          <TextField
            name="pan_number"
            value={formData.pan_number}
            onChange={handleInputChange}
            placeholder="PAN card Number"
            size="small"
            fullWidth
            sx={{
              backgroundColor: 'rgba(214,214,214, 0.3)',
              borderRadius: '5px',
              height: '45px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { border: 'none' },
                '&:hover fieldset': { border: 'none' },
                '&.Mui-focused fieldset': { border: 'none' },
                '& input': { padding: '10px 14px' },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <Typography sx={{ fontSize: '15px' }} pb={1}>
            Driving Licence number{' '}
            {formData.driving_licence_number && isDocumentVerified(formData.driving_licence_number, 'DRVLC') ? (
              <DoneIcon sx={{
                color: "white",
                backgroundColor: "#262262",
                fontSize: "15px",
                borderRadius: "100%",
                ml: 1,
              }}/>
            ) : (
              <span style={{ color: '#FFA500', fontSize: '15px' }}>🟠</span>
            )}
          </Typography>
          <TextField
            name="driving_licence_number"
            value={formData.driving_licence_number}
            onChange={handleInputChange}
            placeholder="Driving License number"
            inputProps={{ maxLength: 30 }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: 'rgba(214,214,214, 0.3)',
              borderRadius: '5px',
              height: '45px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': { border: 'none' },
                '&:hover fieldset': { border: 'none' },
                '&.Mui-focused fieldset': { border: 'none' },
                '& input': { padding: '10px 14px' },
              },
            }}
          />
        </Grid>



          {/* Digiocker version Input */}
          <Box>
      <Button
        endIcon={<LockPersonIcon />}
        sx={{ mt: 2, mb: 1 }}
        fullWidth
        onClick={handleAuthorizeClick}
        variant="outlined"
        color="primary">
        Authorize Digilocker
      </Button>
      <Typography mx={2} variant="body1">
        <em> Driving Licence and PAN card details will be fetched via Digilocker</em>
      </Typography>
    
    </Box>
          <OtpPopup
            open={showOtpModal}
            onClose={() => setShowOtpModal(false)}
            onSubmitOtp={handleSubmitOtp} // OTP handling function
          />

          <Grid item xs={8} mx="auto" my={2}>
            <CustomButton onClick={handleSubmit}>{"Next"}</CustomButton>
          </Grid>
          <Grid item xs={8} mx="auto" mb={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleNextClick}
              sx={{ border: "1px solid #272260", color: "#272260" }}>
              Back
            </Button>
          </Grid>
        </Container>
      </Container>
    </>
  );
}
