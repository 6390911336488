import axios from "axios";
import { getBlockchainNodeUrl, SERVER_BASE_ADDRESS } from "./const";
import React from "react";
import { isAuthenticated } from "./authentication";

export const getFromBackend = (url, useAuth = true) => {
  // url = url.replace('&', '%26');
  // console.log(url);
  const token = window.localStorage.getItem("token");

  var headers;
  if (useAuth) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
    };
  }

  return axios.get(SERVER_BASE_ADDRESS + url, { headers: headers }).then((result) => result.data);
  // .catch(e => {if(e.response.status === 401){
  //   alert('Session expired. Please login again.');
  //   window.location.href='login';
  // }})
};

export const postToBackend = (url, data) => {
  const token = window.localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + token,
  };

  return axios.post(SERVER_BASE_ADDRESS + url, data, { headers: headers }).then((result) => result.data);
  // .catch(e => {if(e.response.status === 401){
  //   alert('Session expired. Please login again.');
  //   window.location.href='login';
  // }})
};

// export function logout() {
//   window.sessionStorage.removeItem("auth");
//   window.sessionStorage.removeItem("is_QC_ashish");
//   window.localStorage.removeItem("deliverChallenInward");
//   window.localStorage.removeItem("welcomeUser");
//   window.location = "/";
// }
export function logout() {
  window.sessionStorage.clear();
  window.localStorage.clear();
  window.open("/login", "_self");
}

export function ValidateEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  alert("You have entered an invalid email address!");
  return false;
}

// export function isLoggedIn() {
//   // return isAuthenticated();
//   return window.localStorage.getItem("token") !== undefined && window.localStorage.getItem("token") !== null;
// }
export function isLoggedIn() {
  return window.sessionStorage.getItem("auth") !== undefined && window.sessionStorage.getItem("auth") !== null;
}

export function getColorForRiskWording(riskValue) {
  switch (riskValue) {
    case "Very Low":
      return "#29812c";
    case "Low":
      return "#8bc34a";
    case "Medium":
      return "#ffc107";
    case "High":
      return "#e36944";
    case "Very High":
      return "#ee2e31";
    default:
      return "black";
  }
}

export function getColorForRecommendation(recommendation) {
  var backgroundColor = "white";
  var fontColor = "black";

  switch (recommendation) {
    case "STRONG_BUY":
      backgroundColor = "#29812c";
      fontColor = "white";
      break;
    case "BUY":
      backgroundColor = "#8bc34a";
      fontColor = "white";
      break;
    case "HOLD":
    case "WEAK_HOLD":
    case "STRONG_HOLD":
      backgroundColor = "#ffbf00";
      fontColor = "white";
      break;
    case "SELL":
    case "WEAK_SELL":
      backgroundColor = "#e36944";
      fontColor = "white";
      break;
    case "STRONG_SELL":
      backgroundColor = "#ee2e31";
      fontColor = "white";
      break;
    default:
      break;
  }

  return { backgroundColor: backgroundColor, fontColor: fontColor };
}

export function getRiskReturnComparisonBigText(verdict) {
  switch (verdict) {
    case "Risk << Returns":
      return (
        <div>
          <span style={{ fontSize: "12px" }}>Risk</span> &lt; <span style={{ fontSize: "16px" }}>Returns</span>
        </div>
      );
    case "Risk < Returns":
      return (
        <div>
          <span style={{ fontSize: "12px" }}>Risk</span> &lt; <span style={{ fontSize: "14px" }}>Returns</span>
        </div>
      );
    case "Risk ~ Returns":
      return (
        <div>
          <span style={{ fontSize: "12px" }}>Risk</span> ~ <span style={{ fontSize: "12px" }}>Returns</span>
        </div>
      );
    case "Risk > Returns":
      return (
        <div>
          <span style={{ fontSize: "14px" }}>Risk</span> &gt; <span style={{ fontSize: "12px" }}>Returns</span>
        </div>
      );
    case "Risk >> Returns":
      return (
        <div>
          <span style={{ fontSize: "16px" }}>Risk</span> &gt; <span style={{ fontSize: "12px" }}>Returns</span>
        </div>
      );
    default:
      return "";
  }
}

export const scaleImageMap = {
  "Risk << Returns": "/hawkeye/images/report/tradeoff-inverted.jpg",
  "Risk < Returns": "/hawkeye/images/report/tradeoff-inverted.jpg",
  "Risk ~ Returns": "/hawkeye/images/report/equalriskreturn.png",
  "Risk > Returns": "/hawkeye/images/report/tradeoff.jpg",
  "Risk >> Returns": "/hawkeye/images/report/tradeoff.jpg",
};

export const isValidWalletAddress = (address) => {
  return /^0x([a-f0-9]){40,40}$/.test(address);
};

export const signTransaction = async (transaction, wallet) => {
  let res = await axios.post(getBlockchainNodeUrl() + "/sign-transaction", {
    wallet_data: wallet,
    transaction_data: transaction,
  });
  return res.data;
};

export const isWalletDownloaded = async (walletAddress) => {
  const walletDownloaded = localStorage.getItem("DOWNLOADED:" + walletAddress);
  console.log(walletDownloaded === "yes");
  return walletDownloaded === "yes";
};

export const setWalletDownloaded = async (walletAddress) => {
  localStorage.setItem("DOWNLOADED:" + walletAddress, "yes");
};

export function saveAuth(token) {
  if (token !== undefined) saveToLocal("auth", token);
  else window.sessionStorage.removeItem("auth");
}

export function saveRole(role) {
  if (role !== undefined) saveToLocal("user_role", role);
  else window.sessionStorage.removeItem("user_role");
}

export function saveToLocal(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getToken() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return auth["token"];
  }
  return null;
}

export async function compressImage(file) {
  const MAX_WIDTH = 800; // set the maximum width of the image
  const reader = new FileReader();
  const fileName = file.name;

  const eventToPromise = (target, event) => new Promise((resolve) => target.addEventListener(event, resolve, { once: true }));

  const dataUrl = await new Promise((resolve) => {
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });

  const image = new Image();
  image.src = dataUrl;

  await eventToPromise(image, "load");

  const canvas = document.createElement("canvas");
  let width = image.width;
  let height = image.height;

  if (width > MAX_WIDTH) {
    height *= MAX_WIDTH / width;
    width = MAX_WIDTH;
  }

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);

  const blob = await new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 0.7));
  const imageProperties = {
    fileName: fileName,
    blob: blob,
  };
  return imageProperties;
}


// authUtils.js

// Obscured constants for user status
const USER_STATUS_KEY = "usr_x9j2k_status_8y7z";
const PAID_STATUS_VALUE = "9x8cv7b6nm4k3j2h1gf";
const UNPAID_STATUS_VALUE = "2m3n4b5v6c7x8z9a1";

// Check if user is paid
export const checkUserPaidStatus = () => {
  return localStorage.getItem(USER_STATUS_KEY) === PAID_STATUS_VALUE;
};

// Set user's paid status
export const setUserPaidStatus = (isPaid) => {
  localStorage.setItem(
    USER_STATUS_KEY,
    isPaid ? PAID_STATUS_VALUE : UNPAID_STATUS_VALUE
  );
};

// Clear user's paid status
export const clearUserPaidStatus = () => {
  localStorage.removeItem(USER_STATUS_KEY);
};

// Get the status key (for use in login component)
export const getUserStatusKey = () => USER_STATUS_KEY;

// Get the status values (for use in login component)
export const getPaidStatusValue = () => PAID_STATUS_VALUE;
export const getUnpaidStatusValue = () => UNPAID_STATUS_VALUE;