import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Button, Tabs, Tab, Dialog, DialogContent, DialogActions, IconButton } from '@mui/material';
import { FaMapMarkerAlt, FaRegClock } from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Niralogo from "../../../assets/Nira.png";
import IveriLogo from "../../../assets/iveri.png";
import rafikiImg from "../../../assets/rafiki.png";
import Grow from '@mui/material/Grow';


const JobDetailView = () => {
  const { id } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false); // State to control the dialog

  const handleBack = () => {
    history.goBack();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const jobData = [
    {
      id: 1,
      Designation: "Web Developer",
      company: "ASQI",
      location: "California, United States",
      salary: "$15k - $25k",
      postedTime: "1 Month ago",
      profile: "Commercial Manager, 10-12 Years",
      qualification: "Experience in managing large projects...",
      skills: "Leadership, Communication, Finance"
    },
    // Add more job objects here
  ];

  const job = jobData.find((job) => job.id === parseInt(id));

  if (!job) {
    return <Typography variant="h6">Job not found</Typography>;
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ p: 2, backgroundColor: '#fff', borderRadius: '8px', boxShadow: 3, maxWidth: 800, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <ArrowBackIosIcon
          onClick={handleBack}
          sx={{
            position: "absolute",
            top: "5%",
            left: "5%",
            border: "2px solid black",
            borderRadius: "100%",
            padding: "1px",
            paddingLeft: "6px",
            color: "black",
            height: "15px",
            width: "11px",
          }}
        />
        <img src={IveriLogo} alt="company logo" style={{ width: '40%', height: '40%' }} />
      </Box>

      <Box sx={{border: "1px solid #272260", borderRadius: "30px"}} p={2} display="flex" justifyContent="center" alignItems="center" flexDirection='column'>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src={Niralogo} alt="company logo" style={{ width: '50px', height: '50px' }} />
        </Box>
        <Typography variant="h5" align="center">{job.Designation}</Typography>
        <Typography variant="subtitle1" align="center">{job.company}</Typography>
        <hr style={{ borderColor: '#ddd' }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <FaMapMarkerAlt size={18} />
          <Typography variant="body1" sx={{ ml: 1 }}>{job.location}</Typography>
        </Box>
        <Typography variant="body2" align="center" sx={{ color: '#6b6b6b', mb: 2 }}>
          {job.salary} / month
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <FaRegClock size={18} />
          <Typography variant="body2" sx={{ ml: 1 }}>{job.postedTime}</Typography>
        </Box>
        <Button variant="contained" sx={{ backgroundColor: '#272260'}} onClick={handleClickOpen}>
          Apply for Job
        </Button>
      </Box>

      <Tabs value={selectedTab} onChange={handleTabChange} sx={{
          mt: 4,
          '& .MuiTabs-indicator': {
            backgroundColor: '#272260',
          },
          '& .MuiTab-root': {
            color: '#272260',
            '&.Mui-selected': {
              color: '#272260',
              fontWeight: 'bold',
            },
          },
        }} centered>
        <Tab label="Job Profile" />
        <Tab label="Qualification" />
        <Tab label="Skills" />
      </Tabs>

      <Box sx={{ mt: 4 }}>
        {selectedTab === 0 && (
          <Typography variant="body2">{job.profile}</Typography>
        )}
        {selectedTab === 1 && (
          <Typography variant="body2">{job.qualification}</Typography>
        )}
        {selectedTab === 2 && (
          <Typography variant="body2">{job.skills}</Typography>
        )}
      </Box>


      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Grow}  // Adding the Grow transition
        PaperProps={{
          style: {
            borderRadius: 20,
            padding: '16px',
          },
        }}
      >
        <DialogContent sx={{ textAlign: 'center', position: 'relative' }}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 3, top: 3 }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
           <img src={rafikiImg} />
            <Box sx={{ width: '100px', height: '100px', backgroundColor: '#f5f5f5', borderRadius: '50%', mb: 2 }} />
          </Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Congratulations</Typography>
          <Typography variant="body2">You have applied Successfully!</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: '#272260' }}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobDetailView;
