import axios from "axios";
import { SERVER_BASE_ADDRESS } from "./const";
import { getToken, saveAuth } from "./utils";

const token = localStorage.getItem('access_token');


const instance = axios.create({
  baseURL: SERVER_BASE_ADDRESS,
  headers: {
    "Content-Type": "application/json",
    // Authorization: `JWT ${token}`,
  },
});
// const instance = axios.create({
// 	baseURL: SERVER_BASE_ADDRESS,
// 	headers: {
// 		Authorization: localStorage.getItem('access_token')
// 			? 'JWT ' + localStorage.getItem('access_token')
// 			: null,
// 		'Content-Type': 'application/json',
// 		accept: 'application/json',
// 	}, 
// });

instance.interceptors.request.use((config) => {
	if (token) {
	  config.headers['Authorization'] = `JWT ${token}`;
	}
	return config;
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === SERVER_BASE_ADDRESS + 'token/refresh/'
		) {
			window.location.href = '/login/';
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401 &&
			error.response.statusText === 'Unauthorized'
		) {
			const refreshToken = localStorage.getItem('refresh_token');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				console.log(tokenParts.exp);

				if (tokenParts.exp > now) {
					return instance
						.post('/token/refresh/', { refresh: refreshToken })
						.then((response) => {
							localStorage.setItem('access_token', response.data.access);
							localStorage.setItem('refresh_token', response.data.refresh);

							instance.defaults.headers['Authorization'] =
								'JWT ' + response.data.access;
							originalRequest.headers['Authorization'] =
								'JWT ' + response.data.access;

							return instance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					window.location.href = '/login/';
				}
			} else {
				console.log('Refresh token not available.');
				window.location.href = '/login/';
			}
		}

		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default instance;
