import React from 'react'
import { Grid, Card, CardContent, Typography, Chip, Container, Button } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import instance from '../globals/axios';

import { Link } from 'react-router-dom';  
import { styled } from '@mui/styles';


const ViewRequirementCard = ({ requirement, role }) => {


    const company_id = parseInt(localStorage.getItem('company_id'));
    const iveri_id = localStorage.getItem('iveri_id');


    const handleDelete = () => {
      try {
          const formData = new FormData();
          formData.append('requirement_id', requirement.requirement_id);
      
          const response = instance.post(`/company/${company_id}/delete_requirement/`, formData);
          alert("Requirement Deleted successfully!")
          setTimeout(() => {
              window.location.reload();
          }, 500);
            
      } catch (error) {
          console.error('Error publishing requirement:', error);
          alert('Error: ' + error.response.data.error);
      }
  };

  const handleClose = async (requirement_id) => {
    try {
      const response = await instance.post(`company/${company_id}/close-requirement/`, {
        requirement_id: requirement_id, 
        closed_by: iveri_id
      });
  
      if (response.data && response.data.error) {
        alert('Error: ' + response.data.error);
      } else {
        alert('Action successful');
      }
      setTimeout(() => {
          window.location.reload();
      }, 500);
  
    } catch (error) {
      if (error.response) {
        alert('Error: ' + (error.response.data.error || 'Unknown error'));
      } else if (error.request) {
        alert('No response received from the server');
      } else {
        console.error('Error making POST request:', error.message);
      }
    }
  };


  return (
    <Card>
    <CardContent>
      <Typography variant="h6" my={1} component="div">
        {requirement.profile_title}
      </Typography>
      <Typography variant='body1' color="text.secondary" gutterBottom>
        {requirement.designation}
      </Typography>
      <Typography my={1}>
      Requirement Raised On:
      </Typography>
      <Typography color="text.secondary" gutterBottom>
        <AccessTimeIcon /> <span sx={{my:"auto"}}>{new Date(requirement.requirement_raised_on).toLocaleString()}</span> 
      </Typography>
      <Typography>
      Requirement Status:  
      {requirement.is_published ?  
        (   <span>
                <Chip
                  sx={{ ml: 2 }}
                  icon={<CheckCircleIcon />}
                  label="Published"
                  color="success"
                  variant="contained"
                />
            </span>
        
        ) : 
       ( 
        <span>
          <Chip
            sx={{ ml: 2 }}
            icon={
              requirement.requirement_status === "Approved" ? <CheckCircleIcon /> :
              requirement.requirement_status === "Rejected" ? <CancelOutlinedIcon /> :
              <ErrorOutlineIcon />
            }
            label={requirement.requirement_status}
            color={
              requirement.requirement_status === "Approved" ? "success" :
              requirement.requirement_status === "Rejected" ? "error" :
              "default" // Assuming 'default' color for "Pending"
            }
            variant="outlined"
          />
        </span>
          )
      }
      

      </Typography>

      <Typography color="text.secondary" sx={{ my:1 }} gutterBottom>
        Requirement ID: {requirement.requirement_id}
      </Typography>

      <Grid container spacing={2}>
       
        <Grid item>
              <Link to={`/view-job/${requirement.requirement_id}`} style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
                  View JD
              </Button>
              </Link>
        </Grid>

        {
            role=="HM" && (
                requirement.is_published && 
                (   
                    <Grid item>
                    <Link
                      to={{
                        pathname: `/view-application/${requirement.requirement_id}`,  
                        state: {
                          requirement_id: requirement.requirement_id,
                          profile_title: requirement.profile_title,
                          designation: requirement.designation
                        }
                      }}
                      style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" sx={{ marginTop: 2,  color:"#214383"}}>
                        View Applicants
                        </Button>
                    </Link>
                    </Grid>
                ))
        }
          

        {
            role=="HM" && ( 

              requirement.requirement_status === "Approved" ?   (
                  <Grid item>
                
                  </Grid>

              ) : (
                  <React.Fragment>
                    {
                      !requirement.is_published && (
                        <Grid item>
                          <Link to={`/update-requirements/${requirement.requirement_id}`} style={{ textDecoration: 'none' }}>
                          <Button variant="outlined" color="secondary" sx={{ marginTop: 2 }}>
                          Update
                          </Button>
                          </Link>
                        </Grid>
                      )
                    }
                  </React.Fragment>
              )
            )
        }

        {
            role==="HR" && (

                requirement.is_published ?  
                    (   

                      <>
                        <Grid item>
                        <Link
                              to={{
                                pathname: `/view-application/${requirement.requirement_id}`,  
                                state: {
                                  requirement_id: requirement.requirement_id,
                                  profile_title: requirement.profile_title,
                                  designation: requirement.designation
                                }
                              }}
                              style={{ textDecoration: 'none' }} >
                            <Button variant="outlined" sx={{ marginTop: 2,  color:"#214383"}}>
                            View Applicants
                            </Button>
                        </Link>
                        </Grid>

                        <Grid item>
                            <Button onClick={() => handleClose(requirement.requirement_id)} variant="outlined" color="error" sx={{ marginTop: 2 }}>
                                Close Opening
                            </Button>
                        </Grid>
                      </>
                    ) : 
                    (
                        requirement.requirement_status === "Approved" ?   (
                          <Grid item>
                            <Link to={`/publish-requirements/${requirement.requirement_id}`} style={{ textDecoration: 'none' }}>
                              <Button variant="outlined" color="success" sx={{ marginTop: 2 }}>
                              Publish
                              </Button>
                            </Link>
                          </Grid>

                        ) : 
                        (
                            <React.Fragment>

                              <Grid item>
                                <Link to={`/hr-req-approval/${requirement.requirement_id}`} style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" color="primary" sx={{ marginTop: 2 }}>
                                Approve / Reject
                                </Button>
                                </Link>
                              </Grid>

                              <Grid item>
                                  <Button onClick={handleDelete} variant="outlined" color="error" sx={{ marginTop: 2 }}>
                                  Delete
                                  </Button>
                              </Grid>

                              <Grid item>
                                <Link to={`/update-requirements/${requirement.requirement_id}`} style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" color="secondary" sx={{ marginTop: 2 }}>
                                Update
                                </Button>
                                </Link>
                              </Grid>
                            </React.Fragment>
                        )
                        
                    )
            )
        }

        
       

      </Grid>
    </CardContent>
  </Card>
  )
}

export default ViewRequirementCard