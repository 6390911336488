import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'; // Import Card component
import CardContent from '@mui/material/CardContent';
import { MdVisibility } from 'react-icons/md';
import { IconButton } from "@mui/material";
import instance from "../../globals/axios.js";
import DetailLabel from "../../components/Timeline/Timelinelabel.js";
import { styled } from '@mui/system';

const CustomCard = styled(Card)({
  boxShadow: 'none !important',
  padding: '10px !important',
  margin: '10px !important',
  backgroundColor: '#F8F9F9 !important',
  border: 'none !important',
});

const EducationTimeline = () => {
  const [educationData, setEducationData] = useState([]);

  useEffect(() => {
    instance.get('educational-details/')
      .then(response => {
        setEducationData(response.data.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {educationData.map((detail, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: 'black' }} />
            <TimelineConnector sx={{ backgroundColor: "black" }} />
          </TimelineSeparator>
          <TimelineContent sx={{ margin: "2 !important", padding: "2 !important" }}>
            <Paper
              sx={{
                p: "2 !important",
                boxShadow: 'none !important',
                border: 'none !important',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${new Date(detail.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(detail.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>

              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {detail.degree}
              </Typography>
              <Typography sx={{ fontSize: "15px !important", fontWeight: "550 !important" }}>
                {detail.institute_name}
              </Typography>

              <CardContent>
                <DetailLabel label="College Name" value={detail.institute_name} />
                <DetailLabel label="Specialization" value={detail.specialization} />
                <DetailLabel label="Board" value={detail.board} />
                <DetailLabel label="Score" value={detail.score} />
                <DetailLabel label="Total Score" value={detail.total_score} />
                <DetailLabel label="Score Unit" value={detail.score_unit} />
                <DetailLabel label="Certificates" value={detail.certificate_name} />

                {detail.projects && detail.projects.map(project => (
                  <Box key={project.id}>
                    <CustomCard sx={{ p: "10px !important"}}>
                      <DetailLabel label="Project Title" value={project.project_title} />
                      <DetailLabel label="Project Description" value={project.project_desc} />
                      <DetailLabel label="Project Link" value={project.project_link} />
                    </CustomCard>
                  </Box>
                ))}


                {detail.internships && detail.internships.map(internship => (
                  <Box key={internship.id}>
                    <CustomCard sx={{ p: "10px !important" }}>
                      <DetailLabel label="Internship Company" value={internship.internship_company} />
                      <DetailLabel label="Internship Role" value={internship.internship_role} />
                      <DetailLabel label="From" value={internship.internship_duration_from ? new Date(internship.internship_duration_from).toLocaleDateString() : "N.A."} />
                      <DetailLabel label="To" value={internship.internship_duration_to ? new Date(internship.internship_duration_to).toLocaleDateString() : "N.A."} />
                    </CustomCard>
                  </Box>
                ))}


              </CardContent>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default EducationTimeline;
