import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Chip,
  Grid,
  TableRow,
} from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import { useHistory } from "react-router-dom";

import TrashIcon from "../../../assets/Icons/delete.png";
import EditIcon from "../../../assets/Icons/Edit.png";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import Sidebar from "../../../components/Sidebar";

const LanguageProficiencyView = () => {
  const history = useHistory();
  const [languageData, setLanguageData] = useState([]);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const refetchData = () => {
    instance
      .get("language-data/")
      .then((response) => {
        // Ensure response data is an array
        if (Array.isArray(response.data)) {
          setLanguageData(response.data);
        } else {
          setLanguageData([]);
          setError("Unexpected data format received.");
        }
        setError(""); // Clear any previous errors
      })
      .catch((error) => {
        console.error("There was an error fetching the languages!", error);
        setError("Error fetching data. Please try again.");
        setLanguageData([]); // Ensure it's an array even if there's an error
      });
  };

  const handleEditButton = (id) => {
    history.push(`/edit-language-proficiencies/${id}`);
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    instance
      .delete(`language-data/${selectedId}/`)
      .then(() => {
        refetchData();
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error("Error deleting language proficiency:", error);
        setError("Error deleting language proficiency. Please try again.");
        setOpenDialog(false);
      });
  };

  useEffect(() => {
    refetchData();
  }, []);
  const handleAddLanguage = () => {
    history.push("edit-language-proficiency");
  };

  return (
    <>
    <Sidebar />
    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false} />
      {error && <Typography color="error">{error}</Typography>}

      {languageData.length === 0 ? (
        <>
          <Typography variant="h6" sx={{ fontWeight: "600" }} mt={2}>
            Language Proficiencies
          </Typography>
          <AddSectionPlaceholder
            message="language proficiency"
            buttonText="+ Add Language Proficiency"
            link="/edit-language-proficiency"
            onClick={() => history.push("/edit-language-proficiency")}
          />
        </>
      ) : (
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "600" }} mt={2}>
            Language Proficiencies
          </Typography>
          <Box item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "4px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#262262" }}>
                      Language
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "4px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#262262" }}>
                      Read
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "4px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#262262" }}>
                      Write
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "4px" }}>
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#262262" }}>
                      Speak
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: "4px" }} align="right">
                    <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: "#262262" }}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {languageData.map((proficiency) => (
                  <TableRow key={proficiency.id}>
                    <TableCell sx={{ padding: "4px" }}>
                      <Typography sx={{ fontSize: "12px", color: "#262262" }}>
                        {proficiency.languageName}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: "4px" }}>
                      <Typography sx={{ fontSize: "12px", color: "#262262" }}>
                        {proficiency.readingProficiency}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: "4px" }}>
                      <Typography sx={{ fontSize: "12px", color: "#262262" }}>
                        {proficiency.writingProficiency}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: "4px" }}>
                      <Typography sx={{ fontSize: "12px", color: "#262262" }}>
                        {proficiency.speakingProficiency}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: "4px" }} align="right">
                      <IconButton
                        onClick={() => handleEditButton(proficiency.id)}
                        sx={{ padding: "4px" }}>
                        <img src={EditIcon} style={{ width: "16px", height: "16px" }} alt="Edit" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(proficiency.id)}
                        sx={{ padding: "4px" }}>
                        <img
                          src={TrashIcon}
                          style={{ width: "16px", height: "16px" }}
                          alt="Delete"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Button
            sx={{
              border: "1px solid #666671",
              textTransform: "none",
              color: "#666671",
              fontSize: "24px",
              marginTop: "20px !important",
            }}
            fullWidth
            onClick={handleAddLanguage}>
            + Add Language Proficiency
          </Button>
        </Box>
      )}

      <ConfirmDeleteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onDelete={handleDelete}
        title={"Language Proficiency"}
      />
    </Container>
    </>
  );
};

export default LanguageProficiencyView;
