import React from "react";
import { Button, Typography, Card, Box, Container, Select, MenuItem} from "@mui/material";
import { useState, useEffect } from "react";
import NavBar from "../../../components/NavBar";

const LabelField = ({ label, value }) => {
  const [selectedCandidateStatus, setSelectedCandidateStatus] = useState('');
  const [selectedCandidateSubStatus, setSelectedCandidateSubStatus] = useState('');

  const optionsByLabel = {
    'Candidate Status': [
      'Assessment',
      'Billing',
      'Documentation',
      'F2F',
      'First Cut',
      'Medical',
      'Offer',
      'Onboarding',
      'Telephonic',
      'VC',
    ],
  };

  const subStatusOptionsByStatus = [
    {
      status: 'First Cut',
      options: ['Yet to call', 'Rejected', 'Declined', 'On Hold', 'Shortlisted'],
    },
    {
      status: 'Telephonic',
      options: [
        'Yet to connect',
        'Rejected',
        'Declined',
        'Backed out',
        'Interested',
        'Shortlisted',
        'Interview Lined Up',
        'Interview Done - Feedback Awaited',
        'On Hold',
      ],
    },
    {
      status: 'VC',
      options: [
        'Yet to connect',
        'Rejected',
        'Declined',
        'Backed out',
        'Interested',
        'Shortlisted',
        'Interview Lined Up',
        'Interview Done - Feedback Awaited',
        'On Hold',
      ],
    },
    {
      status: 'F2F',
      options: [
        'Yet to connect',
        'Rejected',
        'Declined',
        'Backed Out',
        'Interested',
        'Shortlisted',
        'Interview Lined Up',
        'Interview Done - Feedback Awaited',
        'On Hold',
      ],
    },
    {
      status: 'Assessment',
      options: ['Pending', 'Completed', 'Shortlisted', 'Rejected', 'Backed Out', 'On Hold'],
    },
    {
      status: 'Documentation',
      options: ['Pending', 'Received', 'Backed Out', 'On Hold'],
    },
    {
      status: 'Medical',
      options: ['Pending', 'Completed', 'Rejected', 'Backed Out', 'On Hold'],
    },
    {
      status: 'Offer',
      options: ['Negotiation', 'Accepted', 'Declined'],
    },
    {
      status: 'Onboarding',
      options: ['Joined', 'Pending', 'Backed Out'],
    },
    {
      status: 'Billing',
      options: ['Pending', 'Completed'],
    },
  ];

  const handleCandidateStatusChange = (e) => {
    const newStatus = e.target.value;
    setSelectedCandidateStatus(newStatus);
  };

  const handleCandidateSubStatusChange = (e) => {
    const newSubStatus = e.target.value;
    setSelectedCandidateSubStatus(newSubStatus);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      margin="5px 0"
    >
      <Typography style={{ color: "rgb(0, 184, 230)", fontWeight: "600" }}>
        {label + ":"}
      </Typography>
      {label === 'Candidate Status' ? (
        <Select
          style={{ minWidth: "120px" }}
          value={selectedCandidateStatus}
          onChange={handleCandidateStatusChange}
        >
          {optionsByLabel[label].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ) : label === 'Candidate Sub Status' ? (
        <Select
          style={{ minWidth: "120px" }}
          /* value={selectedCandidateSubStatus} */
          onChange={handleCandidateSubStatusChange}
        >
          {selectedCandidateStatus === 'Assessment' ? (
      <>
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
        <MenuItem value="Shortlisted">Shortlisted</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
        <MenuItem value="Backed Out">Backed Out</MenuItem>
        <MenuItem value="On Hold">On Hold</MenuItem>
      </>
    ) : selectedCandidateStatus === 'Telephonic' ? (
      <>
        <MenuItem value="Yet to connect">Yet to connect</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
        <MenuItem value="Declined">Declined</MenuItem>
        <MenuItem value="Backed out">Backed out</MenuItem>
        <MenuItem value="Interested">Interested</MenuItem>
        <MenuItem value="Shortlisted">Shortlisted</MenuItem>
        <MenuItem value="Interview Lined Up">Interview Lined Up</MenuItem>
        <MenuItem value="Interview Done - Feedback Awaited">Interview Done - Feedback Awaited</MenuItem>
        <MenuItem value="On Hold">On Hold</MenuItem>
      </>
    ) : selectedCandidateStatus === 'VC' ? (
      <>
        <MenuItem value="Yet to connect">Yet to connect</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
        <MenuItem value="Declined">Declined</MenuItem>
        <MenuItem value="Backed out">Backed out</MenuItem>
        <MenuItem value="Interested">Interested</MenuItem>
        <MenuItem value="Shortlisted">Shortlisted</MenuItem>
        <MenuItem value="Interview Lined Up">Interview Lined Up</MenuItem>
        <MenuItem value="Interview Done - Feedback Awaited">Interview Done - Feedback Awaited</MenuItem>
        <MenuItem value="On Hold">On Hold</MenuItem>
      </>
    ) : selectedCandidateStatus === 'F2F' ? (
      <>
        <MenuItem value="Yet to connect">Yet to connect</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
        <MenuItem value="Declined">Declined</MenuItem>
        <MenuItem value="Backed Out">Backed Out</MenuItem>
        <MenuItem value="Interested">Interested</MenuItem>
        <MenuItem value="Shortlisted">Shortlisted</MenuItem>
        <MenuItem value="Interview Lined Up">Interview Lined Up</MenuItem>
        <MenuItem value="Interview Done - Feedback Awaited">Interview Done - Feedback Awaited</MenuItem>
        <MenuItem value="On Hold">On Hold</MenuItem>
      </>
    ) : selectedCandidateStatus === 'First Cut' ? (
      <>
        <MenuItem value="Yet to call">Yet to call</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
        <MenuItem value="Declined">Declined</MenuItem>
        <MenuItem value="On Hold">On Hold</MenuItem>
        <MenuItem value="Shortlisted">Shortlisted</MenuItem>
      </>
    ) : 
    selectedCandidateStatus === 'Documentation' ?(
        <>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Received">Received</MenuItem>
          <MenuItem value="Backed Out">Backed Out</MenuItem>
          <MenuItem value="On Hold">On Hold</MenuItem>
        </>
      ) : selectedCandidateStatus === 'Medical' ? (
        <>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
          <MenuItem value="Rejected">Rejected</MenuItem>
          <MenuItem value="Backed Out">Backed Out</MenuItem>
          <MenuItem value="On Hold">On Hold</MenuItem>
        </>
      ) : selectedCandidateStatus === 'Offer' ? (
        <>
          <MenuItem value="Negotiation">Negotiation</MenuItem>
          <MenuItem value="Accepted">Accepted</MenuItem>
          <MenuItem value="Declined">Declined</MenuItem>
        </>
      ) : selectedCandidateStatus === 'Onboarding' ? (
        <>
          <MenuItem value="Joined">Joined</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Backed Out">Backed Out</MenuItem>
        </>
      ) : selectedCandidateStatus === 'Billing' ? (
        <>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
        </>
      ) : (
        /* Add options for other statuses */
        <>
          {/* Add options for other statuses */}
        </>
    )}
        </Select>
      ) : (
        <Typography>{value}</Typography>
      )}
  </Box>
       
  );
};
      
const LabelField1 = ({ label, value }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleInputChange = (event) => {
    // Check if the selected option is "Vendor"
    const selectedSource = event.target.value;
    setSelectedOption(selectedSource);
  };

  return (
    <Box
      display="flex"
      flexDirection="column" // Display components vertically
      justifyContent="space-between" 
      margin="5px 0"
    >
      {label === "Sourced Via" ? (
        <>
        <Typography style={{ color: "rgb(0, 184, 230)", fontWeight: "600" }}>
        {label + ":"}
        </Typography>
        <Select
          value={selectedOption}
          onChange={handleInputChange}
          style={{ minWidth: "120px" }}
        >
          <MenuItem value="Vendor">Vendor</MenuItem>
          <MenuItem value="Internal Team">Internal Team</MenuItem>
        </Select>
        </>
        ) : (
          <>
      
      </>
       )
  }
     {selectedOption === "Internal Team" && (
        <Box>
          {empData2.map(({ label, value }) => (
            <LabelField key={label} label={label} value={value} />
          ))}
        </Box>
      )}

      {/* Render the selected option part below when selectedOption is "Vendor" */}
      {selectedOption === "Vendor" && (
        <Box>
          {empData1.map(({ label, value }) => (
            <LabelField key={label} label={label} value={value} />
          ))}
        </Box>
      )}
      </Box>
      );
    };

const labelValuePairs = [
  { label: "Total Positions Open", value: "2" },
  { label: "Total Positions Closed", value: "2" },
  { label: "Total Candidates in Joining Stage", value: "1" },
  { label: "Total Candidates in Offer / Documentation Stage", value: "1" },
  { label: "Total Candidates in Interview Stage", value: "2" },
];

const empData = [
  {
    label: "SBU Name",
    value: "Adani Group",
  },
  {
    label: "Department Name",
    value: "Airport Division",
  },
  {
    label: "Profile Title",
    value: "Logistics",
  },
  {
    label: "Designation",
    value: "DGM",
  },
  {
    label: "HR SPOC",
    value: "Sachin Gupta",
  },
  {
    label: "Hiring Manager",
    value: "Raghu Ram",
  },
];

const empData1 = [

{
  label: "Vendor Company Iveri ID",
  value: "321123",
},
{
  label: "Vendor Company Name",
  value: "Great Placements",
},
{
  label: "Candidate Name",
  value: "Raghav Raman",
},
{
  label: "Candidate Iveri ID",
  value: "5423678",
},
{
  label: "Candidate Status",
  value: "",
},
{
  label: "Candidate Sub Status",
  value: "",
},
{
  label: "Remarks",
  value: "Remarks",
},
];

const empData2 =[
  {
    label: "Internal Team Member Iveri ID",
    value: "123321",
  },
  {
    label: "Internal Team Member Name",
    value: "Rohan",
  },
  {
    label: "Candidate Name",
    value: "Raghav Raman",
  },
  {
    label: "Candidate Iveri ID",
    value: "5423678",
  },
  {
    label: "Candidate Status",
    value: "",
  },
  {
    label: "Candidate Sub Status",
    value: "",
  },
  {
    label: "Remarks",
    value: "Remarks",
  },
];

export default function EmpDashboardDetails() {
  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Typography
          style={{
            color: "rgb(0, 184, 230)",
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "5px",
          }}
        >
          Dashboard
        </Typography>

        <Box>
          {labelValuePairs.map(({ label, value }) => (
            <LabelField key={label} label={label} value={value} />
          ))}
        </Box>

        <Typography
          style={{
            color: "rgb(0, 184, 230)",
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "5px",
          }}
        >
          All List of BGVs
        </Typography>

        <Box>
          <Card sx={{ boxShadow: 2, p: 2 }}>
            {empData.map(({ label, value }) => (
              <LabelField key={label} label={label} value={value} />
            ))}
            
            <LabelField1 label={"Sourced Via"} value={""} />
            
          </Card>
        </Box>
        </Container>
    </>
  );
}
