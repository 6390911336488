import React, { useState } from "react";
import { Button, Typography, Box, TextField, Select, MenuItem, Container, Grid } from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useEffect } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { FaTimes } from 'react-icons/fa';

const handleSaveClick = () => {
  // Handle final submission logic
};

const initialState = [
  { name: 'Company Email ID' },
  { name: 'Password' },
  { name: 'User Name' },
  { name: 'Role' },
  { name: 'Company Number' },
];


export default function UserManagement() {
  const [moreDetails, setMoreDetails] = useState(false);
  const [companyEmail, setCompanyEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);

  const formData = new FormData();

  // Append the values to the formData object
  formData.append('Company Email ID', companyEmail);
  formData.append('Password', password);
  formData.append('User Name', userName);
  formData.append('Role', role);
  formData.append('Company Number', companyNumber);

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    switch (name) {
      case 'Company Email ID':
        setCompanyEmail(value);
        break;
      case 'Password':
        setPassword(value);
        break;
      case 'User Name':
        setUserName(value);
        break;
      case 'Role':
        setRole(value);
        break;
      default:
        break;
    }
  };

  const handlePhoneNumberChange = (formattedValue) => {
    setCompanyNumber(formattedValue);
    setShowOtpInput(true);
  };
  
  const handleOtpChange = (value) => {
    setOtp(value);
  };

  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px" marginBottom="15px">
          <Typography variant="h5" style={{ color: "rgb(0, 184, 230)"}}>
            User Management
          </Typography>
          <Button
            variant="contained"
            style={{
              color: "rgb(3, 22, 48)",
              borderRadius: "30px",
              backgroundColor: "rgb(0, 184, 230)",
              padding: "2px 30px",
              fontWeight: "600",
              fontSize: "15px",
            }}
            onClick={handleSaveClick}
          >
            SAVE
          </Button>
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px" marginBottom="15px">
          <Typography variant="h6" style={{ color: "rgb(0, 184, 230)"}}>
            List of Entries
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography>user1@hermes.com</Typography>
              </Grid>
              <Grid item>
                <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>view</Button>
                <Button>edit</Button>
              </Grid>
            </Grid>
         </Grid>
         <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>user2@hermes.com</Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>view</Button>
              <Button>edit</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>user3@hermes.com</Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => setMoreDetails(true)} style={{ marginRight: "10px" }}>view</Button>
              <Button>edit</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

        {moreDetails && (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px" marginBottom="15px">
            <Grid container spacing='2' mt={.25}>
            <Grid item xs={8}> 
              <Typography variant="h6" style={{ color: "rgb(0, 184, 230)"}}>
                More Details
              </Typography>
            </Grid>
            <Grid item xs={4}> 
            <Button onClick={() => setMoreDetails(false)}>
            <FaTimes />
            </Button>
            </Grid>
            </Grid>  
            </Box>

            <form>
      {initialState.map((field) => (
        <Box key={field.name} marginBottom="10px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            margin="5px 0"
          >
            <Grid container spacing='2' mt={.25}>
            <Grid item xs={12}>  
            <Typography style={{ color: 'rgb(0, 184, 230)', fontWeight: '600' }}>
              {field.name}:
            </Typography>
            </Grid>
            <Grid item xs={12}>
            {field.name === 'Role' ? (
              <Select
                name={field.name}
                value={role}
                onChange={handleChange}
                style={{ minWidth: '395px'}}
              >
                <MenuItem value="Admin Role">Admin Role</MenuItem>
                <MenuItem value="Finance">Finance</MenuItem>
                <MenuItem value="Execution TL">Execution TL</MenuItem>
                <MenuItem value="Execution TM">Execution TM</MenuItem>
              </Select>
            ) : field.name === 'Company Number' ? (
              <>
                <Grid item fullWidth xs={12} style={{ marginTop: '10px' }}>
                  <PhoneInput
                    country={'in'}
                    value={companyNumber}
                    onChange={handlePhoneNumberChange}
                    inputProps={{
                      required: true,
                    }}
                    inputStyle={{ width: '100%' }}
                    containerStyle={{ width: '100%' }}
                  />
                </Grid>

                {showOtpInput && (
                  <Grid item fullWidth xs={12} style={{ marginTop: '10px' }}>
                    <MuiOtpInput
                      value={otp}
                      onChange={handleOtpChange}
                      numInputs={4} // Number of OTP inputs
                    />
                  </Grid>
                )}
              </>
            ) : (
              <TextField
                type="text"
                name={field.name}
                style={{ minWidth: '395px'}}
                value={
                  field.name === 'Company Email ID'
                    ? companyEmail
                    : field.name === 'Password'
                    ? password
                    : field.name === 'User Name'
                    ? userName
                    : companyNumber
                }
                onChange={handleChange}
              />
            )}
            </Grid> 
           </Grid>
          </Box>
        </Box>
      ))}
    </form>

            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="10px" marginBottom="15px">
              <Typography variant="h6" style={{ color: "rgb(0, 184, 230)", fontWeight: "600", marginBottom: "5px" }}>
                Iveri ID
              </Typography>
              <Typography>12345</Typography>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}
