import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Grid, Button, Typography, Chip, Avatar, Container } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import instance from '../../../globals/axios';
import NavBar from '../../../components/NavBar';

const CompanyCard = () => {
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    instance.get('company/')
      .then(response => {
        setCompanyData(response.data);
      })
      .catch(error => {
        console.error('Error fetching company data:', error);
      });
  }, []);

  return (
    <>
    <NavBar />
    <Container maxWidth="md" sx={{ marginTop: "120px"}}>
      {companyData && companyData.map(company => (
        <Grid item key={company.id} xs={12} md={6}>
          <Card variant="outlined" spacing={2}>
            <CardContent>
              <Button
              variant="outlined"
              color="primary"
              href="/edit-emp-company"
            >
              Edit
            </Button>
              <Typography variant="h5">{company.name}</Typography>
              <Chip
                avatar={<Avatar><BusinessIcon /></Avatar>}
                label={company.pan_num}
                color="primary"
                variant="outlined"
                style={{ margin: '8px 0' }}
              />
              <Chip
                avatar={<Avatar><BusinessIcon /></Avatar>}
                label={company.gst_num}
                color={company.gst_verified ? 'primary' : 'secondary'}
                variant="outlined"
                style={{ margin: '8px 0' }}
              />
              <Typography variant="body2" color="text.secondary">
                Company Brief: {company.company_brief || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Services: {company.services || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Location: {company.location || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Type: {company.type || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Parent Company: {company.parent_company || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Address: {company.address || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Website: {company.website || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Social Media Page: {company.social_media_page || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                iVeri ID: {company.iVeri_id || 'N/A'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Admin ID: {company.admin}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Created at: {new Date(company.created_at).toLocaleString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Updated at: {new Date(company.updated_at).toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Container>
    </>
  );
};

export default CompanyCard;
