import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Card, CardContent, CardActions, Button, Backdrop, Typography, CircularProgress, Box, TextField, Chip, Avatar, Autocomplete, Container } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { Doughnut } from 'react-chartjs-2';
import instance from '../../globals/axios';
import dayjs from 'dayjs';
import NavBar from '../../components/NavBar';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const MatchingDetails = () => {
  const location = useLocation();
  const { state } = location;
  const { doc, process } = state || {};
  const [loading, setLoading] = useState(true);
  const [matchingDetails, setMatchingDetails] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedJd, setSelectedJd] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [designation, setDesignation] = useState('');
  const [JobProfile, setJobProfile] = useState('');
  const [topicInput, setTopicInput] = useState('');
  const [topics, setTopics] = useState([]);
  const interviewerIveriId = localStorage.getItem('iveri_id');
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewTime, setInterviewTime] = useState(null);
  useEffect(() => {
    fetchMatchingDetails();
  }, [doc]);

  const fetchMatchingDetails = async () => {
    setLoading(true);
    try {
      const endpoint = '/resume-matching-system/jd-resume-match/';
      const res = await instance.get(endpoint, { params: { [process === 'resume' ? 'resume' : 'jd']: doc.id } });
      setMatchingDetails(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPopup = (job) => {
    setSelectedJd(job);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setEmail('');
    setEmailError('');
    setDesignation('');
    setJobProfile('');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleDesignationChange = (e, value) => {
    setDesignation(value);
  };

  const handleJobProfileChange = (e) => {
    setJobProfile(e.target.value);
  };

  const handleTopicInputChange = (e) => {
    setTopicInput(e.target.value);
  };
  
  const handleAddTopic = (e) => {
    if (e.key === 'Enter' && topicInput.trim()) {
      setTopics((prevTopics) => [...prevTopics, topicInput.trim()]);
      setTopicInput('');
    }
  };
  
  const handleDeleteTopic = (index) => {
    setTopics((prevTopics) => prevTopics.filter((_, i) => i !== index));
  };
  
  

  const handleConfirmInvite = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address.');
      return;
    }

    if(topics.length === 0) { 
      alert('Please add topics to include in the interview.');
      return;
    }

    if(JobProfile === '') {
      alert('Please enter the job profile.');
      return;
    }

    if(designation === '') {
      alert('Please enter the designation.');
      return;
    }

    if (!interviewDate || !interviewTime) {
      alert('Please select a date and time for the interview.');
      return;
    }

    confirmInvite();
  };

  const [sendLinkLoader, setSendLinkLoader] = useState(false);

  const confirmInvite = () => {
    if (selectedJd) {
      setSendLinkLoader(true);
      instance.post('interview-system/talk/', {
        jd_id: selectedJd.jd.id,
        topics: topics,
        // selected_candidate_id: candidateId,
        interviewer_iveri_id: interviewerIveriId,
        email: email,
        designation: designation,
        job_profile: JobProfile,
        interview_date: interviewDate.format('YYYY-MM-DD'),
        interview_time: interviewTime.format('HH:mm:ss'),
      })
      .then(response => {
        setPopupOpen(false);
        alert('Interview invitation sent successfully!');
        setSendLinkLoader(false);
      })
      .catch(error => {
        console.error('Error inviting candidate:', error);
        alert('Failed to send interview invitation.');
        setSendLinkLoader(false);
      });
    }
  };

  const generateChartData = (score) => ({
    labels: ['Matching Percentage', 'Remaining'],
    datasets: [{
      data: [score, 100 - score],
      backgroundColor: ['#3f51b5', '#e0e0e0'],
      hoverBackgroundColor: ['#303f9f', '#d6d6d6'],
    }]
  });

  return (
    <React.Fragment>
      <NavBar />

      <Container maxWidth="md" style={{ marginTop: '80px' }}>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>Loading matching details...</Typography>
        </Box>
      ) : (
        <Grid container spacing={4} p={4}>
        <Typography variant="h4"  sx={{ mb: 2, textAlign: "center" }}>Matching Details</Typography>
        
        { matchingDetails.length === 0 && (
            <Typography variant="h6" sx={{ textAlign: 'center', mt: 12 }}>No matching details found.</Typography>
          )
        }
        
        {matchingDetails.map((match, index) => (
          <Grid item xs={12} key={index}>
            <Card sx={{ height: '100%', position: 'relative', px: 2, py:1, '&:hover': { transform: 'scale(1.025)', transition: 'transform 0.3s' } }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Box sx={{ my:'auto', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Doughnut data={generateChartData(match.score)} options={{ plugins: { legend: { display: false } } }} />
                    <Typography variant="h6" sx={{ mt: 2 }}>{`${match.score.toFixed(2) }%`}</Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>Match Score</Typography>

                  </Box>
                </Grid>
                <Grid item ml={2} xs={8}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar>
                      {process === 'resume' ? <FileOpenIcon /> : <PersonOutlined />}
                    </Avatar>
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      {process === 'resume' ? match.jd.jd_file_name : match.resume.resume_file_name}
                    </Typography>
                  </Box>
                  <Typography variant="body2">Uploaded At: {dayjs(process === 'resume' ? match.jd.uploaded_at : match.resume.uploaded_at).format('HH:mm DD-MM-YYYY')}</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                    {(process === 'resume' ? match.jd.keywords : match.resume.keywords).slice(0, 5).map((keyword, keywordIndex) => (
                      keyword && <Chip key={keywordIndex} label={keyword} />
                    ))}
                  </Box>
                  <Grid container spacing={2} mt={2}>
                    <Grid item>
                      <Button
                        size="small"
                        variant="outlined"
                        href={process === 'resume' ? match.jd.jd_file_pdf : match.resume.resume_file_pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View PDF
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        variant="outlined"
                        color='success'
                        onClick={() => handleOpenPopup(match)}
                      >
                        Schedule Interview
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      )}

      {/* Confirmation Popup */}
      {popupOpen && selectedJd && (
          <Backdrop open={popupOpen} sx={{ zIndex: 999, paddingTop: "30px" }} >

      <div style={{ position: 'fixed', overflowY: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', zIndex: '1000', marginTop: '100px', maxHeight: '80vh' }}>
          <h2>Confirm Interview Invitation</h2>
          <p>Are you sure you want to send an interview invitation for the JD: <strong>{selectedJd.jd.jd_file_name.slice(0,-4)}</strong>?</p>
          
          {/* Candidate Email Input */}
          <TextField
            label="Candidate Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
            margin="normal"
          />

          {/* Designation Input */}
          <Autocomplete
            options={['Entry Level', 'Experienced', 'Manager', 'Executive', 'Senior Executive']}
            getOptionLabel={(option) => option}
            value={designation}
            onChange={handleDesignationChange}
            renderInput={(params) => <TextField {...params} label="Designation" variant="outlined" margin="normal" />}
          />

          <TextField
            label="Job Profile"
            variant="outlined"
            fullWidth
            value={JobProfile}
            onChange={handleJobProfileChange}
            margin="normal"
          />

                {/* Topics to Include Input */}
          <TextField
            label="Add Topics"
            variant="outlined"
            fullWidth
            value={topicInput}
            onChange={handleTopicInputChange}
            onKeyDown={handleAddTopic}
            margin="normal"
            helperText="Press enter to enter multiple topics"

          />

          {/* Display Topics as Chips */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
            {topics.map((topic, index) => (
              <Chip
                key={index}
                label={topic}
                onDelete={() => handleDeleteTopic(index)}
                deleteIcon={<DeleteIcon />}
              />
            ))}
          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container textAlign="center" spacing={1} mt={1}>
              {/* Date Picker */}
              <Grid item xs={4} >
                <DatePicker
                  label="Date"
                  format='DD-MM-YYYY'
                  value={interviewDate}
                  onChange={(newValue) => setInterviewDate(newValue)}
                  renderInput={(params) => <TextField {...params}  />}
                />
              </Grid>

              {/* Time Picker */}
              <Grid item xs={4} >
                <TimePicker
                  label="Time"
                  value={interviewTime}
                  onChange={(newValue) => setInterviewTime(newValue)}
                  renderInput={(params) => <TextField {...params}  />}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>



          <Grid container>
            <Grid item container xs={6}>

            </Grid>
          </Grid>

          {
            sendLinkLoader ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) :
            (
              <Box mt={2}>
                <Button onClick={handleConfirmInvite} variant="contained" color="primary">
                  Send Invite
                </Button>
                <Button onClick={handleClosePopup} variant="outlined" color="error" style={{ marginLeft: '10px' }}>
                  Cancel
                </Button>
              </Box>
            )

          }
          
          <Typography variant="body2" color="#0f0f0f" sx={{ mt: 2 }}>
            Please be careful to fill in the job profile and designation as the interview will be scheduled accordingly.
          </Typography>
        </div>
        </Backdrop>
      )}
              </Container>
    </React.Fragment>
  );
};

export default MatchingDetails;
