import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Chip, Container, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FilterListIcon from '@mui/icons-material/FilterList';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import instance from '../../../globals/axios';
import NavBar from '../../../components/NavBar';
import { Link } from 'react-router-dom';  
import ViewRequirementCard from '../../../components/ViewRequirementCard';


const MyComponent = () => {
  const [requirements, setRequirements] = useState([]);
  const company_id = localStorage.getItem('company_id');

  useEffect(() => {
    instance.get(`/company/${company_id}/get_requirement/`)
      .then(response => {
        setRequirements(response.data);
      })
      .catch(error => {
        console.error('Error fetching requirements:', error);
      });
  }, []);

  const [filter, setFilter] = useState('');
  const [requirementStatus, setRequirementStatus] = useState([]);
  const [raisedOnStart, setRaisedOnStart] = useState(null);
  const [raisedOnEnd, setRaisedOnEnd] = useState(null);
  const [published, setPublished] = useState(false);

  const handleFilterChange = () => {
    // Logic to apply filters
    const newFilter = {
      filter,
      requirementStatus,
      raisedOnStart,
      raisedOnEnd,
      published,
    };
    console.log(newFilter)
    setFilter(newFilter)
    // onFilterChange(newFilter);
  };


  return (
    <Container px={10} sx={{ marginTop:"100px"}}>
    <NavBar />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Active Requirements
        </Typography>
      </Grid>
      <Grid container spacing={2} mx="auto" my={2} alignItems="center">
        <Grid item xs={2}>

        </Grid>
        {/* <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={published}
                onChange={() => setPublished(!published)}
              />
            }
            label="Published"
          />
        </Grid> */}
        <Grid item xs={2}>
          <TextField
            select
            label="Status"
            value={requirementStatus}
            onChange={(e) => setRequirementStatus(e.target.value)}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </TextField>
        </Grid>
    
        <Grid item xs={2}>
          <TextField
            id="raised-on-start"
            label="Raised On (Start)"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={raisedOnStart}
            onChange={(e) => setRaisedOnStart(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="raised-on-end"
            label="Raised On (End)"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={raisedOnEnd}
            onChange={(e) => setRaisedOnEnd(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleFilterChange}
          >
            Apply Filters
          </Button>
        </Grid>
      </Grid>

      {requirements.map(requirement => (
        <Grid item xs={12} md={6} key={requirement.id}>
          <ViewRequirementCard role="HM" requirement={requirement} />
        </Grid>
      ))}
    </Grid>

    </Container>
  );
}

export default MyComponent;
