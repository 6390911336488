import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Chip, CircularProgress, Container, Button, Box, TextField, ButtonGroup } from '@mui/material';
import {makeStyles} from '@mui/styles'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';  // Import Link
import Sidebar from '../../components/Sidebar';


import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';
import { Tabs, Tab, Divider } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const RequirementCard = () => {
    const classes = useStyles();
    const [requirements, setRequirements] = useState(null);
    const [loading, setLoading] = useState(false);
    const company_id = localStorage.getItem('company_id');
    const [reqId, setReqId] = useState("");
    const [value, setValue] = useState(0);

    const [applicants, setApplicants] = useState([]);
    const [jobOpenings, setJobOpenings] = useState([]);

    const iveri_id = localStorage.getItem('iveri_id');

  
    const formData = new FormData();
    formData.append('applicant_iveri_id', iveri_id);

    useEffect(() => {

        instance.post(`/company/my-applications/`, formData)
            .then(response => {
            setRequirements(response.data);
            })
            .catch(error => {
            console.error('Error fetching requirements:', error);
            });
    }, []);
  
    // const handleCardClick = (requirementId) => {
    //   console.log('Clicked Requirement ID:', requirementId);
    //   setReqId(requirementId)
    // };
    
    const handleCardClick = (requirementId) => {
      setReqId(requirementId)
    };
    
    useEffect(() => {
      setLoading(true)
      instance.get(`company/${company_id}/get-applicants/?requirement_id=${reqId}`)
      .then(response => {
        setApplicants(response.data);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching requirement data:', error);
        setLoading(false)
      });

    }, [reqId]);


  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  }

  if (loading) {
    return <CircularProgress />;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <Container>
    <Sidebar />
    <Container sx={{ marginTop: "90px", p:2}}>
        <Grid item xs={12}>
            
            <Tabs value={value} onChange={handleChange} centered outlined>
                <Tab sx={{ fontSize: "1.15rem" }} label='Applied Jobs' />
                <Tab sx={{ fontSize: "1.15rem" }} label='Saved Jobs' />
            </Tabs>

        </Grid>


    {
        value===0 && (
            <Box>
                {requirements && (
                                
                    <Grid container spacing={4} my={2} >
                    {
                    requirements.map(requirement => (
                        <Grid item xs={11} md={6} mx="auto" key={requirement.id}>
                        <Link to={`/view-job/${requirement.requirement_id}`} style={{ textDecoration: 'none' }}>
                            <Card>
                                <CardContent>
                                <Typography variant="h6" my={1} component="div">
                                    {requirement.profile_title}
                                </Typography>
                                <Typography variant='body1' color="text.secondary" gutterBottom>
                                    {requirement.designation}
                                </Typography>
                                
                                <Typography my={1} color="text.secondary" gutterBottom>
                                Requirement Raised On:  <span sx={{my:"auto"}}>{new Date(requirement.requirement_raised_on).toLocaleString()}</span> 
                                </Typography>
                              


                                

                                
                                <Typography color="text.secondary" sx={{ my:1 }} gutterBottom>
                                    Requirement ID: {requirement.requirement_id}
                                </Typography>
                                </CardContent>
                            </Card>
                          </Link>
                        </Grid>
                    ))}
                    </Grid>
                )}
            </Box>
        )
        
    }

    {
        value===1 && (
            <Box>
            </Box>
        )
        
    }
 
    </Container>
    </Container>
  );
};

export default RequirementCard;
