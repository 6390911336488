import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, Avatar, ListItemText, Button,
  Typography, Box, IconButton, Grid, Paper, CircularProgress, Container } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'chart.js/auto'; // Required for Chart.js 3
import instance from '../../globals/axios';
import { useParams } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import PersonIcon from '@mui/icons-material/Person';
import ComputerIcon from '@mui/icons-material/Computer';
import CloseIcon from '@mui/icons-material/Close';


const ReportComponent = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const interviewId = window.location.pathname.split('/').pop();
  const [ chatData, setChatData ] = useState([]);

  useEffect(() => {
    
    instance.get(`interview-system/assessment/?interview_id=${interviewId}`)
      .then(response => {
        setData(response.data);
        const filteredChatData = response.data.interview_data.slice(1);
        setChatData(filteredChatData);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
        setLoading(false);
      });
  }, []);

  const downloadPDF = () => {
    const input = document.getElementById('report-content');
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      position += imgHeight;

      while (position < canvas.height) {
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position - canvas.height, imgWidth, imgHeight);
        position += imgHeight;
      }

      pdf.save(`report.pdf`);
    });
  };


    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const renderAvatar = (role) => {
      if (role === 'user') {
        return (
          <Avatar>
            <PersonIcon />
          </Avatar>
        );
      }
      return (
        <Avatar>
          <ComputerIcon />
        </Avatar>
      );
    };

  const renderDonutChart = (label, value) => {
    const data = {
      labels: [label, 'Remaining'],
      datasets: [
        {
          label: label,
          data: [parseInt(value), 100 - parseInt(value)],
          backgroundColor: ['#3f51b5', '#e0e0e0'],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return <Pie data={data} options={options} />;
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', my: "auto", mt: 10 }}>
        <Typography variant="h6">Loading the report...</Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return <Typography variant="h6" color="error">Failed to load the report.</Typography>;
  }

  return (
    <React.Fragment>
    <NavBar />
    <Container maxWidth= "md" style={{ marginTop: '80px' }}>
    <Box sx={{ padding: 4 }} id="report-content">
      <Typography variant="h4" align="center" gutterBottom>
        Assessment Report
      </Typography>
      {
        chatData.length > 2 ? (
          <Paper>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">Strengths</Typography>
                  <ul>
                    {data.assessment.strengths.map((strength, index) => (
                      <li key={index}>{strength}</li>
                    ))}
                  </ul>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">Weaknesses</Typography>
                  <ul>
                    {data.assessment.weaknesses.map((weakness, index) => (
                      <li key={index}>{weakness}</li>
                    ))}
                  </ul>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">Metrics</Typography>
                  <Grid container spacing={2}>
                    {Object.entries(data.assessment.metrics).map(([label, value], index) => (
                      <Grid item xs={6} md={3} key={index}>
                        <Box>
                          <Typography mt={2} variant="subtitle1" align="center">
                            {label}
                          </Typography>
                          <Typography mb={2} variant="subtitle2" align="center">
                            {value}
                          </Typography>
                          <Box p={2}>
                          {renderDonutChart(label, value)}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">Overall Performance</Typography>
                  <Typography variant="body1">{data.assessment.overall_performance}</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Box sx={{ textAlign: 'center', marginTop: 4 }}>
              <Button variant="outlined" color="primary" onClick={downloadPDF}>
                Download Report as PDF
              </Button>
              <Button variant="contained" color="primary" onClick={handleClickOpen}>
                View Chat
              </Button>
            </Box>

          </Paper>
        ) : (
          <Box>
            <Typography textAlign="center">
              Interview Detailed Assessment not genereated
            </Typography>

          </Box>
        )
      }
      
    </Box>

    <div>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Chat History
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <List>
              {chatData.map((message, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemAvatar>
                    {renderAvatar(message.role)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant="body1">{message.role === 'assistant' ? 'AI (Greg)' : 'You'}</Typography>}
                    secondary={<Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{message.content}</Typography>}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
    </Container>
    </React.Fragment>
  );
};

export default ReportComponent;
