import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import { Grid, Card, Box, Typography, Container } from "@mui/material";
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { FaFilePdf } from 'react-icons/fa';

import instance from "../../globals/axios";
import ProfileHeader from "../../components/ProfileHeader";
import CustomButton from "../../components/Button/CustomButtom";
import BackButton from "../../components/Button/BackButton";
import Sidebar from "../../components/Sidebar";
import LinkedIcon from "../../assets/devicon_linkedin.png"
import gmailIcon from "../../assets/logos_google-gmail.png"
import whatsappIcon from "../../assets/logos_whatsapp-icon.png"
import pdfIcon from "../../assets/sharefile.png"
import linkIcon from "../../assets/link.png"
import { SERVER_BASE_ADDRESS } from "../../globals/const"

export default function Skills() {
  const [shareUrl, setShareUrl] = useState("");
  const [personalDetails, setPersonalDetails] = useState({});
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const iveri_id = localStorage.getItem("iveri_id");
    if (iveri_id) {
      setShareUrl(`https://test-env.d1hsq3jwlq96fn.amplifyapp.com/iveri-profile/${iveri_id}`);
      // Fetch user name from your API or localStorage
      setUserName("Your Name"); // Replace with actual user name
    }
  }, []);


  useEffect(() => {
    instance
      .get(SERVER_BASE_ADDRESS + "view-profile/")
      .then((res) => res.data)
      .then((data) => {
        setPersonalDetails(data.personal_details);
      });
  }, []);

  const generateShareMessage = (recipientName = "[Recipient Name]") => {
    return `Dear ${recipientName},

I'm excited to share my iVeri profile with you! iVeri has verified my credentials, including my education, work experience, skills, and certifications. You can view my complete profile here: ${shareUrl}.

I'd love to explore potential opportunities and collaborations with you. Let's connect!

Best regards,
${personalDetails.name}`;
  };

const handleShare = (platform) => {
  const message = generateShareMessage();
  let url;

  switch (platform) {
    case 'linkedin':
      url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&summary=${encodeURIComponent(message)}`;
      break;
    case 'email':
      const subject = encodeURIComponent("My Verified iVeri Profile – Let’s Connect!");
      const body = encodeURIComponent(message);
      url = `mailto:?subject=${subject}&body=${body}`;
      break;
    case 'whatsapp':
      url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
      break;
    default:
      url = shareUrl;
  }

  window.open(url, '_blank');
};


  const pdfUrl = 'path/to/your/file.pdf'; 

  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "20px" }}>
        <ProfileHeader showUploadIcon={false} />

        <Box>
          <Typography sx={{fontWeight: "600"}} py={2} variant="h5" gutterBottom>
            Share My Profile
          </Typography>
          <Card
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: 'grey.400',
              boxShadow: 'none'
            }}
          >
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
                <div onClick={() => handleShare('linkedin')}>
                  <img src={LinkedIcon} style={{width: "40px", height: "40px", cursor: "pointer"}} alt="LinkedIn" />
                </div>
                <Typography variant="caption">LinkedIn</Typography>
              </Grid>
              <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
                <div onClick={() => handleShare('email')}>
                  <img src={gmailIcon} style={{width: "40px", height: "40px", cursor: "pointer"}} alt="Email" />
                </div>
                <Typography variant="caption">Gmail</Typography>
              </Grid>
              <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
                <div onClick={() => handleShare('whatsapp')}>
                  <img src={whatsappIcon} style={{width: "40px", height: "40px", cursor: "pointer"}} alt="WhatsApp" />
                </div>
                <Typography variant="caption">WhatsApp</Typography>
              </Grid>
              <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
                <a href={pdfUrl} download>
                  <img src={pdfIcon} style={{width: "40px", height: "40px"}} alt="PDF" />
                </a>
                <Typography variant="caption">PDF</Typography>
              </Grid>
              <Grid item xs={4} display="flex" flexDirection="column" alignItems="center">
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(shareUrl).then(() => {
                      alert('Link copied to clipboard!');
                    });
                  }}
                  style={{cursor: "pointer"}}
                >
                  <img src={linkIcon} style={{ width: "40px", height: "40px" }} alt="Copy Link" />
                </div>
                <Typography variant="caption">Copy Link</Typography>
              </Grid>
            </Grid>
          </Card>
          <CustomButton my={1}>{"Next"}</CustomButton>
          <BackButton/>
        </Box>
      </Container>
    </>
  );
}