import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Container, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, Chip, TableRow, Paper, Grid, Box, Card, CardContent } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { LocalConvenienceStoreOutlined, OpenInNew } from '@mui/icons-material';
import { LocationOn, MonetizationOn, Event, Description, AccountCircle, Business, AccountBalance, Person, EventNote } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser, FaCheck, FaTimes, FaPlus} from 'react-icons/fa';

const ViewExperiences = ({ experiences }) => {
  return (
    <React.Fragment>
      <Box>
        <Typography variant="h5" sx={{ marginTop:4 }} textAlign="center">
            Experience Details
        </Typography>
      </Box>

    {experiences && experiences.length > 0 ? (
            <Grid container spacing={2} p={2}>
            {experiences.map(experience => (
              <Grid item xs={12} md={6} key={experience.id}>
                <Card variant="outlined" sx={{ backgroundColor: '#f5f5f5', borderRadius: '15px', padding: '8px', marginBottom: '8px' }}>
                  <CardContent>
                
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <Business sx={{ marginRight: '4px' }} />
                      Company: {experience.company || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <Person sx={{ marginRight: '4px' }} />
                      Designation: {experience.designation || 'N.A.'}
                    </Typography>
                    {experience.website && (
                      <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
    
                        <Link href={experience.website} target="_blank">
                          <OpenInNew sx={{ marginRight: '4px' }} />
                          Company website
                        </Link>
    
                      </Typography>
                      )
                  }
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <Event sx={{ marginRight: '4px' }} />
                      Start Date: {experience.start_date ? new Date(experience.start_date).toLocaleDateString() : 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <Event sx={{ marginRight: '4px' }} />
                      End Date: {experience.end_date ? new Date(experience.end_date).toLocaleDateString() : 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <MonetizationOn sx={{ marginRight: '4px' }} />
                      Fixed Salary: {experience.fixed_salary_currency || 'N.A.'} {experience.fixed_salary_amount || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <MonetizationOn sx={{ marginRight: '4px' }} />
                      Variable Salary: {experience.variable_salary_currency || 'N.A.'} {experience.variable_salary_amount || 'N.A.'}
                    </Typography>
                    
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <AccountCircle sx={{ marginRight: '4px' }} />
                      Reporting To: {experience.reporting_to || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      <AccountCircle sx={{ marginRight: '4px' }} />
                      Reportee: {experience.reportee || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                    <AccountCircle sx={{ marginRight: '4px' }} />  
                    Role: {experience.role || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                    <LocationOn sx={{ marginRight: '4px' }} /> 
                    Work Location: {experience.work_location || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Office Type: {experience.office_type || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Notice Period: {experience.notice_period || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Days Negotiable: {experience.days_negotiable || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Accommodation: {experience.accommodation || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Accommodation Furniture: {experience.accommodation_furniture || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Accommodation Family: {experience.accommodation_family || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Perks: {experience.perks || 'N.A.'}
                    </Typography>
    
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Currency: {experience.currency || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Expected Salary: {experience.expected_salary || 'N.A.'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      Brief Role: {experience.brief_role || 'N.A.'}
                    </Typography>
    
                    <Box sx={{ my:2}}>
    
                    {experience.uploaded_files && experience.uploaded_files.length > 0 && (
                        experience.uploaded_files.map((file, index) => (
                          <Box sx={{ my:1}} key={index}>
                            <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                              File: {file.file_name}
                            </Typography>
                            
                            <Button
                              fullWidth
                              onClick={() => window.open(file.file, '_blank')}
                              variant="outlined"
                              startIcon={<OpenInNew />}
                            >
                              View Document
                            </Button>
                          </Box>
                        ))
                      )}
                    </Box>
    
                  
              
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
    ) : (
      <Grid item xs={12} sx={{  mx: "auto ", textAlign:"center"}}>
        <Chip sx={{ mx: "auto ", textAlign:"center"}} label="No Experience Detail Added" color="warning" variant="outlined" />
      </Grid>
    )}



    </React.Fragment>
  )
}

export default ViewExperiences