import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import { useHistory } from "react-router-dom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ProfileCard from "../../../components/Card/ProfileDetails";
import Sidebar from "../../../components/Sidebar";

const BasicInfoViewPage = () => {
  const history = useHistory();
  const [basicInfoDetails, setBasicInfoDetails] = useState({});
  const [error, setError] = useState("");

  const refetchData = () => {
    instance
      .get("basic-info/")
      .then((response) => {
        const data = response.data;
        setBasicInfoDetails(data);
        setError("");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setBasicInfoDetails({});
      });
  };

  const handleEditButton = (id) => {
    history.push(`/edit-basic-info`);
  };

  useEffect(() => {
    refetchData();
  }, []);

  return (
    <>
          <Sidebar />
    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false}  />

      <Box>
        <ProfileCard
          key={basicInfoDetails.email}
          title="Basic Information"
          data={{
            Name: 
            `${basicInfoDetails.first_name} ${
              basicInfoDetails.middle_name && basicInfoDetails.middle_name !== "null" 
                ? basicInfoDetails.middle_name + ' ' 
                : ''
            }${basicInfoDetails.last_name}`,
            Email: basicInfoDetails.email,
            Phone: basicInfoDetails.mobile_number,
          }}
          
          deleteIcon={true}
          onEdit={() => handleEditButton(basicInfoDetails.id)}
          // onDelete={() => handleDeleteClick(basicInfoDetails.id)}
        />
      </Box>
    </Container>
    </>
  );
};

export default BasicInfoViewPage;
