import React from "react";
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";



const buttons = [
    {
        label: "Link Company",
        path: "/emp-link-company",
    },
    {
        label: "View / Edit Company Profile",
        path: "/emp-company-profile",
    },
    // {
    //     label: "Company Structure",
    //     path: "/company-structure",
    // },
    {
        label: "User Management",
        path: "/emp-user-management",
    },
    // {
    //     label: "Banking Details",
    //     path: "/emp-banking-details",
    // },
    {
        label: "View Dashboard",
        path: "/emp-dashboard-details",
    },
    
]

export default function EmpMenu() {

    return (
        <>
            <NavBar />
            <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
                {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}

</Container>
        </>

    );
};