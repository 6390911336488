import React, { useState } from "react";
import { Modal, Button } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import CustomButton from "../Button/CustomButtom";

const OtpPopup = ({ open, onClose, onSubmitOtp }) => {
  const [otp, setOtp] = useState("");

  const handleVerifyOtp = () => {
    onSubmitOtp(otp); 
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle}>
        <h2>OTP</h2>
        <p>Enter the 6-digit code we have sent on your email address</p>
        <MuiOtpInput
          length={6}
          value={otp}
          onChange={(value) => setOtp(value)}
          TextFieldsProps={{ fullWidth: true }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              // padding: "5px", // Add padding inside each OTP input box
              gap: "4px",
            },
          }}
        />
        <CustomButton variant="contained" color="primary" fullWidth onClick={handleVerifyOtp}>
          Verify OTP
        </CustomButton>
      </div>
    </Modal>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  backgroundColor: "white",
  padding: "16px",
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
};

export default OtpPopup;
