import React, { useState, useEffect } from "react";
import NavBar from "../../../components/NavBar";
import { degreeOptions } from './data';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

import { Button, Container, CircularProgress, Typography, TextField, Select, Input, MenuItem, Grid, Box, InputLabel, FormControl } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import { saveAuth, saveRole } from "../../../globals/utils";
import instance from "../../../globals/axios.js";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDropzone } from 'react-dropzone';
import FileInput from "../../../components/FileInput";

const DropzoneContainer = styled('div')({
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: '20px',
});

const EducationDetail = () => {
  const { id } = useParams();

  const [isSaved, setIsSaved] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [educationType, setEducationType] = useState('');
  const [degree, setDegree] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [instituteName, setInstituteName] = useState('');
  const [passingYear, setPassingYear] = useState('');
  const [board, setBoard] = useState('');
  const [score, setScore] = useState('');
  const [totalScore, setTotalScore] = useState('');
  const [awards, setAwards] = useState('');
  const [extraCurricular, setExtraCurricular] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [certificateName, setCertificateName] = useState('');
  const [scoreUnit, setScoreUnit] = useState('');
  const [awardsFile, setAwardsFile] = useState(null);
  const [extraCurricularFile, setExtraCurricularFile] = useState(null);
  const [educationFile, setEducationFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const isoStringToDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    instance.get(`edit-educational-details/${id}/`)
      .then(response => {
        const data = response.data;
        setEducationType(data.type)
        setDegree(data.degree)
        setCertificateName(data.certificate_name);
        setStartDate(isoStringToDate(data.start_date));
        setEndDate(isoStringToDate(data.end_date));
        setSpecialization(data.specialization);
        setBoard(data.board);
        setInstituteName(data.institute_name);
        setScore(data.score);
        setTotalScore(data.total_score);
        setScoreUnit(data.score_unit);
        setAwards(data.awards);
        setExtraCurricular(data.extra_curricular);
      })
      .catch(error => {
        console.error('Error fetching educational details:', error);
      });
  }, [id]);
    

    const onDropExtraCurricular = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setExtraCurricularFile(acceptedFiles[0]);
      }
    };
    
    const onDropEducation = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setEducationFile(acceptedFiles[0]);
      }
    };
  
    const onDropAwards = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setAwardsFile(acceptedFiles[0]);
      }
    };
    
    const { getRootProps: getAwardsRootProps, getInputProps: getAwardsInputProps } = useDropzone({
      accept: '.pdf, .jpeg, .jpg, .png',  
      onDrop: onDropAwards,
    });

    // For Extra Curricular
    const { getRootProps: getExtraCurricularRootProps, getInputProps: getExtraCurricularInputProps } = useDropzone({
      accept: '.pdf',
      onDrop: onDropExtraCurricular,
    });

    // For Education
    const { getRootProps: getEducationRootProps, getInputProps: getEducationInputProps } = useDropzone({
      accept: '.pdf',
      onDrop: onDropEducation,
    });


  const [internshipFormData, setInternshipFormData] = useState([
    {
      internship_company: '',
      internship_role: '',
      internship_duration_from: null,
      internship_duration_to: null
    }
  ]);

  const [projectFormData, setProjectFormData] = useState([
    {
      project_title: '',
      project_desc: '',
      project_link: ''
    }
  ]);

  const handleInternChange = (event, index) => {
    const { name, value } = event.target;
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData[index][name] = value;
    setInternshipFormData(updatedInternshipFormData);
  };

  const handleDateChange = (date, name, index) => {
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData[index][name] = date;
    setInternshipFormData(updatedInternshipFormData);
  };

  const handleAddBoxIntern = () => {
    setInternshipFormData([
      ...internshipFormData,
      {
        internship_company: '',
        internship_role: '',
        internship_duration_from: null,
        internship_duration_to: null
      }
    ]);
  };

  const handleRemoveBoxIntern = (index) => {
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData.splice(index, 1);
    setInternshipFormData(updatedInternshipFormData);
  };

  const handleProjectChange = (event, index) => {
    const { name, value } = event.target;
    const updatedProjectFormData = [...projectFormData];
    updatedProjectFormData[index][name] = value;
    setProjectFormData(updatedProjectFormData);
  };

  const handleAddBoxProject = () => {
    setProjectFormData([
      ...projectFormData,
      {
        project_title: '',
        project_desc: '',
        project_link: ''
      }
    ]);
  };

  const handleRemoveBoxProject = (index) => {
    const updatedProjectFormData = [...projectFormData];
    updatedProjectFormData.splice(index, 1);
    setProjectFormData(updatedProjectFormData);
  };


  const handleSaveClick = (event) => {
    event.preventDefault();
    setLoading(true);

    const sendData = new FormData();
    // Assuming `formData` is an object with the properties mentioned above
    sendData.append('type', educationType);
    sendData.append('degree', degree);
    sendData.append('specialization', specialization);
    sendData.append('institute_name', instituteName);
    sendData.append('start_date', startDate); 
    sendData.append('end_date', endDate); 
    sendData.append('passing_year', passingYear);
    sendData.append('board', board);
    sendData.append('score', score);
    sendData.append('total_score', totalScore);
    sendData.append('awards', awards);
    sendData.append('extra_curricular', extraCurricular);
    sendData.append('video_file', videoFile);
    sendData.append('certificate_name', certificateName);
    sendData.append('score_unit', scoreUnit);
    
      
    // awards_file extra_curricular_file educational_file
    const formattedInternshipData = internshipFormData.map(item => {
      return {
        internship_company: item.internship_company,
        internship_role: item.internship_role,
        internship_duration_from: item.internship_duration_from,
        internship_duration_to: item.internship_duration_to,
      };
    });
    
    // Convert projectFormData to a format that can be sent to the server
    const formattedProjectData = projectFormData.map(item => {
      return {
        project_title: item.project_title,
        project_desc: item.project_desc,
        project_link: item.project_link,
      };
    });

    // sendData.append('internship_data', JSON.stringify(formattedInternshipData));
    // sendData.append('project_data', JSON.stringify(formattedProjectData));
    

      instance.post(`edit-educational-details/${id}/`, sendData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // Extract the educational_details_id from the response
        const formData = new FormData();
        
        formData.append('awards_file', awardsFile);
        formData.append('educational_file', educationFile);
        formData.append('extra_curricular_file', extraCurricularFile);

        // Second API call to upload files
        instance.post(`upload-educational-files/${id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((res) => {
          setLoading(false);

          alert('Data Updated successfully!');
          setTimeout(() => {
            window.location = "/view-education";
          }, 500);
        })
        .catch((error) => {
          setLoading(false);
          alert('Error uploading files. Please try again.');
        });
      })
      .catch((error) => {
        setLoading(false);
        alert('Error creating/retrieving EducationalDetails entry. Please try again.');
      });

  };


  return (
    <>
      <NavBar />
      <Container maxWidth="xs" style={{ marginTop: "80px" }}>
      <Typography variant="h6" sx={{ color: "rgb(0, 184, 230)" }}>
        Education Details
      </Typography>

      <Grid my={2} px={2} container spacing={2}>
        
        
        { isSaved ? 
           (
          <Grid container spacing={2}>
            <Grid m={2} spacing={2} container>
              {projectFormData.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <Box boxShadow={3} padding={2}>
                    <Typography sx={{ my: 1 }} variant="subtitle1">Project Title</Typography>
                    <TextField
                      fullWidth sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Project Title"
                      name="project_title"
                      value={item.project_title}
                      onChange={(event) => handleProjectChange(event, index)}
                    />
                    <Typography sx={{ my: 1 }} variant="subtitle1">Project Description</Typography>
                    <TextField
                      fullWidth sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Project Description"
                      name="project_desc"
                      value={item.project_desc}
                      onChange={(event) => handleProjectChange(event, index)}
                    />
                    <Typography sx={{ my: 1 }} variant="subtitle1">Project Link</Typography>
                    <TextField
                      fullWidth sx={{ my: 1 }}
                      variant="outlined"
                      placeholder="Project Link"
                      name="project_link"
                      value={item.project_link}
                      onChange={(event) => handleProjectChange(event, index)}
                    />
                    {index > 0 && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRemoveBoxProject(index)}
                      >
                        - Remove Project
                      </Button>
                    )}
                  </Box>
                </Grid>
              ))}
              <Grid flexDirection="row-reverse" item xs={12}>
                <Button variant="outlined" color="primary" onClick={handleAddBoxProject}>
                  + Add Project
                </Button>
              </Grid>
            </Grid>
      
            <Grid spacing={2} m={2} container>
            {internshipFormData.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box boxShadow={3} padding={2}>
                  <Typography sx={{ my: 1 }} variant="subtitle1">Internship Company</Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Internship Company"
                    value={item.internship_company}
                    name="internship_company"
                    onChange={(event) => handleInternChange(event, index)}
                  />
                  <Typography sx={{ my: 1 }} variant="subtitle1">Internship Role</Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Internship Role"
                    value={item.internship_role}
                    name="internship_role"
                    onChange={(event) => handleInternChange(event, index)}
                  />
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider sx={{ my: 1 }} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        value={item.internship_duration_from}
                        onChange={(date) =>
                          handleDateChange(date, 'internship_duration_from', index)
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider sx={{ my: 1 }} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To"
                        value={item.internship_duration_to}
                        onChange={(date) =>
                          handleDateChange(date, 'internship_duration_to', index)
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  {index > 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveBoxIntern(index)}
                    >
                      - Remove Project
                    </Button>
                  )}
                </Box>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" onClick={handleAddBoxIntern}>
                + Add Internship
              </Button>
            </Grid>
          </Grid>

            <Grid mx={2} item xs={12} md={6}>
              <Typography variant="subtitle1">Awards</Typography>
            </Grid>
            <Grid mx={2} item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Awards"
                multiline
                rows={3}
                value={awards}
                name="awards"
                onChange={(e) => setAwards(e.target.value)}
              />
            </Grid>
            <Grid mx={2} item xs={12} md={6}>
              <Typography variant="subtitle1">Extra Curricular</Typography>
            </Grid>
            <Grid mx={2} item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Extra Curricular"
                multiline
                rows={3}
                value={extraCurricular}
                name="extra_curricular"
                onChange={(e) => setExtraCurricular(e.target.value)}
              />
            </Grid>

        

          <Grid p={4}>
                        <Typography variant="h6" sx={{ textAlign: "center", fontAlign: "center"}} gutterBottom>
                        Upload Awards File
                      </Typography>
                      <DropzoneContainer {...getAwardsRootProps()}>
                        <input {...getAwardsInputProps()} />
                        <Typography variant="body1">
                          Drag and drop a PDF file here, or click to select one.
                        </Typography>
                      </DropzoneContainer>
                      {awardsFile && (
                        <Typography variant="body1">Awards File: {awardsFile.name}</Typography>
                      )}
          </Grid>

          <Grid p={4}>
            <Typography variant="h6" sx={{ textAlign: "center", fontAlign: "center" }} gutterBottom>
              Upload Education File
            </Typography>
            <DropzoneContainer {...getEducationRootProps()}>
              <input {...getEducationInputProps()} />
              <Typography variant="body1">
                Drag and drop a PDF file here, or click to select one.
              </Typography>
            </DropzoneContainer>
            {educationFile && (
              <Typography variant="body1">Education File: {educationFile.name}</Typography>
            )}
          </Grid>

          <Grid p={4}>
            <Typography variant="h6" sx={{ textAlign: "center", fontAlign: "center" }} gutterBottom>
              Upload Extra Curricular File
            </Typography>
            <DropzoneContainer {...getExtraCurricularRootProps()}>
              <input {...getExtraCurricularInputProps()} />
              <Typography variant="body1">
                Drag and drop a PDF file here, or click to select one.
              </Typography>
            </DropzoneContainer>
            {extraCurricularFile && (
              <Typography variant="body1">Extra Curricular File: {extraCurricularFile.name}</Typography>
            )}
          </Grid>





          </Grid>
        )
         
          :
        (

          <Grid container spacing={2}>
          {/* Type */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Education Type</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="type-dropdown-label">Education Type</InputLabel>
              <Select
                fullWidth
                labelId="type-dropdown-label"
                id="type-dropdown"
                value={educationType}
                name="type_dropdown"
                label="Education Type"
                onChange={(e) => setEducationType(e.target.value)}
              >
                  <MenuItem value="School">School</MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value="Graduation">Graduation</MenuItem>
                  <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
                  <MenuItem value="Doctorate">Doctorate (PhD)</MenuItem>
                  <MenuItem value="Professional Certification">Professional Certification</MenuItem>
                  <MenuItem value="Voluntary Certification">Voluntary Certification</MenuItem>
         
              </Select>
            </FormControl>
          </Grid>
        
          {/* Degree */}
          {educationType !== 'Professional Certification' && educationType !== 'Voluntary Certification' && (
            <Grid item xs={12} container fullWidth>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Degree</Typography>
              </Grid>
              <Grid my={2} item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="degree-dropdown-label">Degree</InputLabel>
                  <Select
                    fullWidth
                    labelId="degree-dropdown-label"
                    id="degree-dropdown"
                    value={degree}
                    name="degree_dropdown"
                    label="Degree"
                    onChange={(e) => setDegree(e.target.value)}
                    disabled={!educationType}
                  >
                    {degreeOptions[educationType] && degreeOptions[educationType].map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}


          {/* Specialisation */}
          {educationType !== 'Professional Certification' && educationType !== 'Voluntary Certification' && (
            <Grid item xs={12} container fullWidth>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Specialisation</Typography>
              </Grid>
              <Grid my={2} item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Specialisation"
                value={specialization}
                name="specialization_dropdown"
                onChange={(e) => setSpecialization(e.target.value)}
              />
            </Grid>
            </Grid>
          )}

          {/* Certificate Name */}
          {
            (educationType === 'Professional Certification' || educationType === 'Voluntary Certification') && (
            <Grid item xs={12} container fullWidth>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Certificate Name</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  value={certificateName}
                  onChange={(e) => setCertificateName(e.target.value)}
                />
              </Grid>
            </Grid>
          )}

        
          {/* Passing Year 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="End Date" value={endDate} onChange={(newValue) => setEndDate(newValue)} />
            </LocalizationProvider>
        */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Course Duration</Typography>
          </Grid>
          <Grid item xs={6}>
            
            <TextField
                fullWidth
                type="date"
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                    shrink: true, 
                    style: { marginTop: '4px' } 
                }}
            />
          </Grid>
          
          <Grid item xs={6}>

          <TextField
                fullWidth
                type="date"
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)} 
                InputLabelProps={{
                    shrink: true, 
                    style: { marginTop: '4px' } 
                }}/>
          </Grid>

          {/*

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Passing Year"
              value={passingYear}
              name="passing_year"
              onChange={(e) => setPassingYear(e.target.value)}
            />
          </Grid>

          */}
        
          {/* Board */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Board</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="board-dropdown-label">Board</InputLabel>
              <Select
                fullWidth
                labelId="board-dropdown-label"
                id="board-dropdown"
                value={board}
                name="board_dropdown"
                onChange={(e) => setBoard(e.target.value)}
              >
                <MenuItem value="ICSE">ICSE</MenuItem>
                <MenuItem value="CBSE">CBSE</MenuItem>
                <MenuItem value="IGCSE">IGCSE</MenuItem>
                <MenuItem value="IB">IB</MenuItem>
                <MenuItem value="State Board">State Board</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        
          {/* Institute Name */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Institute Name</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Institute Name"
              value={instituteName}
              name="institute_name"
              onChange={(e) => setInstituteName(e.target.value)}
            />
          </Grid>
        
          {/* Score */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Score</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Score"
              value={score}
              name="score"
              onChange={(e) => setScore(e.target.value)}
            />
          </Grid>
        
          {/* Total Score */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Total Score</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Total Score"
              value={totalScore}
              name="total_score"
              onChange={(e) => setTotalScore(e.target.value)}
            />
          </Grid>

          {/* Score Unit */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Score Unit</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="score-unit-dropdown-label">Grading</InputLabel>
              <Select
                fullWidth
                labelId="score-unit-dropdown-label"
                id="score-unit-dropdown"
                value={scoreUnit}
                name="score_unit_dropdown"
                label="Grading"
                onChange={(e) => setScoreUnit(e.target.value)}
              >
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="CGPA">CGPA</MenuItem>
                <MenuItem value="Marks">Marks</MenuItem>
              </Select>
            </FormControl>
          </Grid>



        </Grid>

        )}

        
      </Grid>

      
      { isSaved ? (
          <Box mt="2rem" mx="auto" flex>
          {loading ? (
            <Box my="2rem" mx="auto">
              <Typography>
              Uploading documents...
              </Typography>
              <br />
              <CircularProgress />
            </Box>
            ) : (
              <Box>
              <Button
                  mx={2}
                  sx={{ width: "8rem", mx:2, mb:"2rem" }}
                  variant="contained"
                  onClick={handleSaveClick}
                >
                  Save
                </Button>
                <Button
                  mx={2}
                  sx={{ width: "8rem", mx:2, mb:"2rem" }}
                  variant="contained"
                  onClick={() => setIsSaved(false)}
                >
                  Back
                </Button>
              </Box>

            )
          }
                
          </Box>
      
      ) : (
        
        <Box mx="auto" flex sx={{ width: "8rem", mb:"2rem" }} >
          <Button
            fullWidth
            variant="contained"
            onClick={() => setIsSaved(true)}
          >
            Next
          </Button>
        </Box>

      )}
      
    </Container>
    </>
  );
};

export default EducationDetail;