import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'

import { SERVER_BASE_ADDRESS } from '../../../globals/const'
import { saveAuth } from "../../../globals/authentication";
import logo from "../../../iveri.png";
import Layout from '../../../components/Layout'
import {
    TextField,
    Container,
    Button,
    Grid,
    Radio,
    Typography,
} from "@mui/material";

const styles = {
    container: {
        minHeight: "70vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "5.5rem",
        border: "5px rgb(5, 32, 71) solid",
        borderRadius: "20px",
        paddingBottom: "20px",
    },
    img: {
        width: "10rem",
    },
    text: {
        width: "90%",
        borderRadius: "100% !important",
        margin: "15px 0",
        borderRadius: "10px",
        backgroundColor: "rgb(13, 78, 175)",
    },
    btn: {
        marginTop: "15px",
        backgroundColor: "rgb(245, 61, 61)",
        height: "3.5rem",
        fontSize: "1.5rem",
        fontWeight: "600",
        width: "90%",
    },
    grid: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0",
        padding: "0",
        width: "90%",
        marginLeft: "1.1rem",
    },
    radioBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "0px",
    },
    btn2: {
        height: "1.5rem",
        fontSize: "1.5rem",
        fontWeight: "700",
        color: "rgb(3, 22, 48)",
        borderRadius: "30px",
        padding: " 1.6rem 2.3rem",
        letterSpacing: "2.5px",
        backgroundColor: "rgb(0, 184, 230)",
        marginTop: "2rem",
    },
    formStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
    },
};

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function handleLogin(event) {

        event.preventDefault();
        axios
            .post(
                `${SERVER_BASE_ADDRESS}/auth/login`,
                {
                    username: email,
                    password: password
                }
            )
            .then((result) => {
                console.log(result)
                saveAuth(result.data);
                window.open("/view-profile", "_self");
            })
            .catch((err) => {
                alert("Error - " + err.message)
            })
    }

    return (
        <div>
            <Container maxWidth="xs" style={styles.container}>
                <img src={logo} alt="" style={styles.img} />
                <form
                    style={styles.formStyle}
                    onSubmit={handleLogin}>
                    <TextField
                        style={styles.text}
                        id="username"
                        onChange={event => setEmail(event.target.value)}
                        InputProps={{
                            style: { color: "white", fontWeight: "600" },
                            placeholder: "Username",
                            classes: {
                                // Set placeholder color
                                root: { "& input::placeholder": { color: "white" } },
                            },
                            startAdornment: (
                                <FontAwesomeIcon
                                    icon={faUser}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: "white",
                                        padding: "5px 6px",
                                        borderRadius: "100%",
                                        color: "rgb(0, 107, 179)",
                                    }}
                                />
                            ),
                        }}
                    />
                    <TextField
                        style={styles.text}
                        type="password"
                        id="password"
                        placeholder="Password"
                        onChange={event => setPassword(event.target.value)}
                        InputProps={{
                            style: { color: "white", fontWeight: "600" },
                            placeholder: "Password",
                            inputProps: {
                                style: {
                                    // change placeholder color
                                    "::placeholder": { color: "white" },
                                },
                            },
                            startAdornment: (
                                <FontAwesomeIcon
                                    icon={faLock}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: "white",
                                        padding: "5px 6px",
                                        borderRadius: "100%",
                                        color: "rgb(0, 107, 179)",
                                    }}
                                />
                            ),
                        }}
                    />
                    <Button type="submit" variant="contained" style={styles.btn}>
                        Login
                    </Button>
                    <Grid container spacing={2} style={styles.grid}>
                        <Grid item style={styles.radioBtn}>
                            <Radio
                                value="a"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                size="small"
                            />
                            <Typography
                                style={{
                                    fontSize: "0.9rem",
                                    margin: "0",
                                    color: "rgb(77, 77, 77)",
                                }}
                            >
                                Remember me
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{
                                    fontSize: "0.9rem",
                                    color: "rgb(77, 77, 77)",
                                }}
                            >
                                Forgot password?
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="h6"
                        style={{
                            color: "rgb(5, 32, 71)",
                            marginTop: "1.5rem",
                            textAlign: "center",
                        }}
                    >
                        Not and existing user <br /> Please Signup to continue{" "}
                    </Typography>
                    <Link to="./sign-up">
                        <Button variant="contained" style={styles.btn2}>
                            SIGN UP
                        </Button>
                    </Link>
                </form>
            </Container>
        </div>
    );
};
