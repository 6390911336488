import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";

const GenericTable = ({ columns, data, renderRow }) => {
  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto", // Prevents horizontal scroll by allowing table to adjust within the width
      }}>
      <TableContainer component={Paper}>
        <Table aria-label="generic table" sx={{ tableLayout: "fixed", width: "100%" }}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ whiteSpace: "nowrap" }}
                  style={{ fontSize: "12px", fontWeight: 700 }}>
                  {" "}
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {renderRow(row).map((cell, cellIndex) => (
                  <TableCell key={cellIndex} style={{ fontSize: "14px", fontWeight: 500 }}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GenericTable;
