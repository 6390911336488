//Global constants in this file

// export const HOME_PAGE = process.env.PUBLIC_URL;
export const HOME_PAGE = "";

export const ASQI_COLOR = "#0e4476";

export const RZP_SECRET = process.env.RZP_SECRET;;
export const RZP_KEY = process.env.REACT_APP_RZP_KEY;


//Dev
// var _SERVER_BASE_ADDRESS = "http://127.0.0.1:8001/api/";
// var _FRONTEND_ADDRESS = "http://localhost:3000/";

 // var _SERVER_BASE_ADDRESS = "https://iverify-uat.newrl.net/api/";
 // var _FRONTEND_ADDRESS = "https://test-env.d1hsq3jwlq96fn.amplifyapp.com/";

var _SERVER_BASE_ADDRESS = "https://iverify-api.newrl.net/api/";
var _FRONTEND_ADDRESS = "https://app.iveri.io/";


// Jade backend
// var _SERVER_BASE_ADDRESS = "https://iveri-dev-api.newrl.net/api/";
// var _SERVER_BASE_ADDRESS = "http://localhost:8000/api/";
// var _FRONTEND_ADDRESS = "https://main.iveri.newrl.net/";

export const SERVER_BASE_ADDRESS = _SERVER_BASE_ADDRESS;
export const FRONTEND_ADDRESS = _FRONTEND_ADDRESS;

// export const getBlockchainNodeUrl() = 'https://devnetapi.newrl.net';
// export let getBlockchainNodeUrl() = 'http://archive1-mainnet.newrl.net:8456';

export const NEWRL_PAY_SERVICE = "https://newrl-pay-dev.herokuapp.com";
export const FAUCET_URL =
  "https://gsd8hwxzw9.execute-api.ap-south-1.amazonaws.com/default/NWRLFaucet";

export const NWRL_DECIMAL_MULTIPLIER = 1000000;

export const changeBlockChain = (chain) => {
  if (chain === "Mainnet") {
    // getBlockchainNodeUrl() = 'http://archive1-mainnet.newrl.net:8456';
    localStorage.setItem("blockchain", "Mainnet");
  } else {
    // getBlockchainNodeUrl() = 'https://devnetapi.newrl.net';
    localStorage.setItem("blockchain", "Testnet");
  }
  // window.location.reload()
};

export const BLOCKCHAINS = {
  Mainnet: {
    node: "https://mainnetgw.newrl.net",
    scanner: "https://newrl-mainnet-scan-backend.herokuapp.com",
    port: 8456,
  },
  Testnet: {
    node: "https://devnetapi.newrl.net",
    scanner: "https://newrl-testnet-scan-backend.herokuapp.com",
    port: 8421,
  },
};

export const getBlockchainNodeUrl = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }

  return BLOCKCHAINS[chain]["node"];
};

export const getBlockchain = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }
  return chain;
};

export const getBlockchainPort = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }

  return BLOCKCHAINS[chain]["port"];
};

export const getBlockchainScanner = () => {
  let chain = localStorage.getItem("blockchain");
  if (!chain || chain === null) {
    chain = "Mainnet";
    localStorage.setItem("blockchain", "Mainnet");
  }

  return BLOCKCHAINS[chain]["scanner"];
};

export const getTransactionToSignFromLocal = () => {
  let txn = localStorage.getItem("transactionToSign");
  try {
    return txn;
  } catch {
    return "";
  }
};

export function getUserName() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.user.first_name} ${auth.user.last_name}`;
  }
  return null;
}
export function getFirstName() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.user.first_name}`;
  }
  return null;
}
export function getUserId() {
  let authJson = window.sessionStorage.getItem("auth");
  if (authJson !== null && authJson !== undefined) {
    let auth = JSON.parse(authJson);
    return `${auth.user.id}`;
  }
  return null;
}
export function is_new_user() {
  let authJson = JSON.parse(window.sessionStorage.getItem("auth"));
  if (authJson !== null && authJson !== undefined) {
    if (authJson.is_new_user) {
      window.location = "/change-password";
    }
  }
}
