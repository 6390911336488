import React from 'react';
import { Button, Container, Box, Card, CardContent } from '@mui/material';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';

export default function ProfilePage(){
  // Replace this with your logic to determine whether a profile exists
  const hasProfileData = true; // Change this value based on your data


  return (
    <>
  <Sidebar />
    <Container maxWidth= "xs" > 
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Card variant="outlined">
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px' }}>
        <Link to='/edit-profile-menu' style={{ textDecoration: 'none' }}>
          <Button variant="contained"  size="large" color="primary" style={{ height: '120px', width: '300px', fontSize: '1.5rem', backgroundImage: 'linear-gradient(to right, #FFA500, #FF4500)', }}>
            {hasProfileData ? 'Edit Profile' : 'Create Profile'}
          </Button>
        </Link>
        <Link to="/view-profile" style={{ textDecoration: 'none' }}> 
          <Button variant="contained"  size="large" color="primary" style={{ height: '120px', width: '300px', fontSize: '1.5rem', backgroundImage: 'linear-gradient(to right, #00CED1, #1E90FF)' ,}}>
            View Profile
          </Button>
        </Link> 
        <Link to="/share-profile" style={{ textDecoration: 'none' }}> 
          <Button variant="contained"  size="large" color="primary" style={{ height: '120px', width: '300px', fontSize: '1.5rem', backgroundImage: 'linear-gradient(to right, #7B68EE, #9400D3)', }}>
            Share Profile
          </Button>
        </Link>
        </CardContent>
      </Card>
    </Box>
    </Container>
    </>
  );
};

