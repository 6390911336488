import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you have axios installed and set up
import { Grid, TextField, Button, Container, Box, Card, FormControl, InputLabel, Select, MenuItem, Typography, Divider, IconButton, Radio, RadioGroup, FormControlLabel, CircularProgress} from '@mui/material';
import { useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { degreeOptions } from '../../User/EditPages/data';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import instance from '../../../globals/axios';
import NavBar from '../../../components/NavBar';

const RequirementDetails = () => {
    const [requirement, setRequirement] = useState(null);
    const [updatedRequirement, setUpdatedRequirement] = useState({});
    const [educationType, setEducationType] = useState('');
    const [degree, setDegree] = useState('');
    const [generatingAIResponse, setGeneratingAIResponse] = useState(false);


    const [isEditable, setIsEditable] = useState({
      job_brief: false,
      roles_and_responsibilities: false,
      internal_interface: false,
      external_interface: false,
    });
    const [kraJson, setKraJson] = useState(requirement?.KRA || {});
    const [kraIsEditable, setKraIsEditable] = useState({});

    const toggleEdit = (field) => {
      setIsEditable(prev => ({ ...prev, [field]: !prev[field] }));
      if (!isEditable[field] && requirement && requirement[field]) {
        setUpdatedRequirement(prev => ({ ...prev, [field]: requirement[field] }));
      }
    };

    const [isCompEditable, setIsCompEditable] = useState({
      functional_competency: false,
      behavioural_competency: false,
    });
    const company_id = localStorage.getItem('company_id');
    const { req_id } = useParams();

    const handleChange = (field, value) => {
      setUpdatedRequirement(prevState => ({
        ...prevState,
        [field]: value,
      }));
    };

    const handleUpdate = () => {
      if (Object.keys(kraJson).length > 0) {
        updatedRequirement.KRA = kraJson;
      }
      
      if (Object.keys(updatedRequirement).length === 0) {
        alert('No changes to update');
        return;
      }
      updatedRequirement.id = requirement.id;


      instance.put(`company/${company_id}/update_requirement/`, updatedRequirement)
        .then(response => {
          alert('Requirement updated successfully!');
          // window.location.reload();
        })
        .catch(error => {
          console.error('Error updating requirement:', error);
        });
    };

    const toggleCompEdit = (competencyType) => {
      setIsCompEditable(prev => {
        const isFinishingEdit = prev[competencyType];
        const updatedEditables = { ...prev, [competencyType]: !prev[competencyType] };
    
        setIsCompEditable(updatedEditables);
    
        if (isFinishingEdit) {
          setTimeout(handleUpdate, 0); // Assuming handleUpdate is defined elsewhere
        }
    
        return updatedEditables;
      });
    };
    
    useEffect(() => {
      const handleGetRequirement = () => {
        instance.get(`company/get_requirement_by_req_id/?requirement_id=${req_id}`)
            .then(response => {
              const data = response.data;
              // Assuming response data's dates are in 'YYYY-MM-DD' format or null
              const formattedStartDate = data.start_date ? dayjs(data.start_date) : null;
              const formattedCloseDate = data.close_date ? dayjs(data.close_date) : null;
              const formattedRaisedOn = data.requirement_raised_on ? dayjs(data.requirement_raised_on).format("DD MMM YYYY, hh:mm A") : null;
              setDegree(data.degree)
              setEducationType(data.education_type)
              
              // Set the state with formatted dates for display in DatePicker
              setRequirement({
                ...data, 
                start_date: formattedStartDate,
                close_date: formattedCloseDate,
                requirement_raised_on: formattedRaisedOn
              });
              setUpdatedRequirement({
                functional_competency: data.functional_competency,
                behavioural_competency: data.behavioural_competency
              })
              setKraJson((data?.KRA || {}))
            })
            .catch(error => {
              console.error('Error fetching requirement data:', error);
            });
        };
          
      handleGetRequirement();

    }, [])


    const handleGenAIJD = () => {
        // Create a FormData object
        const formData = new FormData();
    
        // Dynamic user input string based on your requirement
        const user_input = `
        Write job breif and responsibilities of a ${requirement.profile_title} ${requirement.designation}.
        Give response in json 4 lines for each. Put in response in specific json objects named job_brief and roles_and_responsibilities short concise and formal,
        also add Internal Interface which will be 3-4 comma seperated designations the candidate will be interacting inside the organization 
        and add External Interface which will be 3-4 comma seperated designations or authorities or organizations the candidate will be interacting outside the organization 
        also add behavioural_competency that should be 2 behavioural skills candidate shall have
        also add functional_competency that should be 2 functional skills candidate shall have
        act as human resource executive.
        the answer format should always be 
        {
          "job_brief": "Explaining about the job and role in around 50 words",
          "roles_and_responsibilities": {
              "1":" Responsibility of ....",
              "2":" Line 2 ...",
              "3":"Line 3 .... ",
              "4":"Line 4 .... "
          },
          "internal_interface": "...",
          "external_interface": "...",
          "functional_competency": {
            "Competancy Title":"Proficeincy level which is a Positive integer (from 1 to 4 ) required for the role",
            "Competancy Title":"Proficeincy level which is a Positive integer (from 1 to 4 ) required for the role",
            "Competancy Title":"Proficeincy level which is a Positive integer (from 1 to 4 ) required for the role",
          },
          "behavioural_competency": {
            "Competancy Title":"Proficeincy level which is a Positive integer (from 1 to 4 ) required for the role",
            "Competancy Title":"Proficeincy level which is a Positive integer (from 1 to 4 ) required for the role",
            "Competancy Title":"Proficeincy level which is a Positive integer (from 1 to 4 ) required for the role",
          },
        }
        `;


        // Append your data to the FormData object
        formData.append('user_input', user_input);
        setGeneratingAIResponse(true)
    
        // Make the POST request using axios
        instance.post('promptbot/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure you set the content type to multipart/form-data
            },
        })
        .then(response => {
          // Assuming the response directly contains the job_brief and roles_and_responsibilities
          const { job_brief, roles_and_responsibilities, internal_interface, external_interface, functional_competency, behavioural_competency} = response.data;
      
          // Convert roles_and_responsibilities object into a string with each role on a new line
          const rolesAndResponsibilitiesStr = Object.values(roles_and_responsibilities).join('\n');
      
          // handleChange('job_brief', job_brief);
          // handleChange('roles_and_responsibilities', rolesAndResponsibilitiesStr);
      
          // setUpdatedRequirement(prev => ({
          //     ...prev,
          //     job_brief: job_brief, 
          //     roles_and_responsibilities: rolesAndResponsibilitiesStr,
          //     internal_interface: internal_interface,
          //     external_interface: external_interface,
          //     functional_competency: functional_competency,
          //     behavioural_competency: behavioural_competency,
          // }));
          setGeneratingAIResponse(false)

          setUpdatedRequirement(prev => {
            // Default to using existing values from prev (the current state of updatedRequirement)
            const newValues = {...prev};
          
            newValues.job_brief = requirement.job_brief === "" || requirement.job_brief === null || requirement.job_brief === undefined ? job_brief : requirement.job_brief;
            newValues.roles_and_responsibilities = requirement.roles_and_responsibilities === "" || requirement.roles_and_responsibilities === null || requirement.roles_and_responsibilities === undefined ? rolesAndResponsibilitiesStr : requirement.roles_and_responsibilities;
            newValues.internal_interface = requirement.internal_interface === "" || requirement.internal_interface === null || requirement.internal_interface === undefined ? internal_interface : requirement.internal_interface;
            newValues.external_interface = requirement.external_interface === "" || requirement.external_interface === null || requirement.external_interface === undefined ? external_interface : requirement.external_interface;
            // newValues.functional_competency = requirement.functional_competency === "" || requirement.functional_competency === null || requirement.functional_competency === undefined ? functional_competency : requirement.functional_competency;
            // newValues.behavioural_competency = requirement.behavioural_competency === "" || requirement.behavioural_competency === null || requirement.behavioural_competency === undefined ? behavioural_competency : requirement.behavioural_competency;
          
            return newValues;
          });

      })
        .catch(error => {
            // Handle any errors here
            setGeneratingAIResponse(false)
            console.error('Error posting data:', error);
        });

    };

    const handleDeleteCompetency = (competencyType, key) => {
      setUpdatedRequirement(prev => {
        const { [key]: _, ...remaining } = prev[competencyType];
        return {
          ...prev,
          [competencyType]: remaining,
        };
      });
    };
    const handleAddOrUpdateCompetency = (competencyType, title, level) => {
      setUpdatedRequirement((prev) => {
        const competencies = { ...prev[competencyType] };
        competencies[title] = level; // Add or update competency level
        return { ...prev, [competencyType]: competencies };
      });
    };
    const handleChangeCompetencyTitle = (competencyType, oldTitle, newTitle) => {
      setUpdatedRequirement((prev) => {
        const competencies = { ...prev[competencyType] };
        // Ensure the new title doesn't overwrite an existing competency
        if (competencies[newTitle] === undefined || oldTitle === newTitle) {
          const level = competencies[oldTitle];
          delete competencies[oldTitle]; // Remove old title
          competencies[newTitle] = level; // Set level to new title
        }
        return { ...prev, [competencyType]: competencies };
      });
    };

    const renderCompetencyInputs = (competencyType) => {
      const competencies = updatedRequirement[competencyType] || {};
    
      return (
        <Container>
          {Object.keys(competencies).map((title, index) => (
            <Grid container p={2} spacing={2} key={index}>
              <Grid item xs={6}>
                <TextField
                  label="Competency Title"
                  fullWidth
                  defaultValue={title}
                  disabled={!isCompEditable[competencyType]} 
                  onBlur={(e) => handleChangeCompetencyTitle(competencyType, title, e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <RadioGroup
                  row
                  value={String(competencies[title])}
                  onChange={(e) => handleAddOrUpdateCompetency(competencyType, title, parseInt(e.target.value, 10))}
                >
                  {[1, 2, 3, 4].map((level) => (
                    <FormControlLabel
                      key={level}
                      value={String(level)}
                      control={<Radio />}
                      label={String(level)}
                      disabled={!isCompEditable[competencyType]} 
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleDeleteCompetency(competencyType, title)} disabled={!isCompEditable[competencyType]}> 
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          {isCompEditable[competencyType] && (
            <Box mx="auto">
              <Button fullWidth textAlign="center" startIcon={<AddIcon />} onClick={() => handleAddOrUpdateCompetency(competencyType, ` `)}>
                Add Competency
              </Button>
            </Box> 
          )}
        </Container>
      );
    };

    const toggleKraEdit = (kraTitle) => {
      setKraIsEditable(prev => ({ ...prev, [kraTitle]: !prev[kraTitle] }));
    };

    const handleAddOrUpdateKRA = (kraTitle, kraWeight, kpiTitle = null, kpiValue = null) => {
      setKraJson(prev => {
        const updatedKRA = { ...prev[kraTitle] || { kra_wt: kraWeight, kpis: {} }, kpis: { ...prev[kraTitle]?.kpis } };
        if (kpiTitle && kpiValue !== null) {
          updatedKRA.kpis[kpiTitle] = kpiValue;
        } else {
          updatedKRA.kra_wt = kraWeight; // Update KRA weight if KPI is not being updated
        }
        return { ...prev, [kraTitle]: updatedKRA };
      });
    };
    
    const handleDeleteKRA = (kraTitle) => {
      setKraJson(prev => {
        const updatedJson = { ...prev };
        delete updatedJson[kraTitle];
        return updatedJson;
      });
    };
    
    const handleDeleteKPI = (kraTitle, kpiTitle) => {
      setKraJson(prev => {
        const updatedKRA = { ...prev[kraTitle], kpis: { ...prev[kraTitle].kpis } };
        delete updatedKRA.kpis[kpiTitle];
        return { ...prev, [kraTitle]: updatedKRA };
      });
    };

    const [tempKraTitles, setTempKraTitles] = useState({});
    const [tempKpiTitles, setTempKpiTitles] = useState({});
  
    useEffect(() => {
      setTempKraTitles(
        Object.keys(kraJson).reduce((acc, curr) => {
          acc[curr] = curr; // Initialize temporary titles with current titles
          return acc;
        }, {})
      );
  
      setTempKpiTitles(
        Object.keys(kraJson).reduce((acc, kraTitle) => {
          acc[kraTitle] = Object.keys(kraJson[kraTitle].kpis).reduce((kpiAcc, kpiKey) => {
            kpiAcc[kpiKey] = kpiKey; // Initialize temporary KPI titles
            return kpiAcc;
          }, {});
          return acc;
        }, {})
      );
    }, [kraJson]);
  
    const handleKraTitleChange = (originalTitle, newTitle) => {
      if (newTitle !== originalTitle) {
        const updatedKra = { ...kraJson[originalTitle], kpis: { ...kraJson[originalTitle].kpis } };
        const newKraJson = { ...kraJson, [newTitle]: updatedKra };
        delete newKraJson[originalTitle];
        setKraJson(newKraJson);
  
        // Update editable state for the new title
        const newEditState = { ...kraIsEditable, [newTitle]: kraIsEditable[originalTitle] };
        delete newEditState[originalTitle];
        setKraIsEditable(newEditState);
      }
    };
  
    const handleKpiTitleChange = (kraTitle, originalTitle, newTitle) => {
      if (newTitle !== originalTitle) {
        const updatedKpis = { ...kraJson[kraTitle].kpis, [newTitle]: kraJson[kraTitle].kpis[originalTitle] };
        delete updatedKpis[originalTitle];
        setKraJson(prev => ({
          ...prev,
          [kraTitle]: {
            ...prev[kraTitle],
            kpis: updatedKpis
          }
        }));
      }
    };

    const renderKRAs = () => {
      return (
        <Grid container spacing={2}>
           <Box mx="auto">
                <Typography textAlign="center" px={2} variant="h6" mt={3}>
                    KRAs and KPIs
                </Typography>
            </Box>
          {Object.entries(kraJson).map(([kraTitle, { kra_wt, kpis }]) => (
            <Grid item xs={12} key={kraTitle}>
              {kraIsEditable[kraTitle] ? (
                <TextField
                  label="KRA Title"
                  value={tempKraTitles[kraTitle] || ''}
                  onChange={(e) => setTempKraTitles({ ...tempKraTitles, [kraTitle]: e.target.value })}
                  onBlur={() => handleKraTitleChange(kraTitle, tempKraTitles[kraTitle])}
                  margin="normal"
                  fullWidth
                />
              ) : (
                <Typography variant="h6">{kraTitle}</Typography>
              )}
              <TextField
                label="KRA Weight"
                type="number"
                value={kra_wt}
                onChange={(e) => handleAddOrUpdateKRA(kraTitle, e.target.value)}
                disabled={!kraIsEditable[kraTitle]}
                margin="normal"
                fullWidth
              />
              <Grid container >
                {Object.entries(kpis).map(([kpiTitle, kpiValue]) => (
                  <Card  sx={{ width: "100%", m:2, p:2, borderRadius:"1rem"}} elevation={4} container key={kpiTitle}>
                      <Grid item xs={12}>
                        {kraIsEditable[kraTitle] ? (
                          <TextField
                            sx={{ my:2 }}
                            label="KPI Title"
                            value={tempKpiTitles[kraTitle][kpiTitle] || ''}
                            onChange={(e) => {
                              const newKpiTitles = { ...tempKpiTitles };
                              newKpiTitles[kraTitle][kpiTitle] = e.target.value;
                              setTempKpiTitles(newKpiTitles);
                            }}
                            onBlur={() => handleKpiTitleChange(kraTitle, kpiTitle, tempKpiTitles[kraTitle][kpiTitle])}
                            fullWidth
                          />
                        ) : (
                          <Typography mb={2}>{kpiTitle}</Typography>
                        )}
                        
                      </Grid>
                      <Grid display="flex" item m={1} xs={12}>
                        <TextField
                          label="KPI Value"
                          type="number"
                          value={kpiValue}
                          onChange={(e) => handleAddOrUpdateKRA(kraTitle, kra_wt, kpiTitle, e.target.value)}
                          disabled={!kraIsEditable[kraTitle]}
                          fullWidth
                        />
                        <IconButton onClick={() => handleDeleteKPI(kraTitle, kpiTitle)} disabled={!kraIsEditable[kraTitle]}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                  </Card >
                ))}
                {kraIsEditable[kraTitle] && (
                  <Grid item xs={12}>
                    <Button startIcon={<AddIcon />} onClick={() => handleAddOrUpdateKRA(kraTitle, kra_wt, `New KPI ${Object.keys(kpis).length + 1}`, 0)}>
                      Add KPI
                    </Button>
                    {kraIsEditable[kraTitle] && (
                      <Button startIcon={<DeleteIcon />} onClick={() => handleDeleteKRA(kraTitle)} sx={{ ml: 2 }}>
                        Delete KRA
                      </Button>
                    )}
                  </Grid>
                )}
              </Grid>
              <Button onClick={() => toggleKraEdit(kraTitle)} sx={{ mt: 2 }}>
                {kraIsEditable[kraTitle] ? 'Finish Editing' : 'Edit KRA'}
              </Button>
             
            </Grid>
          ))}
         
                
        </Grid>
      );
    };
    

  
   
    return (
      <React.Fragment>
        <NavBar />
        <Container maxWidth="md" sx={{ marginTop: "100px"}}>
      
        {
          requirement && (

            <Grid container p={2} maxWidth="md" spacing={2}>



            <Grid item xs={12}>
            <TextField
              label="Profile Title"
              fullWidth
              value={requirement.profile_title}
              onChange={e => handleChange('profile_title', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Designation"
              fullWidth
              value={requirement.designation}
              onChange={e => handleChange('designation', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Requirement Raised On"
              fullWidth
              value={(requirement.requirement_raised_on)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Requirement Status"
              fullWidth
              value={requirement.requirement_status}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Requirement ID"
              fullWidth
              value={requirement.requirement_id}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Number of Positions"
              fullWidth
              defaultValue={1}
              value={requirement.number_of_positions}
              onChange={e => handleChange('number_of_positions', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Job Location"
              fullWidth
              value={requirement.job_location}
              onChange={e => handleChange('job_location', e.target.value)}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            sx={{ width: "100%"}}
            label="Start Date (MM-DD-YYYY)"
            value={requirement.start_date}
            onChange={(newValue) => {
              const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
              // Update your component's state with formatted date string
              setRequirement((prev) => ({
                ...prev,
                start_date: newValue, // Assuming you want to keep this format in your local state
              }));
              // Pass the YYYY-MM-DD formatted date to your handleChange function
              handleChange('start_date', formattedDate);
            }}
            renderInput={(params) => <TextField {...params}  />}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            sx={{ width: "100%"}}
            label="Close Date (MM-DD-YYYY)"
            value={requirement.close_date}
            onChange={(newValue) => {
              const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
              setRequirement((prev) => ({
                ...prev,
                close_date: newValue,
              }));
              handleChange('close_date', formattedDate);
            }}
            renderInput={(params) => <TextField {...params}  />}
          />
        </Grid>
        </LocalizationProvider>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="level-of-position-label">Level of Position</InputLabel>
              <Select
            labelId="level-of-position-label"
            id="level-of-position-select"
            value={requirement.level_of_position}
            label="Level of Position"
            onChange={(event) => {
              const newValue = event.target.value;
              setRequirement((prevRequirement) => ({
                ...prevRequirement,
                level_of_position: newValue,
              }));
              handleChange('level_of_position', newValue); // Call handleChange with the new value
            }}
          >
                <MenuItem value="Executive or senior management">Executive or senior management</MenuItem>
                <MenuItem value="Middle management">Middle management</MenuItem>
                <MenuItem value="First-level management">First-level management</MenuItem>
                <MenuItem value="Intermediate or experienced (senior staff)">Intermediate or experienced (senior staff)</MenuItem>
                <MenuItem value="Entry-level">Entry-level</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="level-of-position-label">Role Type</InputLabel>
              <Select
            labelId="role-label"
            id="role-select"
            value={requirement.role_type}
            label="Role Type"
            onChange={(event) => {
              const newValue = event.target.value;
              setRequirement((prevRequirement) => ({
                ...prevRequirement,
                role_type: newValue,
              }));
              handleChange('role_type', newValue); // Call handleChange with the new value
            }}
          >
                
                <MenuItem value="Full-time">Full-time</MenuItem>
                <MenuItem value="Part-time">Part-time</MenuItem>
                <MenuItem value="Casual">Casual</MenuItem>
                <MenuItem value="Contract">Contract</MenuItem>
                <MenuItem value="Apprenticeship">Apprenticeship</MenuItem>
                <MenuItem value="Traineeship">Traineeship</MenuItem>
                <MenuItem value="Employment on commission">Employment on commission</MenuItem>
                <MenuItem value="Probation">Probation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Reporting To"
              fullWidth
              value={requirement.reporting_to}
              onChange={e => handleChange('reporting_to', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Reportees"
              fullWidth
              value={requirement.reportees}
              onChange={e => handleChange('reportees', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Minimum Experience (in years) "
              fullWidth
              type='number'
              value={requirement.total_experience}
              onChange={e => handleChange('total_experience', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            
          </Grid>
          <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="type-dropdown-label">Education Type</InputLabel>
                <Select
                  fullWidth
                  labelId="type-dropdown-label"
                  id="type-dropdown"
                  value={educationType}
                  name="type_dropdown"
                  label="Education Type"
                  onChange={(e) => {
                    setEducationType(e.target.value);
                    handleChange('education_type', e.target.value) // Call handleChange with the new value
                  }}
                >
                    <MenuItem value="School">School</MenuItem>
                    <MenuItem value="Diploma">Diploma</MenuItem>
                    <MenuItem value="Graduation">Graduation</MenuItem>
                    <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
                    <MenuItem value="Doctorate">Doctorate (PhD)</MenuItem>
                    <MenuItem value="Professional Certification">Professional Certification</MenuItem>
                    <MenuItem value="Voluntary Certification">Voluntary Certification</MenuItem>
          
                </Select>
              </FormControl>
          </Grid>
          {/* Degree */}
          {educationType !== 'Professional Certification' && educationType !== 'Voluntary Certification' && (
              <Grid item xs={12} md={6} container fullWidth>
                  <FormControl fullWidth>
                    <InputLabel id="degree-dropdown-label">Degree</InputLabel>
                    <Select
                      fullWidth
                      labelId="degree-dropdown-label"
                      id="degree-dropdown"
                      value={degree}
                      name="degree_dropdown"
                      label="Degree"
                      onChange={(e) => {
                        setDegree(e.target.value);
                        handleChange('degree', e.target.value) // Call handleChange with the new value
                      }}
                      disabled={!educationType}
                    >
                      {degreeOptions[educationType] && degreeOptions[educationType].map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Specialization"
              fullWidth
              value={requirement.specialization}
              onChange={e => handleChange('specialization', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Other Skills"
              fullWidth
              value={requirement.other_skills}
              onChange={e => handleChange('other_skills', e.target.value)}
            />
          </Grid>

          <Grid container mt={1} spacing={2} p={0} m={0} xs={12}>
              <Grid item xs={6}>
                <Typography textAlign="center" mx="auto" mt={2}>
                Offered CTC Range (in lakhs per annum) 
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Minimum "
                  fullWidth
                  type="number"
                  value={requirement.offered_ctc_range_min}
                  onChange={e => handleChange('offered_ctc_range_min', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Maximum "
                  fullWidth
                  type="number"
                  value={requirement.offered_ctc_range_max}
                  onChange={e => handleChange('offered_ctc_range_max', e.target.value)}
                />
              </Grid>
          </Grid>

          <Box fullWidth px={10} mt={4}>
                <Typography variant='body2' fontStyle="italic" color="#bdbdb9">
                  Click on the Generate with AI button to generate Job Breif, Roles and responsibilities, Internal and External interfaces for {requirement.profile_title}, {requirement.designation} position.
                </Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Divider style={{ width: '100%' }}>
              <Box textAlign="center" my={2}> 
              { generatingAIResponse ? (
                <CircularProgress />
              ) :
              (
                <Button startIcon={<AutoAwesomeIcon />} onClick={handleGenAIJD}>
                  Generate Using AI
                </Button>
                  )
              }

              </Box>
            </Divider>
          </Box>


          <Grid container m={0} p={0} mb={1} spacing={2}>
              {Object.keys(isEditable).map((field) => (
                <Grid item xs={12} key={field} style={{ display: 'flex', alignItems: 'center' }}>
            

                          <TextField
                            label={field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            fullWidth
                            multiline={["job_brief", "roles_and_responsibilities"].includes(field)}
                            rows={field === "job_brief" ? 5 : field === "roles_and_responsibilities" ? 7 : 1}
                            value={isEditable[field] ? updatedRequirement[field] || '' : requirement[field] || ""}
                            onChange={(e) => handleChange(field, e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            disabled={!isEditable[field]}
                          />
                          <Button onClick={() => toggleEdit(field)} style={{ marginLeft: '10px' }}>Edit</Button>
                  
                </Grid>
              ))}
          </Grid>

          <Grid container m={0} p={0} spacing={2}>
            {/* Functional Competency Section */}
            <Grid item xs={12}>
              {renderCompetencyInputs('functional_competency')}
              <Box display="flex" justifyContent="center" mt={2}> {/* Add marginTop if needed */}
                <Button
                  onClick={() => toggleCompEdit('functional_competency')}
                  variant="outlined"
                  color="primary"
                  startIcon={isCompEditable['functional_competency'] ? <CheckCircleIcon /> : <EditIcon />}
                >
                  {isCompEditable['functional_competency'] ? 'Finish Editing' : 'Edit Functional Competency'}
                </Button>
              </Box>
            </Grid>

            {/* Behavioural Competency Section */}
            <Grid item xs={12}>
              {renderCompetencyInputs('behavioural_competency')}
              <Box display="flex" justifyContent="center" mt={2}> {/* Add marginTop if needed */}
                <Button
                  onClick={() => toggleCompEdit('behavioural_competency')}
                  variant="outlined"
                  color="primary"
                  startIcon={isCompEditable['behavioural_competency'] ? <CheckCircleIcon /> : <EditIcon />}
                >
                  {isCompEditable['behavioural_competency'] ? 'Finish Editing' : 'Edit Behavioural Competency'}
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* <Grid item xs={8}>
            <TextField
              label="KRA"
              fullWidth
              value={requirement.KRA}
              onChange={e => handleChange('KRA', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="KRA Weightage"
              fullWidth
              value={requirement.KRA_weightage}
              onChange={e => handleChange('KRA_weightage', e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              label="KPI"
              fullWidth
              value={requirement.KPI}
              onChange={e => handleChange('KPI', e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="KPI Weightage"
              fullWidth
              value={requirement.KPI_weightage}
              onChange={e => handleChange('KPI_weightage', e.target.value)}
            />
          </Grid> */}

          <Grid container mt={2} xs={12}>
          {renderKRAs()}

            <Grid item mx="auto" textAlign="center">
            <Button fullWidth startIcon={<AddIcon />} onClick={() => handleAddOrUpdateKRA(`New KRA ${Object.keys(kraJson).length + 1}`, 0)}>
              Add KRA
            </Button>
            </Grid>
            <Grid item mx="auto" textAlign="center">
            <Button fullWidth variant='outlined' onClick={handleUpdate} sx={{ ml: 2 }}>
              Update KRA
            </Button>
            </Grid>
          </Grid>
    
        
          <Grid item xs={8} mt={2} mb={6} mx="auto">
            <Button 
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleUpdate}>
            Update Requirement</Button>
          </Grid>
          
          
            </Grid>

          )
        }
      
        </Container>

      </React.Fragment>
    );
};

export default RequirementDetails;
