import {React, useState} from 'react'
import NavBar from '../../../components/NavBar'
import {   Button,
    Container,
    Typography,
    TextField,
    Select,
    MenuItem,
    Grid,
    Box,
    InputLabel,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faLink } from "@fortawesome/free-solid-svg-icons";
import instance from "../../../globals/axios";
import RS_Select from 'react-select';

export default function HmViewApplicant() {
    const [currentPage, setCurrentPage] = useState(1);
    ///////////

    const [requirementId, setRequirementId] = useState(null);
    const [sbuName, setSbuName] = useState(null);
    const [profileTitle, setProfileTitle] = useState(null);
    const [designation, setDesignation] = useState(null);
    const [HrSpoc,setHrSpoc] = useState('')
    const [reqRaisedDate, setReqtRaisedDate] = useState(null);
    const [reqApprovedDate, setReqApprovedDate] = useState(null);
    const [noApplicant, setNoApplicant] = useState("");
    const [jobLoc, setJobLoc] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [closeDate, setCloseDate] = useState(null);
    const [levelPos, setLevelPos] = useState("");
    const [deparment, setDepartment] = useState("");
    const [jobBrief, setJobBrief] = useState("");
    const [roleResponsibilty, setroleResponsibilty] = useState("");
    const [reporting, setReporting] = useState("");
    const [reportees, setReportees] = useState("");
    const [typeRole, setTypeRole] = useState("");
    const [internalInterface, setInternalInterface] = useState("");
    const [externalInterface, setExternalInterface] = useState("");
    

 
    const [peopleList, setPeopleList] = useState([]);
    const [inputMode, setInputMode] = useState('name');
    const handleSaveClick = () => {
      const formData = new FormData();
      formData.append("requirement_id", requirementId);
      formData.append("sbu_name", sbuName);
      formData.append("profile_title", profileTitle);
      formData.append("designation", designation);
      formData.append("hr_spoc", HrSpoc);
      formData.append('requirement_raised', reqRaisedDate.toISOString()); // Adjust date format as needed
      formData.append('requirement_approved', reqApprovedDate.toISOString());
      formData.append("number_of_applicant", noApplicant);
      formData.append("job_loc", jobLoc);
      formData.append('start_date', startDate.toISOString());
      formData.append("level_pos", levelPos);
          // Append other form data as needed

    console.log(formData)
    // Send a POST request using Axios
    instance.post('update-hm-applicant/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        // Handle successful response here
        console.log('Data saved successfully:', response.data);
      })
      .catch((error) => {
        // Handle error here
        console.error('Error saving data:', error);
      });
      
    }




        // Define options for each dropdown
        const requirementIdOptions = [
            { value: 'reqId1', label: 'Requirement ID 1' },
            { value: 'reqId2', label: 'Requirement ID 2' },
            { value: 'reqId3', label: 'Requirement ID 3' },
          ];
        
          const sbuNameOptions = [
            { value: 'sbuName1', label: 'SBU Name 1' },
            { value: 'sbuName2', label: 'SBU Name 2' },
            { value: 'sbuName3', label: 'SBU Name 3' },

          ];
        
          const profileTitleOptions = [
            { value: 'profile1', label: 'Profile Title 1' },
            { value: 'profile2', label: 'Profile Title 2' },
            { value: 'profile3', label: 'Profile Title 3' },

          ];
        
          const designationOptions = [
            { value: 'designation1', label: 'Designation 1' },
            { value: 'designation2', label: 'Designation 2' },
            { value: 'designation3', label: 'Designation 3' },

          ];
  const hrSpocOptions = [
    { value: 'hrSpoc1', label: 'HR SPOC 1' },
    { value: 'hrSpoc2', label: 'HR SPOC 2' },
    { value: 'hrSpoc3', label: 'HR SPOC 3' },

  ];
  const options = [
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Banglore', label: 'Banglore' },

  ];

  // Handle changes for each dropdown
  const handleRequirementIdChange = (selectedOption) => {
    setRequirementId(selectedOption);
  };

  const handleSbuNameChange = (selectedOption) => {
    setSbuName(selectedOption);
  };

  const handleProfileTitleChange = (selectedOption) => {
    setProfileTitle(selectedOption);
  };

  const handleDesignationChange = (selectedOption) => {
    setDesignation(selectedOption);
  };
  const handleHrSpocChange = (selectedOption) => {
    setHrSpoc(selectedOption);
  };
  const handleJobLocChange = (selectedOption) => {
    setJobLoc(selectedOption);
  };
/////////

/////table///
const [applicantData, setApplicantData] = useState([
    {
      name: 'Arun Patel',
      iveriLink: 'Loremipsum.com',
      totalExperience: 11,
      status: 'Status 1',
      subStatus: 'Sub Status 1',
    },
  ]);
  //////



const handleNextClick = () => {
    setCurrentPage(currentPage+1)
  };
  const handleBackClick = () => {
    setCurrentPage(currentPage-1)
  };
  return (
    <>
     <NavBar />
     <Container maxWidth="xs" style={{ marginTop: "80px" }} mb={2}>
     <Grid container alignItems="center" justifyContent="space-between" mb={1.5}>
        <Grid item mb={3}>
          <Typography variant="h6" sx={{ color: "rgb(0, 184, 230)" }}>
          View Applicant
          </Typography>
        </Grid>
        <Grid item>
          {(currentPage === 1) ? (
            <Button
              variant="contained"
              sx={{
                color: "rgb(3, 22, 48)",
                borderRadius: "30px",
                backgroundColor: "rgb(0, 184, 230)",
                padding: "2px 30px",
                fontWeight: "600",
                fontSize: "15px",
              }}
              onClick={handleNextClick}
            >
              NEXT
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                color: "rgb(3, 22, 48)",
                borderRadius: "30px",
                backgroundColor: "rgb(0, 184, 230)",
                padding: "2px 30px",
                fontWeight: "600",
                fontSize: "15px",
              }}
              onClick={handleBackClick}
            >
              Back
            </Button>
          )}
        </Grid>
      </Grid>

      {currentPage === 1 && (
        <Container my={3}>
         <Grid container spacing={2}>
         <Grid item xs={12} style={{ position: 'relative', zIndex: 500 }}>
        <FormControl fullWidth>
          <RS_Select
            labelId="requirement-id-label"
            id="requirement_id"
            value={requirementId}
            name="requirement-id-dropdown"
            onChange={handleRequirementIdChange}
            options={requirementIdOptions}
            isSearchable={true}
            placeholder="Select Requirement ID..."
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ position: 'relative', zIndex: 400 }}>
        <FormControl fullWidth>
          <RS_Select
            labelId="sbu-name-label"
            id="sbu_name"
            value={sbuName}
            name="sbu-name-dropdown"
            onChange={handleSbuNameChange}
            options={sbuNameOptions}
            isSearchable={true}
            placeholder="Select SBU Name..."
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ position: 'relative', zIndex: 300 }}>
        <FormControl fullWidth>
          <RS_Select
            labelId="profile-title-label"
            id="profile_title"
            value={profileTitle}
            name="profile-title-dropdown"
            onChange={handleProfileTitleChange}
            options={profileTitleOptions}
            isSearchable={true}
            placeholder="Select Profile Title..."
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ position: 'relative', zIndex: 200 }}>
        <FormControl fullWidth>
          <RS_Select
            labelId="designation-label"
            id="designation"
            value={designation}
            name="designation_dropdown"
            onChange={handleDesignationChange}
            options={designationOptions}
            isSearchable={true}
            placeholder="Select Designation..."
          />
        </FormControl>
      </Grid>

        <Grid item xs={12} style={{ position: 'relative', zIndex: 100 }}>
          <FormControl fullWidth>
          <RS_Select
            fullWidth
            labelId="Hr-Spoc-label"
            id="hr_spoc"
            value={HrSpoc}
            name="hr-spoc-dropdown"
            onChange={handleHrSpocChange}
            options={hrSpocOptions}
            isSearchable={true} // Enable search functionality
            placeholder="Select HR SPOC..."
          />
        </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Requirement Raised on" value={reqRaisedDate} onChange={(newValue) => setReqtRaisedDate(newValue)} />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Requirement Approved on" value={reqApprovedDate} onChange={(newValue) => setReqApprovedDate(newValue)} />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                <TextField type="number" value={noApplicant} label="Number of Applicants" onChange={(e) => setNoApplicant(e.target.value)} fullWidth></TextField>
            </Grid>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 1000 }}>
            <FormControl fullWidth>
    <RS_Select
      labelId="job-loc-label"
      id="job_loc"
      value={jobLoc}
      name="job-loc-dropdown"
      onChange={(selectedOption) => handleJobLocChange(selectedOption)}
      options={options}
      isSearchable={true}
      placeholder="Select a job location..."
    />
  </FormControl>
    </Grid>
             <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker label="Start Date" value={startDate}  onChange={(newValue) => setStartDate(newValue)} />
              </LocalizationProvider>
            </Grid> 
         </Grid>
         <Grid item xs={12} mt={2}>
            <FormControl fullWidth>
                <InputLabel id="level-pos-label">Level of Position (Optional)</InputLabel>
                <Select fullWidth labelId="level-pos-label" id="level_pos" value={levelPos} onChange={(e) => setLevelPos(e.target.value)}>
                <MenuItem value="junior">Junior</MenuItem>
                <MenuItem value="mid">Mid</MenuItem>
                <MenuItem value="senior">Senior</MenuItem>
                </Select>
            </FormControl>
         </Grid>
        </Container>
      )}
  {currentPage === 2 && (
    <Container my={3}>
        <Grid container spacing={2}>
        <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Summary of all Applicants</TableCell>
                    <TableCell>Iveri ID / Link</TableCell>
                    <TableCell>Total Experience</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Sub Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicantData.map((applicant, index) => (
                    <TableRow key={index}>
                      <TableCell>{applicant.name}</TableCell>
                      <TableCell>{applicant.iveriLink}</TableCell>
                      <TableCell>{applicant.totalExperience}</TableCell>
                      <TableCell>{applicant.status}</TableCell>
                      <TableCell>{applicant.subStatus}</TableCell>
                      <TableCell>
                        <Button variant="outlined" color="primary">
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            style={{
                color: "rgb(3, 22, 48)",
                borderRadius: "30px",
                backgroundColor: "rgb(0, 184, 230)",
                padding: "2px 30px",
                fontWeight: "600",
                fontSize: "15px",
            }}
            onClick={handleSaveClick}
        >
            SAVE
        </Button>
      </Grid>
        </Grid>
    </Container>
  )}

     </Container>
     </>
  )
}

