import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Grid, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ProfileHeader from "../../components/ProfileHeader";
import Sidebar from "../../components/Sidebar";


const defaultProfilePic = 'https://via.placeholder.com/100'; 

const buttons = [
  { label: "Basic Information", path: "/edit-basic-info" },

  { label: "Edit Address Details", path: "/edit-address" },
  { label: "Edit Additional Information", path: "/edit-additional-info" },
  { label: "Edit Language Proficiency", path: "/edit-language-proficiency" },
  { label: "Edit Education Details", path: "/edit-education-details" },
  { label: "Edit Experience Details", path: "/edit-experience-details" },
  { label: "Edit Skills", path: "/edit-skills" },
  { label: "Edit Document Locker", path: "/edit-document-locker" },
];

export default function ProfileEditMenu() {
  return (
   <>
   <Sidebar />

    <Container maxWidth="xs" style={{ marginTop: '30px'}}>
      <Container>
      <ProfileHeader showUploadIcon={true} />
      <Grid container spacing={1} mt={3} direction="column">
        <Typography variant="h6" sx={{ fontWeight: "500" }} pb={1}>Complete Your Profile</Typography>
        {buttons.map((button, index) => (
          <Grid item key={index} mb={1.6}>
            <Button
              fullWidth
              component={Link}
              to={button.path}
              endIcon={<EditIcon sx={{ color: 'black', fontSize: '1.5rem' }} />}
              sx={{
                backgroundColor: 'rgba(214, 214, 214, 0.3)',
                color: 'black',
                padding: "10px",
                textTransform: 'none',
                justifyContent: 'space-between',
                '&:hover': {
                  backgroundColor: 'darkgray',
                },
              }}
            >
              {button.label}
            </Button>
          </Grid>
        ))}
      </Grid>
      </Container>
    </Container>
    </>
  );
}
