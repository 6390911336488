import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useHistory, useLocation, Link } from "react-router-dom";
import instance from "../../globals/axios";
import CustomButton from "../../components/Button/CustomButtom";
import axios from "../../globals/axios"

const VerifyMobile = () => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [otpSent, setOtpSent] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const creds = location.state?.creds;

  // Check for missing credentials
  useEffect(() => {
    if (!creds) {
      alert("Missing credentials. Redirecting to register page.");
      history.push("/sign-up");
    }
  }, [creds, history]);

  const phoneNumber = creds?.phone_number.startsWith("91")
    ? creds.phone_number.slice(2)
    : creds.phone_number;

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  useEffect(() => {
    const sendOtp = async () => {
      if (otpSent) return;

      try {
        await axios.get(`/otp/otpmoduleintegrations/send_otp/?phone_number=${phoneNumber}`);
        alert("OTP sent successfully!");
        setOtpSent(true);
        setTimer(30);
      } catch (err) {
        console.log("Failed to send OTP", err);
        alert("Failed to send OTP, please try again.");
      }
    };

    sendOtp();

    // Timer logic
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [otpSent, phoneNumber]);

  const verifyOtp = async () => {
    try {
      const res = await axios.get(
        `/otp/otpmoduleintegrations/verify_otp/?data=${phoneNumber}&otp=${otp}`
      );
      if (res.status === 200) {
        alert("OTP verified successfully! Completing registration...");
        registerUser();
      } else {
        alert("OTP verification failed.");
      }
    } catch (err) {
      console.log("Failed to verify OTP", err);
      alert("Invalid OTP, please try again.");
    }
  };

  const registerUser = async () => {
    try {
      const res = await axios.post(`user/register/`, creds);
      if (res.status === 201) {
        alert("Registration successful!");
        history.push("/login");
      } else {
        alert("Registration failed.");
      }
    } catch (err) {
      console.log("Failed to register user", err);
      alert("Failed to register, please try again.");
    }
  };

  const handleResendOtp = () => {
    setOtpSent(false);
  };

  return (
    <Box sx={{ mt: 8, mx: "auto", maxWidth: 400, textAlign: "center", margin: "20px" }}>
      <Typography variant="h5" fontWeight="bold">
        Verify Phone No.
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        6-digit code sent to your number.
      </Typography>

      <Box sx={{ height: "65vh" }}>
        <Box sx={{ my: 3 }}>
          <MuiOtpInput length={6} value={otp} onChange={handleOtpChange} />
        </Box>

        <Typography variant="body2">
          Didn’t receive the code?{" "}
          <Button onClick={handleResendOtp} disabled={timer > 0} sx={{ fontWeight: "bold" }}>
            Send Again
          </Button>
          {timer > 0 && <span>{`00:${timer < 10 ? `0${timer}` : timer}`}</span>}
        </Typography>
      </Box>

      <CustomButton onClick={verifyOtp}>Verify</CustomButton>

      <Typography variant="body2" sx={{ mt: 2 }}>
        Wrong Number? <Link to="/sign-up">Change Mobile Number</Link>
      </Typography>
    </Box>
  );
};

export default VerifyMobile;
