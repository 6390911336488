import React, { useState } from "react";
import { Button, Typography, Box, TextField, Container, Grid} from "@mui/material";
import { useDropzone } from 'react-dropzone';

import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import instance from "../../../globals/axios";


const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

const imageStyles = {
  maxWidth: '100%',
  maxHeight: '200px',
};


export default function BankingDetaiils() {
  const [branchName, setBranchName] = useState("");
  const [ifscCode, setIsfcCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [cancelledCheque, setCancelledCheque] = useState(null);

  const userRole = localStorage.getItem("role")

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCancelledCheque(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('account_holder_name', branchName);
    formData.append('ifsc_code', ifscCode);
    formData.append('account_number', accountNumber);
    formData.append('cancelled_cheque', cancelledCheque);
    
    const apiUrl = userRole === 'Employer' ? 'agency/employer-bank-details/' : 'agency/agency-bank-details/';

    instance.post(apiUrl, formData)
      .then((response) => {
        console.log('Response:', response.data);
        alert("Data upload succesful!")
      })
      .catch((error) => {
        alert('Banking details already exist!', error);
      });

  };



  return (
    <>
      <NavBar />
      <Container maxWidth= "md" style={{ marginTop: '110px' }}>

        <Grid mt={6} >
          <Typography variant="h5" textAlign="center" style={{ marginBottom: "20px", marginTop: "4rem"}}>
            Banking Details
          </Typography>
        </Grid>
        

        <Box display="flex" justifyContent="space-between" alignItems="center" margin="25px 0">
          <Grid container spacing={2}>
          <Grid item xs={3} my="auto" mx="auto">
          <Typography style={{ color: "rgb(0, 184, 230)", fontWeight: "600" }}>Branch Name:</Typography>
          </Grid>  
          <Grid item xs={6}>
          <TextField
            type="text"
            variant="outlined"
            required
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
            fullWidth
          />
          </Grid> 
        </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" margin="25px 0">
        <Grid container spacing={2}>
          <Grid item xs={3} my="auto" mx="auto"> 
          <Typography style={{ color: "rgb(0, 184, 230)", fontWeight: "600" }}>IFSC / Branch Code:</Typography>
          </Grid> 
          <Grid item xs={6}> 
          <TextField
            type="text"
            variant="outlined"
            required
            value={ifscCode}
            onChange={(e) => setIsfcCode(e.target.value)}
            fullWidth
          />
          </Grid> 
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" margin="25px 0" >
        <Grid container spacing={2}>
          <Grid item xs={3} my="auto" mx="auto">
          <Typography style={{ color: "rgb(0, 184, 230)", fontWeight: "600" }}>Account Number:</Typography>
          </Grid> 
          <Grid item xs={6}>
          <TextField
            type="text"
            variant="outlined"
            required
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            fullWidth
          />
          </Grid> 
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" margin="15px 0" >
          <Grid container spacing={2}>
            <Grid item xs={3} my="auto" mx="auto">
              <Typography style={{ color: "rgb(0, 184, 230)", fontWeight: "600" }}>Cancelled Cheque:</Typography>
            </Grid> 
            <Grid item xs={6}>
            <Box>
        <div {...getRootProps()} style={dropzoneStyles}>
          <input {...getInputProps()} />
          <Typography variant="body1" align="center">
            Drag & drop a cancelled cheque image here, or click to select one
          </Typography>
        </div>
        {cancelledCheque && (
          <Box mt={2}>
            <Typography variant="body1">Uploaded File:</Typography>
            <Typography>{cancelledCheque.name}</Typography>
            <img
              src={URL.createObjectURL(cancelledCheque)}
              alt={cancelledCheque.name}
              style={imageStyles}
            />
          </Box>
        )}
      </Box>
            
            </Grid> 
          </Grid>
        </Box> 

        <Grid container fullWidth>
          <Grid item xs={5} my={4} mx="auto">
                  <Button
                      variant="contained"
                      fullWidth
                      style={{
                          marginBottom: 4,
                          color: "rgb(3, 22, 48)",
                          borderRadius: "30px",
                          backgroundColor: "rgb(0, 184, 230)",
                          padding: "4px 30px",
                          fontWeight: "600",
                          fontSize: "15px",
                      }}
                      onClick={handleFormSubmit}
                  >
                      SAVE
                  </Button>
          </Grid>
        </Grid>


      
        </Container>
    </>
  );
}
