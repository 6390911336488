import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const data = [
  {
    personName: "Sudhir Patel",
    contactNumber: "7894561231",
    email: "sudhir@sample.co",
    relationWithCandidate: "Educational Connect",
    verificationDocuments: "upload",
  },
];

const LabelContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: "rgb(0, 184, 230)",
  fontWeight: "600",
  marginRight: theme.spacing(1),
}));

const LabelField = ({ label, value }) => {
  const isSocialMediaLink = label === "Link of the Course";
  const isDocumentUpload = label === "Document uploaded";

  const handleLinkClick = () => {
    if (isSocialMediaLink) {
      window.open(value, "_blank");
    }
  };

  const handleDocumentClick = () => {
    if (isDocumentUpload) {
      window.open(value, "_blank", "fullscreen=yes");
    }
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <LabelContainer item>
      <FontAwesomeIcon icon={faCircleCheck} style={{color: "rgb(0, 184, 230)",}} />
        <LabelText>{label + ":"}</LabelText>
      </LabelContainer>
      {isSocialMediaLink ? (
        <Grid item>
          <a href={value} target="_blank" rel="noopener noreferrer">
            <Typography style={{ cursor: "pointer" }} onClick={handleLinkClick}>
              {value}
            </Typography>
          </a>
        </Grid>
      ) : isDocumentUpload ? (
        <Grid item>
          <Typography style={{ cursor: "pointer" }} onClick={handleDocumentClick}>
            {value}
          </Typography>
        </Grid>
      ) : (
        <Grid item>
          <Typography>{value}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const labelValuePairs = [
  { label: "Degree", value: "M.B.A." },
  { label: "Specialization", value: "Marketing" },
  { label: "Passing Year", value: "2018" },
  { label: "College", value: "L.J. Institute of Management and Studies" },
  { label: "Board", value: "G.T.U." },
  { label: "Results", value: "9.1 out of 10 (CPI)" },
  { label: "Link of the Course", value: "Not Applicable" },
  { label: "Internship Company", value: "EStore Factory" },
  { label: "Internship Duration", value: "2 Months" },
  { label: "Document uploaded", value: "Degree_certi.pdf" },
];

export default function EducationalDetails() {
  const classes = useStyles();
  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Grid container spacing={2}>
          {labelValuePairs.map(({ label, value }) => (
            <Grid item xs={12} key={label}>
              <LabelField label={label} value={value} />
            </Grid>
          ))}
        </Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="My Table">
            <TableHead>
              <TableRow>
                <TableCell>Person Name</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Relation with Candidate</TableCell>
                <TableCell>Verification Documents</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.personName}>
                  <TableCell component="th" scope="row">
                    {row.personName}
                  </TableCell>
                  <TableCell>{row.contactNumber}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.relationWithCandidate}</TableCell>
                  <TableCell>{row.verificationDocuments}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
    </>
  );
}
