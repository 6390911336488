import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Chip, CircularProgress, Container, Button, Box, TextField, ButtonGroup } from '@mui/material';
import {makeStyles} from '@mui/styles'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmailIcon from '@mui/icons-material/Email';

import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const RequirementCard = () => {
    const classes = useStyles();
    const [requirement, setRequirement] = useState(null);
    const [loading, setLoading] = useState(false);
    const company_id = localStorage.getItem('company_id');
    const [reqId, setReqId] = useState("");
    const [applicants, setApplicants] = useState([]);
    const [jobOpenings, setJobOpenings] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await 
          instance.get(`company/get-job-openings`)
          setJobOpenings(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleCardClick = (requirementId) => {
      console.log('Clicked Requirement ID:', requirementId);
      setReqId(requirementId)
    };
    
    useEffect(() => {
      setLoading(true)
      instance.get(`company/${company_id}/get-applicants/?requirement_id=${reqId}`)
      .then(response => {
        setApplicants(response.data);
        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching requirement data:', error);
        setLoading(false)
      });

    }, [reqId]);


  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return new Date(dateTimeString).toLocaleString(undefined, options);
  }

  if (loading) {
    return <CircularProgress />;
  }


  return (
    <Container>
    <NavBar/>

    <Container sx={{ marginTop: "90px", p:2}}>

    <Grid container >
    <Grid item spacing={2} xs={4}>
      {jobOpenings.map((job) => (
        <Grid item key={job.id} fullWidth sx={{ width: "100%"}}>
          <Card sx={{ my:2}} className={classes.card} onClick={() => handleCardClick(job.requirement_id)}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6">{job.profile_title}</Typography>
              <Typography variant="body1" color="textSecondary">{job.designation}</Typography>
              <Typography variant="body2" color="textSecondary">{formatDateTime(job.requirement_raised_on)}</Typography>
              {/* <Typography variant="body2" color="textSecondary">{job.requirement_status}</Typography> */}
              <Typography variant="body2" color="textSecondary">{job.requirement_id}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

      <Grid item xs={8} spacing={2} p={4}>
        {applicants.map(applicant => (
          <Grid fullWidth sx={{ my:2 }} item key={applicant.id} xs={12} >
            <Card  sx={{ backgroundImage: 'linear-gradient(125deg, #FFF 70%, #e1f1fd 90%)', cursor: 'pointer', boxShadow: 5 }}>
              <CardContent>
                <Typography variant="h6" component="div">{applicant.profile_title}</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" color="text.secondary">
                      <AccountCircleIcon /> {applicant.applicant_first_name} {applicant.applicant_last_name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <AccessTimeIcon /> Applied On: {formatDateTime(applicant.requirement_raised_on)}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      <EmailIcon /> Email: {applicant.applicant_email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" color="text.secondary">
                      Status: {applicant.summary_of_assigment}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Applicant ID: {applicant.requirement_applicant_id}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Iveri ID: {applicant.applicant_iveri_id}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Designation: {applicant.designation}
                    </Typography>
                    {/* Add more fields as needed */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>

    </Container>
    </Container>
  );
};

export default RequirementCard;
