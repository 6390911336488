import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const KRATable = ({ requirement }) => {
  if (!requirement || !requirement.KRA || Object.keys(requirement.KRA).length === 0) {
    return (
      <Box fullWidth>
        <Typography textAlign="center" color="#bdbdbd" variant="body1">No KRAs available.</Typography>
      </Box>
    );
  }

  const rows = [];
  Object.entries(requirement.KRA).forEach(([kraTitle, { kra_wt, kpis }], kraIndex) => {
    const kpiEntries = Object.entries(kpis);
    const firstKpiTitle = kpiEntries.length > 0 ? kpiEntries[0][0] : '';
    const firstKpiValue = kpiEntries.length > 0 ? kpiEntries[0][1] : '';
    rows.push({
      kraNumber: kraIndex + 1,
      kraTitle,
      kraWeight: kra_wt,
      kpiNumber: 1,
      kpiTitle: firstKpiTitle,
      kpiValue: firstKpiValue,
      rowspan: kpiEntries.length > 0 ? kpiEntries.length : 1,
    });

    if (kpiEntries.length > 1) {
      kpiEntries.slice(1).forEach(([kpiTitle, kpiValue], kpiIndex) => {
        rows.push({
          kpiNumber: kpiIndex + 2,
          kpiTitle,
          kpiValue,
        });
      });
    }
  });

  return (
    <TableContainer sx={{ mt:3}} component={Paper}>
      <Table aria-label="KRA KPI table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ maxWidth: "20px", textAlign: "center"}}>KRA Number</TableCell>
            <TableCell>KRA Title</TableCell>
            <TableCell sx={{ maxWidth: "20px", textAlign: "center"}}>KRA Weight</TableCell>
            <TableCell>KPI</TableCell>
            <TableCell sx={{ maxWidth: "30px", textAlign: "center"}}>KPI Weight</TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.kraNumber && (
                <>
                  <TableCell rowSpan={row.rowspan} sx={{ maxWidth: "20px", textAlign: "center"}}>{row.kraNumber}</TableCell>
                  <TableCell rowSpan={row.rowspan}>{row.kraTitle}</TableCell>
                  <TableCell rowSpan={row.rowspan} sx={{ maxWidth: "30px", textAlign: "center"}}>{row.kraWeight}</TableCell>
                </>
              )}
              {/* {row.kpiNumber} */}
              <TableCell> {row.kpiTitle}</TableCell>
              <TableCell sx={{ maxWidth: "30px", textAlign: "center"}}>{row.kpiValue}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KRATable;
