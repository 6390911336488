import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Typography, Button, Autocomplete, TextField } from "@mui/material";
import Sidebar from "../../../components/Sidebar";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import DocumentBox from "../../../components/DocumentBox";
import { engineeringSkills } from "../../../Data/Options";
import { useParams } from "react-router-dom";

export default function Skills() {
  const [data, setData] = useState(null);
  const [skills, setSkills] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      instance
        .get(`/edit-skills/${id}/`)
        .then((response) => {
          setData(response.data);
          if (response.data) {
            const skillValue = response.data.skill || "";
            setInputValue(skillValue);
            setSelectedValue(skillValue);
            setSkills([
              {
                id: response.data.id,
                skill: skillValue,
                file: null,
                fileUrl: response.data.file_uploaded,
                fileName: `${skillValue} Certificate.pdf`
              }
            ]);
          }
        })
        .catch((error) => console.error("Error fetching skill data:", error));
    }
  }, [id]);

  const handleSkillInputChange = (event, newInputValue) => {
    setInputValue(newInputValue || "");
  };

  const handleSkillChange = (event, newValue) => {
    setSelectedValue(newValue);
    if (id) {
      setSkills(prevSkills => 
        prevSkills.map(skill => ({
          ...skill,
          skill: newValue || ""
        }))
      );
    }
  };

  const handleAddSkill = () => {
    const skillToAdd = selectedValue || inputValue;
    if (skillToAdd.trim() !== "") {
      const newSkill = {
        skill: skillToAdd,
        file: uploadedFile,
        fileUrl: uploadedFile ? URL.createObjectURL(uploadedFile) : null,
        fileName: uploadedFile ? uploadedFile.name : null
      };

      if (id) {
        setSkills([newSkill]);
      } else {
        setSkills(prevSkills => [...prevSkills, newSkill]);
      }
      
      setInputValue("");
      setSelectedValue(null);
      setUploadedFile(null);
    }
  };

  const handleRemoveSkill = (index) => {
    if (id) {
      setSkills(prevSkills =>
        prevSkills.map(skill => ({
          ...skill,
          file: null,
          fileUrl: null,
          fileName: null
        }))
      );
    } else {
      setSkills(skills.filter((_, i) => i !== index));
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
    if (id) {
      setSkills(prevSkills =>
        prevSkills.map(skill => ({
          ...skill,
          file: file,
          fileUrl: URL.createObjectURL(file),
          fileName: file.name
        }))
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (id) {
      // Editing existing skill
      const skillData = skills[0];
      if (!skillData?.skill) {
        alert("Please select or enter a skill");
        return;
      }
      formData.append("skill", skillData.skill);
      if (skillData.file) {
        formData.append("file_uploaded", skillData.file);
      }
    } else {
      // Adding new skills
      if (skills.length === 0) {
        alert("Please add at least one skill");
        return;
      }

      // Match the backend's expected format
      skills.forEach((skillObj, index) => {
        formData.append("skill", skillObj.skill);
        if (skillObj.file) {
          formData.append("file_uploaded", skillObj.file);
        }
      });
    }

    try {
      const requestMethod = id ? "put" : "post";
      const url = id ? `/edit-skills/${id}/` : "edit-skills/";

      const response = await instance[requestMethod](url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        alert(response.data.message || "Data saved successfully");
        window.location = "/skill-viewpage";
      }
    } catch (error) {
      console.error("Error:", error);
      alert(error.response?.data?.message || "Error saving data");
    }
  };

  return (
    <>
      <Sidebar />
      <Container style={{ marginTop: "20px" }}>
        <ProfileHeader showUploadIcon={false} />
        <Grid container justifyContent="space-between" alignItems="center" mt={2} mb={1}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "600" }}>
              {id ? "Edit Skill" : "Add Skills"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" mt={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="add-skill-autocomplete"
                options={engineeringSkills}
                value={selectedValue}
                onChange={handleSkillChange}
                inputValue={inputValue}
                onInputChange={handleSkillInputChange}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Designer e.g"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                      height: "45px",
                      borderRadius: "5px",
                      padding: "0",
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  border: "1px dashed #ccc",
                  padding: "16px",
                  margin: "16px 0",
                  textAlign: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                  position: "relative",
                }}>
                <input
                  type="file"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                  onChange={handleFileUpload}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                />
                {uploadedFile ? uploadedFile.name : "Upload file"}
              </Box>
            </Grid>

            {!id && (
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSkill}
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    minWidth: "30px",
                    minHeight: "35px",
                    color: "#666671",
                    border: "1px solid #666671",
                    textTransform: "none",
                    boxShadow: "none",
                    fontSize: "15px",
                    padding: "10px 0px",
                    "&:hover": {
                      backgroundColor: "white",
                      border: "1px solid #666671",
                      boxShadow: "none",
                    },
                    "&:active": {
                      backgroundColor: "white",
                      border: "1px solid #666671",
                      boxShadow: "none",
                    },
                  }}>
                  +Add
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid mt={2} item xs={12}>
            {skills.map((skillObj, index) => (
              <Box key={index} mb={2}>
                <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
                  {skillObj.skill}:
                </Typography>
                <Box alignItems="center">
                  {(skillObj.file || skillObj.fileUrl) && (
                    <DocumentBox
                      fileName={skillObj.fileName || (skillObj.file ? skillObj.file.name : "Certificate.pdf")}
                      documentUrl={skillObj.fileUrl || (skillObj.file ? URL.createObjectURL(skillObj.file) : null)}
                      onClick={(url) => window.open(url, "_blank")}
                      showEdit={false}
                      showDelete={true}
                      onDeleteClick={() => handleRemoveSkill(index)}
                    />
                  )}
                </Box>
              </Box>
            ))}

            {(skills.length > 0 || id) && (
              <Grid container justifyContent="center" my={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                    sx={{ backgroundColor: "#272260" }}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}