import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Card,
  CardContent,
  InputAdornment,
  Button
} from "@mui/material";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from 'react-router-dom';
import instance from "../../../globals/axios";
import { Link } from '@mui/material';



const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 550,
  },
  select: {
    width: "100%",
  },
  label: {
    fontWeight: "600",
    minWidth: 200,
    color: "rgb(0, 184, 230)",
  },
  selectContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const initialData = [
  {
    personName: "Sudhir Patel",
    contactNumber: "7894561231",
    email: "sudhir@sample.co",
    relationWithCandidate: "Educational Connect",
    verificationDocuments: "upload",
  },
];



const LabelField = ({ label, value, onChange, objectId }) => {
  const handleConfirmClick = (label, value) => {
    console.log(`Confirmed: ${label}: ${value}`);
    const payload = {
      user: 1,
      // the one making verification to change
      content_type: "basicinfo",
      object_id: objectId, 
      field: label.toLowerCase(),
      status: "V"
    };
    // console.log(payload)

    instance.post('field-verification/', payload)
      .then(response => {
        console.log('Field confirmed successfully!');
        alert(`${label} confirmed successfully!`)
      })
      .catch(error => {
        console.error('Error making POST request:', error);
      });
  };

  return (
    <Card sx={{ boxShadow: 2, p: 2, marginBottom: "20px" }}>
      <CardContent>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontSize: "20px" }}>
        
          {label}:  
          { label === "Linkedin" || label === "GitHub" || label === "Facebook" || label === "Behance" || label === "Adhaar File" || label === "PAN File" || label === "Other Platform links"
              ? <Link href={value} target="_blank" rel="noopener noreferrer">{value}</Link>
              : label === "Mobile Number"
              ? ` +${value}`
              : ` ${value}`
          }
        
        </Typography>
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
                <InputLabel id="status">Status</InputLabel>
                <Select fullWidth label="Status" id="status">
                <MenuItem value="Confirmed">Confirmed</MenuItem>
                <MenuItem value="Not Confirmed">Not Confirmed</MenuItem>
                <MenuItem value="Lack of data / doc from Candidate">Lack of data / doc from Candidate</MenuItem>
                </Select>
            </FormControl>
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
                <InputLabel id="source">Source of Verification</InputLabel>
                <Select fullWidth label="Source of Verification" id="source">
                <MenuItem value="Online">Online</MenuItem>
                <MenuItem value="Telephonic">Telephonic</MenuItem>
                <MenuItem value="Physical">Physical</MenuItem>
                <MenuItem value="iVeri link via mail">iVeri link via mail</MenuItem>
                </Select>
            </FormControl>
      </Grid>
      <Grid item xs={6}>
      <TextField fullWidth multiline rows={2} variant="outlined" size="large" label="More Details"/>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          my="auto"
          mx="auto"
          color="primary"
          component="label"
          htmlFor="upload-documents"
          style={{width:"150px",height:"55px", margin: "10px"}}
        >
          Upload Docs
          <input type="file" id="upload-documents" style={{ display: "none" }} />
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleConfirmClick(label, value)}
          style={{width:"150px",height:"55px", margin: "10px"}}
        >
          Confirm
        </Button>
      </Grid>


      </Grid>
      </CardContent>
    </Card>
  );
};

const EditPersonal = () => {
  const classes = useStyles();
  const [data, setData] = useState(initialData);
  const [values, setValues] = useState({});
  const { verification_id } = useParams();
  const [ objectId, setObjectId ] = useState(0)
  console.log(verification_id)

  const [labelValuePairs, setLabelValuePairs] = useState([
    { label: "Name", value: "" },
    { label: "Mobile Number", value: "" },
    { label: "E-mail ID", value: "" },
    { label: "Address", value: "" },
    { label: "Birthday", value: "" },
    { label: "Linkedin", value: "" },
    { label: "GitHub", value: "" },
    { label: "Facebook", value: "" },
    { label: "Other Platform links", value: "", options: [] },
    { label: "Adhaar File", value: "" },
    { label: "PAN File", value: "" },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(`agency/view-personal-verification/${verification_id}/`);
        const data = response.data;

        const basicInfo = data.verification_for_basic_info;
        const addressInfo = data.verification_for_address;
        setObjectId(basicInfo.id)

        setLabelValuePairs([
          { label: "Name", value: `${basicInfo.first_name} ${basicInfo.middle_name ? basicInfo.middle_name + ' ' : ''}${basicInfo.last_name}` },
          { label: "Mobile Number", value: basicInfo.mobile_number },
          { label: "E-mail ID", value: basicInfo.email },
          { label: "Address", value: addressInfo.concatenated_address },
          { label: "Birthday", value: basicInfo.birth_date },
          { label: "Linkedin", value: basicInfo.linkedin },
          { label: "GitHub", value: basicInfo.github },
          { label: "Behance", value: basicInfo.behance },
          { label: "Facebook", value: basicInfo.facebook },
          { label: "Other Platform links", value: basicInfo.other, options: basicInfo.social_links },
          { label: "Adhaar File", value: basicInfo.aadhar_file },
          { label: "PAN File", value: basicInfo.pan_file },
        ]);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (label, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [label]: value,
    }));
  };

  return (
    <>
      <NavBar />
      <Container maxWidth= "md" style={{ marginTop: '100px' }}>
          
          {labelValuePairs.map((item, index) => (
            <LabelField
              key={item.label}
              label={item.label}
              value={item.value}
              objectId={objectId}
              onChange={handleChange}
            />
          ))}
        
        </Container>
    </>
  );
};

export default EditPersonal;
