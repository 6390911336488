import React, { useState, useEffect } from "react";
import { Container, Box, Typography, IconButton, Divider, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import instance from "../../../globals/axios";
import ProfileHeader from "../../../components/ProfileHeader";
import EditButton from "../../../assets/Icons/Edit.png";
import TrashButton from "../../../assets/Icons/delete.png";
import CustomButton from "../../../components/Button/CustomButtom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import Sidebar from "../../../components/Sidebar";

const ExperienceViewProfile = () => {
  const history = useHistory();
  const [experiences, setExperiences] = useState([]);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const refetchData = () => {
    instance
      .get("experience-details")
      .then((response) => {
        setExperiences(response.data);
        setError("");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
      });
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    instance
      .delete(`experience-details/${selectedId}/`)
      .then(() => {
        refetchData();
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error("Error deleting experience", error);
        setError("Error deleting experience. Please try again.");
        setOpenDialog(false);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N.A.";
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric',
      day: 'numeric'
    });
  };

  useEffect(() => {
    refetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <Container maxWidth="xs" style={{ marginTop: "30px" }}>
        <ProfileHeader showUploadIcon={false} />
        {error && <Typography color="error">{error}</Typography>}
        
        <Box>
          {Array.isArray(experiences) && experiences.length === 0 ? (
            <>
              <Typography variant="h6" sx={{ fontWeight: "600" }} mt={2}>
                Experience Details
              </Typography>
              <AddSectionPlaceholder
                message="experience"
                buttonText="+ Add Experience"
                link="/edit-experience-details"
                onClick={() => history.push("/edit-experience-details")}
              />
            </>
          ) : (
            <>
              {experiences.map((experience) => (
                <Box key={experience.id} p={2} pb={2}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <Typography sx={{ fontWeight: "bold" }} mr={1}>
                        Company Name:
                      </Typography>
                      <Typography color="#262262" sx={{ fontWeight: "bold" }}>
                        {experience.company || "N.A."}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton>
                        <img
                          src={EditButton}
                          style={{ width: "20px", height: "20px", marginRight: "7px" }}
                          alt="Edit Icon"
                          onClick={() => history.push(`/edit-experience-detail/${experience.id}`)}
                        />
                        <img
                          src={TrashButton}
                          style={{ width: "20px", height: "20px" }}
                          alt="Delete Icon"
                          onClick={() => handleDeleteClick(experience.id)}
                        />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" mb={0.8}>
                    <Typography sx={{ fontWeight: "bold" }}>Start Date:</Typography>
                    <Typography ml={1} sx={{ fontWeight: "bold" }} color="#262262">
                      {formatDate(experience.start_date)}
                    </Typography>
                  </Box>

                  {experience.currently_working ? (
                    <Box display="flex" alignItems="center" mb={0.8}>
                      <Typography sx={{ fontWeight: "bold" }} color="#262262">
                        Currently Working
                      </Typography>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" mb={0.8}>
                      <Typography sx={{ fontWeight: "bold" }}>End Date:</Typography>
                      <Typography ml={1} sx={{ fontWeight: "bold" }} color="#262262">
                        {formatDate(experience.end_date)}
                      </Typography>
                    </Box>
                  )}
                  <Divider />
                </Box>
              ))}
              
              <Button
                onClick={() => history.push('/edit-experience-details')}
                mt={2}
                sx={{ border: "1px solid #666671", textTransform: "none", color: "#666671", fontSize: "24px" }}
                fullWidth
              >
                + Add Experience Details
              </Button>
            </>
          )}
        </Box>

        <ConfirmDeleteDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          onDelete={handleDelete} 
          title={"Experience Detail"}
        />
      </Container>
    </>
  );
};

export default ExperienceViewProfile;