import React, { useEffect, useState } from "react";
import { degreeOptions } from "./data";
import { styled } from "@mui/material/styles";

import {
  Container,
  Typography,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Grid,
  Box,
  FormControl,
  Button,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import instance from "../../../globals/axios.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDropzone } from "react-dropzone";
import ProfileHeader from "../../../components/ProfileHeader.js";
import BackButton from "../../../components/Button/BackButton.js";
import CustomButton from "../../../components/Button/CustomButtom.js";
import Sidebar from "../../../components/Sidebar.js";
import DocumentBox from "../../../components/DocumentBox.js";

const DropzoneContainer = styled("div")({
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px",
});
const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100px",
  width: "100%",
  cursor: "pointer",
  padding: "8px",
  margin: "16px 0",
};


const EducationDetail = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { id } = useParams();
  const [educationType, setEducationType] = useState("");
  const [degree, setDegree] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [board, setBoard] = useState("");
  const [score, setScore] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [awards, setAwards] = useState("");
  const [extraCurricular, setExtraCurricular] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [certificateName, setCertificateName] = useState("");
  const [scoreUnit, setScoreUnit] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [awardsFile, setAwardsFile] = useState(null);
  const [projectDocumentFile, setProjectDocumentFile] = useState(null);
  const [extraCurricularFile, setExtraCurricularFile] = useState(null);
  const [educationFile, setEducationFile] = useState(null);
  const [waitingForResult, setWaitingForResult] = useState(false);
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [internshipFormData, setInternshipFormData] = useState([
    {
      internship_company: "",
      internship_role: "",
      internship_duration_from: null,
      internship_duration_to: null,
    },
  ]);

  const [projectFormData, setProjectFormData] = useState([
    {
      project_title: "",
      project_desc: "",
      project_link: "",
      documentType: "",
    },
  ]);

  useEffect(() => {
    if (id) {
      instance
        .get(`educational-details/${id}/`)
        .then((response) => {
          const data = response.data.data;
          setEducationType(data.type);
          setDegree(data.degree);
          setSpecialization(data.specialization);
          setInstituteName(data.institute_name);

          setStartDate(dayjs(data.start_date));
          setEndDate(dayjs(data.end_date));
          setPassingYear(data.passing_year);
          setScoreUnit(data.score_unit);
          setBoard(data.board);
          setScore(data.score);
          setTotalScore(data.total_score);
          setAwards(data.awards);
          setExtraCurricular(data.extra_curricular);

          setInternshipFormData(data.internships);
          setProjectFormData(data.projects);
          if (data.extra_curricular_file) {
            setProjectDocumentFile(data.extra_curricular_file);
          }
          if (data.educational_file) {
            setEducationFile(data.educational_file);
          }
          if (data.awards_file) {
            setAwardsFile(data.awards_file);
          }
        })
        .catch((error) => {
          console.error("Error fetching education details:", error);
        });
    }
  }, [id]);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  };
  

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first file selected
    setSelectedFile(file);
    setSelectedFileName(file ? file.name : ""); // Automatically use the file name
  };

  const handleUpload = () => {
    if (selectedFile && selectedFileName) {
      setUploadedFiles([...uploadedFiles, { file: selectedFile, fileName: selectedFileName }]);
      setSelectedFile(null);
      setSelectedFileName("");
    }
  };

  
  const handleRemove = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };
  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
  });
  const onDropExtraCurricular = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setProjectDocumentFile(acceptedFiles[0]);
    }
  };

  const onDropEducation = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setEducationFile(acceptedFiles[0]);
    }
  };

  const onDropAwards = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setAwardsFile(acceptedFiles[0]);
    }
  };

  const { getRootProps: getAwardsRootProps, getInputProps: getAwardsInputProps } = useDropzone({
    accept: ".pdf, .jpeg, .jpg, .png",
    onDrop: onDropAwards,
  });

  const { getRootProps: getExtraCurricularRootProps, getInputProps: getExtraCurricularInputProps } =
    useDropzone({
      accept: ".pdf",
      onDrop: onDropExtraCurricular,
    });

  const { getRootProps: getEducationRootProps, getInputProps: getEducationInputProps } =
    useDropzone({
      accept: ".pdf",
      onDrop: onDropEducation,
    });

  const handleInternChange = (event, index) => {
    const { name, value } = event.target;
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData[index][name] = value;
    setInternshipFormData(updatedInternshipFormData);
  };

// Update the handleDateChange to accept the index parameter
const handleDateChange = (date, name, index) => {
  const updatedInternshipFormData = [...internshipFormData];
  updatedInternshipFormData[index][name] = date;
  setInternshipFormData(updatedInternshipFormData);
};

  const handleAddBoxIntern = () => {
    setInternshipFormData([
      ...internshipFormData,
      {
        internship_company: "",
        internship_role: "",
        internship_duration_from: null,
        internship_duration_to: null,
      },
    ]);
  };

  const handleRemoveBoxIntern = (index) => {
    const updatedInternshipFormData = [...internshipFormData];
    updatedInternshipFormData.splice(index, 1);
    setInternshipFormData(updatedInternshipFormData);
  };
  const getFileNameFromUrl = (file) => {
    if (file instanceof File) {
      return file.name;
    } else if (typeof file === "string") {
      return file.substring(file.lastIndexOf("/") + 1);
    }
    return "";
  };
  const handleProjectChange = (event, index) => {
    const { name, value } = event.target;
    const updatedProjectFormData = [...projectFormData];
    updatedProjectFormData[index][name] = value;
    setProjectFormData(updatedProjectFormData);
  };

  const handleAddBoxProject = () => {
    setIsAddingProject(true);
    setEditIndex(null);
    setProjectFormData([
      ...projectFormData,
      {
        project_title: "",
        project_desc: "",
        project_link: "",
      },
    ]);
  };
  const handleViewClick = (file) => {
    if (file instanceof File) {
      const url = URL.createObjectURL(file);
      console.log("File URL:", url);
      window.open(url, "_blank");
    } else if (typeof file === "string" && file.startsWith("http")) {
      console.log("Opening URL:", file);
      window.open(file, "_blank");
    } else {
      console.error("Invalid file:", file);
    }
  };



  const handleEditProject = (index) => {
    setIsAddingProject(true);
    setEditIndex(index);
  };

  const handleRemoveBoxProject = (index) => {
    const updatedProjectFormData = [...projectFormData];
    updatedProjectFormData.splice(index, 1);
    setProjectFormData(updatedProjectFormData);
    if (editIndex === index) {
      setEditIndex(null);
      setIsAddingProject(false);
    }
  };

  const handleSaveClick = (event) => {
    event.preventDefault();

    const sendData = new FormData();
    sendData.append("type", educationType);
    sendData.append("degree", degree);
    sendData.append("specialization", specialization);
    sendData.append("institute_name", instituteName);
    if (startDate !== null && endDate !== null) {
      if (endDate > startDate) {
        sendData.append("start_date", startDate.toISOString());
        sendData.append("end_date", endDate.toISOString());
      } else {
        alert("End Date must be later than Start Date.");
        return;
      }
    } else {
      alert("Please select Valid Course Duration.");
      return;
    }

    sendData.append("passing_year", passingYear);
    sendData.append("board", board);
    sendData.append("score", score);
    sendData.append("total_score", totalScore);
    sendData.append("awards", awards);
    sendData.append("extra_curricular", extraCurricular);
    sendData.append("video_file", videoFile);
    sendData.append("certificate_name", certificateName);
    sendData.append("score_unit", scoreUnit);
    sendData.append("result_waiting", waitingForResult);

    const formattedInternshipData = internshipFormData.map((item) => ({
      internship_company: item.internship_company || "",
      internship_role: item.internship_role || "",
      internship_duration_from: item.internship_duration_from
        ? new Date(item.internship_duration_from).toISOString()
        : null,
      internship_duration_to: item.internship_duration_to
        ? new Date(item.internship_duration_to).toISOString()
        : null,
    }));

    sendData.append("internships", JSON.stringify(formattedInternshipData));

    const formattedProjectData = projectFormData.map((item) => ({
      project_title: item.project_title || "",
      project_desc: item.project_desc || "",
      project_link: item.project_link || "",
    }));

    sendData.append("projects", JSON.stringify(formattedProjectData));
    if (
      educationFile &&
      !(typeof educationFile === "string" && educationFile.startsWith("https://"))
    ) {
      sendData.append("educational_file", educationFile);
    }
    if (
      projectDocumentFile &&
      !(typeof projectDocumentFile === "string" && projectDocumentFile.startsWith("https://"))
    ) {
      sendData.append("extra_curricular_file", projectDocumentFile);
    }
    if (awardsFile && !(typeof awardsFile === "string" && awardsFile.startsWith("https://"))) {
      sendData.append("awards_file", awardsFile);
    }

    const url = id ? `educational-details/${id}/` : "educational-details/";

    const requestMethod = id ? "put" : "post";
    instance[requestMethod](url, sendData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      const educational_details_id = response.data.data.id;

      // Create a new FormData for file upload
      const uploadFormData = new FormData();
      uploadFormData.append("educational_details_id", educational_details_id);
      
      // Append all uploaded files
      uploadedFiles.forEach((fileData, index) => {
        uploadFormData.append(`file_names_${index}`, fileData.fileName);
        uploadFormData.append(`files_${index}`, fileData.file);
      });

      // Make the file upload request
      return instance.post("upload-educational-extra-details/", uploadFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    })
    .then(() => {
      alert("Data saved successfully!");
      setTimeout(() => {
        window.location = "/education-viewpage";
      }, 200);
    })
    .catch((error) => {
      alert("Error saving data: " + error.message);
    });
  };

  console.log("upload", awardsFile);
  return (
    <>
      <Sidebar />
      <Container maxWidth="md" style={{ marginTop: "20px" }}>
        <ProfileHeader showUploadIcon={false} />
        <Grid item xs={12} my={1}>
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: "600",
              marginBottom: "5px",
            }}>
            Educational Details
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} container fullWidth>
            {/* Type */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1">Education Type</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  id="type-dropdown"
                  value={educationType}
                  name="type_dropdown"
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  label="Education Type"
                  variant="outlined"
                  onChange={(e) => setEducationType(e.target.value)}>
                  <MenuItem value="School">School</MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value="Graduation">Graduation</MenuItem>
                  <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
                  <MenuItem value="Doctorate">Doctorate (PhD)</MenuItem>
                  <MenuItem value="Professional Certification">Professional Certification</MenuItem>
                  <MenuItem value="Voluntary Certification">Voluntary Certification</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* Degree */}
          {educationType !== "Professional Certification" &&
            educationType !== "Voluntary Certification" && (
              <Grid item xs={12} container fullWidth>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Degree</Typography>
                </Grid>
                <Grid my={2} item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      labelId="degree-dropdown-label"
                      id="degree-dropdown"
                      value={degree}
                      name="degree_dropdown"
                      label="Degree"
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      onChange={(e) => setDegree(e.target.value)}
                      disabled={!educationType}>
                      {degreeOptions[educationType] &&
                        degreeOptions[educationType].map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

          {/* Specialisation */}
          {educationType !== "Professional Certification" &&
            educationType !== "Voluntary Certification" && (
              <Grid item xs={12} container fullWidth>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">Specialisation</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <Select
                      fullWidth
                      variant="outlined"
                      placeholder="Specialisation"
                      value={specialization}
                      name="specialization_dropdown"
                      onChange={(e) => setSpecialization(e.target.value)}
                      sx={{
                        backgroundColor: "rgba(214,214,214, 0.3)",
                        height: "45px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem value="Diploma">Diploma</MenuItem>
                      <MenuItem value="Graduation">Graduation</MenuItem>
                      <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
                      <MenuItem value="Doctorate">Doctorate (PhD)</MenuItem>
                      <MenuItem value="Professional Certification">
                        Professional Certification
                      </MenuItem>
                      <MenuItem value="Voluntary Certification">Voluntary Certification</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}

          {/* Certificate Name */}
          {(educationType === "Professional Certification" ||
            educationType === "Voluntary Certification") && (
            <Grid item xs={12} container fullWidth>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Certificate Name</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  value={certificateName}
                  onChange={(e) => setCertificateName(e.target.value)}
                />
              </Grid>
            </Grid>
          )}

          {/* Passing Year */}

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Course Duration</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      width: "100%",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    placeholder="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      borderRadius: "5px",
                      width: "100%",
                      height: "45px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                        "& input": {
                          padding: "10px 14px",
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={waitingForResult}
                  onChange={(e) => setWaitingForResult(e.target.checked)}
                />
              }
              label="Waiting for Result"
            />
          </Grid>

          {/* Board */}
          {(educationType === "School" || educationType === "Diploma") && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Board</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    labelId="board-dropdown-label"
                    id="board-dropdown"
                    value={board}
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    name="board_dropdown"
                    onChange={(e) => setBoard(e.target.value)}>
                    <MenuItem value="ICSE">ICSE</MenuItem>
                    <MenuItem value="CBSE">CBSE</MenuItem>
                    <MenuItem value="IGCSE">IGCSE</MenuItem>
                    <MenuItem value="IB">IB</MenuItem>
                    <MenuItem value="State Board">State Board</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </React.Fragment>
          )}

          {(educationType === "Graduation" ||
            educationType === "Post-Graduation" ||
            educationType === "Doctorate") && (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">University Name</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "45px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  variant="outlined"
                  placeholder="University Name"
                  value={board}
                  name="board"
                  onChange={(e) => setBoard(e.target.value)}
                />
              </Grid>
            </React.Fragment>
          )}

          {/* Institute Name */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Institute Name</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              placeholder="Institute Name"
              value={instituteName}
              name="institute_name"
              onChange={(e) => setInstituteName(e.target.value)}
            />
          </Grid>

          {/* Score */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Score</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              placeholder="Score"
              value={score}
              name="score"
              onChange={(e) => setScore(e.target.value)}
            />
          </Grid>

          {/* Total Score */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Total Score</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Total Score"
              value={totalScore}
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                height: "45px",
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              name="total_score"
              onChange={(e) => setTotalScore(e.target.value)}
            />
          </Grid>

          {/* Score Unit */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Score Unit</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Select
                fullWidth
                labelId="score-unit-dropdown-label"
                id="score-unit-dropdown"
                value={scoreUnit}
                name="score_unit_dropdown"
                placeholder="Grading"
                sx={{
                  backgroundColor: "rgba(214,214,214, 0.3)",
                  height: "45px",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                onChange={(e) => setScoreUnit(e.target.value)}>
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="CGPA">CGPA</MenuItem>
                <MenuItem value="Marks">Marks</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid sx={{ marginTop: "10px" }}>
            <DropzoneContainer {...getEducationRootProps()}>
              <input {...getEducationInputProps()} />
              <Typography variant="body1">Upload Education File</Typography>
            </DropzoneContainer>

            <Grid item xs={12} md={4}>
  {educationFile && educationFile !== "https://iveri.s3.amazonaws.com/False" && (
    <DocumentBox
      fileName={getFileNameFromUrl(educationFile)}
      documentType="Education"
      onClick={() => handleViewClick(educationFile)}
    />
  )}
</Grid>


          </Grid>
        </Grid>

        <Grid my={2} px={2} container spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>

              <Box p={2}>


                {projectFormData.slice(0, -1).map((item, index) => (
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">Project Title</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                        {item.project_title}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      {" "}
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => console.log(`Edit Project ${index + 1}`)}>
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {" "}
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleRemoveBoxProject(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography variant="subtitle2">Project Description</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                        {item.project_desc}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="subtitle2">Project Link</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                        {item.project_link}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}

              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ my: 1 }} variant="subtitle1">
                    Project Title
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Project Title"
                    name="project_title"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={projectFormData[projectFormData.length - 1].project_title}
                    onChange={(e) => handleProjectChange(e, projectFormData.length - 1)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ my: 1 }} variant="subtitle1">
                    Project Description
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Project Description"
                    name="project_desc"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={projectFormData[projectFormData.length - 1].project_desc}
                    onChange={(e) => handleProjectChange(e, projectFormData.length - 1)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ my: 1 }} variant="subtitle1">
                    Project Link
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Project Link"
                    name="project_link"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    value={projectFormData[projectFormData.length - 1].project_link}
                    onChange={(e) => handleProjectChange(e, projectFormData.length - 1)}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Grid>
                    <DropzoneContainer {...getExtraCurricularRootProps()}>
                      <input {...getExtraCurricularInputProps()} />
                      <Typography variant="body1">Upload Project Document</Typography>
                    </DropzoneContainer>
                    {projectDocumentFile && projectDocumentFile !== "https://iveri.s3.amazonaws.com/False" && (
  <DocumentBox
    fileName={getFileNameFromUrl(projectDocumentFile)}
    documentType="Project"
    onClick={() => handleViewClick(projectDocumentFile)}
  />
)}

                  </Grid>
                </Grid>
              </Grid>
              <Box my={2}>
                <CustomButton onClick={handleAddBoxProject}>Add More Projects</CustomButton>
              </Box>


            </Grid>
            <Grid item xs={12} md={6}>
              <Grid spacing={2} container>
                <Box p={2}>

                  {internshipFormData.slice(0, -1).map((item, index) => (
                    <Grid container>
                      <Grid item xs={4}>
                        <Typography variant="subtitle2">Internship Company:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                          {item.internship_company}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {" "}
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={() => console.log(`Edit Project ${index + 1}`)}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={1}>
                        {" "}
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleRemoveBoxIntern(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>

                      <Grid item xs={5}>
                        <Typography variant="subtitle2">Internship Role:</Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography style={{ fontSize: "14px", fontWeight: 700 }}>
                          {item.internship_role}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>

                <Grid item xs={12}>
                  <Typography sx={{ my: 1 }} variant="subtitle1">
                    Internship Company
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Internship Company"
                    value={internshipFormData[internshipFormData.length - 1].internship_company}
                    name="internship_company"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    onChange={(e) => handleInternChange(e, internshipFormData.length - 1)}
                  />
                  <Typography sx={{ my: 1 }} variant="subtitle1">
                    Internship Role
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    variant="outlined"
                    placeholder="Internship Role"
                    value={internshipFormData[internshipFormData.length - 1].internship_role}
                    name="internship_role"
                    sx={{
                      backgroundColor: "rgba(214,214,214, 0.3)",
                      height: "45px",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    onChange={(e) => handleInternChange(e, internshipFormData.length - 1)}
                  />
                  <Typography sx={{ my: 1.5 }} variant="subtitle1">
                    Duration
                  </Typography>
                  <Grid fullWidth container>
                    <Grid item xs={12} md={6} pr={1} pt={1}>
                      <LocalizationProvider sx={{ my: 1 }} dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From"
                          value={
                            internshipFormData.length
                              ? dayjs(
                                  internshipFormData[internshipFormData.length - 1]
                                    .internship_duration_from
                                )
                              : null
                          }
                          onChange={(date) =>
                            handleDateChange(
                              date,
                              "internship_duration_from",
                              internshipFormData.length - 1
                            )
                          } // Add index here
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            borderRadius: "5px",
                            width: "100%",
                            height: "45px",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                              "& input": {
                                padding: "10px 14px",
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} pt={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To"
                          value={dayjs(
                            internshipFormData[internshipFormData.length - 1].internship_duration_to
                          )}
                          onChange={(date) =>
                            handleDateChange(
                              date,
                              "internship_duration_to",
                              internshipFormData.length - 1
                            )
                          } // Add index here
                          sx={{
                            backgroundColor: "rgba(214,214,214, 0.3)",
                            borderRadius: "5px",
                            width: "100%",
                            height: "45px",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                              },
                              "&:hover fieldset": {
                                border: "none",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none",
                              },
                              "& input": {
                                padding: "10px 14px",
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid sx={{ marginTop: "20px" }}>
                      <DropzoneContainer {...getAwardsRootProps()}>
                        <input {...getAwardsInputProps()} />
                        <Typography variant="body1">Upload Internship Document</Typography>
                      </DropzoneContainer>
                      {awardsFile && awardsFile !== "https://iveri.s3.amazonaws.com/False" && (
  <DocumentBox
    fileName={getFileNameFromUrl(awardsFile)}
    documentType="Internship"
    onClick={() => handleViewClick(awardsFile)}
  />
)}

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box my={2}>
                <CustomButton onClick={handleAddBoxIntern}>Add More Internship</CustomButton>
              </Box>

              <Grid container spacing={2}>
      <Grid container spacing={2} item ml={1}>
        <Grid my={2} xs={12}>
          <Typography variant="h6" gutterBottom>
            File Upload Section:
          </Typography>
        </Grid>

        <Grid xs={12} fullWidth mb={2}>
          <FormControl fullWidth>
            <Typography sx={{ fontSize: "15px" }} pb={1}>
              Enter File Name
            </Typography>
            <TextField
              value={selectedFileName}
              onChange={(e) => setSelectedFileName(e.target.value)}
              placeholder="Enter file name"
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  height: "45px",
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>


          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag & drop files here, or click to select files</p>
          </div>

          {selectedFile && (
            <Typography variant="subtitle1" gutterBottom>
              Selected File: {selectedFile.name}
            </Typography>
          )}



          <Button
            fullWidth
            variant="contained"
            sx={{ 
              backgroundColor: "#272260", 
              color: "white",
              '&:disabled': {
                backgroundColor: 'rgba(39, 34, 96, 0.5)',
                color: 'white'
              }
            }}
            onClick={handleUpload}
            disabled={!selectedFileName.trim() || !selectedFile}
          >
            + Add File
          </Button>
        </Grid>


{uploadedFiles.length > 0 && (
  <>
   <Grid item xs={12}>
    <Typography variant="h5" gutterBottom>
      Uploaded Files
    </Typography>
    {uploadedFiles.map((uploadedFile, index) => (
        <DocumentBox
          key={index}
          fileName={uploadedFile.fileName}
          onClick={() => window.open(URL.createObjectURL(uploadedFile.file), "_blank")}
          onDeleteClick={() => handleRemove(index)}
          showDelete={true}
        />
      ))}
      </Grid>
  </>
)}
    </Grid>

              <Box mx="auto">
                <CustomButton onClick={handleSaveClick}>{"Next"}</CustomButton>
                <BackButton />
              </Box>

            </Grid>

          </Grid>
          
        </Grid>

      </Container>
    </>
  );
};

export default EducationDetail;

