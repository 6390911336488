import React, { useState, useEffect } from "react";
import { Container, Typography, IconButton, Box } from "@material-ui/core";
import ProfileHeader from "../../../components/ProfileHeader.js";
import instance from "../../../globals/axios.js";
import Editbutton from "../../../assets/Icons/Edit.png";
import trashIcon from "../../../assets/Icons/delete.png";
import { useHistory } from "react-router-dom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import DocumentBox from "../../../components/DocumentBox.js";
import Sidebar from "../../../components/Sidebar.js";

export default function ViewAdditionalPage() {
  const [additionalInfo, setAdditionalInfo] = useState(null); // Single object for additional info
  const [openDialog, setOpenDialog] = useState(false);
  const [infoToDelete, setInfoToDelete] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAdditionalInfo();
  }, []);

  const openDocument = (url) => {
    window.open(url, "_blank");
  };

  const fetchAdditionalInfo = () => {
    instance
      .get("additional-info/")
      .then((response) => {
        console.log("API Response:", response.data); // Debugging log

        const infoObject = response.data.data[0];
        if (infoObject) {
          setAdditionalInfo(infoObject); // Set the single object directly
        } else {
          setAdditionalInfo(null); // Handle case where no additional info exists
        }
      })
      .catch((error) => {
        console.error("Error fetching additional information data:", error);
        setAdditionalInfo(null); // Handle the error case by setting null
      });
  };

  const handleEditButton = (id) => {
    history.push(`/edit-additional-infos/${id}`);
  };

  const handleDeleteClick = (info) => {
    setInfoToDelete(info);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    instance
      .delete(`/additional-info/${infoToDelete.id}`)
      .then(() => {
        setOpenDialog(false);
        fetchAdditionalInfo(); // Refresh the list after deletion
      })
      .catch((error) => {
        console.error("Error deleting additional information:", error);
        alert("An error occurred while deleting.");
        setOpenDialog(false);
      });
  };

  const handleAddMore = () => {
    history.push("edit-additional-info");
  };

  return (
    <>
    <Sidebar />

    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false}  />
      <Box display="flex" alignItems="center" pb={1} mt={2}>
        <Typography variant="h6" style={{ marginTop: "20px", fontWeight: "bold" }}>
          Additional Information
        </Typography>
      </Box>

      {!additionalInfo ? (
        <AddSectionPlaceholder
          message="Additional Information"
          buttonText="+ Add Additional Information"
          link="/edit-additional-info"
          onClick={handleAddMore}
        />
      ) : (
        <React.Fragment>
          <Box display="flex" alignItems="center" justifyContent="space-between" pb={1} mt={2}>
            <Typography variant="h6" sx={{ fontWeight: "600", fontSize: "22px" }}>
              Additional Information
            </Typography>

            <IconButton>
              <img
                src={Editbutton}
                style={{ width: "24px", height: "24px", marginRight: "12px" }}
                onClick={() => handleEditButton(additionalInfo.id)}
                alt="Edit"
              />
              <img
                src={trashIcon}
                style={{ width: "24px", height: "24px" }}
                onClick={() => handleDeleteClick(additionalInfo)}
                alt="Delete"
              />
            </IconButton>
          </Box>

          <Box mb={2}>
            {additionalInfo.disability ? (
              <Box mb={1}>
                <Typography variant="subtitle1">
                  <strong>Disability:</strong> {additionalInfo.disability}
                </Typography>
              </Box>
            ) : (
              <Typography variant="subtitle1">No disability information available.</Typography>
            )}

{additionalInfo.diff_abled_file && additionalInfo.diff_abled_file !== "https://iveri.s3.amazonaws.com/False" && (
  <Box mb={2}>
    <DocumentBox
      fileName={"Disability Certificate.pdf"}
      documentUrl={additionalInfo.diff_abled_file}
      onClick={(url) => openDocument(url)}
    />
  </Box>
)}


            {additionalInfo.visa_type ? (
              <Box mb={1}>
                <Typography variant="subtitle1">
                  <strong>Visa type:</strong> {additionalInfo.visa_type}
                </Typography>
              </Box>
            ) : (
              <Typography variant="subtitle1">No visa information available.</Typography>
            )}

{additionalInfo.visa_file && additionalInfo.visa_file !== "https://iveri.s3.amazonaws.com/False" && (
  <Box>
    <DocumentBox
      fileName={"Visa Certificate.pdf"}
      documentUrl={additionalInfo.visa_file}
      onClick={(url) => openDocument(url)}
    />
  </Box>
)}
          </Box>
        </React.Fragment>
      )}

      <ConfirmDeleteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onDelete={handleDelete}
        title={"Additional Information"}
      />
    </Container>
    </>
  );
}