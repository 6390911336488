import React, { useState } from "react";
import NavBar from "../../../components/NavBar";
import Layout from "../../../components/Layout";
import { Button, Grid, Typography, TextField, Box, Card, Select, FormControlLabel, Checkbox, MenuItem, Container, FormControl, InputLabel} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faLink } from "@fortawesome/free-solid-svg-icons";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import makeStyles from "@mui/material";


import instance from "../../../globals/axios";

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100px',
  width: '100%',
  cursor: 'pointer',
  padding: '8px',
  margin: '16px 0',
};

const fileContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #cccccc',
  padding: '8px',
  margin: '8px 0',
};

export default function ExperienceDetails() {

  const [currentPage, setCurrentPage] = useState(1);

  // State variables for form inputs
  const [company, setCompany] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fixedSalaryCurrency, setFixedSalaryCurrency] = useState('');
  const [fixedSalaryAmount, setFixedSalaryAmount] = useState('');
  const [variableSalaryCurrency, setVariableSalaryCurrency] = useState('');
  const [variableSalaryAmount, setVariableSalaryAmount] = useState('');
  const [accommodation, setAccommodation] = useState('');
  const [accommodationProvided, setAccommodationProvided] = useState(false);

  const [accommodationFurniture, setAccommodationFurniture] = useState('');
  const [accommodationFamily, setAccommodationFamily] = useState('');

  const [perks, setPerks] = useState('');
  const [designation, setDesignation] = useState('');
  const [reportingTo, setReportingTo] = useState('');
  const [reportee, setReportee] = useState('');
  const [role, setRole] = useState('');
  const [workLocation, setWorkLocation] = useState('');
  const [officeType, setOfficeType] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [daysNegotiable, setDaysNegotiable] = useState('');
  const [currency, setCurrency] = useState('');
  const [amountInLakhs, setAmountInLakhs] = useState('');
  const [briefRole, setBriefRole] = useState('');
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (selectedFile && selectedFileName) {
      setUploadedFiles([...uploadedFiles, { file: selectedFile, fileName: selectedFileName }]);
      setSelectedFile(null);
      setSelectedFileName('');
    }
  };

  const handleRemove = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*',
  });


  const [kraList, setKraList] = useState([{ kra: '', achievement: '' }]);

  const handleInputChange = (index, field, value) => {
    const updatedKraList = kraList.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setKraList(updatedKraList);
  };

  const handleAddKra = () => {
    setKraList([...kraList, { kra: '', achievement: '' }]);
  };

  const handleRemoveKra = (index) => {
    const updatedKraList = kraList.filter((item, i) => i !== index);
    setKraList(updatedKraList);
  };




  const handleSaveClick = () => {
    
    const formData = new FormData();
    formData.append('company', company);
    formData.append('company_description', companyDescription);

    const isValidURL = (url) => {
      const pattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
      return pattern.test(url);
    };

    if (website !== null && website !== "" && website !== "null") {
      const isValid = isValidURL(website);
      if (isValid) {
        formData.append('website', website);
      } else {
        alert('Website link is invalid.');
        return;
      }
    }
    else{
      alert('Please add company website.');
      return;
    }

    const currentDate = new Date();

    if (!startDate) {
      alert('Start Date cannot be null.');
      return;
    }    

    if (currentlyWorking) {
      formData.append('start_date', startDate.toISOString());
      formData.append('end_date', currentDate.toISOString()); 
    } 
    else{

      if (endDate > startDate) {
        formData.append('start_date', startDate.toISOString());
        formData.append('end_date', endDate.toISOString());
      } 
      else {
        alert('End Date must be later than Start Date.');
        return;
      }
    
    }

    formData.append('fixed_salary_currency', fixedSalaryCurrency);
    formData.append('fixed_salary_amount', fixedSalaryAmount);
    formData.append('variable_salary_currency', variableSalaryCurrency);
    formData.append('variable_salary_amount', variableSalaryAmount);
    formData.append('accommodation', accommodation);
    formData.append('accommodation_furniture', accommodationFurniture);
    formData.append('accommodation_family', accommodationFamily);
    formData.append('perks', perks);
    formData.append('designation', designation);
    formData.append('reporting_to', reportingTo);
    formData.append('reportee', reportee);
    formData.append('role', role);
    formData.append('work_location', workLocation);
    formData.append('office_type', officeType);
    formData.append('notice_period', noticePeriod);
    formData.append('days_negotiable', daysNegotiable);
    formData.append('currency', currency);
    formData.append('expected_salary', amountInLakhs);
    formData.append('brief_role', briefRole);
    formData.append('currently_working', currentlyWorking);





    const kraJson = kraList.map(({ kra, achievement }) => ({ kra, achievement }));
    formData.append('kra_data', JSON.stringify(kraJson));


    instance.post('experience-details/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      const experience_details_id = response.data.data.experience_details_id;

      const uploadFormData = new FormData();
      uploadFormData.append('experience_details_id', experience_details_id);
      uploadedFiles.forEach((fileData, index) => {
        uploadFormData.append(`file_names_${index}`, fileData.fileName);
        uploadFormData.append(`files_${index}`, fileData.file);
      });
      uploadFormData.append('experience_details_id', experience_details_id);

      instance.post('upload-experience-details/', uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((uploadResponse) => {
        alert('Data saved and uploaded successfully');
        // setTimeout(() => {
        //   window.location = "/edit-skills";
        // }, 200);
      })
      .catch((uploadError) => {
        console.error('Error uploading data:', uploadError.message);
      });
    })
    .catch((error) => {
      alert('Error saving data: ' + error.message);
    });
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage+1)
  };
  const handleBackClick = () => {
    setCurrentPage(currentPage-1)
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    
  };


  return (
    <>
      <NavBar />
      <Container style={{ marginTop: '80px' }} mb={2}>
      <Grid sx={{ textAlign:"center"}} item xs={12} my={1}>
              <Typography style={{
                            color: "rgb(0, 184, 230)",
                            fontSize: "22px",
                            fontWeight: "600",
                            marginBottom: "5px",
                        }}
                    >
                        Experience Details
              </Typography>
            </Grid>

      {currentPage === 1 && (
        <Container my={3}>
          <Grid container mb={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField label="Company" fullWidth variant="outlined" value={company} onChange={(e) => setCompany(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label="Company Description" fullWidth variant="outlined" value={companyDescription} onChange={(e) => setCompanyDescription(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Website" fullWidth variant="outlined" value={website} onChange={(e) => setWebsite(e.target.value)} />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} sx={{ width: '100%' }} />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="End Date" 
                    value={endDate} 
                    onChange={(newValue) => {setEndDate(newValue);}} 
                    disabled={currentlyWorking}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                <label>
                  <input 
                    type="checkbox" 
                    checked={currentlyWorking} 
                    onChange={(event) => {
                      setCurrentlyWorking(event.target.checked);
                      if (event.target.checked) {
                        setEndDate(null);
                      }
                    }} 
                  />
                  Currently Working
                </label>
            </Grid>

            <Grid item xs={12} >
              <Typography variant="subtitle1">Latest Salary and Benefits Details</Typography>
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Fixed Salary</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="fixed-salary-currency-label">Currency</InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  labelId="fixed-salary-currency-label"
                  id="fixed-salary-currency"
                  label="Currency"
                  value={fixedSalaryCurrency}
                  onChange={(e) => setFixedSalaryCurrency(e.target.value)}
                >
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Amount"
                fullWidth
                variant="outlined"
                onKeyPress={(event) => {
                  const charCode = event.charCode;
                  if (!(charCode >= 48 && charCode <= 57)) {
                    event.preventDefault();
                  }
                }}
                value={fixedSalaryAmount}
                onChange={(e) => setFixedSalaryAmount(e.target.value)}
              />
            </Grid>
            
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Variable Salary</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="variable-salary-currency-label">Currency</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                labelId="variable-salary-currency-label"
                id="variable-salary-currency"
                label="Currency"
                value={variableSalaryCurrency}
                onChange={(e) => setVariableSalaryCurrency(e.target.value)}
              >
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Amount" fullWidth variant="outlined" onKeyPress={(event) => {
            const charCode = event.charCode;
            if (!(charCode >= 48 && charCode <= 57)) {
              event.preventDefault();
            }
          }} value={variableSalaryAmount} onChange={(e) => setVariableSalaryAmount(e.target.value)} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Expected CTC</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                label="Currency"
                variant="outlined"
              >
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Amount"
            fullWidth
            variant="outlined"
            value={amountInLakhs}
            onKeyPress={(event) => {
              const charCode = event.charCode;
              if (!(charCode >= 48 && charCode <= 57)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => setAmountInLakhs(e.target.value)}
          />
        </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Accommodation</Typography>
              
            <FormControlLabel
              control={
                <Checkbox
                  checked={accommodationProvided}
                  onChange={(e) => setAccommodationProvided(e.target.checked)}
                />
              }
              label="Accommodation Provided"
            />

          </Grid>

      
          {accommodationProvided && (
          <Grid container spacing={2} fullWidth xs={12}>  
           <Grid my={2} item xs={12} md={6}>
             <FormControl fullWidth>
               <InputLabel id="accommodation-furniture-label">Furniture</InputLabel>
               <Select
                 fullWidth
                 variant="outlined"
                 labelId="accommodation-furniture-label"
                 id="accommodation-furniture"
                 label="Furniture"
                 value={accommodationFurniture}
                 onChange={(e) => setAccommodationFurniture(e.target.value)}
               >
                 <MenuItem value="Furnished">Furnished</MenuItem>
                 <MenuItem value="Semi-Furnished">Semi-Furnished</MenuItem>
                 <MenuItem value="No Furniture">No Furniture</MenuItem>
               </Select>
             </FormControl>
           </Grid>
           <Grid my={2} item xs={12} md={6}>
             <FormControl fullWidth>
               <InputLabel id="accommodation-family-label">Family</InputLabel>
               <Select
                 fullWidth
                 variant="outlined"
                 labelId="accommodation-family-label"
                 id="accommodation-family"
                 label="Family"
                 value={accommodationFamily}
                 onChange={(e) => setAccommodationFamily(e.target.value)}
               >
                 <MenuItem value="Bachelor">Bachelor</MenuItem>
                 <MenuItem value="Family">Family</MenuItem>
               </Select>
             </FormControl>
           </Grid>
          </Grid> )}

          <Grid item xs={12}>
            <TextField
              label="Perks"
              fullWidth
              multiline
              variant="outlined"
              value={perks}
              onChange={(e) => setPerks(e.target.value)}
            />
          </Grid>


          <Grid flex mx="auto" mt="4" item xs={6} md={6}>
            <Button
                variant="contained"
                fullWidth
                sx={{
                  color: "rgb(3, 22, 48)",
                  borderRadius: "30px",
                  backgroundColor: "rgb(0, 184, 230)",
                  padding: "2px 30px",
                  fontWeight: "600",
                  fontSize: "15px",
                }}
                onClick={handleNextClick}
              >
                NEXT
              </Button>
          </Grid>

          


          </Grid>
        </Container>
      )}

      {currentPage === 2 && (
        <Container my={3}>
        <Grid container spacing={2}>


        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Company</Typography>
        </Grid>

       

      <Grid item xs={12} md={4}>
        <TextField
          label="Designation"
          fullWidth
          variant="outlined"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label="Reporting to"
          fullWidth
          variant="outlined"
          value={reportingTo}
          onChange={(e) => setReportingTo(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          label="Reportee"
          fullWidth
          variant="outlined"
          value={reportee}
          onChange={(e) => setReportee(e.target.value)}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label="Role"
            variant="outlined"
          >
            <MenuItem value="Individual Contributor">Individual Contributor</MenuItem>
            <MenuItem value="Team">Team</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
          <TextField
            fullWidth
            label="Work Location"
            value={workLocation}
            onChange={(e) => setWorkLocation(e.target.value)}
            variant="outlined"
          />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="office-type-label">Office Type</InputLabel>
          <Select
            labelId="office-type-label"
            id="office-type"
            value={officeType}
            onChange={(e) => setOfficeType(e.target.value)}
            label="Office Type"
            variant="outlined"
          >
            <MenuItem value="Head Office">Head Office</MenuItem>
            <MenuItem value="Regional Office">Regional Office</MenuItem>
            <MenuItem value="Zonal Office">Zonal Office</MenuItem>
            <MenuItem value="On Site">On Site</MenuItem>
            <MenuItem value="Manufacturing Plant">Manufacturing Plant</MenuItem>
            <MenuItem value="Remote location">Remote location</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Notice period ( in days )"
          fullWidth
          variant="outlined"
          value={noticePeriod}
          onKeyPress={(event) => {
            const charCode = event.charCode;
            if (!(charCode >= 48 && charCode <= 57)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setNoticePeriod(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Days Negotiable upto"
          fullWidth
          variant="outlined"
          value={daysNegotiable}
          onKeyPress={(event) => {
            const charCode = event.charCode;
            if (!(charCode >= 48 && charCode <= 57)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setDaysNegotiable(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Brief Role"
          multiline
          fullWidth
          rows={5}
          value={briefRole}
          onChange={(e) => setBriefRole(e.target.value)}
        />
      </Grid>

<Grid container justifyContent="center" ml={0.5} spacing={2}>
  {kraList.map((kraItem, index) => (
    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ mx: 'auto', p: 2, my: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="KRA"
              fullWidth
              value={kraItem.kra}
              onChange={(e) => handleInputChange(index, 'kra', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Achievement"
              fullWidth
              value={kraItem.achievement}
              onChange={(e) => handleInputChange(index, 'achievement', e.target.value)}
            />
          </Grid>
          {index !== 0 && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleRemoveKra(index)}
                startIcon={<DeleteIcon />}
              >
                Remove KRA
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  ))}
  <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
    <Button variant="outlined" color="primary" onClick={handleAddKra}>
      + Add Key Responsibility Area (KRA)
    </Button>
  </Grid>
</Grid>


    <Grid my={2} xs={12} >
    <Typography variant="h6" gutterBottom>
      File Upload Section:
    </Typography>
    </Grid>

      <Grid xs={12} fullWdith mb={2}>
        <FormControl fullWidth>
          <InputLabel>Select File Name</InputLabel>
          <Select
          value={selectedFileName}
          label="Select File Name"
          onChange={(e) => setSelectedFileName(e.target.value)}
        >
            <MenuItem value="Offer Letter with CTC Breakup">Offer Letter with CTC Breakup</MenuItem>
            <MenuItem value="Appointment Letter with CTC breakup">Appointment Letter with CTC breakup</MenuItem>
            <MenuItem value="Latest 3 Months Salary Slips">Latest 3 Months Salary Slips</MenuItem>
            <MenuItem value="Increment Letter">Increment Letter</MenuItem>
            <MenuItem value="Promotion Letter">Promotion Letter</MenuItem>
            <MenuItem value="Resignation Letter">Resignation Letter</MenuItem>
            <MenuItem value="Resignation Acceptance Letter">Resignation Acceptance Letter</MenuItem>
            <MenuItem value="Reliving Letter">Reliving Letter</MenuItem>
            <MenuItem value="Experience Letter">Experience Letter</MenuItem>
            <MenuItem value="Achievements Rewards Certification Document">Achievements / Rewards Certification Document</MenuItem>
            <MenuItem value="Others">Others </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag & drop files here, or click to select files</p>
      </div>

      {selectedFile && (
        <Typography variant="subtitle1" gutterBottom>
          Selected File: {selectedFile.name}
        </Typography>
      )}

      <Grid mt={1} mb={2} xs={8} mx="auto">
        <Button fullWidth variant="contained" color="primary" onClick={handleUpload}>
        + Add File
        </Button>
      </Grid>

      {uploadedFiles.length > 0 && (
        <div>
          <Typography variant="h5" gutterBottom>
            Uploaded Files
          </Typography>
          {uploadedFiles.map((file, index) => (
            <div key={index} style={fileContainerStyles}>
              <Typography variant="body1">{file.fileName}</Typography>
              <Typography variant="body2">{file.file.name}</Typography>
              <Button variant="outlined" color="error" onClick={() => handleRemove(index)}>
                Remove
              </Button>
            </div>
          ))}
        </div>
      )}

        <Grid mx="auto" container >

          <Grid mx="auto" my={4} px={1} item xs={6}>
              <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    color: "rgb(3, 22, 48)",
                    borderRadius: "30px",
                    backgroundColor: "rgb(0, 184, 230)",
                    padding: "2px 30px",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                  onClick={handleBackClick}
                >
                  Back
              </Button>
          </Grid>
            

          <Grid mx="auto" my={4} px={1} item xs={6}>
            <Button
                variant="contained"
                fullWidth
                style={{
                    color: "rgb(3, 22, 48)",
                    borderRadius: "30px",
                    backgroundColor: "rgb(0, 184, 230)",
                    padding: "2px 30px",
                    fontWeight: "600",
                    fontSize: "15px",
                }}
                onClick={handleSaveClick}
            >
                SAVE
            </Button>
          </Grid>
            
        </Grid>


        </Grid>
        </Container>
      )}

    </Container>
    </>
  );
}

