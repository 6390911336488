import React, { useState, useEffect } from "react";
import { Container, Box, Typography, IconButton, Button } from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import { useHistory } from "react-router-dom";
import EducationIcon from "../../../assets/Icons/education.png";
import TrashIcon from "../../../assets/Icons/delete.png";
import EditIcon from "../../../assets/Icons/Edit.png";
import CustomButton from "../../../components/Button/CustomButtom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder"
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import Sidebar from "../../../components/Sidebar";

const EducationViewProfile = () => {
  const history = useHistory();
  const [educationalDetails, setEducationalDetails] = useState([]); // Initialized as an empty array
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false); 
  const [selectedId, setSelectedId] = useState(null);

  const refetchData = () => {
    instance
      .get("educational-details/")
      .then((response) => {
        setEducationalDetails(response.data.data);
        setError(""); // Clear any previous errors
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data. Please try again.");
        setEducationalDetails([]); // Ensure it's an array even if there's an error
      });
  };

  const handleEditButton = (id) => {
    history.push(`/edit-education-detail/${id}`);
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setOpenDialog(true); 
  };

  const handleDelete = () => {
    instance
      .delete(`educational-details/${selectedId}/`)
      .then(() => {
        refetchData(); 
        setOpenDialog(false); 
      })
      .catch((error) => {
        console.error("Error deleting education:", error);
        setError("Error deleting education. Please try again.");
        setOpenDialog(false);
      });
  };

  useEffect(() => {
    refetchData(); 
  }, []);

console.log("Edu",educationalDetails)
  return (
    <>
          <Sidebar />
    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false}  />
      {error && <Typography color="error">{error}</Typography>}

      {Array.isArray(educationalDetails) && educationalDetails.length === 0 ? (
        <>
      <Typography variant="h6" sx={{ fontWeight: "600" }} mt={2}>Education Details</Typography>
      <AddSectionPlaceholder
        message="education"
        buttonText="+ Add Education"
        link="/edit-education-details"
        onClick={() => history.push("/edit-education-details")}
      />
        </>
      ) : (
        <Box>
       <Typography variant="h6" sx={{ fontWeight: "600" }} mt={2}>Education Details</Typography>
          {educationalDetails.map((education) => (
            <Box key={education.id} mt={3} p={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  borderBottom: "1px solid rgba(201, 201, 201, 0.5)",
                  paddingBottom: "8px",
                }}>
                <IconButton>
                  <img
                    src={EducationIcon}
                    style={{ width: "26px", height: "26px", marginRight: "7px" }}
                    alt="Education Icon"
                  />
                </IconButton>
                <Typography
                  sx={{ fontSize: "16px !important", fontWeight: "bold" }}
                  color="#262262">
                  {education.type}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography
                  ml={1}
                  sx={{
                    fontSize: "16px !important",
                    fontWeight: "bold",
                    color: "#262262",
                  }}>
                  {education.degree}
                </Typography>
                <IconButton>
                  <img
                    src={EditIcon}
                    style={{ width: "20px", height: "20px", marginRight: "7px" }}
                    onClick={() => handleEditButton(education.id)}
                    alt="Edit Icon"
                  />
                  <img
                    src={TrashIcon}
                    style={{ width: "20px", height: "20px", marginRight: "7px" }}
                    onClick={() => handleDeleteClick(education.id)} // Open delete confirmation dialog
                    alt="Delete Icon"
                  />
                </IconButton>
              </Box>

              <Box display="flex" alignItems="center" mb={0.8}>
                <Typography ml={1} sx={{ fontSize: "12px", color: "rgba(82, 75, 107, 0.5)" }}>
                  {education.institute_name}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mb={0.8} ml={1}>
                <Typography sx={{ fontSize: "12px", color: "rgba(82, 75, 107, 0.5)" }}>
                  {`${new Date(education.start_date).toLocaleDateString("en-US", {
                    month: "short",
                    year: "numeric",
                  })} - ${new Date(education.end_date).toLocaleDateString("en-US", {
                    month: "short",
                    year: "numeric",
                  })}`}
                </Typography>
              </Box>

            </Box>
          ))}
                        <Button
        onClick={(() => history.push('/edit-education-details'))}
        mt={2}
        sx={{ border: "1px solid #666671", textTransform: "none", color: "#666671", fontSize: "24px" }}
        fullWidth
      >
        + Add Education Details
      </Button>
        </Box>
      )}
     <ConfirmDeleteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onDelete={handleDelete} 
        title={"Education Detail"}
      />
    </Container>
    </>
  );
};

export default EducationViewProfile;
