import React from 'react'
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import NavBar from '../../components/NavBar';


const buttons = [
    {
        label: "View / Edit Profile",
        path: "/recruiter-profile",
    },
    {
        label: "Add New User",
        path: "/add-new-user",
    },
    {
        label: "View Active Users",
        path: "/view-users",
    },
]

export default function HmMenu() {
  return (
    <Container>
    <NavBar />
    <Container maxWidth= "md" style={{ marginTop: '80px' }}>
        {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}
    </Container>
    </Container>
  )
}
