import React from 'react'
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import NavBar from '../../components/NavBar'
import Layout from '../../components/Layout'


const buttons = [
  {
      label: "HomePage",
      path: "/agency-menu",
  },
  {
      label: "View Profile",
      path: "/viewpage",
  },
  {
      label: "Edit Profile",
      path: "/verification",
  },
]

export default function RoleAgency() {
  return (
    <>
    <NavBar />
    <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
    {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}
    </Container>
    </>
  )
}

