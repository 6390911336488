import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Container, Grid, Snackbar, Modal, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import NavBar from '../../components/NavBar';

const UploadMultipleResumes = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDrop = (acceptedFiles) => {
    setUploadedFiles(acceptedFiles);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleUploadResumes = () => {
    // Simulating uploading resumes
    setTimeout(() => {
      setShowAlert(true);
    }, 2000);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: 'application/pdf',
    maxSize: 1048576, // 1MB in bytes
    maxFiles: 10
  });

  const rows = [
    { id: 1, request_id: '2398923823', created_on: '2024-07-05T10:30:00Z', status: 'Completed', report: 'Link to Report' },
    { id: 2, request_id: '9823989238', created_on: '2024-07-05T11:45:00Z', status: 'Completed', report: 'Link to Report' },
    { id: 3, request_id: '9237923929', created_on: '2024-07-05T13:00:00Z', status: 'Completed', report: 'Link to Report' },
    { id: 4, request_id: '7236829379', created_on: '2024-07-05T14:15:00Z', status: 'Pending', report: 'Link to Report' },
    { id: 5, request_id: '1272893729', created_on: '2024-07-05T15:30:00Z', status: 'Pending', report: 'Link to Report' },
];

  const columns = [
    { field: 'request_id', headerName: 'Request ID', width: 150 },
    { field: 'created_on', headerName: 'Created On', width: 200 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDownloadCSV(params.row)}
        >
          Download Report
        </Button>
      )
    }
  ];

  const handleDownloadCSV = (rowData) => {
    const resumes = ['Atharva Utekar', 'Ram Charan', 'Shivam Pandey'];
    const jobDescriptions = ['HR Executive', 'React JS Intern', 'PHP Developer', 'AWS Architect'];

    const csvData = [];
    let index = 0;

    for (let resume of resumes) {
      for (let job of jobDescriptions) {
        index++;
        const candidateName = resume.toLowerCase().replace(/\s+/g, '');
        const candidateEmail = `${candidateName}@example.com`;
        const jdId = job;
        const idFileUrl = `http://example.com/${candidateName}-resume.pdf`;
        const matchingPercentage = Math.floor(Math.random() * 100);
        const keywords = getRandomKeywords();

        csvData.push({
          id: index,
          candidate_iveri_id: candidateName,
          candidate_email: candidateEmail,
          jd_id: jdId,
          id_file_url: idFileUrl,
          matching_percentage: matchingPercentage,
          keywords: keywords.join(', ')
        });

        if (index >= 12) break;
      }
      if (index >= 12) break;
    }

    const headers = Object.keys(csvData[0]).join(',');
    const csvContent = `${headers}\n${csvData.map(row => Object.values(row).join(',')).join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `resumes_report_${rowData.request_id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getRandomKeywords = () => {
    const keywords = ['React', 'JavaScript', 'Node.js', 'HTML', 'CSS', 'MongoDB', 'REST API', 'AWS', 'Bootstrap', 'SQL'];
    const randomKeywords = [];
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * keywords.length);
      randomKeywords.push(keywords[randomIndex]);
    }
    return randomKeywords;
  };

  return (
    <React.Fragment>
    <NavBar/>

    <Container maxWidth="md" style={{ marginTop: '80px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} px={4}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            autoHeight
            disableSelectionOnClick
            getRowId={(row) => row.id}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowModal(true)}
            style={{ marginBottom: '20px' }}
          >
            Add New Request
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          Request submitted successfully!
        </Alert>
      </Snackbar>
      <Modal
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Resumes
          </Typography>
          <div {...getRootProps()} style={{ border: '2px dashed #aaa', padding: '20px', textAlign: 'center', marginTop: '20px' }}>
            <input {...getInputProps()} />
            {
              uploadedFiles.length > 0 ?
                <List>
                  {uploadedFiles.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={file.name} />
                    </ListItem>
                  ))}
                </List> :
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag 'n' drop some files here, or click to select files</p>
            }
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowModal(false);
              handleUploadResumes();
            }}
            style={{ marginTop: '20px' }}
          >
            Submit Resumes
          </Button>
        </Box>
      </Modal>
    </Container>
    </React.Fragment>
  );
};

export default UploadMultipleResumes;
