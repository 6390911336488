import React, { useState, useEffect } from 'react';
import { TextField, Grid, Container } from '@mui/material';
import NavBar from '../../components/NavBar';
import instance from '../../globals/axios';


const UserData = () => {
  const iveri_id = localStorage.getItem('iveri_id');
  const [userData, setUserData] = useState({
    "is_active": false,
    "user": {
      "iveri_id": "",
      "email": "",
      "first_name": "",
      "last_name": "",
      "role": null,
      "phone_number": "",
      "role_name": "",
      "id": null
    }
  });

  useEffect(() => {
    const url = `user/user/${iveri_id}/`;

    instance.get(url)
      .then(response => {
        setUserData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the user data:', error);
      });
  }, []); 

  
  const handleFieldChange = (field, value) => {
    setUserData({
      ...userData,
      user: {
        ...userData.user,
        [field]: value
      }
    });
  };

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
  
    // Assuming the country code is always of 2 or 3 digits, adjust as needed
    const countryCodeLength = 2
    const countryCode = phoneNumber.substring(0, countryCodeLength);
    const subscriberNumber = phoneNumber.substring(countryCodeLength);
  
    return `+${countryCode} ${subscriberNumber}`;
  }

  return (
  
    <Container maxWidth="md" sx={{ marginTop: "100px"}}>
    <NavBar />
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled
          label="First Name"
          variant="outlined"
          value={userData.user.first_name}
          onChange={(e) => handleFieldChange('first_name', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled
          label="Last Name"
          variant="outlined"
          value={userData.user.last_name}
          onChange={(e) => handleFieldChange('last_name', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={userData.user.email}
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Iveri Id"
          variant="outlined"
          value={userData.user.iveri_id}
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled
          label="Phone Number"
          variant="outlined"
          value={formatPhoneNumber(userData.user.phone_number)}
          onChange={(e) => handleFieldChange('phone_number', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Role"
          variant="outlined"
          value={userData.user.role_name}
          disabled
        />
      </Grid>
    </Grid>
    </Container>
  );
};

export default UserData;
