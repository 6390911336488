import React from "react";
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";



const buttons = [
    {
        label: "View / Edit Own Profile",
        path: "/hr-profile",
    },
    {
        label: "View Active Requirements",
        path: "/hr-view-requirements",
    },
    {
        label: "View Closed Requirements",
        path: "/view-closed-requirements",
    },
    // {
    //     label: "Approve Requirements",
    //     path: "/hr-req-approval",
    // },
    // {
    //     label: "Update Requirements",
    //     path: "/update-requirements",
    // },
    // {
    //     label: "Publish Requirement",
    //     path: "/publish-requirements",
    // },
    {
        label: "View My Applicant",
        path: "/view-applications",
    },

    
]

export default function HRHomepage() {

    return (
        <>
            <NavBar />
            <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
                {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}

            </Container>
        </>

    );
};