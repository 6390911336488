import api from "../globals/axios";
import {
  SUBSCRIPTION_CREATE_ORDER,
  SUBSCRIPTION_CAPTURE_PAYMENT,
  SUBSCRIPTION_GET_ACTIVE,
  SUBSCRIPTION_GET_PLANS,
} from "../globals/endpoints";

export async function createOrder(plan) {
  const response = await api.post(SUBSCRIPTION_CREATE_ORDER, {
    plan: plan,
  });
  return response.data;
}

export async function capturePayment(orderId, paymentId, signature) {
  const response = await api.post(SUBSCRIPTION_CAPTURE_PAYMENT, {
    order_id: orderId,
    payment_id: paymentId,
    signature,
  });
  return response.status === 200;
}

export async function getActiveSubscription() {
  const response = await api.get(`${SUBSCRIPTION_GET_ACTIVE}`);
  return response.data;
}

export async function getAvailablePlans() {
  const response = await api.get(`${SUBSCRIPTION_GET_PLANS}`);
  return response.data;
}