import React, { useState, useEffect } from "react";
import { Container, Box, Typography, IconButton, Button } from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import { useHistory } from "react-router-dom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import TrashIcon from "../../../assets/Icons/delete.png";
import EditIcon from "../../../assets/Icons/Edit.png";
import DoneIcon from "@mui/icons-material/Done";
import DocumentBox from "../../../components/DocumentBox";
import Sidebar from "../../../components/Sidebar";

const SkillView = () => {
  const history = useHistory();
  const [skills, setSkills] = useState([]);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [skillToDelete, setSkillToDelete] = useState(null);

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = () => {
    instance
      .get("edit-skills/")
      .then((response) => {
        setSkills(response.data);
        setError("");
      })
      .catch((error) => {
        console.error("Error fetching skills data:", error);
        setError("Error fetching skills data. Please try again.");
        setSkills([]);
      });
  };
  const openDocument = (url) => {
    window.open(url, "_blank");
  };

  const handleEditButton = (id) => {
    history.push(`/edit-skill/${id}`);
  };

  const handleDeleteClick = (skill) => {
    setSkillToDelete(skill);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    instance
      .delete(`edit-skills/${skillToDelete.id}/`) 
      .then(() => {
        fetchSkills();
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error("Error deleting skill:", error);
        setError("Error deleting skill. Please try again.");
        setOpenDialog(false);
      });
  };

  return (
    <>
    
    <Sidebar />
    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false}  />
      {error && <Typography color="error">{error}</Typography>}

      {/* Skills Section */}
      <Box display="flex" alignItems="center" pb={1} mt={2}>
        <Typography variant="h6" sx={{ fontWeight: "600", fontSize: "22px" }}>
          Skills
        </Typography>
        <DoneIcon
          sx={{
            color: "white",
            backgroundColor: "#262262",
            fontSize: "15px",
            borderRadius: "100%",
            ml: 1,
          }}
        />
      </Box>

      {skills.length === 0 ? (
        <AddSectionPlaceholder
          message="skill"
          buttonText="+ Add Skill"
          link="/edit-skill"
          onClick={() => history.push("/edit-skills")}
        />
      ) : (
        <Box>
          {skills.map((skill) => (
  <Box key={skill.id} mb={2}>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography sx={{ fontSize: "20px !important", fontWeight: "550" }}>
        {skill.skill}
      </Typography>
      <IconButton>
        <img
          src={EditIcon}
          style={{ width: "20px", height: "20px", marginRight: "7px" }}
          onClick={() => handleEditButton(skill.id)}
          alt="Edit Icon"
        />
        <img
          src={TrashIcon}
          style={{ width: "20px", height: "20px", marginRight: "7px" }}
          onClick={() => handleDeleteClick(skill)}
          alt="Delete Icon"
        />
      </IconButton>
    </Box>
    {skill.file_uploaded && (
      <Box pl={1}>
        <DocumentBox
          fileName={`${skill.skill} Certificate.pdf`}
          documentUrl={skill.file_uploaded}
          onClick={(url) => openDocument(url)}
        />
      </Box>
    )}
  </Box>
))}

          <Button
            onClick={() => history.push("/edit-skills")}
            mt={2}
            sx={{
              border: "1px solid #666671",
              textTransform: "none",
              color: "#666671",
              fontSize: "24px",
            }}
            fullWidth>
            + Add Skills
          </Button>
        </Box>
      )}

      <ConfirmDeleteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onDelete={handleDelete}
        title={"Skill Detail"}
      />
    </Container>
    </>
  );
};

export default SkillView;
