import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import { Grid, TextField, Button, Container } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import instance from '../../globals/axios';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'


const UserData = () => {
    const iveri_id = localStorage.getItem('iveri_id');

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        password: '',
        role: 1,
        creator_role: 8,
        creator_iveri_id: iveri_id
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [phoneNumber, setPhoneNumber] = useState('');
  
    const handlePhoneNumberChange = (formattedValue) => {
        setPhoneNumber(formattedValue);
        setFormData({ ...formData, phone_number: formattedValue }); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Object.values(formData).some(field => field === '')) {
        alert('Please fill in all fields.');
        return;
        }

        console.log(phoneNumber.length)
        if (phoneNumber.length !== 12) {
            alert('Incorrect Phone Number');
            return;
        }

        try {
        const response = await instance.post('/recruiter/recruiter-users/', formData);
          
        if(response.status){
          alert("User Created Succesfully!");
        }
        else{
          alert("User Creation Failed!");
        }

        } catch (error) {
          alert("User Creation Failed!");
        }
    };

  


  return (
  <React.Fragment>
    <NavBar />

    <Container maxWidth="md" sx={{ marginTop: "100px"}}>
    <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField 
              required 
              fullWidth 
              label="First Name" 
              name="first_name" 
              value={formData.first_name} 
              onChange={handleChange} 
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
              required 
              fullWidth 
              label="Last Name" 
              name="last_name" 
              value={formData.last_name} 
              onChange={handleChange} 
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
              required 
              fullWidth 
              label="Email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              type="email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <PhoneInput 
                country={'in'}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputProps={{
                  required: true,
                }}
                inputStyle={{ width: '100%' }}
                containerStyle={{ width: '100%' }}
              />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
              required 
              fullWidth 
              label="Password" 
              name="password" 
              value={formData.password} 
              onChange={handleChange} 
            />
          </Grid>
          
          <Grid item xs={8} mx="auto">
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              onClick={handleSubmit}
              endIcon={<SendIcon />}
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
    </Container>
  </React.Fragment>
  );
};

export default UserData;
