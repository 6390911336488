import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Chip, CircularProgress, Container, Button, Box, TextField, ButtonGroup} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PauseIcon from '@mui/icons-material/Pause';

import ViewReqByReqId from '../../../components/ViewReqByReqId';
import instance from '../../../globals/axios';
import NavBar from '../../../components/NavBar';

const RequirementCard = () => {
  const [requirement, setRequirement] = useState(null);
  const [loading, setLoading] = useState(false);
  const company_id = localStorage.getItem('company_id');
  const [reqId, setReqId] = useState("");

  const handleUpdateStatus = (newStatus) => {
    const updatedRequirementData = { 
      requirement_status: newStatus,
      id: reqId
    };

    instance.put(`company/${company_id}/update_requirement/`, updatedRequirementData)
      .then(response => {
        setRequirement(response.data);
        alert("Action successful")
        setTimeout(() => {
          window.location = '/human-resources'; 
        }, 500); 
      })
      .catch(error => {
        console.error('Error updating requirement status:', error);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }


  return (
    <>
      <NavBar/>
      <Container maxWidth="md" sx={{ marginTop: "110px", p:2}}>


        <ViewReqByReqId setReqId={setReqId} />

        <Grid container spacing={4} mb={6} xs={8} mx='auto' justifyContent="center" alignItems="center">
          <Grid item xs={5}>
              <Button fullWidth variant="contained"  color="error" onClick={() => handleUpdateStatus(3)}>Reject</Button>
              {/* <Button variant="outlined"  onClick={() => handleUpdateStatus(4)}>Hold</Button> */}
          </Grid>
          <Grid item xs={5}>
              <Button variant="contained" fullWidth color="success" onClick={() => handleUpdateStatus(2)}>Approve</Button>
          </Grid>
        </Grid>

      </Container>
    </>
  );
};

export default RequirementCard;


