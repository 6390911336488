import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button, CircularProgress, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import instance from '../globals/axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';


const FileUploader = ({ process }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const onDrop = (acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter(file => file.size <= 1048576 && file.type === 'application/pdf');
    setFiles([...files, ...filteredFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxSize: 1048576,
  });

  const handleProcess = async () => {
    setLoading(true);
    const formData = new FormData();
    files.forEach(file => formData.append('pdf_files', file));

    try {

      let url = process === 'Job Description'
      ? 'resume-matching-system/extract-jd-data/'
      : 'resume-matching-system/extract-resume-data/';

      const res = await instance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setResponse(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFiles([]);
    setResponse(null);
  }, [process]);

  return (
    <Box pt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h5">Extract Data from {process}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', p: 4, textAlign: 'center' }}>
            <input {...getInputProps()} />
            <Typography>Drag & drop some files here, or click to select files (PDF, max 1MB)</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {files.length > 0 && (
            <Box>
              <Typography variant="h6">Uploaded Files:</Typography>
              {files.map((file, index) => (
                <Typography key={index}>{file.name}</Typography>
              ))}
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleProcess} disabled={loading}>
            Extract Data from Files
          </Button>
        </Grid>
        <Grid item xs={12}>
          {loading && <CircularProgress />}
          {response && (
            <Box>
              <Typography variant="h6">Response:</Typography>
              <List>
                {response.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      {file.status ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <ErrorIcon color="error" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.filename}
                      secondary={file.error_message ? `Error: ${file.error_message}` : 'Processed successfully'}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FileUploader;
