import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Container, CircularProgress, IconButton, Modal, Button, Alert, AlertTitle } from '@mui/material';
import { ReactMic } from 'react-mic';
import { useParams } from 'react-router-dom';
import instance from '../../globals/axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const NotificationBox = ({ icon, title, message }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', 
    }}
  >
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: '#fff', 
        padding: 4, 
        borderRadius: 2, 
        boxShadow: 3, 
        textAlign: 'center',
      }}
    >
      <Alert
        icon={icon}
        severity="info"
        sx={{
          fontSize: '1.25rem',
        }}
      >
        <AlertTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          {title}
        </AlertTitle>
        {message}
      </Alert>
    </Container>
  </Box>
);

const InterviewComponent = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showFinishAlert, setShowFinishAlert] = useState(false);
  const [ chatResponse, setChatResponse ] = useState("")
  const [status, setStatus] = useState("scheduled")


  const { interview_id } = useParams();

  const startRecording = () => {
    setIsRecording(true);
    setShowDisclaimer(false); // Hide disclaimer when recording starts
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const fetchInterviewStatus = async () => {
    try {
        const res = await instance.get(`interview-system/interviews/${interview_id}/`);
        setStatus(res.data.status);
        const interview_data = res.data.interview_data
        const assistantMessage = interview_data?.find(msg => msg.role === "assistant");
        console.log(interview_data)
        setChatResponse(assistantMessage?.content)

    } catch (error) {
        setStatus("expired");
        console.error(error);
    }
  };


  useEffect(() => {
    fetchInterviewStatus();
    setShowDisclaimer(true);
  }, [interview_id]);

  const onStop = async (recordedBlob) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', recordedBlob.blob, 'audio.mp3');
    formData.append('interview_id', interview_id);

    try {
      const res = await instance.post('interview-system/talk/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(res.data)
      setChatResponse(res.data.response)
    } catch (error) {
      console.error(error);
      setShowDisclaimer(true); // Show disclaimer again on error
    } finally {
      setLoading(false);
    }
  };

  const handleFinishInterview = () => {
    setShowFinishAlert(true);
    setStatus("attempted");

    setTimeout(() => {
        window.close();
    }, 5000);
};

  const handleAlertClose = () => {
    setShowFinishAlert(false);
    // Update the UI or navigate to a different screen to indicate interview conclusion
  };

  return (
    
    <>
      {/* <NavBar /> */}
    

    {status === "scheduled" && (
      <NotificationBox
        icon={<AccessTimeIcon fontSize="large" />}
        title="There is time left for your interview."
        message="Please make sure you are prepared and ready."
      />
    )}

    {status === "active" && (<>

      <Container maxWidth="md">
        <Box sx={{ p: 4, mt: 10 }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" align="center">Interview System</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <IconButton color="primary" onClick={startRecording} disabled={isRecording}>
                <MicIcon fontSize="large" />
              </IconButton>
              <IconButton color="secondary" onClick={stopRecording} disabled={!isRecording}>
                <StopIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <ReactMic
                record={isRecording}
                className="sound-wave"
                onStop={onStop}
                mimeType="audio/mp3"
                strokeColor="#000000"
              />
              {loading && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
              {chatResponse && (
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <CheckCircleIcon color="success" fontSize="large" />
                  <Typography variant="h6" sx={{ mt: 1 }}>{chatResponse}</Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleFinishInterview}>
                Finish Interview
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Disclaimer Modal */}
      <Modal
        open={showDisclaimer}
        onClose={() => setShowDisclaimer(false)}
        aria-labelledby="disclaimer-modal-title"
        aria-describedby="disclaimer-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}>
      
          <Typography variant="h6" id="disclaimer-modal-title" gutterBottom>
            Disclaimer
          </Typography>
          <Typography variant="body1" id="disclaimer-modal-description" gutterBottom>
            <MicIcon color="primary" fontSize="medium" /> Click on the microphone to start recording.<br />
            <StopIcon color="primary" fontSize="medium" /> Press the pause button to stop and send your response to the interviewer.<br />
            <InfoIcon color="primary" fontSize="medium" /> Please keep responses under 30 seconds.
          </Typography>
        </Box>
      </Modal>

      {/* Finish Interview Alert */}
      <Modal
        open={showFinishAlert}
        onClose={handleAlertClose}
        aria-labelledby="finish-alert-modal-title"
        aria-describedby="finish-alert-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" id="finish-alert-modal-title" gutterBottom>
            Interview Concluded
          </Typography>
          <Typography variant="body1" id="finish-alert-modal-description">
            Interview has ended and submitted successfully.
          </Typography>
        </Box>
      </Modal>
    </>)}

    {status === "expired" && (
      <NotificationBox
        icon={<InfoIcon fontSize="large" />}
        title="Sorry this link has expired."
        message=""
      />
    )}

    {status === "attempted" && (
      <NotificationBox
        icon={<CheckCircleIcon fontSize="large" />}
        title="Thank you for giving the interview."
        message=""
      />
    )}

    </>
  );
};

export default InterviewComponent;
