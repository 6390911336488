import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { MdVisibility } from 'react-icons/md';
import instance from "../../globals/axios.js";
import DetailLabel from "../../components/Timeline/Timelinelabel.js";

const ExperienceTimelineResume = () => {
  const [experiences, setExperiences] = useState([]);
  const [experiencesFile, setExperiencesFile] = useState([]);

  useEffect(() => {
    instance
      .get("experience-details/")
      .then((response) => {
        setExperiences(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    instance
      .get("upload-experience-details/")
      .then((response) => {
        setExperiencesFile(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
      {experiences.map((experience, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: 'black' }} />
            <TimelineConnector sx={{ backgroundColor: "black" }} />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              margin: "2 !important",
              padding: "2 !important",
            }}
          >
            <Paper
              sx={{

                boxShadow: 'none !important',
                border: 'none !important',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${experience.start_date ? new Date(experience.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : "N.A."}`}
                -
                {` ${experience.end_date ? new Date(experience.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : "Till Now"}`}
              </Typography>

              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {experience.company || "N.A."}
              </Typography>
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {experience.designation || "N.A."}
              </Typography>

              <CardContent>
                <DetailLabel label="Company Name" value={`${experience.company || "N.A."}`} />
                <DetailLabel label="Company Description" value={`${experience.description || "N.A."}`} />
                <DetailLabel label="Website" value={`${experience.website || "N.A."}`} />
                <DetailLabel label="Fixed Salary" value={` ${Number(experience.fixed_salary_amount || "N.A.").toLocaleString()} ${experience.fixed_salary_currency || "N.A."}`} />
                <DetailLabel label="Variable Salary" value={`${Number(experience.variable_salary_amount || "N.A").toLocaleString()} ${experience.variable_salary_currency || "N.A."}`} />
                <DetailLabel label="Total Salary"value={`${(Number(experience.fixed_salary_amount || 0) + Number(experience.variable_salary_amount || 0)).toLocaleString()} ${experience.fixed_salary_currency || "N.A."} `}/>
                <DetailLabel label="Other Benefits" value={experience.perks || "N.A."} />
                <DetailLabel label="Accommodation" value={`${experience.accommodation_furniture || "N.A."} , ${experience.accommodation_family || "N.A."}`} />
                <DetailLabel label="Expected Salary" value={` ${Number(experience.expected_salary|| "N.A.").toLocaleString()} ${experience.currency || "N.A."}`} />
                <DetailLabel label="Notice Period" value={`${experience.notice_period || "N.A."}`} />
                <DetailLabel label="Notice Period negotiable days " value={`${experience.days_negotiable || "N.A."} days`} />
                <DetailLabel label="Reporting To" value={experience.reporting_to || "N.A."} />
                <DetailLabel label="Reportee" value={experience.reportee || "N.A."} />
                <DetailLabel label="Role" value={experience.role || "N.A."} />
                <DetailLabel label="Brief Role" value={experience.brief_role || "N.A."} />
                <DetailLabel label="Work Location" value={experience.work_location || "N.A."} />
                <DetailLabel label="Office Type" value={experience.office_type || "N.A."} />
                
                {experience.kra && experience.kra.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    {experience.kra.map((kraItem, kraIndex) => (
                      <Box key={kraIndex} sx={{ mb: 1 }}>
                        <Typography sx={{ fontWeight: 'bold !important', color: "#262262" }}>
                          KRA: 
                          <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
                            {` - ${kraItem.kra}`}
                          </Typography>
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold !important', color: "#262262" }}>
                          Achievement:
                          <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
                            {` - ${kraItem.achievement}`}
                          </Typography>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}

    
              </CardContent>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ExperienceTimelineResume;
