import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, Container, Typography, TextField, Select, Input, MenuItem, Grid, Box, InputLabel, FormControl } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar.js';

import NavBar from "../../../components/NavBar";

import instance from "../../../globals/axios.js";


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  icon: {
    fontSize: '1.2rem',
  },
}));

const TimelineComponent = () => {
  const classes = useStyles();

  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    instance.get('timeline-data/')  
      .then(response => {
        setTimelineData(response.data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  return (

    <>
    
   
    <Sidebar />
    <Container maxWidth="xs" style={{ marginTop: "80px" }}>
   
    <Timeline
  sx={{
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0,
    },
  }}
>
  {timelineData.map((item, index) => (
    <RouterLink
      key={index}
      to={
        item.type === 'Education'
          ? `/view-education`
          : item.type === 'Experience'
          ? `/view-experience`
          : `/view-gap`
      }
      style={{ textDecoration: 'none' }}
    >
      {index === 0 || item.data.company !== timelineData[index - 1].data.company ? (
        // Only render if it's the first entry or the company is different from the previous entry
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              {item.type === 'Education' ? <SchoolIcon /> : item.type === 'Experience' ? <WorkIcon /> : <AccessTimeIcon />}
            </TimelineDot>
            {index < timelineData.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" component="h1">
                {item.type === 'Education' ? 'Education' : item.type === 'Experience' ? 'Experience' : 'Gap'}
              </Typography>
              <Typography>
                {`${new Date(item.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(item.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>
              {item.type === 'Education' && (
                <div>
                  <Typography>Degree: {item.data.degree}</Typography>
                  <Typography>Specialization: {item.data.specialization}</Typography>
                  <Typography>Institute Name: {item.data.institute_name}</Typography>
                </div>
              )}
              {item.type === 'Experience' && (
                <div>
                  <Typography>Company: {item.data.company}</Typography>
                  {timelineData
                    .slice(index)
                    .filter((nextItem) => nextItem.data.company === item.data.company)
                    .map((expItem, expIndex) => (
                      <Typography key={expIndex}>Designation: {expItem.data.designation}</Typography>
                    ))}
                </div>
              )}
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ) : null}
    </RouterLink>
  ))}
</Timeline>


    </Container>
    </>
  );
};

export default TimelineComponent;
