import { Container, Box, IconButton, Avatar, MenuItem, Menu, InputLabel, FormControl, Button, Select } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../iveri.png';
import profile from "../profile.jpg";

const styles = {
  container: {
    minHeight: "70vh",
    padding: "15px",
    border: "5px rgb(5, 32, 71) solid",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  img: {
    width: "7rem",
  },
  nav: {
    minHeight: "10vh",
    display: "flex",
    alignItems: "center",
    marginTop: "5.5rem",
    border: "5px rgb(5, 32, 71) solid",
    padding: "0",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    justifyContent: "space-between",
    paddingRight: "1rem",
  },
  profilePic: {
    width: "3rem",
    height: "3rem",
    marginLeft: "1rem",
    border: "3.5px rgb(5, 32, 71) solid"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  buttonText: {
    fontSize: "0.8rem",
  },
  // select: {
  //   minWidth: '5rem',
  //   color: "rgb(3, 22, 48)",
  //   borderRadius: "30px",
  //   backgroundColor: "rgb(0, 184, 230)",
  //   padding: "4px",
  //   fontWeight: "500",
  //   fontSize: "15px",
  // },
};

function Layout({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const history = useHistory();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const path = event.target.value.toLowerCase().replace(/\s/g, '-');
    history.push(`/${path}`);
  };

  const handlePrevPage = () => {
    history.goBack();
  };

  const handleNextPage = () => {
    history.goForward();
  };

  return (
    <div>
      <Container maxWidth="xs" style={styles.nav}>
        <Box display="flex" alignItems="center">
          <img src={logo} alt="logo" style={styles.img} />
        </Box>
        <Box display="flex" alignItems="center">
          <Box style={styles.buttonContainer}>
            <div style={styles.buttonText}>Back</div>
            <IconButton onClick={handlePrevPage}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box style={styles.buttonContainer}>
            <div style={styles.buttonText}>Next</div>
            <IconButton onClick={handleNextPage}>
              <ArrowForward />
            </IconButton>
          </Box>
          <FormControl sx={{ ml: 2, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Go to</InputLabel>

            <Select
            labelId="demo-simple-select-standard-label"
              value={selectedOption}
              onChange={handleOptionChange}
              displayEmpty
              autoWidth     
              variant="outlined"    
              >
              <MenuItem value="Viewpage">Home page</MenuItem>
              <MenuItem value="Personal-Details">Personal</MenuItem>
              <MenuItem value="education-details">Education</MenuItem>
            </Select>
          </FormControl>
          {/*
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            variant="outlined"
            label="Home page"
          >
            <MenuItem value="Viewpage">Home page</MenuItem>
            <MenuItem value="Personal-Details">Personal</MenuItem>
            <MenuItem value="education-details">Education</MenuItem>
          </Select>
          */}
          <Avatar src={profile} alt="Profile Picture" style={styles.profilePic} />

        </Box>
      </Container>
      <Container maxWidth="xs" style={styles.container}>
        {children}
      </Container>
    </div>
  );
}

export default Layout;
