import React, { useState, useEffect } from "react";
import { Container, Box, Typography, IconButton } from "@mui/material";
import ProfileHeader from "../../../components/ProfileHeader";
import instance from "../../../globals/axios";
import { useHistory } from "react-router-dom";
import AddSectionPlaceholder from "../../../components/Button/AddSectionPlaceholder";
import ConfirmDeleteDialog from "../../../components/Popups/ConfirmDeleteDialog";
import CustomTypography from "../../../components/Textfield/CustomTypography";
import TrashIcon from "../../../assets/Icons/delete.png";
import EditIcon from "../../../assets/Icons/Edit.png";
import DoneIcon from '@mui/icons-material/Done';

const AddressView = () => {
  const history = useHistory();
  const [tempAddressDetails, setTempAddressDetails] = useState(null);
  const [permAddressDetails, setPermAddressDetails] = useState([]);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [addressType, setAddressType] = useState("");

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = () => {
    // Fetch temporary address
    instance
      .get("update-temp-address/")
      .then((response) => {
        console.log("Temporary address response:", response.data);
        // If response contains "detail" with "not found" message, set to null
        if (response.data?.detail?.includes("not found")) {
          setTempAddressDetails(null);
        } else {
          setTempAddressDetails(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching temporary address data:", error);
        setTempAddressDetails(null);
      });

    // Fetch permanent address
    instance
      .get("update-address/")
      .then((response) => {
        setPermAddressDetails(Array.isArray(response.data) ? response.data : []);
      })
      .catch((error) => {
        console.error("Error fetching permanent address data:", error);
        setPermAddressDetails([]);
      });
  };

  const handleEditButton = (id, type) => {
    if (type === "temp") {
      history.push(`/edit-temp-address/${id}`);
    } else if (type === "perm") {
      history.push(`/edit-address/${id}`);
    }
  };

  const handleDeleteClick = (address, type) => {
    setAddressToDelete(address);
    setAddressType(type);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    const endpoint = addressType === "temp" ? "update-temp-address/" : `update-address/${addressToDelete.id}/`;
    instance
      .delete(endpoint, { data: addressToDelete })
      .then(() => {
        fetchAddresses();
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error("Error deleting address:", error);
        setError("Error deleting address. Please try again.");
        setOpenDialog(false);
      });
  };

  console.log("Current tempAddressDetails:", tempAddressDetails);

  return (
    <Container maxWidth="xs" style={{ marginTop: "30px" }}>
      <ProfileHeader showUploadIcon={false} />
      
      <Box display="flex" alignItems="center" pb={1} mt={2}>
        <Typography variant="h6" sx={{ fontWeight: "600", fontSize: "22px" }}>Address</Typography>
        <DoneIcon sx={{ color: 'white', backgroundColor: "#262262", fontSize: "15px", borderRadius: "100%", ml: 1 }} />
      </Box>

      {/* Temporary Address Section */}
      <Box mb={3}>
        <Typography variant="h6" sx={{ mb: 2 }}>Temporary Address</Typography>
        
        {tempAddressDetails === null ? (
          // This is the "empty state" that should show the add button
          <Box>
            <AddSectionPlaceholder
              message="temporary address"
              buttonText="+ Add Temporary Address"
              link="/edit-temp-address"
              onClick={() => history.push("/edit-temp-address")}
            />
          </Box>
        ) : (
          // This shows the existing address details
          <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography sx={{ fontSize: "20px !important", fontWeight: "550" }}>
                Temporary Address
              </Typography>
              <Box>
                <IconButton onClick={() => handleEditButton(tempAddressDetails.id, "temp")}>
                  <img src={EditIcon} alt="Edit" style={{ width: "20px", height: "20px" }} />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(tempAddressDetails, "temp")}>
                  <img src={TrashIcon} alt="Delete" style={{ width: "20px", height: "20px" }} />
                </IconButton>
              </Box>
            </Box>
            <Box pl={1}>
              {Object.entries(tempAddressDetails).map(([key, value]) => {
                if (key === "id" || key === "detail") return null;
                return (
                  <Box display="flex" alignItems="center" mt={1} mb={0.8} key={key}>
                    <CustomTypography>{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}</CustomTypography>
                    <Typography>{value || "N/A"}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>

      {/* Permanent Address Section */}
      <Box>
        {permAddressDetails.length === 0 ? (
          <AddSectionPlaceholder
            message="permanent address"
            buttonText="+ Add Permanent Address"
            link="/edit-address"
            onClick={() => history.push("/edit-address")}
          />
        ) : (
          permAddressDetails.map((address) => (
            <Box key={address.id}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography sx={{ fontSize: "20px !important", fontWeight: "550" }}>
                  Permanent Address
                </Typography>
                <Box>
                  <IconButton onClick={() => handleEditButton(address.id, "perm")}>
                    <img src={EditIcon} alt="Edit" style={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(address, "perm")}>
                    <img src={TrashIcon} alt="Delete" style={{ width: "20px", height: "20px" }} />
                  </IconButton>
                </Box>
              </Box>
              <Box pl={1}>
                {Object.entries(address).map(([key, value]) => {
                  if (key === "id" || key === "verifications" || key === "user") return null;
                  return (
                    <Box display="flex" alignItems="center" mt={1} mb={0.8} key={key}>
                      <CustomTypography>{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}</CustomTypography>
                      <Typography>{value || "N/A"}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ))
        )}
      </Box>

      <ConfirmDeleteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        onDelete={handleDelete}
        title="Address Detail"
      />
    </Container>
  );
};

export default AddressView;