import {React, useState} from 'react'
import NavBar from '../../../components/NavBar'
import {   Button,
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faLink } from "@fortawesome/free-solid-svg-icons";
import instance from "../../../globals/axios";


export default function HRNewRequirement() {
    const [currentPage, setCurrentPage] = useState(1);
    ///////////

    const [HrSpoc,setHrSpoc] = useState('')
    const [reqRaisedDate, setReqtRaisedDate] = useState(null);
    const [position, setPosition] = useState("");
    const [jobLoc, setJobLoc] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [closeDate, setCloseDate] = useState(null);
    const [levelPos, setLevelPos] = useState("");
    const [deparment, setDepartment] = useState("");
    const [jobBrief, setJobBrief] = useState("");
    const [roleResponsibilty, setroleResponsibilty] = useState("");
    const [reporting, setReporting] = useState("");
    const [reportees, setReportees] = useState("");
    const [typeRole, setTypeRole] = useState("");
    const [internalInterface, setInternalInterface] = useState("");
    const [externalInterface, setExternalInterface] = useState("");
    const [requirementId, setrequirementId] = useState("");
    const [sbuName, setSbuName] = useState("");
    const [profileTitle,  setProfileTitle] = useState("");
    const [designation, setDesgination] = useState("");
    const [iveriId, setIveriID] = useState("");
    const [statusReqRaised, setStatusReqRaised] = useState("");

 
    const [peopleList, setPeopleList] = useState([]);
    const [inputMode, setInputMode] = useState('name');
    const handleSaveClick = () => {
      const formData = new FormData();
      formData.append("hr-spoc", HrSpoc);
      formData.append('requirement-raised', reqRaisedDate.toISOString()); // Adjust date format as needed
      formData.append("no_position", position);
      formData.append("job-loc", jobLoc);
      formData.append("start-date", startDate.toISOString());
      formData.append("close-date", closeDate.toISOString());
      formData.append("level-pos", levelPos);
      formData.append("department", deparment);
      formData.append("job-brief", jobBrief);
      formData.append("role-responsibility", roleResponsibilty);
      formData.append("reporting", reporting);
      formData.append("reportees", reporting);
      formData.append("type-of-role", typeRole);
      formData.append("internal-interface", internalInterface);
      formData.append("external-interface", externalInterface);
      formData.append("requirement-id", requirementId)
      formData.append("sbu-name", sbuName)
      formData.append("project-title", profileTitle)
      formData.append("designation", designation)
      formData.append("iveri-id", iveriId)
      formData.append("status-required-raised", statusReqRaised);
    }

    const handleAddPerson = () => {
        if (reporting.trim() === '') return;
    
        const newPerson = inputMode === 'name'
          ? { type: 'name', value: reporting }
          : { type: 'link', value: reporting };
    
        setPeopleList([...peopleList, newPerson]);
        setReporting('');
        setInputMode('name');
      };
    
      const toggleInputMode = () => {
        setInputMode(inputMode === 'name' ? 'link' : 'name');
      };

      const options = [
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Mumbai', label: 'Mumbai' },
        { value: 'Banglore', label: 'Banglore' },
        // Add more options as needed
      ];
      const handleJobLocChange = (selectedOption) => {
        setJobLoc(selectedOption);
      };

      const handleNextClick = () => {
        setCurrentPage(currentPage+1)
      };
      const handleBackClick = () => {
        setCurrentPage(currentPage-1)
      };
/////////////table////////
      /* const [tableData, setTableData] = useState([]);
      const handleAddRow = () => {
        if (sbuName.trim() === '' || profileTitle.trim() === '' || designation.trim() === '') {
          return; // Don't add empty rows
        }
    
        const newRow = {
          sbuName: sbuName,
          profileTitle: profileTitle,
          designation: designation,
        };
    
        setTableData([...tableData, newRow]);
    
        // Clear input fields
        setSbuName('');
        setProfileTitle('');
        setDesgination('');
      }; */

      const [tableData, setTableData] = useState([
        { sbuName: '', profileTitle: '', designation: '' },
      ]);
    
      const handleAddRow = () => {
        setTableData([...tableData, { sbuName: '', profileTitle: '', designation: '' }]);
      };

      const handleDeleteRow = () => {
        if (tableData.length > 1) {
          const updatedData = [...tableData];
          updatedData.pop(); // Remove the last row
          setTableData(updatedData);
        }
      };
    
      const handleInputChange = (e, index, field) => {
        const updatedData = [...tableData];
        updatedData[index][field] = e.target.value;
        setTableData(updatedData);
      };
    

  return (
    <>
     <NavBar />
     <Container maxWidth="xs" style={{ marginTop: "80px" }} mb={2}>
     <Grid container alignItems="center" justifyContent="space-between" mb={1.5}>
        <Grid item mb={3}>
          <Typography variant="h6" sx={{ color: "rgb(0, 184, 230)" }}>
          Requirement Requisition
          </Typography>
        </Grid>
        <Grid item>
          {(currentPage === 1) ? (
            <Button
              variant="contained"
              sx={{
                color: "rgb(3, 22, 48)",
                borderRadius: "30px",
                backgroundColor: "rgb(0, 184, 230)",
                padding: "2px 30px",
                fontWeight: "600",
                fontSize: "15px",
              }}
              onClick={handleNextClick}
            >
              NEXT
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                color: "rgb(3, 22, 48)",
                borderRadius: "30px",
                backgroundColor: "rgb(0, 184, 230)",
                padding: "2px 30px",
                fontWeight: "600",
                fontSize: "15px",
              }}
              onClick={handleBackClick}
            >
              Back
            </Button>
          )}
        </Grid>
      </Grid>

      {currentPage === 1 && (
        <Container my={3}>
         <Grid container spacing={2}>
         <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="sbu-name">SBU Name</InputLabel>
            <Select
              fullWidth
              label="SBU-Name"
              id="sbu-name"
              value={sbuName}
              name="Sbu-name-dropdown"
              onChange={(e) => setSbuName(e.target.value)}
            >
            </Select>
          </FormControl>
        </Grid>
            <Grid item xs={12}>
                <TextField fullWidth  value={profileTitle} label="Project Title" id='project-title' onChange={(e) => setProfileTitle(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth  value={designation} label="Designation" id='designation' onChange={(e) => setDesgination(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth  value={iveriId} label="Iveri Id" id='iveri-id' onChange={(e) => setIveriID(e.target.value)} />
            </Grid>
            
            <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="Hr-Spoc-label">HR SPOC</InputLabel>
            <Select
              fullWidth
              label="HR-SPOC"
              id="hr-spoc"
              value={HrSpoc}
              name="hr-spoc-dropdown"
              onChange={(e) => setHrSpoc(e.target.value)}
            >
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Requirement Raised on" value={reqRaisedDate} onChange={(newValue) => setReqtRaisedDate(newValue)} />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="Status of Requirement Raised">Status of Requirement Raised</InputLabel>
            <Select
              fullWidth
              label="Status of Requirement Raised"
              id="status-required-raised"
              value={statusReqRaised}
              name="status-required-raised-dropdown"
              onChange={(e) => setStatusReqRaised(e.target.value)}
            >
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="underApproval">Under approval</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth  value={requirementId} label="Requirement ID" id='requirement-id' onChange={(e) => setrequirementId(e.target.value)} />
        </Grid>
         </Grid>

         {/* <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SBU Name</TableCell>
                    <TableCell>Profile Title</TableCell>
                    <TableCell>Designation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.sbuName}</TableCell>
                      <TableCell>{row.profileTitle}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddRow}
                  fullWidth
                >
                  Add Row
                </Button>
              </Grid> */}
 
 <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SBU Name</TableCell>
              <TableCell>Profile Title</TableCell>
              <TableCell>Designation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    value={row.sbuName}
                    onChange={(e) => handleInputChange(e, index, 'sbuName')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.profileTitle}
                    onChange={(e) => handleInputChange(e, index, 'profileTitle')}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={row.designation}
                    onChange={(e) => handleInputChange(e, index, 'designation')}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" onClick={handleAddRow} fullWidth>
          Add Row
        </Button>
      </Grid>
        {tableData.length > 1 && (
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDeleteRow}
              fullWidth
            >
              Delete Last Row
            </Button>
          </Grid>
        )}
        </Container>
      )}
 
     </Container>
     </>
  )
}