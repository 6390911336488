import React, { useState } from 'react';
import {
  Button, Container, Stepper, Step, StepLabel, Grid, Box, Typography
} from "@mui/material";
import NavBar from '../../components/NavBar';
import ExtractorComponent from '../../components/ExtracterComponent';
import ResumeMatcher from '../../components/ResumeMatcher';


export default function RoleEmployer() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [process, setProcess] = useState("Job Description");
  const steps = [`Extract Data from ${process}`, 'Get Match Results'];

  return (
    <>
      <NavBar />
      <Container maxWidth="md" style={{ marginTop: '80px' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Box textAlign="center" mt={4}>
            <Typography variant="h6">All steps completed</Typography>
            <Button onClick={handleReset} variant="contained" color="primary" sx={{ mt: 2 }}>
              Restart
            </Button>
          </Box>
        ) : (
          <Grid px={4} mb={6} container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              {activeStep === 0 ? <ExtractorComponent process={process} /> : <ResumeMatcher process={process} />}
            </Grid>
            <Grid item xs={12} textAlign="center">
              {
                process ==="Job Description" ? (
                  <Button variant="outlined" color="primary" onClick={() => setProcess("Resume")}>
                    Process Resumes
                  </Button>
                ) : (
                  <Button variant="outlined" color="primary" onClick={() => setProcess("Job Description")}>
                    Process Job Description
                  </Button>
                )
              }
           
              <Button
                variant="outlined"
                color="primary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
