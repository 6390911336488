import React, { useState } from 'react';
import { Grid, Typography, Button, CircularProgress, Alert, Card, CardContent, CardHeader, Container, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import instance from '../../globals/axios';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { green, red } from '@mui/material/colors';
import NavBar from '../../components/NavBar';

const CVpool = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState(null);
  const [validEntries, setValidEntries] = useState(null);


  const onDrop = async (acceptedFiles) => {
    setLoading(true);
    setError('');
    setResponse(null);

    const formData = new FormData();
    acceptedFiles.forEach(file => {
      formData.append('pdf_files', file);
    });

    try {
      const res = await instance.post('process-cv-pool-pdf/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      
      // Preprocess the response only if it's a string
      const preprocessedResponse = typeof res.data === 'string' ? preprocessResponse(res.data) : res.data;
      
      // Parse the string values into JSON objects
      const parsedResponse = {};
      for (const key in preprocessedResponse) {
        if (preprocessedResponse.hasOwnProperty(key)) {
          parsedResponse[key] = JSON.parse(preprocessedResponse[key]);
        }
      }
      setResponse(parsedResponse);

      const valid_entries = Object.entries(parsedResponse).filter(([_, value]) => value.is_valid);
      setValidEntries(valid_entries);

      console.log('Valid Entries:', valid_entries);

    } catch (err) {
      setError('Error uploading files. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const preprocessResponse = (data) => {
    // Remove \n and \t
    data = data.replace(/\\n/g, '').replace(/\\t/g, '');
  
    // Replace \" with "
    data = data.replace(/\\"/g, '"');
  
    // Remove extra "{ and }"
    data = data.replace(/"{/g, '{').replace(/}"/g, '}');
  
    return data;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.pdf',
    maxSize: 5 * 1024 * 1024, // 5MB
    multiple: true
  });

  return (
    <React.Fragment>
        <NavBar/> 
        <Container maxWidth= "xs" style={{ marginTop: '80px' }}>

    <Grid p={4} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Upload PDF Files</Typography>
      </Grid>
      <Grid item xs={12}>
        <div {...getRootProps()} style={{ padding: '20px', textAlign: 'center', border: '2px dashed #aaa', borderRadius: '5px' }}>
          <input {...getInputProps()} />
          <Typography variant="body1">Drag and drop PDF files here, or click to select files</Typography>
        </div>
      </Grid>
      <Grid item mx="auto" xs={9}>
        <Button fullWidth variant="contained" color="primary" onClick={onDrop} disabled={loading}>Upload</Button>
      </Grid>
      {loading && (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography mt={4} textAlign="center" variant="h6">Processing Files...</Typography>
 
            <CircularProgress sx={{ mt:2 }} />
        </Grid>
      )}
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      )}
      {response && (
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h6">Uploaded Files Details:</Typography>
          <Divider sx={{ my:2}} />
          <Grid container spacing={2}>
           
            {Object.keys(response).map((fileName, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ borderRadius:'15px', p:"5px", borderColor: response[fileName].is_valid ? green[500] : red[500] }} variant="outlined">
                  <CardHeader title={fileName} />
                  <CardContent>
                    <Grid container>
                      <Grid item fullWidth>
                      <ListItem>
                        <ListItemIcon>
                          {
                            response[fileName].is_valid ? <TaskAltIcon sx={{ color:green[500] }} /> : <PersonIcon />
                          }
                        </ListItemIcon>
                        <ListItemText primary={response[fileName].name || 'No name found'} />
                      </ListItem> 
                      </Grid>      

                      <Grid item fullWidth>
                      <ListItem>
                        <ListItemIcon>
                          <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary={response[fileName].email || 'No email found'} />
                      </ListItem>
                      </Grid>

                      <Grid item fullWidth>
                      <ListItem>
                        <ListItemIcon>
                          <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${response[fileName].mobile_number_country_code || ''} ${response[fileName].mobile_number || 'No phone number found'}`} />
                      </ListItem>
                      </Grid>

                    </Grid >
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid container>
          <Grid mt={2} item xs={12}>
            <Typography textAlign="center" variant="h5" gutterBottom>
              Valid Extracted Data
            </Typography>
            <Typography textAlign="center" variant="body1" mb={2}>
              Number of valid entries found: {validEntries.length}
            </Typography>
            <Divider my={2} />
            <List>
              {validEntries?.map(([fileName, entry], index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    {entry.is_valid && <TaskAltIcon sx={{ color: green[500] }} />}
                  </ListItemIcon>
                  <ListItemText primary={entry.name} />
                </ListItem>
              ))}
            </List>
            {validEntries && (
              <Grid>
              <Button startIcon={<GroupAddIcon />} variant='outlined' fullWidth>
                Create Accounts
              </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        </Grid>
      )}
    </Grid>
    </Container>
    </React.Fragment>
  );
};

export default CVpool;
