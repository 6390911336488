import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { grey } from "@material-ui/core/colors";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineIcon from '@mui/icons-material/Timeline';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import LogoutIcon from '@mui/icons-material/Logout';
import WidgetsIcon from '@mui/icons-material/Widgets';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SourceIcon from '@mui/icons-material/Source';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentIcon from '@mui/icons-material/Assignment';

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Container, Box, FormControl, InputLabel, MenuItem, Select, Avatar

} from "@mui/material";

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import logo from '../iveri.png';
import profile from "../profile.jpg";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from '@mui/icons-material/Share';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { HOME_PAGE } from "../globals/const";
import Timeline from "../screens/User/EditPages/Timeline";
import { useHistory, Link } from "react-router-dom";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeIcon: {
    position: 'flex',
    marginLeft: '10%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
    backgroundColor: grey[800],
    opacity: "0.5",
  },
}));

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  img: {
    height: 40,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    marginRight: 8,
    marginTop: 2
  },
  profilePic: {
    marginLeft: 16,
  },
  mobileButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileProfilePic: {
    marginTop: 8,
  },
  profilePicContainer: {
    display: "flex",
    alignItems: "center",
    
  },
};

export default function NavBar({ backRoute, nextRoute, showAvatar }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const history = useHistory();

  const userFirstName = localStorage.getItem('first_name');
  const userRole = localStorage.getItem('role');
  const userIveriId = localStorage.getItem('iveri_id');

  const getInitials = (name) => {
    return `${name.charAt(0)}`;
  };


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const path = event.target.value.toLowerCase().replace(/\s/g, '-');
    history.push(`/${path}`);
  };
  
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className={classes.root}>
      
      <AppBar position="fixed" className={classes.appBar}>

        <Toolbar>


          <Box display="flex" alignItems="center" flexGrow={1}>
            { (showAvatar === undefined || showAvatar) && (
              <React.Fragment>

                <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>

                <Link to="/profile-page">
                  <img src={logo} alt="logo" style={styles.img} />
                </Link>
              </React.Fragment>
              )}


              
            <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
            { showAvatar === false && (
                <img src={logo} alt="logo" style={styles.img} />
              )}

            <Box sx={{ ...(open ? styles.mobileButtonContainer : styles.buttonContainer) }}>
                {backRoute && <Link to={backRoute} style={{ textDecoration: 'none', color: 'inherit', marginRight:8, marginTop:2 }} >Back</Link>}
                {nextRoute && <Link to={nextRoute} style={{ textDecoration: 'none', color: 'inherit', marginRight:8, marginTop:2 }} >Next</Link>}
              </Box>

              <Box sx={{ ...styles.buttonContainer }}>
                {backRoute && (
                  <Link to={backRoute}>
                    <IconButton>
                      <ArrowBack />
                    </IconButton>
                  </Link>
                )}
                {nextRoute && (
                  <Link to={nextRoute}>
                    <IconButton>
                      <ArrowForward />
                    </IconButton>
                  </Link>
                )}
              </Box>
            </Box>
          </Box>

          {
          (showAvatar === undefined || showAvatar) &&
          <Box display="flex" alignItems="center">
            <Box
              style={{
                backgroundColor: '#f0f0f0',
                padding: '6px',
                borderRadius: '50%',
                margin: 8
              }}
            >
              <Avatar
                alt="Profile Picture"
                style={{
                  color: "#000",
                  width: '36px',
                  height: '36px',
                  fontSize: '18px',
                }}
              >
                {getInitials(userFirstName)}
              </Avatar>
            </Box>
          </Box>
        }

        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        // variant="persistent"
        anchor="left"
        style={{ zIndex: open ? 1800 : 0 }}  
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle} // Close the drawer on overlay click
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        
      <IconButton
          aria-label="close drawer"
          onClick={handleDrawerClose}
          style={{
            position: 'absolute',
            top: '8px', // Adjust top position as needed
            right: '8px', // Adjust right position as needed
            color: 'black',
            border: '1px solid black',
            borderRadius: '50%',
            backgroundColor: 'white',
          }}
        >
          <CloseIcon fontSize="small" />
      </IconButton>

      <List>
        {userRole === 'User' && (
          <Box>
            <ListItemButton
              onClick={() => {
                window.open(HOME_PAGE + '/edit-profile-menu', '_self');
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Profile" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                window.open(HOME_PAGE + '/view-profile', '_self');
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="View Profile" />
            </ListItemButton>

            <ListItemButton 
                onClick={() => {
                  window.open(HOME_PAGE + '/share-profile', '_self');
                  setOpen(false);
                }}>
              <ListItemIcon>
                <ShareIcon />
              </ListItemIcon>
              <ListItemText primary="Share Profile" />
            </ListItemButton>


            <ListItemButton
              onClick={() => {
                window.open(HOME_PAGE + '/timeline', '_self');
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary="Timeline" />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                window.open(HOME_PAGE + '/resume-parser', '_self');
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText primary="Resume Parser" />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                window.open(HOME_PAGE + '/apply-jobs', '_self');
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="View Jobs" />
            </ListItemButton>

            <ListItemButton 
                onClick={() => {
                  window.open(HOME_PAGE + '/my-applications', '_self');
                  setOpen(false);
                }}>
              <ListItemIcon>
                <SourceIcon />
              </ListItemIcon>
              <ListItemText primary="My Applications" />
            </ListItemButton>


       
 
          </Box>
        )}
        
        {userRole === 'Employer' && (
          <ListItemButton
            onClick={() => {
              window.open(HOME_PAGE + '/role-employer', '_self');
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText primary="Employer" />
          </ListItemButton>
        )}

        {userRole === 'HR' && (
          <React.Fragment>
                  <ListItemButton
                    onClick={() => {
                      window.open(HOME_PAGE + '/human-resources', '_self');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <WidgetsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Main Menu" />
                  </ListItemButton>

                  {/* <ListItemButton
                  onClick={() => {
                    window.open(HOME_PAGE + '/jd-creator', '_self');
                    setOpen(false);
                  }}
                  >
                  <ListItemIcon>
                    <VoiceChatIcon />
                  </ListItemIcon>
                  <ListItemText primary="JD Creator" />
                  </ListItemButton> */}

                  <ListItemButton
                    onClick={() => {
                      window.open(HOME_PAGE + '/resume-matching', '_self');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <AssignmentTurnedInIcon />
                    </ListItemIcon>
                    <ListItemText primary="Resume Matching" />
                  </ListItemButton>

                  
                  {/* <ListItemButton
                    onClick={() => {
                      window.open(HOME_PAGE + '/batch-upload', '_self');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <TextSnippetIcon />
                    </ListItemIcon>
                    <ListItemText primary="Batch Process JDs" />
                  </ListItemButton> */}

                  
                  <ListItemButton
                    onClick={() => {
                      window.open(HOME_PAGE + '/my-uploads', '_self');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <TextSnippetIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Uploads" />
                  </ListItemButton>

                  <ListItemButton
                    onClick={() => {
                      window.open(HOME_PAGE + '/view-interviews', '_self');
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon>
                      <VisibilityIcon />
                    </ListItemIcon>
                    <ListItemText primary="View Interviews" />
                  </ListItemButton>

                  {/* <ListItemButton
                  onClick={() => {
                    window.open(HOME_PAGE + '/cv-pool', '_self');
                    setOpen(false);
                  }}
                  >
                  <ListItemIcon>
                    <VoiceChatIcon />
                  </ListItemIcon>
                  <ListItemText primary="CV Pool " />
                  </ListItemButton>



                  <ListItemButton
                    onClick={() => {
                      window.open(HOME_PAGE + '/view-candidates', '_self');
                      setOpen(false);
                    }}
                    >
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="View All candidates" />
                  </ListItemButton>


                  <ListItemButton
                  onClick={() => {
                    window.open(HOME_PAGE + '/ai-jd-creator', '_self');
                    setOpen(false);
                  }}
                  >
                  <ListItemIcon>
                    <VoiceChatIcon />
                  </ListItemIcon>
                  <ListItemText primary="AI JD Creator" />
                  </ListItemButton>

                  <ListItemButton
                  onClick={() => {
                    window.open(HOME_PAGE + '/jd-parser', '_self');
                    setOpen(false);
                  }}
                  >
                  <ListItemIcon>
                    <TextSnippetIcon />
                  </ListItemIcon>
                  <ListItemText primary="Convert JD to iveri JD" />
                  </ListItemButton> */}


          </React.Fragment>
        )}

        {userRole === 'Hiring Manager' && (
                  <React.Fragment>
                          <ListItemButton
                            onClick={() => {
                              window.open(HOME_PAGE + '/hiring-manager', '_self');
                              setOpen(false);
                            }}
                          >
                            <ListItemIcon>
                              <WidgetsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Main Menu" />
                          </ListItemButton>

                          <ListItemButton
                            onClick={() => {
                              window.open(HOME_PAGE + '/ai-jd-creator', '_self');
                              setOpen(false);
                            }}
                            >
                            <ListItemIcon>
                              <VoiceChatIcon />
                            </ListItemIcon>
                            <ListItemText primary="AI JD Creator" />
                            </ListItemButton>

                            <ListItemButton
                            onClick={() => {
                              window.open(HOME_PAGE + '/jd-parser', '_self');
                              setOpen(false);
                            }}
                            >
                            <ListItemIcon>
                              <TextSnippetIcon />
                            </ListItemIcon>
                            <ListItemText primary="Convert JD to iveri JD" />
                            </ListItemButton>

                            <ListItemButton
                            onClick={() => {
                              window.open(HOME_PAGE + '/cv-pool', '_self');
                              setOpen(false);
                            }}
                            >
                            <ListItemIcon>
                              <VoiceChatIcon />
                            </ListItemIcon>
                            <ListItemText primary="CV Pool " />
                            </ListItemButton>
                        
                  </React.Fragment>
        )}

        {userRole === 'Agency' && ( 
          <ListItemButton
            onClick={() => {
              window.open(HOME_PAGE + '/agency-menu', '_self');
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Home page" />
          </ListItemButton>
        )}

        <ListItemButton
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
        </ListItemButton>

      </List>
       
      </Drawer>

      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleDrawerClose}
      />
    </div>
  );
};