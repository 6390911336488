import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, CardContent, Container, Typography, Chip, Button, Box, IconButton } from '@mui/material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
  } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {
  OpenInNew, Description, AccountCircle, Event, AccountBalance, Person,
  Business, MonetizationOn, LocationOn, EventNote, Check
} from '@mui/icons-material';
import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';
import { Link } from 'react-router-dom';


function EducationalDetailsCards() {
  const [educationalDetails, setEducationalDetails] = useState([]);

  useEffect(() => {
    instance.get('educational-details/')
      .then(response => {
        setEducationalDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedEducationId, setSelectedEducationId] = useState(null);

  const handleDeleteClick = (educationId) => {
    setSelectedEducationId(educationId);
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationYes = () => {
    instance.post('delete-entry/', {
      entry_type: 'education',
      entry_id: selectedEducationId,
    })
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setConfirmationOpen(false);
      });
  };

  return (
    <>
  
    <NavBar />
    <Container maxWidth="xs" sx={{ p:2, marginTop: "3rem" }} >
    <Typography variant="h5" sx={{ my:2 }} textAlign="center">
    My Educational Details
    </Typography>
    
    <Grid container spacing={2}>
        {educationalDetails.map(detail => (
        <Grid item xs={12} md={6} key={detail.id}>
            <Card variant="outlined" sx={{ backgroundColor: '#f5f5f5', padding: '8px', marginBottom: '8px' }}>
            <CardContent>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Type: {detail.type || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Degree: {detail.degree || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Start Date: {detail.start_date ? new Date(detail.start_date).toLocaleDateString() : 'N.A.'} 
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                End Date: {detail.end_date ? new Date(detail.end_date).toLocaleDateString() : 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Specialization: {detail.specialization || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Board: {detail.board || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Institute Name: {detail.institute_name || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Score: {detail.score || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Total Score: {detail.total_score || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Score Unit: {detail.score_unit || 'N.A.'}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Certificates: {detail.certificate_name || 'N.A.'}
                </Typography>
                <Box sx={{ alignItems:"center", justifyContent: 'center' }}>
                <Box sx={{mt:2}}>
                {detail.awards_file && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/null") && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/False") && (
                    <Button
                    fullWidth
                        onClick={() => window.open(detail.awards_file, '_blank')}
                        variant="outlined"
                        startIcon={<OpenInNew />}
                    >
                        Awards Document
                    </Button>
                )}
                </Box>
                <Box sx={{mt:2}}>
                {detail.extra_curricular_file && (detail.extra_curricular_file !== "https://iveri-docs.s3.amazonaws.com/null") && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/False") && (
                    <Button
                    fullWidth
                        variant="outlined"
                        startIcon={<OpenInNew sx={{ marginRight: '4px' }} />}
                        onClick={() => window.open(detail.extra_curricular_file, '_blank')}
                    >
                        Extra Curricular Document
                    </Button>
                )}
                </Box>
                <Box sx={{mt:2, mb:2}}>
                {detail.educational_file && (detail.educational_file !== "https://iveri-docs.s3.amazonaws.com/null") && (detail.awards_file !== "https://iveri-docs.s3.amazonaws.com/False") && (
                    <Button
                    fullWidth
                        variant="outlined"
                        startIcon={<OpenInNew sx={{ marginRight: '4px' }} />}
                        onClick={() => window.open(detail.educational_file, '_blank')}
                    >
                        Educational Document
                    </Button>
                )}
                </Box>
            </Box>
            
                <Typography variant="body1"  sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                    Projects:
                </Typography>

                {detail.projects && detail.projects.map(project => (
                    project.project_title && 
                    <Box>
                    <Card sx={{ p:1, m:1}} elevation={2}>
                        <Typography key={project.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                        Project Title: {project.project_title}
                        </Typography>
                        <Typography key={project.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                        Project Description: {project.project_desc}
                        </Typography>
                        <Typography key={project.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                        Project Link: {project.project_link}
                        </Typography>
                    </Card>
                    </Box>
                ))}
                <Typography variant="body1" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                    Internships:
                </Typography>
                {detail.internships && detail.internships.map(internship => (
                    internship.internship_company && internship.internship_role &&
                    <Box>
                    
                        <Card sx={{ p:1, m:1}} elevation={2}>
                            <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            Internship Company: {internship.internship_company}
                            </Typography>
                            <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            Internship Role: {internship.internship_role}
                            </Typography>
                            <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            From: { new Date(internship.internship_duration_from).toLocaleDateString()}
                            </Typography>
                            <Typography key={internship.id} variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            To: { new Date(internship.internship_duration_to).toLocaleDateString() || 'N.A.'}
                            </Typography> 
                        </Card>

                    </Box>
                ))}


                <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Button
                    onClick={() => handleDeleteClick(detail.id)}
                    variant="outlined"
                    color="error"
                    fullWidth
                    startIcon={<DeleteOutlineIcon />}
                    >
                    Delete
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                    component={Link}
                    to={`/update-education-details/${detail.id}`}
                    variant="contained"
                    fullWidth
                    startIcon={<EditNoteIcon />}
                    >
                    Edit
                    </Button>
                </Grid>
               
                </Grid>

                   



                    <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
                        <DialogTitle>Delete Confirmation</DialogTitle>
                        <DialogContent>
                        <Typography>Are you sure you want to delete this education detail?</Typography>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleConfirmationClose} color="primary">
                            No
                        </Button>
                        <Button onClick={handleConfirmationYes} color="primary">
                            Yes
                        </Button>
                        </DialogActions>
                    </Dialog>

            </CardContent>
            </Card>
        </Grid>
        ))}
    </Grid>
    </Container>
    </>
  );
}

export default EducationalDetailsCards;
