import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
Avatar,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomTypography from "../../../components/Textfield/CustomTypography"
import Editbutton from "../../../assets/Icons/Edit.png";

import { useParams } from "react-router-dom"; 
import Paper from '@mui/material/Paper';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { MdVisibility } from 'react-icons/md';
import DocumentBox from "../../../components/DocumentBox";
import CustomAccordian from "../../../components/CustomAccordian";
import { SERVER_BASE_ADDRESS } from "../../../globals/const";

export default function ShareProfileDoc() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usePermanentAddress, setUsePermanentAddress] = useState(true);
  const { uuid } = useParams();
  const [experiences, setExperiences] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(SERVER_BASE_ADDRESS + `shared-profile/${uuid}`);
        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }
        const jsonData = await response.json();
        setProfileData(jsonData);
        const experiences = jsonData.experience_details || [];
        console.log('Experiences:', experiences);

      // Sort experiences by end_date in descending order to get the most recent first
      const sortedExperiences = experiences.sort(
        (a, b) => new Date(b.end_date) - new Date(a.end_date)
      );
      setExperiences(sortedExperiences);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    if (uuid) {
      fetchData();
    }
  }, [uuid]); 

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;
  if (!profileData) return <Typography>No profile data available.</Typography>;

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{5})$/);
    if (match) {
      return `(+${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return "N/A"; // or any placeholder you prefer
  
    const today = new Date();
    const birthDateObj = new Date(birthDate);
  
    if (isNaN(birthDateObj)) return "N/A"; // checks if birthDate is an invalid date
  
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
  
    return age;
  };
  
  const openDocument = (url) => {
    window.open(url, "_blank");
  };
  
  const handleCheckboxChange = (event) => {
    setUsePermanentAddress(event.target.checked);
  };

  const DetailLabel = ({ label, value }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
      <Box
        sx={{
          width: '8px !important',
          height: '8px !important',
          borderRadius: '50% !important',
          backgroundColor: '#262262 !important',
          marginRight: '8px !important',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
        }}
      />
      <Typography variant="body1" sx={{ color: "#262262", fontWeight: '600 !important' }}>
        {label}: <Box component="span" sx={{ color: 'black !important', fontWeight: '600 !important' }}>{value || 'N.A.'}</Box>
      </Typography>
    </Box>
  );
  
  const formatEndDate = (endDate, currentlyWorking) => {
    if (currentlyWorking) return 'Present';
    return new Date(endDate).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <Container maxWidth="xs" style={{ marginTop: "30px", marginLeft: "3.3px"}}>
      <Grid container spacing={2} sx={{ mb: "0.2rem", pb: "0" }} flex justifyContent="center" alignItems="center">
      <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      mb={1}>
        
      <Box my={2}>
        <Avatar
          src={profileData.basic_info.profile_picture}
          sx={{ width: 100, height: 100 }}
        />
        </Box>
        <Box>
        {experiences.length > 0 && (
          <Typography textAlign="center">
            {experiences[0].designation}
          </Typography>
        )}
      </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: "#F8F9F9", padding: 2, borderRadius: 1 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}} color="#262262">
                Basic Information
              </Typography>

            </Box>

            <Box display="flex" alignItems="center" mt={1} mb={0.8}>
              <CustomTypography>Name: </CustomTypography>
              <Typography>{`${profileData.basic_info.first_name} ${
    profileData.basic_info.middle_name && profileData.basic_info.middle_name !== "null" 
      ? profileData.basic_info.middle_name + ' ' 
      : ''
  }${profileData.basic_info.last_name}`}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <CustomTypography>Gender:</CustomTypography>
              <Typography>
                {profileData.basic_info.gender === "M" ? "Male" : profileData.basic_info.gender === "F" ? "Female" : profileData.basic_info.gender === "O" ? "Other" : "No data yet"}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <CustomTypography>Phone number:</CustomTypography>
              <Typography>{formatPhoneNumber(profileData.basic_info.mobile_number)}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <CustomTypography>Email Id:</CustomTypography>
              <Typography>{profileData.basic_info.email}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ backgroundColor: "#F8F9F9", padding: 2, borderRadius: 1 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}} color="#262262">Personal Information</Typography>

            </Box>

            <Box display="flex" alignItems="center" mt={1} mb={0.8}>
              <CustomTypography>Date of birth: </CustomTypography>
              <Typography>
                {profileData.basic_info.birth_date
                  ? new Date(profileData.basic_info.birth_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })
                  : "N.A."}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <CustomTypography>Age:</CustomTypography>
              <Typography>{calculateAge(profileData.basic_info.birth_date)}</Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <CustomTypography>Relationship:</CustomTypography>
              <Typography>
                {profileData.basic_info.relationship_status === "S" ? "Single"
                  : profileData.basic_info.relationship_status === "M" ? "Married"
                  : profileData.basic_info.relationship_status === "D" ? "Divorced"
                  : profileData.basic_info.relationship_status === "W" ? "Widowed"
                  : profileData.basic_info.relationship_status === "H" ? "Homemaker"
                  : "N.A."}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
  <CustomTypography>Hobbies:</CustomTypography>
  <Typography>
    {profileData && profileData.basic_info && profileData.basic_info.hobbies && Array.isArray(profileData.basic_info.hobbies) && profileData.basic_info.hobbies.length > 0 ? (
      profileData.basic_info.hobbies.map((hobby, index) => (
        <span key={hobby}>
          <Chip
            label={hobby.trim()}
            sx={{
              backgroundColor: "#F8F9F9 !important",
              fontSize: "15.5px !important",
              padding: 0,
              '& .MuiChip-label': {
                padding: 0,
              }
            }}
          />
          {index < profileData.basic_info.hobbies.length - 1 && ", "}
        </span>
      ))
    ) : "N.A."}
  </Typography>
</Box>


            <Box sx={{ backgroundColor: "#F8F9F9", borderRadius: 1, margin: 0.5 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginY: 1 }}>
                <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">Family Details</Typography>
              </Box>

              <Box sx={{ marginTop: 0.5 }}>
                <TableContainer>
                  <Table sx={{ borderCollapse: 'collapse' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Relationship</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Name</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Age</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: '14px', fontWeight: "550 !important" }} color="#262262">Occupation</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
  {profileData && profileData.family_members && Array.isArray(profileData.family_members) && profileData.family_members.length > 0 ? (
    profileData.family_members.map((member) => (
      <TableRow key={member.id}>
        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{member.relationship || "N/A"}</TableCell>
        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{member.name || "N/A"}</TableCell>
        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{member.age !== null ? member.age : "N/A"}</TableCell>
        <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>{member.occupation || "N/A"}</TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '8px' }}>No family member data available.</TableCell>
    </TableRow>
  )}
</TableBody>

                  </Table>
                </TableContainer>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">
                Social Media Links
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={0.8} mt={1}>
  {profileData.basic_info.social_links && profileData.basic_info.social_links.length > 0 ? (
    <Box display="flex" flexDirection="column" gap={2}>
      {profileData.basic_info.social_links.map((link, index) => (
        <Box key={index} display="flex" alignItems="center">
          <Typography 
            component="span" 
            sx={{ 
              marginRight: 1,
              color: "#262262 !important",
              fontSize: "16px !important",
              fontWeight: "510 !important",
            }}
          >
            {link.name} -
          </Typography>
          <Typography
            component="a"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              wordBreak: 'break-all',
              color: '#1976d2',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {link.url}
          </Typography>
        </Box>
      ))}
    </Box>
  ) : (
    <Box>No social links available</Box>
  )}
</Box>

<Box display="flex" alignItems="center" justifyContent="space-between">
  <Typography
    sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }}
    color="#262262"
  >
    Additional Information
  </Typography>
</Box>

<Box display="flex" alignItems="center" mt={1} mb={0.8}>
  <CustomTypography>Disability: </CustomTypography>
  <CustomTypography>
    {profileData.additional_info && profileData.additional_info.disability || "N/A"}
  </CustomTypography>
</Box>

{profileData.additional_info && profileData.additional_info.diff_abled_file && 
  profileData.additional_info.diff_abled_file !== "https://iveri.s3.amazonaws.com/False" && (
    <DocumentBox
      fileName="Disability Certificate.pdf"
      onClick={openDocument}
      documentUrl={profileData.additional_info.diff_abled_file}
    />
)}

<Box display="flex" alignItems="center" mt={1} mb={0.8}>
  <CustomTypography>Visa Type: </CustomTypography>
  <CustomTypography>
    {profileData.additional_info && profileData.additional_info.visa_type || "N/A"}
  </CustomTypography>
</Box>

{profileData.additional_info && profileData.additional_info.visa_file && 
  profileData.additional_info.visa_file !== "https://iveri.s3.amazonaws.com/False" && (
    <DocumentBox
      fileName="Visa Certificate.pdf"
      onClick={openDocument}
      documentUrl={profileData.additional_info.visa_file}
    />
)}
</Box>



        </Grid>
        <Grid item xs={12}>
      <Box
        sx={{
          backgroundColor: "#F8F9F9",
          borderRadius: 1,
          margin: 0.5
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ margin: 0.5 }}>
          <Typography sx={{ fontSize: "22px !important", mt: 1.5, fontWeight: "550 !important" }} color="#262262">
            Language Proficiency
          </Typography>
        </Box>

        <Box sx={{ marginTop: 0.5 }}>
          <TableContainer>
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">
                      Language
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">
                      Read
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">
                      Write
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '4px' }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }} color="#262262">
                      Speak
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profileData?.languages?.map((language) => (
                  <TableRow key={language.id}>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>
                      {language.languageName}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>
                      {language.readingProficiency}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>
                      {language.writingProficiency}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '7px' }}>
                      {language.speakingProficiency}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {profileData?.languages?.map((language) => (
          language.file && (
            <DocumentBox
              key={language.id}
              fileName={`${language.languageName} Certificate`}
              onClick={() => openDocument(language.file)}
              documentUrl={language.file}
            />
          )
        ))}
      </Box>
    </Grid>
        <Grid item xs={12}>
          <Box 
            sx={{
              backgroundColor: "#F8F9F9", 
              padding: 2,
              borderRadius: 1
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}} color="#262262">
                Address Details
              </Typography>

            </Box>

            <Box display="flex" alignItems="center" mb={0.8}>
              <Typography sx={{fontSize: "22px !important"}} color="#262262">
                Temporary Address
              </Typography>
            </Box>

            {profileData.temporary_address ? (
              <>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>House No: </CustomTypography>
                  <Typography>{profileData.temporary_address.house_no || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Street: </CustomTypography>
                  <Typography>{profileData.temporary_address.street || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Area: </CustomTypography>
                  <Typography>{profileData.temporary_address.area || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>City: </CustomTypography>
                  <Typography>{profileData.temporary_address.city || "No data yet"}</Typography>
                
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>District: </CustomTypography>
                  <Typography>{profileData.temporary_address.district || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Post Office: </CustomTypography>
                  <Typography>{profileData.temporary_address.post_office || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Pincode: </CustomTypography>
                  <Typography>{profileData.temporary_address.pincode || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>State: </CustomTypography>
                  <Typography>{profileData.temporary_address.state || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Country: </CustomTypography>
                  <Typography>{profileData.temporary_address.country || "No data yet"}</Typography>
                </Box>
              </>
            ) : (
              <Typography>No data yet</Typography>
            )}

            <Box alignItems="center" mt={2} mb={0.8}>
              <Typography sx={{fontSize: "22px !important"}} color="#262262">
                Permanent Address
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={usePermanentAddress}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Same as above"
              />
            </Box>

            {!usePermanentAddress && profileData.address ? (
              <>
                <Box display="flex" alignItems="center" mb={0.8}>
                  <Typography sx={{ fontSize: "22px !important" }} color="#262262">
                    Permanent Address
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>House No: </CustomTypography>
                  <Typography>{profileData.address.house_no || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Street: </CustomTypography>
                  <Typography>{profileData.address.street || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Area: </CustomTypography>
                  <Typography>{profileData.address.area || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>City: </CustomTypography>
                  <Typography>{profileData.address.city || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>District: </CustomTypography>
                  <Typography>{profileData.address.district || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Post Office: </CustomTypography>
                  <Typography>{profileData.address.post_office || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Pincode: </CustomTypography>
                  <Typography>{profileData.address.pincode || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>State: </CustomTypography>
                  <Typography>{profileData.address.state || "No data yet"}</Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1} mb={0.8}>
                  <CustomTypography>Country: </CustomTypography>
                  <Typography>{profileData.address.country || "No data yet"}</Typography>
                </Box>
              </>
            ) : (
              !usePermanentAddress && <Typography>No data yet</Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box 
            sx={{
              backgroundColor: "#F8F9F9", 
              padding: 2,
              borderRadius: 1
            }}
          >
<Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
       {profileData?.educational_details && profileData.educational_details.length > 0 ? (
    profileData.educational_details.map((detail, index) => (
        <TimelineItem key={`edu-${index}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: 'white',
                border: "2px black solid",
                padding: "0px !important"
              }}
            />
            <TimelineConnector sx={{ backgroundColor: "black" }} />
          </TimelineSeparator>

          <TimelineContent 
            sx={{
              margin: "2 !important",
              padding: "2 !important",
              maxWidth: '100%',
              overflowX: 'auto',
            }}
          >
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: '#F8F9F9 !important',
                boxShadow: 'none !important',
                border: 'none !important',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${new Date(detail.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${new Date(detail.end_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}`}
              </Typography>

              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {detail.degree}
              </Typography>

              <Typography sx={{ fontSize: "15px !important", fontWeight: "550 !important" }}>
                {detail.institute_name}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <DetailLabel label="College Name" value={detail.institute_name} />
                <DetailLabel label="Specialization" value={detail.specialization} />
                <DetailLabel label="Board" value={detail.board} />
                <DetailLabel label="Score" value={detail.score} />
                <DetailLabel label="Total Score" value={detail.total_score} />
                <DetailLabel label="Score Unit" value={detail.score_unit} />
                <DetailLabel label="Certificates" value={detail.certificate_name} />

                {detail.projects && detail.projects.map(project => (
                  <Box key={project.id}>
                    <DetailLabel label="Project Title" value={project.project_title} />
                    <DetailLabel label="Project Description" value={project.project_desc} />
                    <DetailLabel label="Project Link" value={project.project_link} />
                  </Box>
                ))}

                {detail.internships && detail.internships.map(internship => (
                  <Box key={internship.id}>
                    <DetailLabel label="Internship Company" value={internship.internship_company} />
                    <DetailLabel label="Internship Role" value={internship.internship_role} />
                    <DetailLabel label="From" value={internship.internship_duration_from ? new Date(internship.internship_duration_from).toLocaleDateString() : "N.A."} />
                    <DetailLabel label="To" value={internship.internship_duration_to ? new Date(internship.internship_duration_to).toLocaleDateString() : "N.A."} />
                  </Box>
                ))}

                {detail.uploaded_files && detail.uploaded_files.map((file) => (
                  <Box
                    key={file.uploaded_at}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    border={1}
                    borderRadius={2}
                    sx={{ padding: "2px 6px", mt: 2, mb: 2 }}
                    onClick={() => window.open(file.file, "_blank")}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body1">{file.file_name}</Typography>
                    <MdVisibility />
                  </Box>
                ))}

                {['awards_file', 'extra_curricular_file', 'educational_file'].map((fileType) => {
                  const file = detail[fileType];
                  const fileLabels = {
                    awards_file: 'Internship Document',
                    extra_curricular_file: 'Project Document',
                    educational_file: 'Educational Document'
                  };
                  
                  if (file && file !== "https://iveri.s3.amazonaws.com/False") {
                    return (
                      <Box
                        key={fileType}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        border={1}
                        borderRadius={2}
                        sx={{ padding: "2px 6px", mt: 2, mb: 2 }}
                        onClick={() => window.open(file, "_blank")}
                        style={{ cursor: 'pointer' }}
                      >
                        <Typography variant="body1">{`${detail.institute_name} ${fileLabels[fileType]}`}</Typography>
                        <MdVisibility />
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))
    ) : (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>No educational details available</TimelineContent>
      </TimelineItem>
    )}
    </Timeline>
          </Box> 
        </Grid>

        <Grid item xs={12}>
          <Box 
            sx={{
              backgroundColor: "#F8F9F9", 
              padding: 2,
              borderRadius: 1
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Experience Details</Typography>

            </Box>
            <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: "0 !important",
          padding: "0 !important",
        },
        padding: "0 !important",
      }}
    >
  {profileData?.experience_details && profileData.experience_details.length > 0 ? (
    profileData.experience_details.map((detail, index) => (
        <TimelineItem key={`exp-${index}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: 'white',
                border: "2px black solid",
                padding: "0px !important"
              }}
            />
            <TimelineConnector sx={{ backgroundColor: "black" }} />
          </TimelineSeparator>

          <TimelineContent 
            sx={{
              margin: "2 !important",
              padding: "2 !important",
              maxWidth: '100%',
              overflowX: 'auto',
            }}
          >
            <Paper
              sx={{
                p: "2 !important",
                backgroundColor: '#F8F9F9 !important',
                boxShadow: 'none !important',
                border: 'none !important',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {`${new Date(detail.start_date).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })} - ${formatEndDate(detail.end_date, detail.currently_working)}`}
              </Typography>

              <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important" }}>
                {detail.designation || 'Role Not Specified'}
              </Typography>

              <Typography sx={{ fontSize: "15px !important", fontWeight: "550 !important" }}>
                {detail.company}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <DetailLabel label="Company Description" value={detail.company_description} />
                <DetailLabel label="Website" value={detail.website} />
                <DetailLabel label="Work Location" value={detail.work_location} />
                <DetailLabel label="Office Type" value={detail.office_type} />
                <DetailLabel label="Reporting To" value={detail.reporting_to} />
                <DetailLabel label="Reporting Type" value={detail.reportingType} />
                <DetailLabel label="Notice Period" value={detail.notice_period} />
                <DetailLabel label="Brief Role" value={detail.brief_role} />
                
                {detail.kra && detail.kra.map((kraItem, kraIndex) => (
                  <Box key={kraItem.id || kraIndex}>
                    <DetailLabel label="KRA" value={kraItem.kra} />
                    <DetailLabel label="Achievement" value={kraItem.achievement} />
                  </Box>
                ))}

                {detail.fixed_salary_amount && (
                  <DetailLabel 
                    label="Fixed Salary" 
                    value={`${detail.fixed_salary_currency} ${detail.fixed_salary_amount}`} 
                  />
                )}

                {detail.variable_salary_amount && (
                  <DetailLabel 
                    label="Variable Salary" 
                    value={`${detail.variable_salary_currency} ${detail.variable_salary_amount}`} 
                  />
                )}

                {detail.uploaded_files && detail.uploaded_files.map((file) => (
                  <Box
                    key={file.uploaded_at}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    border={1}
                    borderRadius={2}
                    sx={{ padding: "2px 6px", mt: 2, mb: 2 }}
                    onClick={() => window.open(file.file, "_blank")}
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body1">{file.file_name}</Typography>
                    <MdVisibility />
                  </Box>
                ))}
              </Box>
            </Paper>
          </TimelineContent>
        </TimelineItem>
   ))
  ) : (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
      </TimelineSeparator>
      <TimelineContent>No experience details available</TimelineContent>
    </TimelineItem>
  )}
  </Timeline>
          </Box> 
        </Grid>

        <Grid item xs={12}>
          <Box 
            sx={{
              backgroundColor: "#F8F9F9", 
              padding: 2,
              borderRadius: 1
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Skills</Typography>

            </Box>
            <Box display="flex" flexDirection="column">
  {profileData.skills && profileData.skills.map(skill => (
    <Typography key={skill.id}>
      <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#262262" }}>
        Skills:
      </Typography>
      <Typography component="span" sx={{ fontWeight: 'bold !important', color: "#000000" }}>
        {` ${skill.skill}`}
      </Typography>

      {skill.file_uploaded && (
        <DocumentBox
          fileName={`${skill.skill} Certificate.pdf`}
          onClick={openDocument}
          documentUrl={skill.file_uploaded}
        />
      )}
    </Typography>
  ))}
</Box>

          </Box>
        </Grid>
        <Grid>

        <Grid item xs={12}>
        <Box 
            sx={{
              backgroundColor: "#F8F9F9", 
              padding: 2,
              borderRadius: 1
            }}
          >
<Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography sx={{fontSize: "22px !important", fontWeight: "550 !important"}}  color="#262262">Document Locker</Typography>
            </Box>
            <Grid>
  {/* Languages Section */}
  {profileData.languages && profileData.languages.length > 0 && (
  <CustomAccordian title="Languages">
    {profileData.languages.map((language) => (
      language.file && (
        <DocumentBox
          key={language.id}
          fileName={language.languageName}
          documentUrl={language.file}
          onClick={openDocument}
        />
      )
    ))}
  </CustomAccordian>
)}


  {/* Educational Section */}
  {profileData.educational_details && profileData.educational_details.length > 0 && (
  <CustomAccordian title="Educational">
    {profileData.educational_details.map((education) => (
      <React.Fragment key={education.id}>
        {/* Uploaded Files */}
        {education.uploaded_files && education.uploaded_files.map((file) => (
          file.file !== "https://iveri.s3.amazonaws.com/False" && (
            <DocumentBox
              key={file.uploaded_at}
              fileName={file.file_name}
              documentUrl={file.file}
              onClick={openDocument}
            />
          )
        ))}

        {/* Awards Document */}
        {education.awards_file && education.awards_file !== "https://iveri.s3.amazonaws.com/False" && (
          <DocumentBox
            fileName={`${education.institute_name || 'Institute'} Awards Document`}
            documentUrl={education.awards_file}
            onClick={openDocument}
          />
        )}

        {/* Extra Curricular Document */}
        {education.extra_curricular_file && education.extra_curricular_file !== "https://iveri.s3.amazonaws.com/False" && (
          <DocumentBox
            fileName={`${education.institute_name || 'Institute'} Extra Curricular Document`}
            documentUrl={education.extra_curricular_file}
            onClick={openDocument}
          />
        )}

        {/* Educational Document */}
        {education.educational_file && education.educational_file !== "https://iveri.s3.amazonaws.com/False" && (
          <DocumentBox
            fileName={`${education.institute_name || 'Institute'} Educational Document`}
            documentUrl={education.educational_file}
            onClick={openDocument}
          />
        )}
      </React.Fragment>
    ))}
  </CustomAccordian>
)}


  {/* Experience Section */}
  {profileData.experience_details && profileData.experience_details.length > 0 && (
  <CustomAccordian title="Experience">
    {profileData.experience_details.map((experience) => (
      <React.Fragment key={experience.id}>
        {/* Uploaded Files */}
        {experience.uploaded_files && experience.uploaded_files.map((file) => (
          <DocumentBox
            key={file.uploaded_at}
            fileName={file.file_name}
            documentUrl={file.file}
            onClick={openDocument}
          />
        ))}
      </React.Fragment>
    ))}
  </CustomAccordian>
)}

  {/* Skills Section */}
  {profileData.skills && profileData.skills.length > 0 && (
    <CustomAccordian title="Skills">
      {profileData.skills.map((skill) => (
        skill.file_uploaded && (
          <DocumentBox
            key={skill.id}
            fileName={`${skill.skill} Certificate.pdf`}
            documentUrl={skill.file_uploaded}
            onClick={openDocument}
          />
        )
      ))}
    </CustomAccordian>
  )}

  {/* Additional Info Section */}
  {(profileData.additional_info &&
  (profileData.additional_info?.diff_abled_file !== "https://iveri.s3.amazonaws.com/False" ||
  profileData.additional_info?.visa_file !== "https://iveri.s3.amazonaws.com/False")) && (
  <CustomAccordian title="Additional Data">
    {profileData.additional_info.diff_abled_file && 
      profileData.additional_info.diff_abled_file !== "https://iveri.s3.amazonaws.com/False" && (
        <DocumentBox
          fileName="Disability Certificate.pdf"
          documentUrl={profileData.additional_info.diff_abled_file}
          onClick={openDocument}
        />
      )}
    {profileData.additional_info.visa_file && 
      profileData.additional_info.visa_file !== "https://iveri.s3.amazonaws.com/False" && (
        <DocumentBox
          fileName="Visa Certificate.pdf"
          documentUrl={profileData.additional_info.visa_file}
          onClick={openDocument}
        />
      )}
  </CustomAccordian>
)}

</Grid>
  </Box>
</Grid>
</Grid>
      </Grid>
    </Container>
  );
}
