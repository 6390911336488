import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you have axios installed and set up
import { Grid, TextField, Button, Container, Box } from '@mui/material';

import instance from '../../../globals/axios';
import NavBar from '../../../components/NavBar';
import ViewReqByReqId from '../../../components/ViewReqByReqId';


const RequirementDetails = () => {
    const [requirementId, setRequirementId] = useState(null);
    const [reqId, setReqId] = useState("");

    const company_id = parseInt(localStorage.getItem('company_id'));

    const iveriId = window.localStorage.getItem("iveri_id");

    if (iveriId) {
    console.log("iveri_id:", iveriId);
    } else {
    console.error("iveri_id not found in localStorage");
    }



    const handlePublish = () => {
        console.log("workkss")
        try {
            const formData = new FormData();
            formData.append('is_published', true);
            formData.append('published_on', "2024-01-10T12:00:00Z");
            formData.append('publisher_iveri_id', iveriId);
            formData.append('expiry', "2025-02-10T12:00:00Z");
            formData.append('requirement_id', requirementId);
        
            const response = instance.post(`/company/${company_id}/publish-requirement/`, formData);
            alert("Requirement Published successfully!")
            setTimeout(() => {
                window.location = '/human-resources'; 
            }, 500); 

              
        } catch (error) {
            console.error('Error publishing requirement:', error);
            alert('Error publishing requirement: ' + error.response.data.error);
        }
    };


    return (
        <React.Fragment>

        <NavBar />

        <Container maxWidth="md" sx={{ marginTop: "100px"}}>

        <ViewReqByReqId setReqId={setReqId} setRequirementId={setRequirementId}/>
    
        <Grid container xs={6} mb={6} mx="auto">
            <Button 
                fullWidth
                color="primary"
                variant="contained"
                onClick={handlePublish}>
                Publish
            </Button>
        </Grid>

        </Container>

        </React.Fragment>
    );
};

export default RequirementDetails;
