import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Paper,
  IconButton,
  styled 
} from '@mui/material';

import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import instance from '../../../globals/axios';
import { SERVER_BASE_ADDRESS } from '../../../globals/const';
import SharedProfileLogo from "../../../assets/profileComplete.png"



// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 480,
  margin: '0 auto',
  padding: theme.spacing(3),
  borderRadius: 12,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 120,
  height: 120,
  borderRadius: '50%',
  backgroundColor: '#EEF2FF', // Light indigo background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    right: -8,
    top: 24,
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: '#BFDBFE', // Light blue circle
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 24,
    bottom: 24,
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#BFDBFE', // Light blue circle
  },
}));

const CheckIconWrapper = styled(Box)(({ theme }) => ({
  width: 64,
  height: 64,
  borderRadius: 8,
  backgroundColor: '#312E81', // Dark indigo background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    color: 'white',
    fontSize: 32,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#EFEFF0',
    borderRadius: "10px",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      }
    },
}));

const LinkGeneratedDoc = () => {
  const [sharedurlwithdoc, setSharedurlWithDoc] = useState("");
  const [sharedurlwithoutdoc, setSharedurlWithoutDoc] = useState(""); // Keep this for completeness
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    instance.get(SERVER_BASE_ADDRESS + "create-shared-link/")
      .then((res) => {
        if (Array.isArray(res.data)) {
          res.data.forEach((item) => {
            if (item.sharing_mode === "WITH_DOC") {
              setSharedurlWithDoc(item.uuid);
            } else if (item.sharing_mode === "WITHOUT_DOC") {
              setSharedurlWithoutDoc(item.uuid);
            }
          });
        } else {
          console.error("Unexpected response format:", res.data);
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const generatedLink = `http://${window.location.host}/share-profile-docs/${sharedurlwithdoc}`;

  const handleBack = () => {
    history.push("/share-profile-link");
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#F9FAFB', p: 2 }}>
      <StyledPaper elevation={0}>
        <Box sx={{ position: 'relative', mb: 4 }}>
          <IconButton 
            sx={{ position: 'absolute', left: 0, top: -8 }}
            size="large"
          >
            <ArrowBackIcon onClick={handleBack} />
          </IconButton>
          <Typography 
            variant="h6" 
            align="center" 
            sx={{ fontWeight: 600 }}
          >
            Share my Profile
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <Box sx={{ position: 'relative', width: { xs: 240, sm: 300 }, height: { xs: 240, sm: 300 } }}>
            <img 
              src={SharedProfileLogo}
              alt="Share profile illustration"
              style={{ 
                width: '100%',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography 
            variant="h6" 
            align="center" 
            sx={{ 
              color: '#312E81',
              fontWeight: 600,
              px: 2,
              fontSize: '1.1rem',
              lineHeight: 1.4
            }}
          >
            "Your share profile with documents link has been created successfully!"
          </Typography>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography 
            variant="subtitle1" 
            sx={{ mb: 1, fontWeight: 500 }}
          >
            Generated Link:
          </Typography>
          <StyledTextField
            fullWidth
            variant="outlined"
            value={generatedLink}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button 
            variant="outlined" 
            fullWidth 
            size="large"
            sx={{color: "#272260", border: "1px solid #272260"}}
            onClick={handleBack}
          >
            Go Back to Profile
          </Button>
          <Button 
            variant="contained" 
            fullWidth 
            size="large"
            startIcon={<ShareIcon />}
            sx={{ 
              backgroundColor: "#272260 !important",
            }}
          >
            Share Profile
          </Button>
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default LinkGeneratedDoc;