import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory, useLocation, } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { RZP_KEY } from "../../globals/const";
import {
  capturePayment,
  createOrder,
  getAvailablePlans,
} from "../../api/subscription";
import iveriLogo from '../../assets/iveri.png'

const styles = {
  headerText: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#2A2665',
  },
  featureTitle: {
    color: '#272260',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#2A2665',
    color: 'white',
    textTransform: 'none',
    py: 1.5,
    borderRadius: 1,
    '&:hover': {
      backgroundColor: '#1f1c4b'
    }
  }
};

const Feature = ({ title, description }) => (
  <Typography sx={{ mb: 2 }}>
    <strong style={styles.featureTitle}>{title}:</strong> {description}
  </Typography>
);

const PriceDisplay = ({ currentPrice, originalPrice }) => (
  <Box sx={{ mb: 3 }}>
    <Typography 
      variant="h2" 
      sx={{ 
        fontSize: '32px',
        fontWeight: 600,
        color: '#2A2665',
        mb: 1
      }}
    >
      ₹{currentPrice}
    </Typography>
    <Typography 
      sx={{ 
        textDecoration: 'line-through',
        color: '#B30000',
        fontSize: '26px'
      }}
    >
      ₹{originalPrice}
    </Typography>
  </Box>
);

const PaymentPage = () => {
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [referrer, setReferrer] = useState('/share-profile-link');

  useEffect(() => {
    // Check if a referrer is passed in location state, else default to '/share-profile-doc'
    if (location.state?.from) {
      setReferrer(location.state.from);
    }
  }, [location.state]);

  const features = [
    {
      title: 'Unlimited Profile Sharing',
      description: 'Effortlessly share your profile anytime, anywhere.'
    },
    {
      title: 'Access to All Features',
      description: 'Enjoy full access to all app features with this plan.'
    },
    {
      title: 'Secure Sharing',
      description: "Your profile's privacy and security are our top priorities. Share your profile only with those you choose."
    },
    {
      title: 'Easy Profile Management',
      description: 'Update your profile details easily and share with anyone without hassle.'
    },
    {
      title: 'Dedicated Customer Support',
      description: 'Our customer support team is here to assist you with any questions or help you may need.'
    },
    {
      title: 'Exclusive Promotions',
      description: "With this plan, you'll also receive special offers and discounts."
    }
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    fetchPlans();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fetchPlans = async () => {
    try {
      const availablePlans = await getAvailablePlans();
      setPlans(availablePlans);
      if (availablePlans.length > 0) {
        setSelectedPlan(availablePlans[0]);
      }
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  const verifyPayment = async (payment_data) => {
    try {
      const successful = await capturePayment(
        payment_data.razorpay_order_id,
        payment_data.razorpay_payment_id,
        payment_data.razorpay_signature
      );
      if (successful) {
        // Set is_paid_user to true in localStorage
        window.localStorage.setItem("is_paid_user", true);
        
        // Redirect to /share-profile-link
        history.push(referrer);
      }
    } catch (error) {
      console.error('Payment verification failed:', error);
      alert("Payment verification failed. Please try again.");
    }
  };

  const handleSubscribe = async () => {
    if (!selectedPlan) {
      alert("Please wait while we load the plan details.");
      return;
    }

    setIsLoading(true);
    try {
      const result = await createOrder(selectedPlan.plan_code);
      const options = {
        key: RZP_KEY,
        currency: "INR",
        name: "Iveri subscription",
        description: "Subscribe to Iveri",
        image: iveriLogo,
        order_id: result.order_id,
        handler: function (response) {
          verifyPayment(response);
        },
        modal: {
          ondismiss: function() {
            setIsLoading(false);
          }
        }
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert("Failed to initiate payment. Please try again.");
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Box sx={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <Sidebar />
      <Container maxWidth="sm" sx={{ px: { xs: 2, sm: 3 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 2, justifyContent: 'center' }}>
          <IconButton 
            sx={{ position: 'absolute', left: 16 }}
            aria-label="go back"
            onClick={handleBack}
          >
            <ArrowBackIosNewIcon style={{border: "2px solid black", borderRadius: "100%", fontSize: "18px"}} />
          </IconButton>
          <Typography variant="h1" sx={styles.headerText}>
            Choose your plan
          </Typography>
        </Box>

        <Box sx={{ pt: 2, pb: 4 }}>
          <Typography variant="h1" sx={{ ...styles.headerText, mb: 3 }}>
            One Choice, Limitless Sharing!
          </Typography>

          <Box sx={{ mb: 4 }}>
            {features.map((feature, index) => (
              <Feature 
                key={index}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </Box>

          <PriceDisplay 
            currentPrice={(selectedPlan?.amount ? (selectedPlan.amount / 100).toFixed(2) : '199.00')} 
            originalPrice="599.00" 
          />

          <Button
            variant="contained"
            fullWidth
            sx={styles.button}
            onClick={handleSubscribe}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Next'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentPage;
