import React from "react";
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";



const buttons = [
    {
        label: "View / Edit Profile",
        path: "/company-profile",
    },
    {
        label: "View Dashboard",
        path: "/dashboard-details",
    },
    {
        label: "User Management",
        path: "/user-management",
    },
    {
        label: "Banking Details",
        path: "/banking-details",
    },
    
]

export default function Menu() {

    return (
        <>
            <NavBar />
            <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
                {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}

</Container>
        </>

    );
};