import { Container } from '@mui/material'
import { InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import instance from "../../../globals/axios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import React from 'react'

const LinkCompany = () => {

    const [creds, setCreds] = useState({});


    function setCredField(key, value) {
        let _creds = creds;
        creds[key] = value;
        setCreds(_creds);
    }


  function submitHandler(event) {
    event.preventDefault();
  
  
    try {
      console.log(creds);
  
        instance.post("user/register/", creds).then((res) => {
        console.log("Data got", res.data);

        // Store UUID, first name, and last name in localStorage
        localStorage.setItem('user_uuid', res.data.uuid);
        localStorage.setItem('user_first_name', creds.first_name);
        localStorage.setItem('user_last_name', creds.last_name);
  
        alert("Register Successful");
        setTimeout(() => {
          window.location = "/";
        }, 1000);
      });
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  }

    return (
        <Container>

            <Grid item xs={12}>
            <TextField
            style={{ marginTop: "20px" }}
            variant='outlined'
            required
            fullWidth
            label='Company Name'
            onChange={(event) => setCredField("name", event.target.value)}
            />
            <TextField
            style={{ marginTop: "20px" }}
            variant='outlined'
            required
            fullWidth
            label='Company GST'
            onChange={(event) => setCredField("pan_num", event.target.value)}
        
            />
            <TextField
            style={{ marginTop: "20px" }}
            variant='outlined'
            required
            fullWidth
            label='Company PAN'
            onChange={(event) => setCredField("gst_num", event.target.value)}
        
        />
            </Grid>

        </Container>
    )
}

export default LinkCompany