import {React, useState} from 'react'
import NavBar from '../../../components/NavBar'
import { Button, Container, Typography, TextField, Select, MenuItem, Grid, Box, InputLabel, FormControl } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faLink } from "@fortawesome/free-solid-svg-icons";
import instance from "../../../globals/axios";
import RS_Select from 'react-select';

export default function HRRequiredApproved() {
    const [currentPage, setCurrentPage] = useState(1);
    ///////////
    const [iveriId, setIveriID] = useState("");
    const [HrSpoc,setHrSpoc] = useState('')
    const [reqRaisedDate, setReqtRaisedDate] = useState(null);
    const [reqApprovedDate, setReqApprovedDate] = useState(null);
    const [position, setPosition] = useState("");
    const [jobLoc, setJobLoc] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [closeDate, setCloseDate] = useState(null);
    const [levelPos, setLevelPos] = useState("");
    const [deparment, setDepartment] = useState("");
    const [jobBrief, setJobBrief] = useState("");
    const [roleResponsibilty, setroleResponsibilty] = useState("");
    const [reporting, setReporting] = useState("");
    const [reportees, setReportees] = useState("");
    const [typeRole, setTypeRole] = useState("");
    const [internalInterface, setInternalInterface] = useState("");
    const [externalInterface, setExternalInterface] = useState("");
    const [minYears, setMinYears] = useState("");
    const [maxYears, setMaxYears] = useState("");

 
    const [inputMode, setInputMode] = useState('name');
    const handleSaveClick = () => {
      const formData = new FormData();
      formData.append("iveri-id", iveriId)
      formData.append("hr-spoc", HrSpoc);
      formData.append('requirement-raised', reqRaisedDate.toISOString()); // Adjust date format as needed
      formData.append('requirement-approved', reqApprovedDate.toISOString());
      formData.append("no_position", position);
      formData.append("job-loc", jobLoc);
      formData.append("start-date", startDate.toISOString());
      formData.append("close-date", closeDate.toISOString());
      formData.append("level-pos", levelPos);
      formData.append("department", deparment);
      formData.append("job-brief", jobBrief);
      formData.append("role-responsibility", roleResponsibilty);
      formData.append("reporting", reporting);
      formData.append("reportees", reportees);
      formData.append("type-of-role", typeRole);
      formData.append("internal-interface", internalInterface);
      formData.append("external-interface", externalInterface);
    }

    /////reporting adn reportees///

    const [reportingNames, setReportingNames] = useState([]);
    const [reporteesNames, setReporteesNames] = useState([]);
  
    const handleAddPerson = () => {
      if (reporting) {
        setReportingNames([...reportingNames, reporting]);
        setReporting('');
      }
      if (reportees) {
        setReporteesNames([...reporteesNames, reportees]);
        setReportees('');
      }
    };
    

      const options = [
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Mumbai', label: 'Mumbai' },
        { value: 'Banglore', label: 'Banglore' },
        // Add more options as needed
      ];
      const handleJobLocChange = (selectedOption) => {
        setJobLoc(selectedOption);
      };

      const handleNextClick = () => {
        if (currentPage < 3) {
          setCurrentPage(currentPage + 1);
        }
      };
      
      const handleBackClick = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };

  return (
    <>
     <NavBar />
     <Container maxWidth="xs" style={{ marginTop: "80px" }} mb={2}>
     <Grid container alignItems="flex-start" justifyContent="space-between" mb={1.5}>
        <Grid item mb={3}>
          <Typography variant="h6" sx={{ color: "rgb(0, 184, 230)" }}>
          Requirement - more Details
          </Typography>
        </Grid>
        <Grid item>
        {(currentPage === 1) ? (
    <Button
      variant="contained"
      sx={{
        color: "rgb(3, 22, 48)",
        borderRadius: "30px",
        backgroundColor: "rgb(0, 184, 230)",
        padding: "2px 30px",
        fontWeight: "600",
        fontSize: "15px",
      }}
      onClick={handleNextClick}
    >
      NEXT
    </Button>
  ) : (
<Grid container spacing={2}>
  <Grid item>
    <Button
      variant="contained"
      sx={{
        color: "rgb(3, 22, 48)",
        borderRadius: "30px",
        backgroundColor: "rgb(0, 184, 230)",
        padding: "2px 30px",
        fontWeight: "600",
        fontSize: "15px",
      }}
      onClick={handleBackClick}
    >
      Back
    </Button>
  </Grid>
  {currentPage === 2 && (
    <Grid item>
      <Button
        variant="contained"
        sx={{
          color: "rgb(3, 22, 48)",
          borderRadius: "30px",
          backgroundColor: "rgb(0, 184, 230)",
          padding: "2px 30px",
          fontWeight: "600",
          fontSize: "15px",
        }}
        onClick={handleNextClick}
      >
        NEXT
      </Button>
    </Grid>
  )}
</Grid>
  )}
        </Grid>
      </Grid>

      {currentPage === 1 && (
        <Container my={3}>
         <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Requirement ID:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">System genrated ID</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">SBU Name:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">System genrated ID</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Profile title:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">System genrated ID</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Designation:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">System genrated ID</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth  value={iveriId} label="Iveri Id" id='iveri-id' onChange={(e) => setIveriID(e.target.value)} />
            </Grid>
            
            <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="Hr-Spoc-label">HR SPOC</InputLabel>
            <Select
              fullWidth
              label="HR-SPOC"
              id="hr-spoc"
              value={HrSpoc}
              name="hr-spoc-dropdown"
              onChange={(e) => setHrSpoc(e.target.value)}
            >
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Req. Raised on" value={reqRaisedDate} onChange={(newValue) => setReqtRaisedDate(newValue)} />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Req. Approved on" value={reqApprovedDate} onChange={(newValue) => setReqApprovedDate(newValue)} />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                <TextField type="number" value={position} label="Number of Applicants" onChange={(e) => setPosition(e.target.value)} fullWidth></TextField>
            </Grid>
            <Grid item xs={12} style={{ position: 'relative', zIndex: 1000 }}>
            <FormControl fullWidth>
    <RS_Select
      labelId="job-loc-label"
      id="job-loc"
      value={jobLoc}
      name="job-loc-dropdown"
      onChange={(selectedOption) => handleJobLocChange(selectedOption)}
      options={options}
      isSearchable={true}
      placeholder="Select a job location..."
    />
  </FormControl>
    </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Target Closed Date" value={closeDate} onChange={(newValue) => setCloseDate(newValue)} />
              </LocalizationProvider>
            </Grid>
         </Grid>
        </Container>
      )}
      {currentPage === 2 && (
        <Container my={3}>
            <Grid container spacing={2} mt={.25}>
            <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="level-pos-label">Level of Position</InputLabel>
                <Select fullWidth label="Level of Position" id="level-pos" value={levelPos} onChange={(e) => setLevelPos(e.targert.value)}>
                <MenuItem value="Junior">Junior</MenuItem>
                <MenuItem value="Mid">Mid</MenuItem>
                <MenuItem value="Senior">Senior</MenuItem>
                </Select>
            </FormControl>
         </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
                <InputLabel id="department-label">Department</InputLabel>
                <Select fullWidth label="Department" id="department" value={deparment} onChange={(e) => setDepartment(e.targert.value)}>
                </Select>
            </FormControl>
         </Grid>
          <Grid item xs={12}>
             <TextField fullWidth label="Job Brief" value={jobBrief} id="job-brief" onChange={(e) => setJobBrief(e.target.value)}></TextField>
          </Grid>
          <Grid item xs={12}>
             <TextField fullWidth label="Role and Responsibility" value={roleResponsibilty} id="role-responsibility" onChange={(e) => setroleResponsibilty(e.target.value)}></TextField>
          </Grid>

          <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Reporting to"
          value={reporting}
          id="reporting"
          onChange={(e) => setReporting(e.target.value)}
        />
        {reportingNames.map((name, index) => (
          <div key={index}>{name}</div>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <FontAwesomeIcon
          icon={faPlus}
          style={{
            color: 'rgb(0, 184, 230)',
            height: '1.5rem',
            cursor: 'pointer',
            marginTop: '1rem',
          }}
          onClick={handleAddPerson}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Reportees"
          value={reportees}
          id="reportees"
          onChange={(e) => setReportees(e.target.value)}
        />
        {reporteesNames.map((name, index) => (
          <div key={index}>{name}</div>
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <FontAwesomeIcon
          icon={faPlus}
          style={{
            color: 'rgb(0, 184, 230)',
            height: '1.5rem',
            cursor: 'pointer',
            marginTop: '1rem',
          }}
          onClick={handleAddPerson}
        />
      </Grid>
          <Grid item xs={12}>
             <TextField fullWidth label="Type of Role" value={roleResponsibilty} id="role-responsibility" onChange={(e) => setroleResponsibilty(e.target.value)}></TextField>
          </Grid>
            </Grid>
        </Container>
      )}

      {currentPage === 3 && (
        <Container my="3">
            <Grid container spacing={2}>

                <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="type-of-role-label">On Role/Off Role</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                labelId="type-of-role-label"
                id="type-of-role"
                label="On Role/Off Role"
                value={typeRole}
                onChange={(e) => setTypeRole(e.target.value)}
              >
                <MenuItem value="onRole">On Role</MenuItem>
                <MenuItem value="offRole">Off Role</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
            <Grid item xs={12}>
             <TextField fullWidth label="Internal Interface" value={internalInterface} id="internal-interface" onChange={(e) => setInternalInterface(e.target.value)}></TextField>
          </Grid>
          <Grid item xs={12}>
             <TextField fullWidth label="External Interface" value={externalInterface} id="external-interface" onChange={(e) => setExternalInterface(e.target.value)}></TextField>
          </Grid>

          <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ color: "rgb(0, 184, 230)" }}>Total Experience Required</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
             <TextField label="Min Years"  value={minYears} onChange={(e) => setMinYears(e.target.value)}></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
             <TextField label="Max Years"  value={maxYears} onChange={(e) => setMaxYears(e.target.value)}></TextField>
            </Grid>
          </Grid>
        </Container>
      )}
     </Container>
     </>
  )
}