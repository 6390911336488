import React, { useState } from 'react';
import { Grid, TextField, Button, Alert, Container, Typography, CircularProgress } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import instance from '../globals/axios';
import logo from '../../src/assets/iveri.png'
import { Label } from '@mui/icons-material';


const ChangePhoneNumberForm = () => {
    const [iveriId, setIveriId] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);

    const [alert, setAlert] = useState({ show: false, message: '', severity: 'info' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (phoneNumber.length !== 12) {
            setAlert({ show: true, message: 'Invalid Phone Number', severity: 'error' });
            return; 
        }
        if (iveriId.length !== 10) {
            setAlert({ show: true, message: 'Invalid Iveri-Id', severity: 'error' });
            return; 
        }
    
        setLoading(true); // Enable loading state before the API request
        try {
            const response = await instance.post('/user/change-phone-number/', {
                iveri_id: iveriId,
                email: email,
                new_phone_number: phoneNumber,
            });
            setAlert({ show: true, message: response.data.message, severity: 'success' });
        } catch (error) {
            setAlert({ show: true, message: error.response?.data?.message || 'An error occurred', severity: 'error' });
        } finally {
            setLoading(false); // Disable loading state after the API request
        }
    };
    

    return (
        <Container component='main' fullWidth >

        <Grid fullWidth mt="10%" container justifyContent="center">

        <Grid item my="auto" xs={12} md={5} >       
          <Grid flex xs={6} sx={{ mx:"auto"}}>
            <img mx="auto" alt='iVeri logo' src={logo} style={{ width: "100%" }} />
          </Grid>
          
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {alert.show && <Alert severity={alert.severity}>{alert.message}</Alert>}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Iveri ID"
                        variant="outlined"
                        fullWidth
                        value={iveriId}
                        onChange={(e) => setIveriId(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography textAlign='center' variant='body1' mb={2}>
                        New Phone Number
                    </Typography>
                    <PhoneInput
                        country={'in'}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        inputProps={{
                            required: true,
                        }}
                        inputStyle={{ width: '100%' }}
                        containerStyle={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading} // Disable the button while loading
                    >
                        {loading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </Grid>
            </Grid>
        </form>
    
        </Grid>
      
        </Grid>    

    </Container>
       
    );
};

export default ChangePhoneNumberForm;
