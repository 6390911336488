import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid, Chip, CircularProgress, Container, Button, Box, TextField, ButtonGroup } from '@mui/material';
import {makeStyles} from '@mui/styles'
import { useParams } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';

import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';
import {Link} from 'react-router-dom'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CheckCircleOutline } from '@mui/icons-material';
import { FRONTEND_ADDRESS } from '../../globals/const';

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const RequirementCard = (props) => {
    const classes = useStyles();
    const [requirement, setRequirement] = useState(null);
    const [loading, setLoading] = useState(false);
    const company_id = localStorage.getItem('company_id');
    const iveri_id = localStorage.getItem('iveri_id');
    const [reqId, setReqId] = useState("");
    const [applicants, setApplicants] = useState([]);
    const { requirement_id, profile_title, designation } = props.location.state;
    const { req_id } = useParams();


    const handleMoveToInterview = (requirement_applicant_id) => {
      try {
        const response = instance.post(`company/${company_id}/action-requirement-applicant/`, {
          requirement_applicant_id: requirement_applicant_id, stage: "Interview", action: "Pending", action_by: iveri_id 
        });
  
        alert('Candidate moved to interview successfully');
        window.location.reload()
      } catch (error) {
        console.error('Error making POST request:', error);
      }
    };

    const handleOfferMade = (requirement_applicant_id) => {
      try {
        const response = instance.post(`company/${company_id}/action-requirement-applicant/`, {
          requirement_applicant_id: requirement_applicant_id, stage: "Offer", action: "Completed", action_by: iveri_id
        });
  
        alert('Action successfully');
        window.location.reload()
      } catch (error) {
        console.error('Error making POST request:', error);
      }
    };

    const handleReject = (requirement_applicant_id) => {
      try {
        const response = instance.post(`company/${company_id}/reject-requirement-applicant/`, {
          requirement_applicant_id: requirement_applicant_id, stage: "Interview", action: "Reject", rejected_by: iveri_id
        });
  
        alert('Action successfully');
        window.location.reload()
      } catch (error) {
        console.error('Error making POST request:', error);
      }
    };


    useEffect(() => {

        setLoading(true)
        instance.get(`company/${company_id}/get-applicants/?requirement_id=${req_id}`)
        .then(response => {
            setApplicants(response.data);
            setLoading(false)
        })
        .catch(error => {
            console.error('Error fetching requirement data:', error);
            setLoading(false)
        });

    }, []);


    const formatDateTime = (dateTimeString) => {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };
      return new Date(dateTimeString).toLocaleString(undefined, options);
    }

    if (loading) {
      return <CircularProgress />;
    }


    return (
      <>
      <NavBar/>

      <Container sx={{ marginTop: "90px", p:2}}>

      <Grid container >
    

        <Grid container fullWidth mx={4}>
                <Grid item xs={1}>
                    <Button onClick={() => window.history.back()} fullWidth startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={10} textAlign="center">
                    <Typography variant='h5'>
                    {profile_title}
                    </Typography>
                    <Typography variant='h6'>
                    {designation}
                    </Typography>
                    <Typography mt={1} variant='body1'>
                    Requirement ID: {requirement_id}
                    </Typography>
                </Grid>
        </Grid>

        <Grid item mx="auto" xs={10} spacing={2} p={4}>
          {
              applicants.length===0 && (
                <Box mx="auto">
                  <Typography variant='h5' textAlign='center'>
                    No Applicants Yet
                  </Typography>
                </Box>
              )
          }
          {
          applicants.map(applicant => (
            <Grid fullWidth sx={{ my:2 }} item key={applicant.id} xs={12} >
              <Card  sx={{ backgroundImage: 'linear-gradient(125deg, #FFF 70%, #e1f1fd 90%)', cursor: 'pointer', boxShadow: 5 }}>
                <CardContent>
                  <Typography variant="h6" component="div">{applicant.profile_title}</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" color="text.secondary">
                        <AccountCircleIcon /> {applicant.applicant_first_name} {applicant.applicant_last_name}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        <AccessTimeIcon /> Applied On: {formatDateTime(applicant.requirement_raised_on)}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        <EmailIcon /> Email: {applicant.applicant_email}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" color="text.secondary">
                        Status: {applicant.summary_of_assigment}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        Applicant ID: {applicant.requirement_applicant_id}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        Iveri ID: {applicant.applicant_iveri_id}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        Designation: {applicant.designation}
                      </Typography>
                    </Grid>

                    <Grid container flexDirection="row-reverse" justify="center" mx={1} mt={1} display="flex" alignItems="center" spacing={3}>
                      <Grid item xs={3}>
                        <Button variant='outlined' fullWidth onClick={() => window.location.href = `${FRONTEND_ADDRESS}iveri-profile/${applicant.applicant_iveri_id}`}>
                          View Profile
                        </Button>
                      </Grid>


                      {applicant.sub_status === "Rejected" && (
                        <Grid item xs={4}>
                          <Button variant='outlined' color="error" fullWidth startIcon={<HighlightOffIcon />}>
                            Rejected
                          </Button>
                        </Grid>
                      )}

                      {(applicant.status === "Offer" && applicant.sub_status === "Completed") && (
                          <Grid item xs={4}>
                            <Button variant='outlined' color="success" fullWidth startIcon={<CheckCircleOutline />}>
                              Selected
                            </Button>
                          </Grid>
                      )}

                      {(applicant.status === "Applied" && applicant.sub_status === "Pending") && (
                        <Grid item xs={4}>
                          <Button variant='contained' fullWidth onClick={() => handleMoveToInterview(applicant.requirement_applicant_id)}>
                            Move To Interview
                          </Button>
                        </Grid>
                      )}
                      {
                      (applicant.status==="Interview" && applicant.sub_status === "Pending" ) && (
                        <Grid item xs={3}>
                          <Button variant='contained' fullWidth onClick={() => handleOfferMade(applicant.requirement_applicant_id)}>
                            Offer Made
                          </Button>
                        </Grid>
                      )}
                      {
                      (applicant.status==="Interview" && applicant.sub_status === "Pending" ) && (
                        <Grid item xs={3}>
                          <Button variant='contained' color="error" fullWidth onClick={() => handleReject(applicant.requirement_applicant_id)}>
                            Reject
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      </Container>
      </>
    );
};

export default RequirementCard;
