import React from 'react'
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import NavBar from '../../components/NavBar'
import Layout from '../../components/Layout'


const buttons = [
  {
      label: "HomePage",
      path: "/emp-menu",
  },
  {
      label: "Hiring Manager",
      path: "/hiring-manager",
  },
  {
      label: "Human Resources",
      path: "/human-resources",
  },
]

export default function RoleEmployer() {
  return (
    <>
    <NavBar />
    <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
    {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}
    </Container>
    </>
  )
}
