import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import DeleteRedIcon from "../../assets/Icons/deleteIcon.png"

const ConfirmDeleteDialog = ({ open, handleClose, onDelete, title }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={{ 
      '& .MuiPaper-root': { 
        borderRadius: '20px'
      }
    }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', padding: "0 !important" }}>
        <IconButton onClick={handleClose} sx={{ color: 'black', padding: "15px"  }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
      <img src={DeleteRedIcon} style={{ width: '38px', height: '48px' }} />
        <Typography fontSize="28px" fontWeight="bold" pt={1}>
          Confirm Delete
        </Typography>
        <Typography variant="body1" textAlign="left !important" py={1} color="#7C7C7C">
          Are you sure you want to delete this {title}?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', flexDirection: 'column', gap: 1, pb: 2 }}>
        <Button
          fullWidth
          onClick={onDelete}
          sx={{ fontWeight: 'bold',fontSize: "15px", backgroundColor: "#EF4B4B", color: "white", borderRadius: "13px", textTransform: 'none', paddingY: "15px" , width: "90%" }}
        >
          Delete
        </Button>
        <Button onClick={handleClose} fullWidth sx={{color: "black",textTransform: 'none',fontSize: "15px" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
