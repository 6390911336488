import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Typography, Link, Container } from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

const LabelContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: "rgb(0, 184, 230)",
  fontWeight: "600",
  marginRight: theme.spacing(1),
}));

const LabelField = ({ label, value }) => {
  const isSocialMediaLink = label === "Linkedin" || label === "Twitter" || label === "Facebook";
  const isDocumentUpload = label === "Document uploaded";

  const handleLinkClick = () => {
    if (isSocialMediaLink) {
      window.open(value, "_blank");
    }
  };

  const handleDocumentClick = () => {
    if (isDocumentUpload) {
      window.open(value, "_blank", "fullscreen=yes");
    }
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <LabelContainer item>
        <FontAwesomeIcon icon={faCircleCheck} style={{color: "rgb(0, 184, 230)",}} />
        <LabelText>{label + ":"}</LabelText>
      </LabelContainer>
      {isSocialMediaLink ? (
        <Grid item>
          <Link href={value} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
            <Typography style={{ cursor: "pointer" }}>{value}</Typography>
          </Link>
        </Grid>
      ) : isDocumentUpload ? (
        <Grid item>
          <Typography style={{ cursor: "pointer" }} onClick={handleDocumentClick}>
            {value}
          </Typography>
        </Grid>
      ) : (
        <Grid item>
          <Typography>{value}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const labelValuePairs = [
  { label: "Name", value: "Rajesh Mehta" },
  { label: "Mobile number", value: "9999999" },
  { label: "Email ID", value: "rajesh.mehta@gmail.com" },
  { label: "Address", value: "Ghatlodia, Ahmedabad, Gujarat, India" },
  { label: "Birthday", value: "05th May 1995, Age: 28" },
  { label: "Linkedin", value: "https://linkedin.com/rajeshmehta" },
  { label: "Twitter", value: "https://twitter.com/rajeshmehta" },
  { label: "Facebook", value: "https://facebook.com/rajeshmehta" },
  { label: "Document uploaded", value: "pan_card.pdf" },
];

export default function PersonalDetails() {
  return (
    <>
      <NavBar />
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Grid container spacing={2}>
          {labelValuePairs.map(({ label, value }) => (
            <Grid item xs={12} key={label}>
              <LabelField label={label} value={value} />
            </Grid>
          ))}
        </Grid>
        </Container>
    </>
  );
}
