import React from 'react'
import { Link } from "react-router-dom";
import {
    Button, Container
} from "@mui/material";
import NavBar from '../../../components/NavBar';


const buttons = [
    {
        label: "View / Edit my Profile",
        path: "/hm-view-edit",
    },
    {
        label: "Create A Requirement",
        path: "/new-requirement",
    },
    {
        label: "View Active Requirements",
        path: "/view-hm-requirements",
    },
    {
        label: "View Closed Requirements",
        path: "/view-closed-requirements",
    },
    // {
    //     label: "View My Applicants",
    //     path: "/view-applications",
    // },
]

export default function HmMenu() {
  return (
    <Container>
    <NavBar />
    <Container maxWidth= "md" style={{ marginTop: '80px' }}>
        {buttons.map((button, index) => (
                    <Button key={index} fullWidth component={Link} to={button.path}>
                        {button.label}
                    </Button>
                ))}
    </Container>
    </Container>
  )
}
