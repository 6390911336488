import React, { useState } from 'react';
import { Grid, Box, Typography, Chip, Backdrop, Autocomplete, Button, TextField, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import instance from '../globals/axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DescriptionIcon from '@mui/icons-material/Description';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const ResumeUploader = ({ process }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  // const interviewerIveriId = localStorage.getItem('iveri_id');
  const interviewerIveriId = localStorage.getItem('iveri_id');
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewTime, setInterviewTime] = useState(null);



  const onDrop = (acceptedFiles) => {
    const filteredFile = acceptedFiles.find(file => file.size <= 1048576 && file.type === 'application/pdf');
    setFile(filteredFile || null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    maxSize: 1048576,
    multiple: false
  });

  const handleProcess = async () => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append('pdf_files', file);

    try {
      let url = process === 'Job Description'
      ? 'resume-matching-system/match-resume/' : 
      'resume-matching-system/match-jds/';

      const res = await instance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setResponse(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedJd, setSelectedJd] = useState(null);
  const [candidateId, setCandidateId] = useState('');
  const [candidateIdError, setCandidateIdError] = useState('');
  const [designation, setDesignation] = useState('');
  const [JobProfile, setJobProfile] = useState('');
  const [topicInput, setTopicInput] = useState('');
  const [topics, setTopics] = useState([]);

  const handleInviteCandidate = (jd) => {
    setSelectedJd(jd);  // Store the selected JD for the POST request
    console.log('Selected JD:', jd);
    setPopupOpen(true);  // Open the confirmation popup
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setEmail('');
    setEmailError('');
    setDesignation('');
    setJobProfile('');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleDesignationChange = (e, value) => {
    setDesignation(value);
  };

  const handleJobProfileChange = (e) => {
    setJobProfile(e.target.value);
  };

  const handleTopicInputChange = (e) => {
    setTopicInput(e.target.value);
  };
  
  const handleAddTopic = (e) => {
    if (e.key === 'Enter' && topicInput.trim()) {
      setTopics((prevTopics) => [...prevTopics, topicInput.trim()]);
      setTopicInput('');
    }
  };
  
  const handleDeleteTopic = (index) => {
    setTopics((prevTopics) => prevTopics.filter((_, i) => i !== index));
  };
  
  

  const handleConfirmInvite = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address.');
      return;
    }

    if(topics.length === 0) { 
      alert('Please add topics to include in the interview.');
      return;
    }

    if(JobProfile === '') {
      alert('Please enter the job profile.');
      return;
    }

    if(designation === '') {
      alert('Please enter the designation.');
      return;
    }

    confirmInvite();
  };

  const [sendLinkLoader, setSendLinkLoader] = useState(false);

  const confirmInvite = () => {
    if (selectedJd) {
      setSendLinkLoader(true);
      instance.post('interview-system/talk/', {
        // jd_id: selectedJd.jd.id,
        topics: topics,
        // selected_candidate_id: candidateId,
        interviewer_iveri_id: interviewerIveriId,
        email: email,
        designation: designation,
        job_profile: JobProfile,
        interview_date: interviewDate.format('YYYY-MM-DD'),
        interview_time: interviewTime.format('HH:mm:ss'),
      })
      .then(response => {
        setPopupOpen(false);
        alert('Interview invitation sent successfully!');
        setSendLinkLoader(false);
      })
      .catch(error => {
        console.error('Error inviting candidate:', error);
        alert('Failed to send interview invitation.');
        setSendLinkLoader(false);
      });
    }
  };

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleCandidateIdChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setCandidateId(value);
      setCandidateIdError('');
    } else {
      setCandidateIdError('Candidate ID must be 10 digits.');
    }
  };





  return (
    <Box pt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Upload 
            {process === "Job Description" ? " Resume" : " Job Description"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', p: 4, textAlign: 'center' }}>
            <input {...getInputProps()} />
            <Typography>Drag & drop a file here, or click to select a file (PDF, max 1MB)</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {file && (
            <Box>
              <Typography variant="h6">Selected File:</Typography>
              <Typography>{file.name}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          {
            process === "Job Description" ? 
            (
              <Button variant="contained" color="primary" onClick={handleProcess} disabled={loading}>
                Find Matching JDs
              </Button> ) :
            ( <Button variant="contained" color="primary" onClick={handleProcess} disabled={loading}>
              Find Matching Resumes   
            </Button>
              )
          }
         
        </Grid>
        <Grid item xs={12}>
          {loading && <CircularProgress />}
          {response && (
        <Box >
          {response && response.map((res, index) => (
            <Box key={index} sx={{ mt: 2 }}>
              <Typography variant="h6">Response:</Typography>
              {res.status && process === 'Job Description' && res.matched_jds && (
                <List component="div" disablePadding sx={{ pl: 4 }}>
                  {res.matched_jds.map((jd, jdIndex) => (
                    <ListItem key={jdIndex}>
                      <ListItemIcon>
                        <Avatar>
                          <FileOpenIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body1" component="a" href={jd.jd_file} target="_blank" rel="noopener noreferrer">
                            {jd.jd_file_name}
                          </Typography>
                        }
                        secondary={`Matching Percentage: ${jd.matching_percentage.toFixed(2)}%`}
                      />
                      <Button onClick={() => handleInviteCandidate(jd)} variant="contained" color="primary">
                        Invite Candidate
                      </Button>
                    </ListItem>
                  ))}
                </List>
              )}
            {res.status && process === 'Resume' && res.matched_resumes && (
            <List component="div" disablePadding sx={{ pl: 4 }}>
              {res.matched_resumes.map((resume, resumeIndex) => (
                <ListItem key={resumeIndex}>
                  <ListItemIcon>
                    <Avatar>
                      <FileOpenIcon />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="a" href={resume.resume_file} target="_blank" rel="noopener noreferrer">
                        {resume.resume_file_name}
                      </Typography>
                    }
                    secondary={`Matching Percentage: ${resume.matching_percentage.toFixed(2)}%`}
                  />
                  <Button onClick={() => handleInviteCandidate(resume)} variant="contained" color="primary">
                    Invite Candidate
                  </Button>
                </ListItem>
              ))}
            </List>
          )}
            </Box>
          ))}
        </Box>
      )}

      {/* Confirmation Popup */}
      {popupOpen && selectedJd && (
          <Backdrop open={popupOpen} sx={{ zIndex: 999, paddingTop: "30px" }} >

      <div style={{ position: 'fixed', overflowY: 'auto', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', zIndex: '1000', marginTop: '100px', maxHeight: '80vh' }}>
          <h2>Confirm Interview Invitation</h2>
          <p>Are you sure you want to send an interview invitation for the JD?</p>
          
          {/* Candidate Email Input */}
          <TextField
            label="Candidate Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
            margin="normal"
          />

          {/* Designation Input */}
          <Autocomplete
            options={['Entry Level', 'Experienced', 'Manager', 'Executive', 'Senior Executive']}
            getOptionLabel={(option) => option}
            value={designation}
            onChange={handleDesignationChange}
            renderInput={(params) => <TextField {...params} label="Designation" variant="outlined" margin="normal" />}
          />

          <TextField
            label="Job Profile"
            variant="outlined"
            fullWidth
            value={JobProfile}
            onChange={handleJobProfileChange}
            margin="normal"
          />

                {/* Topics to Include Input */}
          <TextField
            label="Add Topics"
            variant="outlined"
            fullWidth
            value={topicInput}
            onChange={handleTopicInputChange}
            onKeyDown={handleAddTopic}
            margin="normal"
            helperText="Press enter to enter multiple topics"

          />

          {/* Display Topics as Chips */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
            {topics.map((topic, index) => (
              <Chip
                key={index}
                label={topic}
                onDelete={() => handleDeleteTopic(index)}
                deleteIcon={<DeleteIcon />}
              />
            ))}
          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container textAlign="center" spacing={1} mt={1}>
              {/* Date Picker */}
              <Grid item xs={4} >
                <DatePicker
                  label="Date"
                  value={interviewDate}
                  format='DD-MM-YYYY'
                  onChange={(newValue) => setInterviewDate(newValue)}
                  renderInput={(params) => <TextField {...params}  />}
                />
              </Grid>

              {/* Time Picker */}
              <Grid item xs={4} >
                <TimePicker
                  label="Time"
                  value={interviewTime}
                  onChange={(newValue) => setInterviewTime(newValue)}
                  renderInput={(params) => <TextField {...params}  />}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>



          <Grid container>
            <Grid item container xs={6}>

            </Grid>
          </Grid>

          {
            sendLinkLoader ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) :
            (
              <Box mt={2}>
                <Button onClick={handleConfirmInvite} variant="contained" color="primary">
                  Send Invite
                </Button>
                <Button onClick={handleClosePopup} variant="outlined" color="error" style={{ marginLeft: '10px' }}>
                  Cancel
                </Button>
              </Box>
            )

          }
          
          <Typography variant="body2" color="#0f0f0f" sx={{ mt: 2 }}>
            Please be careful to fill in the job profile and designation as the interview will be scheduled accordingly.
          </Typography>
        </div>
        </Backdrop>
      )}

      
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResumeUploader;
