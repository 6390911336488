import React, { useState, useEffect } from 'react';
import NavBar from "../../components/NavBar";
import instance from "../../globals/axios.js";
import { Link } from 'react-router-dom';
import { SERVER_BASE_ViewJobs } from "../../globals/const";
import { Card, Container, CardContent, Typography, Button, Grid, Chip, Stack } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';



const DetailsPage = ({ location, history }) => {
    const requirement = location.state.requirement;
    const iveriId = window.localStorage.getItem("iveri_id");

    const handleApplyNow = async () => {
        const url = `company/${requirement.company}/add_applicant/`;
    
        // Create FormData
        const formData = new FormData();
        formData.append('requirement_id', requirement.requirement_id);
        formData.append('applicant_iveri_id', iveriId);
        formData.append('summary_of_assigment', "Candidate Applied");
    
        try {
            // Make the POST request
            const response = await instance.post(url, formData);
    
            alert('Applied successfully!');
            window.location.href = '/apply-jobs';
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
            alert(error.response.data);
            throw error;
        }
    };
    
  
    return (
        <React.Fragment>
        <NavBar  />
        <Container maxWidth= "md" style={{ marginTop: '80px' }}>

        <Grid container spacing={2} mt={4}>
            <Grid item key={requirement.id} xs={12} p={2} >
            <Card elevation={4} sx={{ background: 'linear-gradient(to bottom, #f0f0f0, #ffffff)' }}>
                <CardContent>
                <Typography variant="h6" textAlign="center" gutterBottom>
                    {requirement.profile_title}
                </Typography>
                <Typography variant="body1" textAlign="center" gutterBottom>
                    {requirement.designation}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "1.5rem" }} color="text.secondary" display="flex" alignItems="center">
                    <BusinessCenterIcon fontSize="medium" />
                    <span style={{ marginLeft: '8px' }}>Number of positions: {requirement.number_of_positions}</span>
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "1.5rem" }} color="text.secondary" display="flex" alignItems="center">
                    <LocationOnIcon fontSize="medium" />
                    <span style={{ marginLeft: '8px' }}>Job location: {requirement.job_location}</span>
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "1.5rem" }} color="text.secondary" display="flex" alignItems="center">
                    <AccessTimeIcon fontSize="medium" />
                    <span style={{ marginLeft: '8px' }}>Start date: {new Date(requirement.start_date).toLocaleDateString()}</span>
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "1.5rem" }} color="text.secondary" display="flex" alignItems="center">
                    <EventAvailableIcon fontSize="medium" />
                    <span style={{ marginLeft: '8px' }}>Expiry date: {new Date(requirement.expiry).toLocaleDateString()}</span>
                </Typography>
                <Typography variant="body1" sx={{ marginTop: "1.5rem" }} color="text.secondary" display="flex" alignItems="center">
                    <AccountBalanceWalletIcon fontSize="medium" />
                    <span style={{ marginLeft: '8px' }}>Offered CTC range: {requirement.offered_ctc_range}</span>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: "1.5rem" }} color="text.secondary" mt={2}>
                    {requirement.job_brief}
                </Typography>
                
                <Grid sx={{textAlign: "cetner "}} container spacing={2}>
                    <Grid item xs={3}>
                            <Button onClick={() => window.history.back()} fullWidth startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            fullWidth
                            onClick={() => handleApplyNow(requirement.requirement_id)}
                            variant="contained"
                            color="primary"
                        >
                            Apply Now
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            endIcon={ <TurnedInNotIcon />}
                            variant="outlined"
                            color="success"
                        >
                            Save
                        </Button>
                    </Grid>
                    
                </Grid>
                
                </CardContent>
            </Card>
            </Grid>
        </Grid>
        </Container>
        </React.Fragment>
    );
}

export default DetailsPage;
