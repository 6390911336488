import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import instance from "../../../globals/axios";

import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@material-ui/core";
import { Container, Box } from "@mui/material";
import { Link, useHistory } from "react-router-dom";


export default function EditCompany(props) {
  const history = useHistory();
  const { verification_id } = useParams();

  const [requisitionType, setRequisitionType] = useState('');
  const [clientName, setClientName] = useState('');
  const [assigned, setAssigned] = useState('');
  const [status, setStatus] = useState('');
  const [verificationRequestId, setVerificationRequestId] = useState('');
  const [updatedAt, setUpdateAt] = useState('');

  const [agency, setAgency] = useState(0);
  const [client, setClient] = useState(0);
  const [verificationFor, setVerificationFor] = useState(0);
  const [assignedTo, setAssignedTo] = useState(0);
  const [req, setReq] =useState('')
  const [cost, setCost] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(`agency/background-verifications/${verification_id}/`);
        const data = response.data;

        setRequisitionType(data.requisition_type === 'C' ? 'Company' : 'Individual');
        setReq(data.requisition_type)
        setClientName(data.client_name);
        setAssignedTo(data.assigned_to_name);
        setStatus(data.status === 'P' ? 'Pending' : data.status === 'C' ? 'Completed' : 'Ongoing');
        setVerificationRequestId(data.verification_request_id);
        setUpdateAt(new Date(data.updated_at).toLocaleString());
        setAssigned(data.assigned_to)
        setClient(data.client)
        setVerificationFor(data.verification_for)
        setAgency(data.agency)
        setCost(data.cost)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  


  const [bgvAssignedTo, setBgvAssignedTo] = useState("");
  const [candidateProfileLink, setCandidateProfileLink] = useState("");
  const [bgvCompletionStatus, setBgvCompletionStatus] = useState("");
  const [bgvRequisitionNumber, setBgvRequisitionNumber] = useState("");
  const [selectedBgvOption, setSelectedBgvOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const buttons = [
    {
      label: "Personal Details",
      // path: "/edit-personal",
      path: `/edit-personal/${verification_id}`
    },
    {
      label: "Educational Details",
      path: "/edit-educational",
    },
    {
      label: "Professional Details",
      path: "/edit-professional",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const path = event.target.value.toLowerCase().replace(/\s/g, "-");
    history.push(`/${path}`);
  };

  const [bgvStatus, setBgvStatus] = useState('');
  
  const handleBgvStatusChange = (event) => {
    setBgvStatus(event.target.value);
  };

  const handleUpdateStatus = () => {
    instance.put(`agency/background-verifications/${verification_id}/`, {
      "status": bgvStatus,
      "agency": agency,
      "client": client,
      "verification_for": verificationFor,
      "assigned_to": assigned,
      "requisition_type": req,
      "cost": cost

    })
    .then(response => {
      alert('Status updated successfully:', response.data);
    })
    .catch(error => {
      console.error('Error updating status:', error);
    });
  };


  return (
    <>
      <NavBar />
      <Container maxWidth= "md" style={{ marginTop: '80px' }}>
          <form onSubmit={handleSubmit}>
            <Grid mx="auto" xs={8} container spacing={2}>

            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>Requisition Type:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1">{requisitionType}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>Requisition Raised By:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1">{clientName}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>BGV Assigned To:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1">{assignedTo}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>Candidate Profile Link:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1"> www.iveri/xzy.com</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>BGV Completion Status:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1">{status}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>BGV Requisition Unique Number:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1">{verificationRequestId}</Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={{ color: "rgb(0, 184, 230)" }}>Last Updated At:</Typography>
            </Grid>
            <Grid item xs={4}>
            <Typography variant="subtitle1">{updatedAt}</Typography>
            </Grid>

            <Grid container mx="auto" spacing={2} p={2} textAlign="center">
              
            <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="bgv-status-label">Bgv Status</InputLabel>
              <Select
                labelId="bgv-status-label"
                id="bgv-status"
                value={bgvStatus}
                label="Bgv Status"
                onChange={(event) => setBgvStatus(event.target.value)}
              >
                <MenuItem value="P">Pending</MenuItem>
                <MenuItem value="R">Rejected</MenuItem>
                <MenuItem value="V">Verified</MenuItem>
                <MenuItem value="I">In Progress</MenuItem>
              </Select>
            </FormControl>
          </Grid>

              <Grid item my="auto" xs={4}>
                <Button variant="outlined" color="primary" onClick={handleUpdateStatus}>
                  Update Status
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">Please select any option to complete the BGV</Typography>
            </Grid>

            <Grid mx="auto" item xs={6}>
              {buttons.map((button, index) => (
            <Button
              key={index}
              fullWidth
              component={Link}
              to={button.path}
              variant="contained"
              style={{ marginBottom: "8px" }}
            >
              {button.label}
            </Button>
          ))}
              </Grid>
            </Grid>
          </form>


        </Container>
    </>
  );
}
