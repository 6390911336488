import React, { useState, useEffect } from "react";
import { Button, Typography, Card, Box, Container, Chip, Grid} from "@mui/material";
import { CardContent } from '@mui/material';
import { CheckCircle, HourglassEmpty, Business, Person } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import instance from "../../../globals/axios";


const bgvData = [
  {
    label: "Requisition Type",
    value: "Company",
  },
  {
    label: "Requisition Raised by Name",
    value: "Adani Group",
  },
  {
    label: "BGV Assigned to",
    value: "Saloni Patel",
  },
  {
    label: "Candidate Profile Link",
    value: "www.iveri/xzy.com",
  },
  {
    label: "BGV Completion Status",
    value: "Completed",
  },
  {
    label: "BGV Requisition Unique Number",
    value: "COM101",
  },
];

export default function DashboardDetails() {

  const [totalClients, setTotalClients] = useState('0');
  const [totalAssigned, setTotalAssigned] = useState('0');
  const [totalInProgress, setTotalInProgress] = useState('0');
  const [totalCompleted, setTotalCompleted] = useState('0');
  const [totalRevenue, setTotalRevenue] = useState('0');

  const [verificationData, setVerificationData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get('agency/background-verifications/');
        setVerificationData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get('agency/agency-dashboard/');
        const data = response.data;

        setTotalClients(data.number_of_clients.toString());
        setTotalAssigned(data.number_of_verifications_assigned.toString());
        setTotalInProgress(data.number_of_verification_in_progress.toString());
        setTotalCompleted(data.number_of_verification_completed.toString());
        setTotalRevenue(data.total_revenue.toString());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <NavBar />
        <Container maxWidth="md" mx="auto" sx={{ marginTop: "100px"}}>

        <Grid container fullWidth>
        <Grid item xs={8} justifyContent="center" flex fullWidth mx="auto">
          <Typography
            style={{
              color: "rgb(0, 184, 230)",
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "5px",
            }}
          >
            Dashboard
          </Typography>

          <Grid container spacing={2} marginBottom="10px">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total number of clients:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip label={totalClients} />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom="10px">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total BGVs Assigned:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip label={totalAssigned} />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom="10px">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total BGVs in Progress:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip label={totalInProgress} />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom="10px">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total BGVs Completed:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip label={totalCompleted} />
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom="10px">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Total Revenue Generated:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip label={totalRevenue} />
            </Grid>
          </Grid>
        
        </Grid>

        <Grid item mx="auto" mt={2} justifyContent="center" flex>
        <Typography
            style={{
              color: "rgb(0, 184, 230)",
              fontSize: "20px",
              fontWeight: "600",
              marginBottom: "5px",
            }}
            textAlign="center"
          >
            All List of BGVs
          </Typography>

          <Grid container justifyContent="center" flex spacing={2}>
            {verificationData.map(item => (
              <Grid item key={item.id} xs={12} md={8}>
                <Card elevation={4} sx={{ my: 1}}>
                  <CardContent>
                  <Link to={`/verification/${item.verification_request_id}`} style={{ textDecoration: 'none' }}>
                  
                    <Typography variant="h6" color="#000">
                      Verification ID: {item.verification_request_id}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Requisition Type: {item.requisition_type === 'C' ? 'Company' : 'Individual'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Status: {item.status === 'P' ? "Pending" : "Completed"}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Last Updated: {new Date(item.updated_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Client: {item.client}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Assigned To: {item.assigned_to_name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Verification For: {item.verification_for_name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Candidate Iveri Id: 
                      <Link href="https://main.iveri.newrl.net/iveri-profile/" target="_blank" rel="noreferrer">
                      https://main.iveri.newrl.net/iveri-profile/
                      </Link>
                    </Typography>
                    

                  </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

        
        </Grid>
        </Grid>
          
        </Container>
    </>
  );
}
