import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Divider,
  IconButton,
  InputAdornment,
  Container,
  Box,
} from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import instance from "../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../globals/const";
import { saveAuth } from "../globals/utils";
import logo from "../../src/assets/iveri.png";
import AppleIcon from "../assets/appleIcon.png";
import GoogleIcon from "../assets/googleIcon.png";
import CustomButton from "../components/Button/CustomButtom.js";

export default function Login() {
  const [creds, setCreds] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePhoneNumberChange = (formattedValue) => {
    setPhoneNumber(formattedValue);
  };

  function setCredField(key, value) {
    let _creds = { ...creds };
    _creds[key] = value;
    setCreds(_creds);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(creds);

    instance
      .post(SERVER_BASE_ADDRESS + "user/login/", creds)
      .then((res) => {
        console.log("Response", res);
        window.localStorage.setItem("access_token", res.data.access);
        window.localStorage.setItem("refresh_token", res.data.refresh);
        window.localStorage.setItem("first_name", res.data.first_name);
        window.localStorage.setItem("last_name", res.data.last_name);
        window.localStorage.setItem("iveri_id", res.data.iveri_id);
        window.localStorage.setItem("role", res.data.role);
        window.localStorage.setItem("company_id", res.data.company_id);
        window.localStorage.setItem("email", res.data.email);
        window.localStorage.setItem("email_validated", res.data.email_validated);
        window.localStorage.setItem("phone_number", res.data.phone_number);
        window.localStorage.setItem("phone_validated", res.data.phone_validated);
        window.localStorage.setItem("is_paid_user", res.data.is_paid_user);

        saveAuth(res.data);


        switch (res.data.role) {
          case "User":
            window.open("/onboarding", "_self");
            break;
          case "Agency":
            window.open("/agency-menu", "_self");
            break;
          case "Employer":
            window.open("/emp-menu", "_self");
            break;
          case "Hiring Manager":
            window.open("/hiring-manager", "_self");
            break;
          case "HR":
            window.open("/human-resources", "_self");
            break;
          case "Recruiter":
            window.open("/recruiter", "_self");
            break;
          default:
            alert("Invalid Role");
        }
      })
      .catch(() => {
        alert("Authentication Failed");
      });
  };

  return (
    <Container component="main" fullWidth>
      <Grid container fullWidth mt="8%" justifyContent="center">
        <Grid item xs={12} md={5}>
          <Box display="flex" justifyContent="center" mb={4}>
            <img src={logo} alt="iVeri logo" style={{ width: "150px", height: "80px" }} />
          </Box>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ fontWeight: 550, pb: 1 }}>
                  Login
                </Typography>
                <Typography sx={{ color: "#999999", fontSize: "13px", pb: 1 }}>
                  Enter the correct details to login to your account!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 550 }}>
                  Email or Phone no.
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "55px !important",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(event) => setCredField("email", event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
                  Password
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  sx={{
                    backgroundColor: "rgba(214,214,214, 0.3)",
                    height: "55px",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(event) => setCredField("password", event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleTogglePassword}
                          aria-label="toggle password visibility"
                     
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} mt={3}>
                <Button sx={{ background: "#272260",
  border: "0.9px solid #272260",
  borderRadius: 2,
  color: "#FFFFFF",
  padding: "0 30px",
  margin: "auto",
  height: "53px",
  marginTop: "20px",
  width: "100%",
  display: "flex",
  marginBottom: "10px",
  fontSize: "20px",
  fontWeight: 400,
  fontFamily:"sans-serif",
  textTransform:"none",
  "&:hover": {
    backgroundColor: "#272260" || "#272260", 
  },}} onClick={handleSubmit}>{"Login"}</Button>
              </Grid>
              <Grid container justifyContent="flex-end" mt={1}>
                <Link to="/forgot-password" style={{ textDecoration: "none" }}>
                  <Box fullWidth>Forgot Password ?</Box>
                </Link>
              </Grid>
            </Grid>
          </form>

          <Divider sx={{ mt: 12, mb: 4 }}>or</Divider>

          {/* <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Link to="#" style={{ textDecoration: "none" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1.5}
                  sx={{ backgroundColor: "#EFEFF0", borderRadius: "4px" }}
                >
                  <img src={GoogleIcon} alt="Google Icon" />
                  <Typography color="black" sx={{ ml: 1 }}>
                    Google
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Link to="#" style={{ textDecoration: "none" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={1.5}
                  sx={{ backgroundColor: "#EFEFF0", borderRadius: "4px" }}
                >
                  <img src={AppleIcon} alt="Apple Icon" />
                  <Typography color="black" sx={{ ml: 1 }}>
                    Apple
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid> */}

          <Grid container justifyContent="center" mt={4}>
            <Link to="/sign-up" style={{ textDecoration: "none" }}>
              <Box textAlign="center">
                <Typography variant="body2" color="textSecondary" component="span" pr={0.5}>
                  Don't have an Account
                </Typography>
                <Typography variant="body2" color="#272260" component="span">
                  Sign Up?
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
