import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Chip, Container, IconButton, Box } from '@mui/material';
import { AccountCircle, Email, LocationOn, LinkedIn, GitHub, Public } from '@mui/icons-material';
import axios from 'axios';
import { SERVER_BASE_ADDRESS, FRONTEND_ADDRESS } from "../../globals/const";

import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';

const ViewCandidate = () => {
    const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get('candidates/');
        setCandidates(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleProfileClick = (iveriId) => {
    // to Replace
    // window.open(`https://main.iveri.newrl.net/iveri-profile/${iveriId}`, '_blank');
    window.location.href = `${FRONTEND_ADDRESS}view-candidates-details/${iveriId}`;

  };

  return (
    <Container maxWidth="md" sx={{ marginTop:"100px"}}>
    <NavBar />
    <Grid container spacing={3}>
    {candidates.map((candidate, index) => (
        <Grid item key={index} xs={12} md={6}>
          <Card
            sx={{ backgroundImage: 'linear-gradient(125deg, #FFF 70%, #e1f1fd 90%)', cursor: 'pointer', boxShadow: 5 }}
            onClick={() => handleProfileClick(candidate.iveri_id)}
          >
            <CardContent>
              <Typography fontWeight={600} variant="h6" >
                {`${candidate.first_name} ${candidate.last_name}`}
              </Typography>
              <br />
              
              <Box display="flex" alignItems="center"  justifyContent="space-between" pr={1}>
                {candidate.iveri_id && (
                  <Chip color="primary" icon={<AccountCircle />} label={candidate.iveri_id} variant="outlined" />
                )}

              </Box>
              
              <Box mt={1} alignItems="center" justifyContent="center" textAlign="left">
              {candidate.email && (
                  <Chip sx={{ borderColor: "#000" }} icon={<Email />} label={candidate.email} variant="outlined" />
                )}
              </Box>

              <Box mt={2} display="flex" alignItems="center">

                {(candidate.address__city || candidate.address__state) && (
                  <Typography fontWeight={500} variant="body1" display="flex" alignItems="center">
                    <span style={{ marginRight: '4px', my: "auto", p: 0, py: "auto" }}>
                      <LocationOn sx={{ my: "auto"}} />
                    </span>
                    {`${candidate.address__city}, ${candidate.address__state}`}
                  </Typography>
                )}

              </Box>

              <Box style={{ marginTop: '10px' }}>
                {candidate.basicinfo__linkedin && (
                  <IconButton href={candidate.basicinfo__linkedin} target="_blank">
                    <LinkedIn />
                  </IconButton>
                )}
                {candidate.basicinfo__github && (
                  <IconButton href={candidate.basicinfo__github} target="_blank">
                    <GitHub />
                  </IconButton>
                )}
                {candidate.basicinfo__behance && (
                  <IconButton href={candidate.basicinfo__behance} target="_blank">
                    <Public />
                  </IconButton>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

    </Container>
  );
}

export default ViewCandidate;
