import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Typography,Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Layout from "../../../components/Layout";
import NavBar from "../../../components/NavBar";
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@material-ui/core';
import {Container, Box } from "@mui/material";
import { styled } from "@mui/system";

const CenteredBox = styled(Box)({
    minHeight: "70vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "5.5rem",
    border: "5px rgb(5, 32, 71) solid",
    borderRadius: "20px",
    paddingBottom: "20px",
    width: "100%",
    maxWidth: "600px",
  });
  
  const styles = {
    header: {
      color: "rgb(0, 184, 230)",
      fontSize: "20px",
      fontWeight: "600",
      marginBottom: "5px",
    },
    button: {
      color: "rgb(3, 22, 48)",
      borderRadius: "30px",
      backgroundColor: "rgb(0, 184, 230)",
      padding: "2px 30px",
      fontWeight: "600",
      fontSize: "15px",
      marginTop: "12px",
    },
    container: {
      minHeight: "70vh",
      padding: "0 12px",
      border: "5px rgb(5, 32, 71) solid",
      borderBottomLeftRadius: "20px",
      borderBottomRightRadius: "20px",
  },
  img: {
      width: "7rem",
  },
  nav: {
      minHeight: "10vh",
      display: "flex",
      alignItems: "center",
      marginTop: "5.5rem",
      border: "5px rgb(5, 32, 71) solid",
      padding: "0",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
  },
  flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "10px",
      marginBottom: "5px",
  },
  };
  
  const AgencySignUp = (props) => {
    
    const [company, setCompany] = useState("");
    const [pan, setPAN] = useState("");
    const [gst, setGST] = useState("");
    const [name, setName] = useState("");
    
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    
    const [pass, setPass] = useState("");
    const [moOTP, setMoOTP] = useState("");
    const [gstOTP, setGSTOTP] = useState("");
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(email);
    };
  
    return (
      <Layout>
        <div>
        <Typography style={styles.header}>Agency Sign Up Page</Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            Company Name*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={company}
                            onChange={(event) => setCompany(event.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            PAN Number*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={pan}
                            onChange={(event) => setPAN(event.target.value)}
                        />
                    </div> 
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            GST Number*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={gst}
                            onChange={(event) => setGST(event.target.value)}
                        />
                    </div> 
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            Your Name*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            E-mail*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            Phone Number*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={number}
                            onChange={(event) => setNumber(event.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "5px 0",
                        }}
                    >
                        <label
                            htmlFor=""
                            style={{
                                fontSize: "20px",
                                fontWeight: "500",
                                letterSpacing: "1.5px",
                                color: "rgb(5, 32, 71)",
                                flex: "1",
                            }}
                        >
                            Set Password*
                        </label>
                        <input
                            type="text"
                            style={{ flex: "2", border: "2.5px black solid" }}
                            required
                            value={pass}
                            onChange={(event) => setPass(event.target.value)}
                        />
                    </div>
                        
          
  
          
  
          
  
          
          
  
          
  
          
  
          
  
                    <div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
     // Adjust the height as needed
  }}
>
  <Button type="submit" variant="contained" color="primary">Submit</Button>
</div>
        
        </form>
  
        
        </div>
        </Layout>
        
    );
  };
  
  export default AgencySignUp;



