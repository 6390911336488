import React, { useState, useEffect } from "react";
import { Grid, Box, Avatar, IconButton, Typography } from "@mui/material";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";
import instance from "../globals/axios";
import { getToken } from "../globals/authentication";
import CameraIcon from "../assets/Icons/cameraIcon.png";


const ProfileHeader = ({
  profilePic,
  showUploadIcon,
  showName = true,
  showTitle = true,
  showBackButton = true
}) => {
  const [currentProfilePic, setCurrentProfilePic] = useState(profilePic);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const history = useHistory();
  const [experiences, setExperiences] = useState([]);


  useEffect(() => {
    const storedFirstName = localStorage.getItem("first_name") || "";
    const storedLastName = localStorage.getItem("last_name") || "";
    setFirstName(storedFirstName);
    setLastName(storedLastName);
  }, []);



  useEffect(() => {
    const fetchExperience = async () => {
      try {
        const response = await instance.get('experience-details/');
        console.log('API Response:', response.data); // Log the entire response
  
        // Access the array directly if response.data is an array
        const experiences = response.data; // Update this line
        console.log('Experiences:', experiences);
  
        const sortedExperiences = experiences.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
        setExperiences(sortedExperiences); // This should now correctly set the experiences
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchExperience();
  }, []);



  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await instance.get("/upload-profile-picture/", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data", 
          },
        });

        if (response.data && response.data.profile_picture) {
          setCurrentProfilePic(response.data.profile_picture);
        } else {
          setCurrentProfilePic("");
        }
      } catch (error) {
        console.error("Error fetching profile picture:", error);
        setCurrentProfilePic("");
      }
    };

    fetchProfilePic();
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleProfilePicUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const newProfilePicUrl = URL.createObjectURL(file);
      setCurrentProfilePic(newProfilePicUrl);

      const formData = new FormData();
      formData.append("profile_picture", file);

      try {
        const response = await instance.post("/upload-profile-picture/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        });
        console.log("Profile picture uploaded successfully:", response.data);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (currentProfilePic && currentProfilePic.startsWith("blob:")) {
        URL.revokeObjectURL(currentProfilePic);
      }
    };
  }, [currentProfilePic]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      mb={1}
      sx={{ position: "relative" }}>
      <Box position="relative">
        <Avatar
          src={currentProfilePic}
          sx={{ width: 100, height: 100 }}
        />
        {showUploadIcon && (
          <IconButton component="label" sx={{ position: "absolute", top: 0, right: -30 }} >
            <img src={CameraIcon} style={{ width: '24px', height: '24px' }}/>
            <input type="file" hidden accept="image/*" onChange={handleProfilePicUpload} />
          </IconButton>
        )}
      </Box>
      {showName && (
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          {firstName} {lastName}
        </Typography>
      )}
{showTitle && experiences.length > 0 && (
  <Box>
    <Typography variant="body2" color="textSecondary">
      {experiences[0].designation ? experiences[0].designation : "No designation available"}
    </Typography>
  </Box>
)}




      {showBackButton && (
        <ArrowBackIosIcon
          onClick={handleBack}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            border: "2px solid black",
            borderRadius: "100%",
            padding: "1px",
            paddingLeft: "6px",
            color: "black",
            height: "15px",
            width: "11px",
          }}
        />
      )}
    </Grid>
  );
};

export default ProfileHeader;
