import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { MdVisibility, MdEdit, MdDelete } from 'react-icons/md';
import eyeIcon from "../assets/Icons/eye.png"
import editIcon from "../assets/Icons/Edit.png"
import trashIcon from "../assets/Icons/delete.png"

const DocumentBox = ({ 
  fileName, 
  onClick, 
  documentUrl, 
  showEdit = false, 
  showDelete = false, 
  onEditClick, 
  onDeleteClick 
}) => {
  return (
    <Box 
      display="flex" 
      alignItems="center" 
      justifyContent="space-between" 
      marginY={1.5} 
      sx={{
        cursor: 'pointer', 
        border: "1.5px solid #262262 !important", 
        borderRadius: "5px", 
        padding: "1px 6px !important"
      }}
    >
      <Typography variant="body1">
        {fileName}
      </Typography>
      <Box display="flex" alignItems="center">
        <IconButton onClick={() => onClick(documentUrl)}>
          <img src={eyeIcon} style={{width: "20px", height: "14px"}} />
        </IconButton>

        {showEdit && (
          <IconButton onClick={() => onEditClick(documentUrl)}>
            <img src={editIcon} style={{width: "20px", height: "20px"}}  />
          </IconButton>
        )}

        {showDelete && (
          <IconButton onClick={() => onDeleteClick(documentUrl)}>
            <img src={trashIcon} style={{width: "20px", height: "20px"}}  />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default DocumentBox;

