// src/components/CustomTypography.js
import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: "#262262 !important",
  fontSize: "16px !important",
  fontWeight: "510 !important",
  paddingRight: theme.spacing(1.5),
}));

export default CustomTypography;
