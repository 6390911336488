import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Tabs, Tab, Typography, CircularProgress, Chip, Button, TextField, Autocomplete, MenuItem, FormControl, InputLabel, Select, Container, Grid, Card, CardContent, CardActions, Switch, FormControlLabel, Avatar
} from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import dayjs from 'dayjs';
import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';
import { PersonOutlined } from '@mui/icons-material';

const industries = [
  "Information Technology (IT)", "Healthcare", "Education", "Accounting", "Pharmaceutical", "Finance", "Engineering",
  "Real Estate", "Higher Education", "Sales", "Government", "Energy", "Retail", "Manufacturing", "Architecture",
  "Human Resources", "Nonprofit", "Transportation", "Hospitality", "Advertising", "Operations"
];

const jobTypes = ["Entry Level", "Experienced", "Manager", "Executive", "Senior Executive"];

const locations = [
  "Remote", "Hybrid", "On Site", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
  "Dadra and Nagar Haveli and Daman and Diu", "Goa", "Gujarat", "Haryana",
  "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala",
  "Ladakh", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur",
  "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab",
  "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal",
];

const MyUploads = () => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [filters, setFilters] = useState({ industry: '', location: '', job_type: '', keywords: [], onlyWorkday: false });

  useEffect(() => {
    fetchDocuments();
  }, [tabValue, filters]);

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const endpoint = tabValue === 0 ? '/resume-matching-system/user-resumes/' : '/resume-matching-system/user-jds/';
      const res = await instance.get(endpoint, { params: filters });
      setDocuments(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleKeywordsChange = (event, newValue) => {
    setFilters({ ...filters, keywords: newValue });
  };

  const handleWorkdayToggle = (event) => {
    setFilters({ ...filters, onlyWorkday: event.target.checked });
  };

  const filteredDocuments = filters.onlyWorkday ? documents.filter((_, index) => index % 2 === 0) : documents;

  return (
    <React.Fragment>
      <NavBar />
      <Container maxWidth="md" style={{ marginTop: '80px', marginBottom: "60px" }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Uploaded Resumes" />
            <Tab label="Uploaded JDs" />
          </Tabs>
          <FormControlLabel
            control={<Switch checked={filters.onlyWorkday} onChange={handleWorkdayToggle} />}
            label="Only show fetched via Workday"
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="select-1">Industry</InputLabel>
            <Select id="select-1" labelId="select-1" name="industry" label="Industry" value={filters.industry} onChange={handleFilterChange}>
              {industries.map((industry, index) => (
                <MenuItem key={index} value={industry}>{industry}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-2">Location</InputLabel>
            <Select id="select-2" labelId="select-2" name="location" label="Location" value={filters.location} onChange={handleFilterChange}>
              {locations.map((location, index) => (
                <MenuItem key={index} value={location}>{location}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-3">Job Type</InputLabel>
            <Select id="select-3" labelId="select-3" name="job_type" label="Job Type" value={filters.job_type} onChange={handleFilterChange}>
              {jobTypes.map((jobType, index) => (
                <MenuItem key={index} value={jobType}>{jobType}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={filters.keywords}
            onChange={handleKeywordsChange}
            renderInput={(params) => (
              <TextField {...params} label="Keywords" placeholder="Add keywords" />
            )}
            fullWidth
          />
        </Box>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ ml: 2 }}>Loading documents...</Typography>
          </Box>
        ) : (
          <Grid container spacing={4}>
            { filteredDocuments.length === 0 && (
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 6, mx: 'auto'}}>No Documents found.</Typography>
              )
            }

            {filteredDocuments.map((doc, index) => (
              <Grid item xs={12} key={index}>
                <Card sx={{ height: '100%', position: 'relative', '&:hover': { transform: 'scale(1.025)', transition: 'transform 0.3s' } }}>
                  <CardContent>
                    {(filters.onlyWorkday || index % 2 === 0) && (
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                      >
                        Via Workday
                      </Button>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar>
                        {
                          tabValue === 0 ? (  
                            <PersonOutlined />
                          ) : (
                            <FileOpenIcon />
                          )
                        }
                      </Avatar>
                      <Typography variant="body1" component="a" href={doc.file} target="_blank" rel="noopener noreferrer" sx={{ ml: 2 }}>
                        {tabValue === 0 ? doc.resume_file_name : doc.jd_file_name}
                      </Typography>
                    </Box>
                    <Typography variant="body2">Uploaded At: {dayjs(doc.uploaded_at).format('HH:mm DD-MM-YYYY')}</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                      {doc.industry && <Chip variant='outlined' label={doc.industry} />}
                      {doc.job_type && <Chip variant='outlined' label={doc.job_type} />}
                      {doc.location && <Chip variant='outlined' label={doc.location} />}
                      {doc.keywords.slice(0, 3).map((keyword, keywordIndex) => (
                        keyword && <Chip variant='outlined' key={keywordIndex} label={keyword} />
                      ))}
                    </Box>
                  </CardContent>
                  <Grid textAlign="right" item xs={12}>

                  <Box mb={2} mx="auto" mr={4} alignContent="center" justifyItems="center">
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ mr: 1 }}
                      href={tabValue === 0 ? doc.resume_file_pdf : doc.jd_file_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View PDF
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      component={Link}
                      to={{
                        pathname: '/matching-details',
                        state: { doc, process: tabValue === 0 ? 'resume' : 'job description' }
                      }}
                    >
                      {tabValue === 0 ? "View Matching JDs" : "View Matching Resumes"}
                    </Button>
                  </Box>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
};

export default MyUploads;
