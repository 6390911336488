import React, { useState } from 'react';
import axios from "axios";
import NavBar from "../../components/NavBar";
import { Button, TextField, Typography, Box, Card, Grid, MenuItem, Container } from "@mui/material";
import instance from "../../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../../globals/const";



export default function Address() {

  
    const codeVerifier = "Z~EzgUE67syTdG65IHBzgiV19ECjgZ7xa7o7GdP4xxibEv4oAPuqp8-qk4FD7plPVpbL7Bwom73S7vofdLg9oZUlvkVE2Xs.AIa2JYFe85464YC3dmJ935hzijO-FCN-"
    const codeChallenge = "vpSG4Q07XUVf94FM93WOvGRtR6dVQs4uDWfbiyaEckE"

    const [code, setCode] = useState('');
    const [state, setState] = useState('');

    const handleAuthorize = async () => {
        try {
       
        const response = await axios.get(
            'https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize',
            {
                mode: 'no-cors',
                params: {
                    response_type: 'code',
                    client_id: 'HYD95FECA5',
                    redirect_uri: 'https://iverify-uat.newrl.net/api/',
                    state: '9636233946',
                    code_challenge_method: 'S256',
                    code_challenge: '01e27171e061878232eadaf2770ad45eb4e6cd79',
                },
            }
        );
    

        // Assuming the response contains code and state
        setCode(response.data.code);
        setState(response.data.state);
        console.log(response.data)

        // Handle the code and state as needed
        } catch (error) {
        console.error('Error:', error);
        // Handle errors
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();


        instance.post(SERVER_BASE_ADDRESS + "update-address/") 
        .then((res) => {
            console.log(res.data);
            alert("Data Saved successfully")
            setTimeout(() => {
            window.location = '/edit-additional-info'; 
            }, 200); 
        })
        .catch((error) => {
            console.error(error);
        });
    };
  
  
    return (
        <>
            <NavBar backRoute={"/edit-basic-info"} nextRoute={"/edit-additional-info"} />
            <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
                <Grid xs={10} mx="auto" textAlign="center">
                <Typography
                    style={{
                    color: "rgb(0, 184, 230)",
                    fontSize: "22px",
                    fontWeight: "600",
                    }}
                >
                    Link My Digilocker
                </Typography>
                
                </Grid>


            
                <Grid xs={5} my={3}>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={handleAuthorize}
                >
                    Authorize
                </Button>
                </Grid>

                <Typography variant='h3'>
                {code} - {state}
                </Typography>

                <Grid xs={6} my={3}>
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        color: "rgb(3, 22, 48)",
                        borderRadius: "30px",
                        backgroundColor: "rgb(0, 184, 230)",
                        padding: "2px 30px",
                        fontWeight: "600",
                        fontSize: "15px",
                    }}
                    //   onClick={handleSubmit}
                >
                    SAVE
                </Button>

                </Grid>

                
            </Container>
        </>
    );
}