import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Container } from '@mui/material';
import instance from '../globals/axios';
import logo from '../../src/assets/iveri.png'


const OtpVerification = () => {
  const [phone, setPhone] = useState(localStorage.getItem('phone_number'));
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [phoneOtp, setPhoneOtp] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [phoneSent, setPhoneSent] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false)

  const handleSendPhoneOtp = async () => {
    setLoadingPhone(true);
    try {
      const response = await instance.get(`/otp/otpmoduleintegrations/send_otp/?phone_number=${phone.substring(2)}`);
      setPhoneSent(true);
      if (response.status === 200) {
        alert('OTP sent successfully to your phone number.');
      }
    } catch (error) {
      alert(`Error sending OTP to your phone number: ${error.message}`);
    } finally {
      setLoadingPhone(false);
    }
  };

  const handleSendEmailOtp = async () => {
    setLoadingEmail(true);
    try {
      const response = await instance.get(`/otp/otpmoduleintegrations/send_otp_email?email=${email}`);
      setEmailSent(true)
      if (response.status === 200) {
        alert('OTP sent successfully to your email address.');
      }
    } catch (error) {
      alert(`Error sending OTP to your email address: ${error.message}`);
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleVerifyPhoneOtp = async () => {
    try {
      const response = await instance.get(`/otp/otpmoduleintegrations/verify_otp/?data=${phone.substring(2)}&otp=${phoneOtp}`);
      if (response.status === 200) {
        alert('Phone OTP verified successfully.');
        setPhoneVerified(true);
      }
    } catch (error) {
      alert(`Error verifying phone OTP: ${error.message}`);
    }
  };

  const handleVerifyEmailOtp = async () => {
    try {
      const response = await instance.get(`/otp/otpmoduleintegrations/verify_otp/?data=${email}&otp=${emailOtp}`);
      if (response.status === 200) {
        alert('Email OTP verified successfully.');
        setEmailVerified(true);
      }
    } catch (error) {

      alert(`Error verifying email OTP: ${error.message}`);
    }
  };

  const iveriId = localStorage.getItem('iveri_id');

  useEffect(() => {
    if (phoneVerified && emailVerified) {
        const sendData = async () => {
          try {
            const response = await instance.post('user/validate/', {
              iveri_id: iveriId,
            });
            alert("Validation Successful");
            setTimeout(() => {
               window.open("/profile-page", "_self");
            }, 200);     
          } catch (error) {
            console.error(error);
          }
        };
  
        sendData();
      }
  }, [phoneVerified, emailVerified]);

  return (
    <Container component='main' fullWidth >

        <Grid fullWidth mt="10%" container justifyContent="center">

        <Grid item my="auto" xs={12} md={5} >       
        <Grid flex xs={6} sx={{ mx:"auto"}}>
            <img mx="auto" alt='iVeri logo' src={logo} style={{ width: "100%" }} />
        </Grid>

        <Grid container spacing={4} p={2}>
        <Grid item xs={12}>
            <TextField
            label="Phone Number"
            value={`+${phone.slice(0, 2)} ${phone.slice(2)}`}
            variant="outlined"
            disabled
            fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <Button
            variant="contained"
            color="primary"
            onClick={handleSendPhoneOtp}
            disabled={loadingPhone || phoneSent}
            fullWidth
            >
            {loadingPhone ? 'Sending...' : phoneSent ? 'Sent OTP' : 'Send Phone OTP'}

            </Button>
        </Grid>
        <Grid item xs={12}>
            <TextField
            label="Phone OTP"
            value={phoneOtp}
            onChange={(e) => setPhoneOtp(e.target.value)}
            variant="outlined"
            fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <Button
            variant={phoneVerified ? 'outlined' : 'contained'}
            color="success"
            onClick={handleVerifyPhoneOtp}
            disabled={!phoneSent || phoneVerified}
            fullWidth
            >
            {phoneVerified ? 'Verified Successfully' : 'Verify Phone OTP'}
            </Button>
        </Grid>

        <Grid item xs={12}>
            <TextField
            label="Email"
            value={email}
            variant="outlined"
            disabled
            fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmailOtp}
            disabled={loadingEmail || emailSent}
            fullWidth
            >
                {loadingEmail ? 'Sending...' : emailSent ? 'Sent OTP' : 'Send Email OTP'}
            </Button>
        </Grid>
        <Grid item xs={12}>
            <TextField
            label="Email OTP"
            value={emailOtp}
            onChange={(e) => setEmailOtp(e.target.value)}
            variant="outlined"
            fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <Button
            variant={emailVerified ? 'outlined' : 'contained'}
            color="success"
            onClick={handleVerifyEmailOtp}
            disabled={!emailSent || emailVerified}
            fullWidth
            >
            {emailVerified ? 'Verified Successfully' : 'Verify Email OTP'}
            </Button>
        </Grid>
        </Grid>
        
        
        </Grid>
    
        </Grid>    

    </Container>

  );
};

export default OtpVerification;
