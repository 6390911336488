import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Box, Grid, MenuItem, Container } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import instance from "../../../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../../../globals/const";
import { statesData, citiesData } from "./data";
import ProfileHeader from '../../../components/ProfileHeader.js';
import Sidebar from '../../../components/Sidebar.js';

export default function PermanentAddress() {
  const [formData, setFormData] = useState({
    houseNo: '',
    street: '',
    landmark: '',
    area: '',
    city: '',
    postOffice: '',
    district: '',
    state: '',
    pincode: '',
    country: 'India',
  });

  const [availableCities, setAvailableCities] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleStateChange = (state) => {
    setFormData(prevState => ({
      ...prevState,
      state: state,
      city: ''
    }));
    setAvailableCities(citiesData[state] || []);
  };

  useEffect(() => {
    instance.get(SERVER_BASE_ADDRESS + "update-address/")
      .then((res) => {
        const addressData = res.data[0];
        setFormData(prevState => ({
          ...prevState,
          ...addressData
        }));
        if (addressData.state) {
          setAvailableCities(citiesData[addressData.state] || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching address data:", error);
      });
  }, []);

  useEffect(() => {
    const allFieldsFilled = Object.values(formData).every(field => field !== '');
    setIsFormValid(allFieldsFilled);
  }, [formData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isFormValid) return;

    const pincodeRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
    if (!formData.pincode.match(pincodeRegex)) {
      alert("Please enter a valid Indian PIN code.");
      return;
    }

    const updatedFormData = new FormData();
    Object.keys(formData).forEach(key => {
      updatedFormData.append(key, formData[key]);
    });

    instance.post(SERVER_BASE_ADDRESS + "update-address/", updatedFormData)
      .then((res) => {
        console.log(res.data);
        alert("Data Saved successfully");
        setTimeout(() => {
          window.location = "/address-viewpage";
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const renderTextField = (name, label, gridProps = {}) => (
    <Grid item {...gridProps}>
      <Typography sx={{ fontSize: "15px" }} pb={1}>
        {label}<span style={{ color: 'red' }}>*</span>
      </Typography>
      <TextField
        name={name}
        value={formData[name]}
        onChange={handleChange}
        variant="outlined"
        required
        fullWidth
        sx={{
          backgroundColor: "rgba(214,214,214, 0.3)",
          borderRadius: "5px",
          height: "45px",
          '& .MuiOutlinedInput-root': {
            '& fieldset': { border: 'none' },
            '&:hover fieldset': { border: 'none' },
            '&.Mui-focused fieldset': { border: 'none' },
            '& input': { padding: '10px 14px' },
          },
        }}
      />
    </Grid>
  );

  return (
    <>
      <Sidebar />
      <Container style={{ marginTop: '30px' }}>
        <ProfileHeader showUploadIcon={false} />
        <Box display="flex" alignItems="center" pb={1} mt={2}>
          <Typography variant="h6" sx={{ fontWeight: "600" }}>Address</Typography>
          <DoneIcon sx={{ color: 'white', backgroundColor: "#262262", fontSize: "15px", borderRadius: "100%", ml: 1 }} />
        </Box>

        <Grid container spacing={2} sx={{ mb: "0.2rem" }}>
          {renderTextField("houseNo", "House No", { xs: 12 })}
          {renderTextField("street", "Street", { xs: 12 })}
          {renderTextField("landmark", "Landmark", { xs: 12 })}
          {renderTextField("area", "Area", { xs: 12 })}
          {renderTextField("country", "Country", { xs: 12 })}

          <Grid item xs={12} md={4}>
            <Typography style={{ marginTop: "6px" }} pb={1}>State<span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              select
              required
              size="small"
              name="state"
              value={formData.state}
              onChange={(e) => handleStateChange(e.target.value)}
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { border: "none" },
                },
                height: "45px",
                borderRadius: "5px",
              }}
              fullWidth
            >
              {statesData.map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography color="black" style={{ marginTop: "6px"}} pb={1}>City<span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              select
              required
              size="small"
              name="city"
              value={formData.city}
              onChange={handleChange}
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { border: "none" },
                },
                height: "45px",
                borderRadius: "5px",
              }}
              fullWidth
            >
              {availableCities.map((city, index) => (
                <MenuItem key={index} value={city}>
                  {city}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {renderTextField("district", "District", { xs: 12, md: 4 })}
          {renderTextField("postOffice", "Post Office", { xs: 12, md: 4 })}

          <Grid item xs={12} md={4}>
            <Typography color="black" style={{ marginTop: "8px" }} pb={1}>Pincode<span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              variant="outlined"
              required
              fullWidth
              sx={{
                backgroundColor: "rgba(214,214,214, 0.3)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { border: "none" },
                },
                height: "45px",
                borderRadius: "5px",
              }}
              size="small"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center" my={3}>
          <Grid xs={12} sm={6}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              sx={{ backgroundColor: "#272260" }}
              disabled={!isFormValid}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}