import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Container, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, Chip, TableRow, Paper, Grid, Box, Card, CardContent } from "@mui/material";
import api from "../../globals/axios";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { LocalConvenienceStoreOutlined, OpenInNew } from '@mui/icons-material';
import { LocationOn, MonetizationOn, Event, Description, AccountCircle, Business, AccountBalance, Person, EventNote } from '@mui/icons-material';
import { SERVER_BASE_ADDRESS, FRONTEND_ADDRESS } from "../../globals/const";
import instance from "../../globals/axios.js";
import { Link } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { FaLinkedin, FaBehance, FaGithub, FaFacebook, FaUser, FaCheck, FaTimes, FaPlus} from 'react-icons/fa';
import ViewEducation from "../../components/ViewEducation.js";
import ViewExperiences from "../../components/ViewExperiences.js";
import ViewLanguages from "../../components/ViewLanguages.js";

import NavBar from "../../components/NavBar";

export default function ViewProfile() {
  const [personalDetails, setPersonalDetails] = useState({});
  const [mediaLinks, setMediaLinks] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [langData, setLangData] = useState(null);
  const [skills, setSkills] = useState(null);

  const history = useHistory();

  function convert_python_case(str) {
    // Converts a string from python case to proper title. For example...
    // example_string --> Example String
    return str.replace("_", " ").toLowerCase().split(" ").map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(" ");
  }

  let { iveri_id } = useParams();
  
  useEffect(() => {
 
    instance
      .get(`view-profile/${iveri_id}/`)
      .then(res => res.data)
      .then(data => {
        console.log(data)
        setPersonalDetails(data.personal_details);
        setLangData(data.personal_details.language_data)
        setSkills(data.personal_details.skills)

        let str = data.personal_details.hobbies;
        let validJsonStr = str.replace(/'/g, '"');
        let myArray = JSON.parse(validJsonStr);
        setHobbies(myArray)
        setMediaLinks(data.links);
      });
  }, []);


  const [educationalDetails, setEducationalDetails] = useState([]);

  useEffect(() => {
    instance.get(`educational-details/${iveri_id}/`)
      .then(response => {
        setEducationalDetails(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    instance.get(`experience-details/${iveri_id}/`)
      .then(response => {
        setExperiences(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleEditBtn = () => {
    history.push("/edit-profile-menu");
  };

  return (
    <div>
      <NavBar showAvatar={false}/>
      <Container maxWidth= "xs" style={{ marginTop: '80px' }}>
        <Grid item xs={12} textAlign="left">
                              <Button onClick={() => window.history.back()} startIcon={<ArrowBackIcon />}>
                                  Back
                              </Button>
        </Grid>

        <Grid container justifyContent="space-between" alignItems="center" mb={2} spacing={2}>
        <Grid item xs={12} textAlign="center" mt={1}>
            <Typography variant="h6" gutterBottom style={{color: "rgb(0, 184, 230)", fontWeight: "600"}}>
              Personal Details
            </Typography>
          </Grid>
        
        </Grid>

        <Grid container direction="column" spacing={2}>


        <Grid item container alignItems="center" spacing={1}>
              
              <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
                Name :
              </Typography>
              <Typography variant="body1" style={{ marginLeft: "5px"}}>
                {personalDetails.name}
              </Typography>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
            <FontAwesomeIcon icon={faCircleCheck} color="primary" style={{ marginRight: "5px", color: "rgb(0, 184, 230)" }} />
            <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
              Mobile Number :
            </Typography>
            <Typography variant="body1" style={{ marginLeft: "5px"}}>
              {personalDetails.mobile_number}
            </Typography>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
          <FontAwesomeIcon icon={faCircleCheck} color="primary" style={{ marginRight: "5px", color: "rgb(0, 184, 230)" }} />
                <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
                  Email ID :
                </Typography>
                <Typography variant="body1" style={{ marginLeft: "5px"}}>
                  {personalDetails.email}
                </Typography>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
              
              <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
                Gender :
              </Typography>
              <Typography variant="body1" style={{ marginLeft: "5px"}}>
                {personalDetails.gender === 'M' ? "Male" : (personalDetails.gender === 'F' ? "Female" : (personalDetails.gender === 'O' ? "Others" : "Not Added"))}
              </Typography>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
              
              <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
                Permanent Address :
              </Typography>
              <Typography variant="body1" style={{ marginLeft: "20px"}}>
                {personalDetails.address}
              </Typography>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
              
              <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
              Temporary Address :
              </Typography>
              <Typography variant="body1" style={{ marginLeft: "20px"}}>
                {personalDetails.temp_address}
              </Typography>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
              
              <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
                Hobbies :
              </Typography>
              <Box>
             
             { hobbies && (hobbies.map((hobby) => (
              <Chip
                key={hobby}
                label={hobby}
                style={{ margin: '4px' }}
              />
                )) )
              }
              </Box>
          </Grid>

          <Grid item container alignItems="center" spacing={1}>
              
              <Typography variant="body1" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
                Skills :
              </Typography>

              <Box mt={2}>
             
              {Array.isArray(skills) && skills.length > 0 && (
                  skills.map((skill) => (
                    <Chip
                      style={{ color: '#fff', backgroundColor: "#1976D2", margin: '4px' }}                
                      label={skill.skill_name}
                    />
                  ))
                )}
              </Box>
          </Grid>

          <Grid item>
            <Typography variant="h6" style={{ fontWeight: "700", color: "rgb(0, 184, 230)" }}>
            Social Platform Links:
            </Typography>
          </Grid>

          <Grid container ml={3} alignItems="center" spacing={2} mt={0.25}>

            <Grid container>
                <Grid item xs={1}>
                  <FaLinkedin size={30} color="primary" />
                </Grid>
                {personalDetails.linkedin!=="N/A" ? (
                    <Grid item ml={2} xs={10}>
                      <Typography
                        onClick={() => window.open(personalDetails.linkedin, '_blank')}
                        style={{ cursor: 'pointer' }}
                      >
                        {personalDetails.linkedin}
                      </Typography>
                    </Grid>
                
                ) : (
                  <Grid item ml={2} xs={10}>
                    <Typography>
                      Not added
                    </Typography>
                  </Grid>
                )}
            </Grid>

            <Grid container>
              <Grid item xs={1}>
                <FaBehance size={30} color="primary" />
              </Grid>
              {personalDetails.behance !== "N/A" ? (
                <Grid item ml={2} xs={10}>
                  <Typography
                    onClick={() => window.open(personalDetails.behance, '_blank')}
                    style={{ cursor: 'pointer' }}
                  >
                    {personalDetails.behance}
                  </Typography>
                </Grid>
              ) : (
                <Grid item ml={2} xs={10}>
                  <Typography>
                    Not added
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container>
              <Grid item xs={1}>
                <FaFacebook size={30} color="primary" />
              </Grid>
              {personalDetails.facebook !== "N/A" ? (
                <Grid item ml={2} xs={10}>
                  <Typography
                    onClick={() => window.open(personalDetails.facebook, '_blank')}
                    style={{ cursor: 'pointer' }}
                  >
                    {personalDetails.facebook}
                  </Typography>
                </Grid>
              ) : (
                <Grid item ml={2} xs={10}>
                  <Typography>
                    Not added
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container>
              <Grid item xs={1}>
                <FaGithub size={30} color="primary" />
              </Grid>
              {personalDetails.github !== "N/A" ? (
                <Grid item ml={2} xs={10}>
                  <Typography
                    onClick={() => window.open(personalDetails.github, '_blank')}
                    style={{ cursor: 'pointer' }}
                  >
                    {personalDetails.github}
                  </Typography>
                </Grid>
              ) : (
                <Grid item ml={2} xs={10}>
                  <Typography>
                    Not added
                  </Typography>
                </Grid>
              )}
            </Grid>

            {personalDetails.other !== "N/A" ? (
            <Grid container>
              <Grid item xs={1}>
                <FaUser size={30} color="primary" />
              </Grid>
              
                <Grid item ml={2} xs={10}>
                  <Typography
                    onClick={() => window.open(personalDetails.other, '_blank')}
                    style={{ cursor: 'pointer' }}
                  >
                    {personalDetails.other}
                  </Typography>
                </Grid>
              </Grid>
              ) : (
                null
              )}
          

          </Grid>
          


          {langData && langData !== "N/A" && langData!== null && (            
            < ViewLanguages langData={langData} />
          )}


          < ViewEducation educationalDetails={educationalDetails} />

          < ViewExperiences experiences={experiences} />

          <Grid mt={2} mb={6} sx={{textAlign: "center "}} container spacing={2}>
                    <Grid item xs={4}>
                            <Button onClick={() => window.history.back()} fullWidth startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                    </Grid>
                   
                    <Grid item xs={6}>
                        <Button
                            fullWidth

                            endIcon={ <TurnedInNotIcon />}
                            // onClick={() => handleApplyNow(requirement.requirement_id)}
                            variant="contained"
                            color="success"
                        >
                            Save
                        </Button>
                    </Grid>
                    
                </Grid>

        </Grid>
        </Container>
    </div>
  );
}