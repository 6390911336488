import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Container, Box, Card, FormControl, InputLabel, Select, MenuItem, Typography, Divider, IconButton, Radio, RadioGroup, FormControlLabel, CircularProgress} from '@mui/material';
import { useParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { degreeOptions } from '../User/EditPages/data';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useLocation } from 'react-router-dom';
import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';

const MyComponent = () => {
    const [hrList, setHrList] = useState([]);
    const [selectedHr, setSelectedHr] = useState('');
    const [designation, setDesignation] = useState('');
    const [profileTitle, setProfileTitle] = useState('');
    const [sbuName, setSbuName] = useState('');
    const [requirement, setRequirement] = useState({
            sbu_name: '',
            profile_title: '',
            designation: '',
            number_of_positions: '',
            level_of_position: '',
            roles_and_responsibilities: '',
            job_brief: ''
    });
    const [updatedRequirement, setUpdatedRequirement] = useState({});
    const [educationType, setEducationType] = useState('');
    const [degree, setDegree] = useState('');
    const role = localStorage.getItem('role')
    console.log(role)

    const { state } = useLocation();
    const extractedData = state ? state.extractedData : null;
    const company_id = localStorage.getItem('company_id');

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        return date.toLocaleDateString(undefined, options);
    }

    useEffect(() => {
        // Fetch HR list
        instance.get(`/company/${company_id}/get_user/`, {
        params: {
            role: 5
        }
        })
        .then(response => {
        setHrList(response.data);
        })
        .catch(error => {
        console.error('Error fetching HR list:', error);
        });

        setRequirement(prevState => ({
            ...prevState,
            profile_title: extractedData?.profile_title,
            designation: extractedData?.designation,
            number_of_positions: extractedData?.number_of_positions,
            level_of_position: extractedData?.level_of_position,
            roles_and_responsibilities: extractedData?.roles_and_responsibilities,
            job_brief: extractedData?.job_brief,
            sbu_name: extractedData?.company
        }));

    }, []);

    const handleHrSelect = (event) => {
        const selectedHrData = hrList.find(hr => hr.user.iveri_id === event.target.value);
        setSelectedHr(selectedHrData);
        setDesignation(selectedHrData.user.designation || '');
        setProfileTitle(selectedHrData.user.profile_title || '');
    }

    const handleChange = (field, value) => {
      setUpdatedRequirement(prevState => ({
        ...prevState,
        [field]: value,
      }));
    };

    const handleUpdate = () => {
  
      const dataToPost = {
        name: selectedHr.user.first_name + ' ' + selectedHr.user.last_name,
        iveri_id: selectedHr.user.iveri_id,
        HR: selectedHr.user.id,
        is_active: true,
        is_published: false
      };

      const rolesAndResponsibilitiesString = requirement.roles_and_responsibilities.join(', ');

      const combinedData = {
        ...dataToPost,
        ...requirement,
        roles_and_responsibilities: rolesAndResponsibilitiesString,
      };

      instance.post(`/company/${company_id}/add_requirement/`, combinedData)
        .then(response => {
          alert('Requirement Posted successfully:');
          setTimeout(() => {
            const { role } = selectedHr.user;
            if (role === 'Hiring Manager') {
              window.location = '/hiring-manager';
            } else if (role === 'HR') {
              window.location = '/human-resource';
            } else {
              // Handle other roles or scenarios
              console.error('Unknown role:', role);
            }
          }, 500);
        })
        .catch(error => {
          console.error('Error posting:', error);
        });
      }


  return (
    <React.Fragment>

    <NavBar />
    <Container maxWidth="md" sx={{ marginTop:"100px"}}>

    <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h5'>
      Create A New Requirement
      </Typography>
    </Grid>
    <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Select HR</InputLabel>
          <Select
            value={selectedHr ? selectedHr.user.iveri_id : ''}
            label="Select HR"
            onChange={handleHrSelect}
          >
            {hrList.map(hr => (
              <MenuItem key={hr.user.iveri_id} value={hr.user.iveri_id}>
                {hr.user.first_name} {hr.user.last_name} - Iveri ID: {hr.user.iveri_id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="SBU or Company Name"
          value={requirement.sbu_name}
          onChange={e => handleChange('sbu_name', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
            <TextField
              label="Profile Title"
              fullWidth
              value={requirement.profile_title}
              onChange={e => handleChange('profile_title', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Designation"
              fullWidth
              value={requirement.designation}
              onChange={e => handleChange('designation', e.target.value)}
            />
          </Grid>
          
          <Grid item xs={6}>
            <TextField
              label="Number of Positions"
              fullWidth
              defaultValue={1}
              value={requirement.number_of_positions}
              onChange={e => handleChange('number_of_positions', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Job Location"
              fullWidth
              value={requirement.job_location}
              onChange={e => handleChange('job_location', e.target.value)}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            sx={{ width: "100%"}}
            label="Start Date (MM-DD-YYYY)"
            value={requirement.start_date}
            onChange={(newValue) => {
              const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
              // Update your component's state with formatted date string
              setRequirement((prev) => ({
                ...prev,
                start_date: newValue, // Assuming you want to keep this format in your local state
              }));
              // Pass the YYYY-MM-DD formatted date to your handleChange function
              handleChange('start_date', formattedDate);
            }}
            renderInput={(params) => <TextField {...params}  />}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            fullWidth
            sx={{ width: "100%"}}
            label="Close Date (MM-DD-YYYY)"
            value={requirement.close_date}
            onChange={(newValue) => {
              const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD') : '';
              setRequirement((prev) => ({
                ...prev,
                close_date: newValue,
              }));
              handleChange('close_date', formattedDate);
            }}
            renderInput={(params) => <TextField {...params}  />}
          />
        </Grid>
        </LocalizationProvider>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="level-of-position-label">Level of Position</InputLabel>
              <Select
            labelId="level-of-position-label"
            id="level-of-position-select"
            value={requirement.level_of_position}
            label="Level of Position"
            onChange={(event) => {
              const newValue = event.target.value;
              setRequirement((prevRequirement) => ({
                ...prevRequirement,
                level_of_position: newValue,
              }));
              handleChange('level_of_position', newValue); // Call handleChange with the new value
            }}
          >
                <MenuItem value="Executive or senior management">Executive or senior management</MenuItem>
                <MenuItem value="Middle management">Middle management</MenuItem>
                <MenuItem value="First-level management">First-level management</MenuItem>
                <MenuItem value="Intermediate or experienced (senior staff)">Intermediate or experienced (senior staff)</MenuItem>
                <MenuItem value="Entry-level">Entry-level</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="level-of-position-label">Role Type</InputLabel>
              <Select
            labelId="role-label"
            id="role-select"
            value={requirement.role_type}
            label="Role Type"
            onChange={(event) => {
              const newValue = event.target.value;
              setRequirement((prevRequirement) => ({
                ...prevRequirement,
                role_type: newValue,
              }));
              handleChange('role_type', newValue); // Call handleChange with the new value
            }}
          >
                
                <MenuItem value="Full-time">Full-time</MenuItem>
                <MenuItem value="Part-time">Part-time</MenuItem>
                <MenuItem value="Casual">Casual</MenuItem>
                <MenuItem value="Contract">Contract</MenuItem>
                <MenuItem value="Apprenticeship">Apprenticeship</MenuItem>
                <MenuItem value="Traineeship">Traineeship</MenuItem>
                <MenuItem value="Employment on commission">Employment on commission</MenuItem>
                <MenuItem value="Probation">Probation</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        
          <Grid item xs={6}>
            <TextField
              label="Minimum Experience (in years) "
              fullWidth
              type='number'
              value={requirement.total_experience}
              onChange={e => handleChange('total_experience', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Job Brief"
              fullWidth
              multiline
              rows={4}
              value={requirement.job_brief}
              onChange={e => handleChange('job_brief', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Roles and Responsibilities"
              fullWidth
              multiline
              rows={4}
              value={requirement.roles_and_responsibilities}
              onChange={e => handleChange('roles_and_responsibilities', e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Minimum Experience (in years) "
              fullWidth
              type='number'
              value={requirement.total_experience}
              onChange={e => handleChange('total_experience', e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="type-dropdown-label">Education Type</InputLabel>
                <Select
                  fullWidth
                  labelId="type-dropdown-label"
                  id="type-dropdown"
                  value={educationType}
                  name="type_dropdown"
                  label="Education Type"
                  onChange={(e) => {
                    setEducationType(e.target.value);
                    handleChange('education_type', e.target.value) // Call handleChange with the new value
                  }}
                >
                    <MenuItem value="School">School</MenuItem>
                    <MenuItem value="Diploma">Diploma</MenuItem>
                    <MenuItem value="Graduation">Graduation</MenuItem>
                    <MenuItem value="Post-Graduation">Post-Graduation</MenuItem>
                    <MenuItem value="Doctorate">Doctorate (PhD)</MenuItem>
                    <MenuItem value="Professional Certification">Professional Certification</MenuItem>
                    <MenuItem value="Voluntary Certification">Voluntary Certification</MenuItem>
          
                </Select>
              </FormControl>
          </Grid>
          {/* Degree */}
          {educationType !== 'Professional Certification' && educationType !== 'Voluntary Certification' && (
              <Grid item xs={12} md={6} container fullWidth>
                  <FormControl fullWidth>
                    <InputLabel id="degree-dropdown-label">Degree</InputLabel>
                    <Select
                      fullWidth
                      labelId="degree-dropdown-label"
                      id="degree-dropdown"
                      value={degree}
                      name="degree_dropdown"
                      label="Degree"
                      onChange={(e) => {
                        setDegree(e.target.value);
                        handleChange('degree', e.target.value) // Call handleChange with the new value
                      }}
                      disabled={!educationType}
                    >
                      {degreeOptions[educationType] && degreeOptions[educationType].map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Specialization"
              fullWidth
              value={requirement.specialization}
              onChange={e => handleChange('specialization', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Other Skills"
              fullWidth
              value={requirement.other_skills}
              onChange={e => handleChange('other_skills', e.target.value)}
            />
          </Grid>

          <Grid container mt={1} spacing={2} p={0} m={0} xs={12}>
              <Grid item xs={6}>
                <Typography textAlign="center" mx="auto" mt={2}>
                Offered CTC Range (in lakhs per annum) 
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Minimum "
                  fullWidth
                  type="number"
                  value={requirement.offered_ctc_range_min}
                  onChange={e => handleChange('offered_ctc_range_min', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Maximum "
                  fullWidth
                  type="number"
                  value={requirement.offered_ctc_range_max}
                  onChange={e => handleChange('offered_ctc_range_max', e.target.value)}
                />
              </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>Requirement raised on: {formatDate(currentDateTime)}</Typography>
          </Grid>

          <Grid item xs={8} mt={2} mb={6} mx="auto">
            <Button 
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleUpdate}>
                Create Requirement
            </Button>
          </Grid>

    </Grid>
    </Container>
    </React.Fragment>
  );
}

export default MyComponent;
