import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import { Grid, TextField, Button, Container, CircularProgress, Typography } from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import instance from '../../globals/axios';
import { FRONTEND_ADDRESS } from '../../globals/const';

const columns = [
  {
    field: 'iveri_id',
    headerName: 'IVERI ID',
    width: 150,
    renderCell: (params) => <Chip label={params.value} />,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 200,
    renderCell: (params) => <><EmailIcon fontSize="small" style={{ marginRight: 8 }} />{params.value}</>,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    width: 150,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    width: 180,
    renderCell: (params) => <><PhoneIcon fontSize="small" style={{ marginRight: 8 }} />{params.value}</>,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'user_activated',
    headerName: 'Activated',
    width: 120,
    renderCell: (params) => params.value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'login_or_view',
    headerName: 'Action',
    width: 120,
    renderCell: (params) => {
        // Assuming 'user_activated' is a field in your row data indicating the activation status
        if (params.row.user_activated) {
            return (
                <Button 
                    variant="contained" 
                    color="primary" 
                    size="medium"
                    startIcon={<VisibilityIcon />} // Use VisibilityIcon or similar for "view" action
                    onClick={() => window.location.href = `${FRONTEND_ADDRESS}iveri-profile/${params.row.iveri_id}`}
                >
                    View
                </Button>
            );
            } else {
                return (
                    <Button 
                        variant="contained" 
                        color="primary" // Optionally, use a different color for login action
                        size="medium"
                        startIcon={<LoginIcon />}
                        onClick={() => window.location.href = '/login'}
                    >
                        Login
                    </Button>
                );
            }
        },
        headerAlign: 'center',
        align: 'center',
    },
];



const UsersTable = () => {
    const iveri_id = localStorage.getItem('iveri_id');

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true); // New state for loading


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Start loading
                const response = await instance.get(`/recruiter/recruiter-users/get-users/?iveri_id=${iveri_id}`);
                const processedData = response.data.map((item, index) => ({ ...item, id: index }));
                setUsers(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchData();
    }, []);

    return (

        <React.Fragment>
        <NavBar />
    
        <Container sx={{ marginTop: "100px"}}>
                {loading && (
                    <Grid container justifyContent="center" alignItems="center" style={{ height: '60vh' }}>
                        <CircularProgress />
                    </Grid>
                )}
                {!loading && (
                    <Grid container spacing={3} >
                        <Grid xs={10} item mx="auto">
                        <Typography variant='h5' textAlign="center">
                            View Active Users
                        </Typography>
                        </Grid>
                        <Grid mx="auto" item xs={11}>
                            <DataGrid
                                rows={users}
                                columns={columns}
                                pageSize={10}
                                disableSelectionOnClick
                            />
                        </Grid>
                    </Grid>
                )}
            </Container>
      </React.Fragment>
        
    );
};

export default UsersTable;
