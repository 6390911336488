import React, { useState } from "react";
import { Button, Modal, Box, TextField, Typography } from "@mui/material";
import CustomButton from "../Button/CustomButtom";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SocialMedialPopup = ({ onAdd }) => {
  const [open, setOpen] = useState(false);
  const [socialMediaName, setSocialMediaName] = useState("");
  const [socialMediaURL, setSocialMediaURL] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddLink = () => {
    onAdd(socialMediaName, socialMediaURL);
    handleClose();
  };

  return (
    <>
      <CustomButton onClick={handleOpen} fontSize={"14px"}>
        {"+Add New"}
      </CustomButton>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Add Social Media</Typography>
          <TextField
            label="Social Media Name"
            variant="outlined"
            fullWidth
            value={socialMediaName}
            onChange={(e) => setSocialMediaName(e.target.value)}
            sx={{ marginTop: "10px" }}
          />
          <TextField
            label="URL"
            variant="outlined"
            fullWidth
            value={socialMediaURL}
            onChange={(e) => setSocialMediaURL(e.target.value)}
            sx={{ marginTop: "10px" }}
          />
          <CustomButton onClick={handleAddLink}>Add</CustomButton>
        </Box>
      </Modal>
    </>
  );
};

export default SocialMedialPopup;
