import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container, Paper, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RZP_KEY } from "../../globals/const";
import {
  capturePayment,
  createOrder,
  getAvailablePlans,
} from "../../api/subscription";
import iveriLogo from "../../assets/iveri.png"

export default function CreateSubscription() {
  const history = useHistory();
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    fetchPlans();

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fetchPlans = async () => {
    const plans = await getAvailablePlans();
    console.log(plans);
    setPlans(plans);
  };

  const verifyPayment = async (payment_data) => {
    const successful = capturePayment(
      payment_data.razorpay_order_id,
      payment_data.razorpay_payment_id,
      payment_data.razorpay_signature
    );
    if (successful) {
      alert("Payment successful");
      navigate("/my-subscriptions");
    }
  };

  const handleSubscribe = async (plan_code) => {
    const result = await createOrder(plan_code);
    const orderId = result.order_id;

    const options = {
      key: RZP_KEY,
      currency: "INR",
      name: "Iveri subscription",
      description: "Subscribe to Iveri",
      image:
        {iveriLogo},
      order_id: orderId,
      handler: function (response) {
        verifyPayment(response);
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <>
      <Typography title={t("Create Subscription")} />
      <Container maxWidth="xs">
        <div>
          <Grid
            container
            justify="center"
            direction="column"
            spacing={2}
            style={{ marginTop: "10px" }}
          >
            <Grid item>
              <h1>{t("Available Plans")}</h1>
            </Grid>
            {plans.map((plan) => (
              <Grid item key={plan.plan_code}>
                <Paper style={{ padding: "10px" }}>
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="subtitle1">{plan.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {t("Amount")}: ₹{plan.amount}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end">
                      <Button
                        onClick={() => handleSubscribe(plan.plan_code)}
                        hideIcon
                      >
                        {t("Subscribe")}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </>
  );
}