import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link } from "react-router-dom";
import instance from "../../globals/axios";
import CustomButton from "../../components/Button/CustomButtom";
import GenericPopup from "../../components/Popups/VerifyPopup";

const VerifyEmail = () => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [email, setEmail] = useState("shahbaz@asqi.in");

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer(timer - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const sendOtp = () => {
    instance
      .get(`otp/otpmoduleintegrations/send_otp_email/?email=${email}`)
      .then((res) => {
        setPopupMessage("OTP Sent Successfully!");
        setIsPopupOpen(true);
        setTimer(30); 
      })
      .catch((error) => {
        setPopupMessage("Failed to send OTP, please try again");
        setIsPopupOpen(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    instance
      .get(`otp/otpmoduleintegrations/verify_otp/?data=${email}&otp=${otp}`)
      .then((res) => {
        if (res.data.success) {
          setPopupMessage("Email Verified Successfully!");
        } else {
          setPopupMessage("Invalid OTP, please try again");
        }
        setIsPopupOpen(true);
      })
      .catch((error) => {
        setPopupMessage("Error verifying OTP, please try again");
        setIsPopupOpen(true);
      });
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <Box sx={{ mt: 8, mx: "auto", maxWidth: 400, textAlign: "center", margin: "20px" }}>
      <Typography variant="h5" fontWeight="bold">
        Verify Email
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        6 digits code sent to your email.
      </Typography>

      <Box sx={{ height: "65vh" }}>
        <Box sx={{ my: 3 }}>
          <MuiOtpInput length={6} value={otp} onChange={handleOtpChange} />
        </Box>

        <Typography variant="body2">
          Didn’t receive the code?{" "}
          <Button onClick={sendOtp} disabled={timer > 0} sx={{ fontWeight: "bold" }}>
            Send Again
          </Button>
          {timer > 0 && <span>{`00:${timer < 10 ? `0${timer}` : timer}`}</span>}
        </Typography>
      </Box>

      <CustomButton onClick={handleSubmit}>Verify</CustomButton>

      <Typography variant="body2" sx={{ mt: 2 }}>
        Wrong email? <Link to="/sign-up">Change Email</Link>
      </Typography>

      {/* Generic Popup */}
      <GenericPopup
        open={isPopupOpen}
        title="Verification"
        message={popupMessage}
        onClose={handlePopupClose}
      />
    </Box>
  );
};

export default VerifyEmail;
