import React, { useRef, useState, useEffect } from 'react';
import { Box, Grid, Typography, Container, Button } from '@mui/material';
import { RingLoader } from 'react-spinners';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useHistory } from "react-router-dom";
import instance from "../../globals/axios.js";
import { SERVER_BASE_ADDRESS } from "../../globals/const.js";
import ProfileHeader from '../../components/ProfileHeader';
import EducationTimelineResume from "../../components/Timeline/EducationTimlineResume.js";
import ExperienceTimelineResume from '../../components/Timeline/ExperienceTimelineResume.js';
import ReactToPrint from 'react-to-print';

const formatAddress = (address) => {
  if (!address) return '';
  const {
    house_no,
    street,
    landmark,
    area,
    city,
    district,
    state,
    country,
    pincode,
    post_office,
  } = address;
  return `${house_no ? house_no + ', ' : ''}${street ? street + ', ' : ''}${landmark ? 'near ' + landmark + ', ' : ''}${area ? area + ', ' : ''}${post_office ? post_office + ', ' : ''}${district ? district + ', ' : ''}${city ? city + ', ' : ''}${state ? state + ', ' : ''}${country ? country + ' - ' : ''}${pincode || ''}`;
};

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{5})$/);
  if (match) {
    return `(+${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

export default function ResumePreview() {
  const [isLoading, setIsLoading] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [basicInfoDetails, setBasicInfoDetails] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    adhaar_number: "",
    pan_number: "",
    birth_date: "",
    linkedin: "",
    github: "",
    facebook: "",
    behance: "",
    other: "",
    gender: "",
    relationship_status: "",
    profile_picture: ""
  });
  const [personalDetails, setPersonalDetails] = useState({});
  const [tempAddressDetails, setTempAddressDetails] = useState(null);
  const [skills, setSkills] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [isPaidUser, setIsPaidUser] = useState(false);
  const [experiences, setExperiences] = useState([]);
  
  const componentRef = useRef();
  const history = useHistory();

  useEffect(() => {
    // Fetch basic info
    instance.get("basic-info/")
      .then((response) => setBasicInfoDetails(response.data))
      .catch((error) => console.error("Error fetching basic info:", error));

    // Fetch profile data
    instance.get(SERVER_BASE_ADDRESS + "view-profile/")
      .then((res) => setPersonalDetails(res.data.personal_details))
      .catch((error) => console.error("Error fetching profile data:", error));

    // Fetch address data
    instance.get("update-temp-address/")
      .then((response) => setTempAddressDetails(response.data))
      .catch((error) => console.error("Error fetching address data:", error));

    // Fetch skills
    instance.get('edit-skills/')
      .then(response => setSkills(response.data))
      .catch(error => console.error('Error fetching skills:', error));

    // Fetch language data
    instance.get('language-data/')
      .then(response => setLanguageData(response.data))
      .catch(error => console.error('Error fetching languages:', error));

    // Check paid user status
    const paidStatus = localStorage.getItem("is_paid_user") === "true";
    setIsPaidUser(paidStatus);
  }, []);

  const handlePreview = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowResume(true);
    }, 3000);
  };

  const handleClick = () => {
    if (!isPaidUser) {
      const currentPath = window.location.pathname;
      history.push('/payment-page', { from: currentPath });
    } else {
      downloadPDF();
    }
  };

  const downloadPDF = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(`${personalDetails.name || "Resume"}.pdf`);
    });
  };

  const fetchExperience = async () => {
    try {
      const response = await instance.get('experience-details/');
      console.log('API Response:', response.data); // Log the entire response
  
      // Access the array directly if response.data is an array
      const experiences = response.data;
      console.log('Experiences:', experiences);
  
      // Sort experiences by end_date in descending order to get the most recent one first
      const sortedExperiences = experiences.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
      setExperiences(sortedExperiences);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  // Use useEffect to call fetchExperience on component mount
  useEffect(() => {
    fetchExperience();
  }, []);


  const pageStyle = `
    @page {
      size: auto;
      margin: 10mm;
    }

    @media print {
      body {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
      }
      .resume-container {
        background-color: rgb(38, 34, 98, 0.92) !important;
        color: white;
      }
    }
  `;

  return (
    <Container maxWidth="xs" style={{ padding: 0, margin: 0, width: '100vw' }}>
      {isLoading && (
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          width: '100vw', 
          height: '100vh', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          backgroundColor: 'rgba(255, 255, 255, 0.7)', 
          zIndex: 9999 
        }}>
          <RingLoader color="#123abc" loading={isLoading} size={150} />
        </div>
      )}

      {showResume && (
        <>
          <Grid container style={{ margin: 0, width: '100vw' }} ref={componentRef}>
            <Grid item xs={5} style={{ backgroundColor: 'rgb(38, 34, 98, 0.92)', color: 'white', padding: '27px 0px 20px 20px' }} className="resume-container">
              <div style={{marginRight: "20px"}}>
                <ProfileHeader 
                  showUploadIcon={false}
                  showName={false}
                  showTitle={false}
                  showBackButton={false}
                />
              </div>
              <Box mt={3.5}>
                <Typography sx={{fontSize: "18px", fontWeight: "700", color: "white", borderBottom: "2px white solid", pb: "2px" }}>Contact</Typography>
                <Typography sx={{color: "white", fontSize: "14px", fontWeight: "600", paddingRight: "10px", pt: 2}}>Phone Number</Typography>
                <Typography sx={{color: "white", fontSize: "12px", paddingRight: "10px"}}>{formatPhoneNumber(basicInfoDetails.mobile_number)}</Typography>
                <Typography sx={{color: "white", fontSize: "14px", fontWeight: "600", paddingRight: "10px", pt: 2}}>Email ID</Typography>
                <Typography sx={{color: "white", fontSize: "12px", paddingRight: "10px"}}>{basicInfoDetails.email}</Typography>
                <Typography sx={{color: "white", fontSize: "14px", fontWeight: "600", paddingRight: "10px", pt: 2}}>Address</Typography>
                <Typography sx={{color: "white", fontSize: "12px", paddingRight: "10px"}}>{formatAddress(tempAddressDetails)}</Typography>
              </Box>
              <Box mt={2}>
                <Typography sx={{fontSize: "18px", fontWeight: "700", color: "white", borderBottom: "2px white solid", pb: "2px" }}>Language</Typography>
                <Box>
                {languageData.map((language, index) => (
                  <Box key={index} display="flex" alignItems="flex-start" py={1} >
                    <Typography sx={{ flex: '1 1 30%' }}>
                      {language.languageName}
                    </Typography>
                
                    <Box sx={{ flex: '2 1 70%', display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ fontSize: "10px" }}>{`Read - ${language.readingProficiency}`}</Typography>
                      <Typography sx={{ fontSize: "10px" }}>{`Write - ${language.writingProficiency}`}</Typography>
                      <Typography sx={{ fontSize: "10px" }}>{`Speak - ${language.speakingProficiency}`}</Typography>
                    </Box>
                  </Box>
                ))}
                </Box>
              </Box>

              <Box mt={2}>
                <Typography sx={{fontSize: "18px", fontWeight: "700", color: "white", borderBottom: "2px white solid", pb: "2px" }}>Skills</Typography>
                {skills.map(skill => (
                  <Typography
                    key={skill.id} 
                    display="flex" 
                    alignItems="center" 
                    justifyContent="space-between" 
                    py={2}
                  >
                    {skill.skill} 
                  </Typography>
                ))}
              </Box>
            </Grid>

            <Grid item xs={7} style={{ padding: '10px' }}>
              <Typography variant="h4" color="#414042">{personalDetails.name || ""}</Typography>
              <Typography variant="subtitle1" color="#414042">
  {experiences && experiences.length > 0 && experiences[0].designation
    ? experiences[0].designation
    : "No designation available"}
</Typography>

<Typography style={{ fontSize: "8px", marginTop: '2px', color: "#414042" }}>
  {experiences && experiences.length > 0 && experiences[0].profile_title
    ? experiences[0].profile_title
    : "No profile title available"}
</Typography>


              <Box mt={4}>
                <Typography sx={{fontSize: "14px", fontWeight: "600", color: "#262262", borderBottom: "2px #262262 solid", pb: "2px" }}>Education</Typography>
                <EducationTimelineResume />
              </Box>

              <Box mt={4}>
                <Typography sx={{fontSize: "14px", fontWeight: "600", color: "#262262", borderBottom: "2px #262262 solid", pb: "2px" }}>Experience</Typography>
                <ExperienceTimelineResume />
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }} onClick={handleClick}>
            <ReactToPrint
              trigger={() => <Button variant="contained" color="primary" disabled={!isPaidUser}>Download PDF</Button>}
              content={() => componentRef.current}
              documentTitle={`${personalDetails.name || "Resume"}`}
              pageStyle={pageStyle}
            />
          </Box>
        </>
      )}

      {!showResume && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <Button variant="contained" color="primary" onClick={handlePreview}>
            Show Resume Preview
          </Button>
        </Box>
      )}
    </Container>
  );
}