import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Grid, Card, CardContent, Typography, Container, Button, Box, Chip, IconButton } from '@mui/material';
import { OpenInNew, LocationOn, MonetizationOn, Event, Description, AccountCircle, Business, AccountBalance, Person, EventNote } from '@mui/icons-material';
import instance from '../../globals/axios';
import NavBar from '../../components/NavBar';
import { Link } from 'react-router-dom';


function ExperienceCards() {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    instance.get('experience-details/')
      .then(response => {
        setExperiences(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // **Exact same code taken from Education details due to time constraint**
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedEducationId, setSelectedEducationId] = useState(null);

  const handleDeleteClick = (educationId) => {
    setSelectedEducationId(educationId);
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationYes = () => {
    instance.post('delete-entry/', {
      entry_type: 'experience',
      entry_id: selectedEducationId,
    })
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setConfirmationOpen(false);
      });
  };

  return (

    <>
  
    <NavBar />

    <Container maxWidth="xs" sx={{ p:2, marginTop: "3rem" }} >

    <Typography variant="h5" sx={{ my:2 }} textAlign="center">
    My Experience Details
    </Typography>

    <Grid container spacing={2}>
      {experiences.map(experience => (
        <Grid item xs={12} md={6} key={experience.id}>
          <Card variant="outlined" sx={{ backgroundColor: '#f5f5f5', padding: '8px', marginBottom: '8px' }}>
            <CardContent>
           
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <Business sx={{ marginRight: '4px' }} />
                Company: {experience.company || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <Person sx={{ marginRight: '4px' }} />
                Designation: {experience.designation || 'N.A.'}
              </Typography>
              {experience.website && (
                <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>

                  <Link href={experience.website} target="_blank">
                    <OpenInNew sx={{ marginRight: '4px' }} />
                    Company website
                  </Link>

                </Typography>
                )
            }
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <Event sx={{ marginRight: '4px' }} />
                Start Date: {experience.start_date ? new Date(experience.start_date).toLocaleDateString() : 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <Event sx={{ marginRight: '4px' }} />
                End Date: {experience.end_date ? new Date(experience.end_date).toLocaleDateString() : 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <MonetizationOn sx={{ marginRight: '4px' }} />
                Fixed Salary: {experience.fixed_salary_currency || 'N.A.'} {experience.fixed_salary_amount || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <MonetizationOn sx={{ marginRight: '4px' }} />
                Variable Salary: {experience.variable_salary_currency || 'N.A.'} {experience.variable_salary_amount || 'N.A.'}
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <AccountCircle sx={{ marginRight: '4px' }} />
                Reporting To: {experience.reporting_to || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                <AccountCircle sx={{ marginRight: '4px' }} />
                Reportee: {experience.reportee || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
              <AccountCircle sx={{ marginRight: '4px' }} />  
              Role: {experience.role || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
              <LocationOn sx={{ marginRight: '4px' }} /> 
              Work Location: {experience.work_location || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Office Type: {experience.office_type || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Notice Period: {experience.notice_period || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Days Negotiable: {experience.days_negotiable || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Accommodation: {experience.accommodation || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Accommodation Furniture: {experience.accommodation_furniture || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Accommodation Family: {experience.accommodation_family || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Perks: {experience.perks || 'N.A.'}
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Currency: {experience.currency || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Expected Salary: {experience.expected_salary || 'N.A.'}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                Brief Role: {experience.brief_role || 'N.A.'}
              </Typography>

              <Box sx={{ my:2}}>

              {experience.uploaded_files && experience.uploaded_files.length > 0 && (
                  experience.uploaded_files.map((file, index) => (
                    <Box sx={{ my:1}} key={index}>
                      <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                        File: {file.file_name}
                      </Typography>
                      
                      <Button
                        fullWidth
                        onClick={() => window.open(file.file, '_blank')}
                        variant="outlined"
                        startIcon={<OpenInNew />}
                      >
                        View Document
                      </Button>
                    </Box>
                  ))
                )}
              </Box>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Button
                    onClick={() => handleDeleteClick(experience.id)}
                    variant="outlined"
                    color="error"
                    fullWidth
                    startIcon={<DeleteOutlineIcon />}
                    >
                    Delete
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                    component={Link}
                    to={`/update-experience-details/${experience.id}`}
                    variant="contained"
                    fullWidth
                    startIcon={<EditNoteIcon />}
                    >
                    Edit
                    </Button>
                </Grid>
               
                </Grid>

                   



                    <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
                        <DialogTitle>Delete Confirmation</DialogTitle>
                        <DialogContent>
                        <Typography>Are you sure you want to delete this experience detail?</Typography>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleConfirmationClose} color="primary">
                            No
                        </Button>
                        <Button onClick={handleConfirmationYes} color="primary">
                            Yes
                        </Button>
                        </DialogActions>
                    </Dialog>

        
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  
    </Container>
    </>
  );
}

export default ExperienceCards;
