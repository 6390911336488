import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you have axios installed and set up
import { Grid, TextField, Button, Container, Box, Typography } from '@mui/material';
import instance from '../globals/axios';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';  
// import ViewReqByReqId from '../../../components/ViewReqByReqId';




const RequirementDetails = ({ setReqId, setRequirementId}) => {
    const [requirement, setRequirement] = useState(null);
    const [updatedRequirement, setUpdatedRequirement] = useState({});
    const company_id = localStorage.getItem('company_id');

    const iveriId = window.localStorage.getItem("iveri_id");
    const { req_id } = useParams();
   

    
    useEffect(() => {
        const handleGetRequirement = () => {
            instance.get(`company/get_requirement_by_req_id/?requirement_id=${req_id}`)
            .then(response => {
                setRequirement(response.data);
                setReqId(response.data.id)
                setRequirementId(response.data.requirement_id)
            })
            .catch(error => {
                console.error('Error fetching requirement data:', error);
            });
        };
        handleGetRequirement();

    }, [])

    // setReqId(requirement.id)
    
    const handleChange = (field, value) => {
        setUpdatedRequirement(prevState => ({
        ...prevState,
        [field]: value,
        }));
    };



    return (
        <React.Fragment>

        <Container maxWidth="md" my={1}>
        <Grid container fullWidth>
            <Grid item xs={2}>
                <Button onClick={() => window.history.back()} fullWidth startIcon={<ArrowBackIcon />}>
                    Back
                </Button>
            </Grid>
            <Grid item xs={10} textAlign="center">
                <Typography variant='h5'>
                    View Job Description
                </Typography>
            </Grid>
        </Grid>
        
        {
        requirement && (

            <Grid container pt={4} px={2} maxWidth="md" spacing={2} mb={6}>

           

            <Grid item xs={12}>
            <TextField
            disabled
            label="Profile Title"
            fullWidth
            value={requirement.profile_title}
            onChange={e => handleChange('profile_title', e.target.value)}
            />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Designation"
                fullWidth
                value={requirement.designation}
                onChange={e => handleChange('designation', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Requirement Raised On"
                fullWidth
                value={new Date(requirement.requirement_raised_on).toLocaleString()}
                InputProps={{
                    readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Requirement Status"
                fullWidth
                value={requirement.requirement_status}
                InputProps={{
                    readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Requirement ID"
                fullWidth
                value={requirement.requirement_id}
                InputProps={{
                    readOnly: true,
                }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Number of Positions"
                fullWidth
                value={requirement.number_of_positions}
                onChange={e => handleChange('number_of_positions', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Job Location"
                fullWidth
                value={requirement.job_location}
                onChange={e => handleChange('job_location', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Start Date"
                fullWidth
                value={requirement.start_date}
                onChange={e => handleChange('start_date', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Close Date"
                fullWidth
                value={requirement.close_date}
                onChange={e => handleChange('close_date', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Level of Position"
                fullWidth
                value={requirement.level_of_position}
                onChange={e => handleChange('level_of_position', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Job Brief"
                fullWidth
                value={requirement.job_brief}
                onChange={e => handleChange('job_brief', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Roles and Responsibilities"
                fullWidth
                value={requirement.roles_and_responsibilities}
                onChange={e => handleChange('roles_and_responsibilities', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Reporting To"
                fullWidth
                value={requirement.reporting_to}
                onChange={e => handleChange('reporting_to', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Reportees"
                fullWidth
                value={requirement.reportees}
                onChange={e => handleChange('reportees', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Role Type"
                fullWidth
                value={requirement.role_type}
                onChange={e => handleChange('role_type', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Internal Interface"
                fullWidth
                value={requirement.internal_interface}
                onChange={e => handleChange('internal_interface', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="External Interface"
                fullWidth
                value={requirement.external_interface}
                onChange={e => handleChange('external_interface', e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                disabled
                label="Total Experience (in years) "
                fullWidth
                value={requirement.total_experience}
                onChange={e => handleChange('total_experience', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Functional Competency"
                fullWidth
                value={requirement.functional_competency}
                onChange={e => handleChange('functional_competency', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Functional Proficiency Level"
                fullWidth
                value={requirement.functional_proficiency_level}
                onChange={e => handleChange('functional_proficiency_level', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Behavioural Competency"
                fullWidth
                value={requirement.behavioural_competency}
                onChange={e => handleChange('behavioural_competency', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Behavioural Proficiency Level"
                fullWidth
                value={requirement.behavioural_proficiency_level}
                onChange={e => handleChange('behavioural_proficiency_level', e.target.value)}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                disabled
                label="KRA"
                fullWidth
                value={requirement.KRA}
                onChange={e => handleChange('KRA', e.target.value)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                disabled
                label="KRA Weightage"
                fullWidth
                value={requirement.KRA_weightage}
                onChange={e => handleChange('KRA_weightage', e.target.value)}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                disabled
                label="KPI"
                fullWidth
                value={requirement.KPI}
                onChange={e => handleChange('KPI', e.target.value)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                disabled
                label="KPI Weightage"
                fullWidth
                value={requirement.KPI_weightage}
                onChange={e => handleChange('KPI_weightage', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Offered CTC Range (in lakhs)"
                fullWidth
                value={requirement.offered_ctc_range}
                onChange={e => handleChange('offered_ctc_range', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Other Skills"
                fullWidth
                value={requirement.other_skills}
                onChange={e => handleChange('other_skills', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Education Type"
                fullWidth
                value={requirement.education_type}
                onChange={e => handleChange('education_type', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Degree"
                fullWidth
                value={requirement.degree}
                onChange={e => handleChange('degree', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                disabled
                label="Specialization"
                fullWidth
                value={requirement.specialization}
                onChange={e => handleChange('specialization', e.target.value)}
                />
            </Grid>
            
            {/* <Grid item xs={8} mb={4} mx="auto">
                    <Button 
                        fullWidth
                        color="primary"
                        variant="contained"
                        // onClick={handlePublish}
                        >
                        Publish
                    </Button>
            </Grid> */}
            
            
            </Grid>

        )
        }
    
        </Container>

        </React.Fragment>
    );
};

export default RequirementDetails;
