/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { getActiveSubscription } from "../../api/subscription";

const SubscriptionTable = ({ subscription }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>Plan Name</Typography>
            </TableCell>
            <TableCell>
              <Typography>{subscription.plan_name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Amount</Typography>
            </TableCell>
            <TableCell>
              <Typography>{subscription.amount}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Start Time</Typography>
            </TableCell>
            <TableCell>
              <Typography>{subscription.start_time}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>End Time</Typography>
            </TableCell>
            <TableCell>
              <Typography>{subscription.end_time}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function MySubscriptions() {
  const history = useHistory();
  const { t } = useTranslation();
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    getActiveSubscription().then((response) => {
      setSubscription(response);
    });
  }, []);

  return (
    <>
      <h1>Create Program</h1>
      <Container maxWidth="xs">
        <div>
          <Grid
            container
            justify="center"
            direction="column"
            style={{ marginTop: "10px" }}
          >
            <h2>{t("Active Subscription")}</h2>

            {subscription ? (
              <SubscriptionTable subscription={subscription} />
            ) : (
              <h2>{t("No Active Subscription")}</h2>
            )}

            <Button onClick={() => navigate(-1)} hideIcon>
              {t("Back")}
            </Button>
          </Grid>
        </div>
      </Container>
    </>
  );
}