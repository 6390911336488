import { SERVER_BASE_ADDRESS } from "./const";

export const PROGRAMS_ENDPOINT = "/programs/programs/";
export const PROGRAM_INPUTS_ENDPOINT = "/programs/programinputs/";
export const PROGRAM_INPUT_TASKS_ENDPOINT = "/programs/programtasks/";
export const UPLOAD_PROGRAM_ENDPOINT = "/programs/upload-program/";
export const DOWNLOAD_PROGRAM_TEMPLATE_ENDPOINT =
  "/programs/download-program-template-csv/";
export const FARMS_ENDPONIT = "/farmer/farms/";
export const GET_USER_DETAILS_ENDPOINT = `${SERVER_BASE_ADDRESS}/auth/user-details/`;
export const SET_USER_MESSAGING_TOKEN = `${SERVER_BASE_ADDRESS}/notifications/set-user-messaging-token/`;
export const MARKET_PRICES_ENDPOINT = "https://commodity-price.asqi.in/";
export const USER_LOCATION_ENDPOINT =
  "https://nominatim.openstreetmap.org/reverse";
export const SUBSCRIPTION_CREATE_ORDER = "/payments/create-order/";
export const SUBSCRIPTION_CAPTURE_PAYMENT = "/payments/capture-payment/";
export const SUBSCRIPTION_GET_ACTIVE = "/payments/get-active-subscription/";
export const SUBSCRIPTION_GET_PLANS = "/payments/get-available-plans/";